import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import PlanFlatFee from 'components/Subscription/PlanFlatFee';
import PlanStairStep, { StairStepProduct } from 'components/Subscription/PlanStairStep';
import { useOrganization } from 'context/OrganizationContext';
import {
  ApiError,
  CancelablePromise,
  CatalogueProduct,
  ModuleEnum,
  OrdersService,
  Plan,
  PricingModelEnum,
  PublicService,
  PurchaserOrderDetail,
  PurchaserOrderItem,
  PurchaserServiceContract,
  TimeFrameEnum,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { EQUINEM_PUBLIC_ORG_UID } from 'ui/Const';
import { ErrorSection } from 'ui/Error';
import { Hint } from 'ui/Hint';
import { InputError } from 'ui/InputError';
import { Label } from 'ui/Label';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { z } from 'zod';
import { AppRoutes } from 'AppRoutes';
import { SubscriptionState } from 'components/Subscription/Helper';
import SubscriptionTable from './CurrentSubscriptionTile/SaveSubscriptionModal/SubscriptionTable';

interface FormModel {
  agreedToTermsAndPrivacy: boolean;
  moduleProducts?: CatalogueProduct[];
  userProduct?: StairStepProduct;
}

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSuccess: (hash: SubscriptionState) => void;
  currentHorseCareServiceContract: PurchaserServiceContract | undefined;
  currentHorseFullServiceContract: PurchaserServiceContract | undefined;
  products: CatalogueProduct[] | undefined;
  plans: Plan[] | undefined;
}

function StartPaidSubscriptionModal({
  isVisible,
  onRequestCloseModal,
  onSuccess,
  currentHorseCareServiceContract,
  currentHorseFullServiceContract,
  products,
  plans,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [purchaserOrderDetail, setPurchaserOrderDetail] = useState<PurchaserOrderDetail>();

  const { t } = useTranslation();
  const { selectedOrganizationUid, refresh, selectedOrganizationPrimaryStableLocation } = useOrganization();

  const schema = useMemo(() => {
    return z.object({
      userProduct: z.object({
        pricePoint: z.object({
          max_units: z.number().nullable(),
        }),
        product: z.object({
          uid: z.string(),
        }),
      }),
    });
  }, []);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    control,
    reset,
  } = useForm<FormModel>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const userProductFormValue = watch('userProduct');
  const moduleProductsFormValue = watch('moduleProducts');

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  // get the user account plan
  const userAccountPlan = plans?.find(
    plan => plan.pricing_model === PricingModelEnum.STAIR_STEP && plan.module === ModuleEnum.USER_ACCOUNTS,
  );

  /**
   * Order products via the order purchased endpoint
   * We do this only for the modules as the user account is already included in the service_contract
   */
  const orderProducts = useCallback(
    (dryRun: boolean) => {
      if (!selectedOrganizationUid || !products || !selectedOrganizationPrimaryStableLocation) return;

      // clear previous errors
      setApiError(undefined);

      let orderedProducts: PurchaserOrderItem[] = [];

      // include the horse care and full service contracts as well
      // in a later stadium we could add a button where the user could add/remove these
      if (currentHorseCareServiceContract) {
        const product = products.find(p => p.uid === currentHorseCareServiceContract.product_uid);
        if (product) {
          orderedProducts.push({
            product_uid: product.uid,
            invoice_period: TimeFrameEnum.MONTH,
            price_point_unit_count: null,
          } as PurchaserOrderItem);
        }
      }
      if (currentHorseFullServiceContract) {
        const product = products.find(p => p.uid === currentHorseFullServiceContract.product_uid);
        if (product) {
          orderedProducts.push({
            product_uid: product.uid,
            invoice_period: TimeFrameEnum.MONTH,
            price_point_unit_count: null,
          } as PurchaserOrderItem);
        }
      }

      if (moduleProductsFormValue) {
        orderedProducts = [
          ...orderedProducts,
          ...moduleProductsFormValue.map(
            product => ({ product_uid: product.uid, quantity: 1, invoice_period: TimeFrameEnum.MONTH }) as PurchaserOrderItem,
          ),
        ];
      }

      if (userProductFormValue) {
        orderedProducts.push({
          product_uid: userProductFormValue.product.uid,
          price_point_unit_count: userProductFormValue.pricePoint.max_units,
          invoice_period: TimeFrameEnum.MONTH,
        } as PurchaserOrderItem);
      }

      // no items, bail out
      if (orderedProducts.length === 0) {
        return;
      }

      const promise = OrdersService.ordersPurchasedCreate({
        purchaserUid: selectedOrganizationUid,
        requestBody: {
          customer_uid: selectedOrganizationPrimaryStableLocation.uid,
          supplier: EQUINEM_PUBLIC_ORG_UID,
          dry_run: dryRun,
          order_items: orderedProducts,
          terms_accepted: true,
        } as PurchaserOrderDetail,
      });

      return promise;
    },
    [
      selectedOrganizationUid,
      products,
      selectedOrganizationPrimaryStableLocation,
      setApiError,
      currentHorseCareServiceContract,
      currentHorseFullServiceContract,
      moduleProductsFormValue,
      userProductFormValue,
    ],
  );

  /**
   * Run a dry run of the order
   */
  const dryRun = useCallback((): CancelablePromise<PurchaserOrderDetail> | undefined => {
    const promise = orderProducts(true);

    if (!promise) return;

    setLoading(true);

    promise
      .then(result => setPurchaserOrderDetail(result))
      .catch(error => {
        if (error instanceof ApiError) {
          setApiError(new ApiErrorParser<PurchaserOrderDetail>(error));
        } else {
          console.error(error);
        }
      })
      .finally(() => setLoading(false));

    return promise;
  }, [orderProducts, setApiError]);

  /**
   * Close event handler, reset the form and clear the api error
   */
  const onClosed = () => {
    reset();
    setApiError(undefined);
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<FormModel> = async () => {
    // call in the order endpoint so we can add newly modules
    const orderDetail = await orderProducts(false);
    if (orderDetail) {
      try {
        const url = new URL(`${AppRoutes.Subscription.path}#${SubscriptionState.SUBSCRIPTION_CREATED_PAID}`, window.location.href);
        const paymentLink = await PublicService.apiV5OrdersMolliePaymentLinkCreate({
          publicAccessUuid: orderDetail.public_access_uuid ?? '',
          requestBody: {
            redirectUrl: url.toString(),
          },
        });
        window.location.href = paymentLink.redirect_uri;
      } catch (e) {
        console.info('Skipping payment due to an error', e);
      }
    }

    // refresh the organization
    refresh();

    // and close the modal if all went well
    onRequestCloseModal();

    // fire the success handler
    onSuccess(SubscriptionState.SUBSCRIPTION_CREATED_PAID);
  };

  /**
   * We do a dry run when the modal is opened and if the flat fee products are changed
   */
  useEffect(() => {
    if (isVisible && (!!userProductFormValue || !!moduleProductsFormValue)) {
      const promise = dryRun();
      return () => promise?.cancel();
    }
  }, [dryRun, isVisible, moduleProductsFormValue, userProductFormValue]);

  return (
    <PageModal
      open={isVisible}
      onClosed={onClosed}
      parentElement='form'
      parentProps={{ id: 'endTrialForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle title={t('continue-equinem-paid', 'Continue Equinem with paid services')} onClose={onRequestCloseModal} />
      <PageModalContent>
        <ErrorSection className='my-4' errors={nonFieldErrors} />

        <div className='space-y-4'>
          <p>
            {t(
              'trial-ended-info-text-subscription',
              'Great new that you have decided to go for a paid subscription. Please select the number of user accounts and any additional modules you would like to use. You can always change this later.',
            )}
          </p>
          <p className='mt-0.5 mb-2'>
            {t(
              'trial-ended-info-text-horse-activities',
              'We also enable the Horse activities for you so you can start using the activities for you horses.',
            )}
          </p>

          <>
            {userAccountPlan && products && (
              <div>
                <Label>{t('user-accounts', 'User accounts')}</Label>
                <Hint>{t('user-accounts-info-text-subscription', 'How many people should be able to login to your organisation?')}</Hint>
                <Controller
                  name='userProduct'
                  control={control}
                  render={({ field }) => (
                    <PlanStairStep
                      className='mt-4'
                      plan={userAccountPlan}
                      products={products}
                      onClick={(selected, pricePoint) => field.onChange({ product: selected, pricePoint })}
                    />
                  )}
                />
                <InputError>{fieldError('userProduct')}</InputError>
              </div>
            )}

            {plans && products && (
              <div>
                <Label>{t('additonal-modules', 'Additional modules')}</Label>
                <Hint>{t('additional-modules-info-text-subscription', 'What other functionality would you like to use?')}</Hint>
                <Controller
                  name='moduleProducts'
                  control={control}
                  render={({ field }) => <PlanFlatFee onClick={selected => field.onChange(selected)} plans={plans} products={products} />}
                />
              </div>
            )}
          </>
        </div>

        <SubscriptionTable
          plans={plans}
          purchaserOrderDetail={purchaserOrderDetail}
          selectedUserProduct={userProductFormValue}
          selectedModuleProducts={moduleProductsFormValue}
          loading={loading}
          currentModuleServiceContracts={undefined}
          currentUserServiceContract={undefined}
          currentHorseCareServiceContract={currentHorseCareServiceContract}
          currentHorseFullServiceContract={currentHorseFullServiceContract}
        />
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            variant: ButtonVariant.Primary,
            type: 'submit',
            loading: isSubmitting,
            text: t('continue-with-paid-subscription', 'Continue with a paid subscription'),
            formId: 'endTrialForm',
          },
        ]}
      />
    </PageModal>
  );
}

export default StartPaidSubscriptionModal;
