import React, { useState } from 'react';
import { Tile } from 'ui/Layout/Tile';
import { useTranslation } from 'react-i18next';
import { CatalogueProduct, CountryEnum, Plan, PricePoint, PurchaserServiceContract } from 'openapi';
import Badge from 'ui/Badge';
import { useOrganization } from 'context/OrganizationContext';
import PlanCredits from '../PlanCredits';
import useModal from 'ui/Modals/UseModal';
import CheckoutRvoCreditsModal from './CheckoutRvoCreditsModal';

interface Props {
  rvoCreditsPricePlan: Plan | undefined;
  products: CatalogueProduct[] | undefined;
  currentRvoServiceContract: PurchaserServiceContract | undefined;
}

function RvoCreditsTile({ rvoCreditsPricePlan, products, currentRvoServiceContract }: Props): JSX.Element {
  const [selectedRvoProduct, setSelectedRvoProduct] = useState<{ product: CatalogueProduct; pricePoint: PricePoint }>();

  const { t } = useTranslation();
  const { selectedOrganizationPrimaryStableLocation } = useOrganization();
  const { closeModal: closeCheckoutModal, modalIsVisible: checkoutModalIsVisible, showModal: showCheckoutModal } = useModal();

  const availableRvoCredits = currentRvoServiceContract?.available_unit_count ?? 0;

  if (selectedOrganizationPrimaryStableLocation?.country !== CountryEnum.NL) {
    return <></>;
  }

  return (
    <Tile
      title={
        <div className='flex gap-x-2 items-center'>
          {t('rvo-credits', 'RvO Credits')}{' '}
          <Badge>
            {availableRvoCredits} {t('available', 'available')}
          </Badge>
        </div>
      }
    >
      {rvoCreditsPricePlan && products && (
        <PlanCredits
          onClick={(product, pricePoint) => {
            setSelectedRvoProduct({ product, pricePoint });
            showCheckoutModal();
          }}
          plans={[rvoCreditsPricePlan]}
          products={products}
        />
      )}
      <p className='mt-2'>{t('subscription-need-more-rvo-credits', 'Need more credits? Select the amount you would like to buy.')}</p>
      <p className='mt-4 text-sm italic text-gray-500'>
        {t('subscription-need-more-rvo-credits-desc', 'RvO credits are needed when you need to report your location change to the RvO')}
      </p>

      {selectedRvoProduct && (
        <CheckoutRvoCreditsModal product={selectedRvoProduct} isVisible={checkoutModalIsVisible} onRequestCloseModal={closeCheckoutModal} />
      )}
    </Tile>
  );
}

export default RvoCreditsTile;
