import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CancelablePromise, Contact, HorsesService, LocationResponse, StateEnum } from 'openapi';
import { useOrganization } from 'context/OrganizationContext';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, NavLink } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';
import { navBackToThisPage } from 'ui/Layout/Page';
import useModal from 'ui/Modals/UseModal';
import RvoImportHorseLocationModal from 'components/Integrations/Rvo/RvoImportHorseLocationModal';
import { Check } from '@phosphor-icons/react';
import { contactName } from 'utilities/Contact';
import ApiErrorParser from 'api/ApiErrorParser';

interface Props {
  contacts: Contact[] | undefined;
}

interface ReturnType {
  isImporting: boolean;
  previousImportMessages: ReactNode[] | undefined;
  importModalElement: ReactNode;
  showImportModal: () => void;
}

/**
 * Small hook that help use with the RVO import status that is now loaded from an Background Task
 */
const useRvoImportStatus = ({ contacts }: Props): ReturnType => {
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [previousImportMessages, setPreviousImportMessages] = useState<ReactNode[]>();

  const { closeModal: closeRvoImportModal, modalIsVisible: rvoImportModalIsVisible, showModal: showRvoImportModal } = useModal();
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  /**
   * List the locations
   */
  const locations = useMemo(() => {
    return (contacts ?? []).filter(
      contact => (contact.external_location && contact.stable_location_uid === null) || contact.stable_location_uid !== null,
    );
  }, [contacts]);

  const formatErrorMessage = useCallback(
    (result: LocationResponse) => {
      if (result.background_task?.state === 'FAILURE' && !result.get_horses_from_rvo_exception) {
        return result.background_task.result;
      }

      // check if we have an error from the previous import
      if (result.background_task?.state === 'FAILURE' && result.get_horses_from_rvo_exception) {
        const location = locations.find(location => location.uid === result.location_uid);

        // in case we hit an error where we found horses without a chipnr or UELN
        let hasHorsesWOChipNr = <></>;
        if (
          result.get_horses_from_rvo_exception.unprocessable_request?.horses_wo_chipnr &&
          result.get_horses_from_rvo_exception.unprocessable_request.horses_wo_chipnr.length > 0
        ) {
          hasHorsesWOChipNr = (
            <Trans
              i18nKey='rvo-import-horses-wo-chipnr'
              defaults='Click <0>here</0> to see which horses are missing a UELN or chip number.'
              components={[
                <NavLink
                  key='horses_wo_chipnr_link'
                  className='underline'
                  to={{
                    pathname: generatePath(AppRoutes.HorsesList.path),
                    search: `noUELNOrChipNr=yes&${navBackToThisPage().toString()}`,
                  }}
                >
                  here
                </NavLink>,
              ]}
            />
          );
        }

        return (
          <p className='text-red-500' key={result.location_uid}>
            <strong>
              {t('rvo-import-for-location-failed-due-to', 'Import for {{location}} failed due to', {
                location: location ? contactName(location) : '',
              })}
              {': '}
            </strong>{' '}
            {result.get_horses_from_rvo_exception.message} {hasHorsesWOChipNr}
          </p>
        );
      }
    },
    [locations, t],
  );

  /**
   * Get the curent status of the import
   */
  const loadStatus = useCallback((): CancelablePromise<LocationResponse[]> | undefined => {
    // only load the status if we have at least 1 location that is connect to the RVO
    if (!locations.some(loc => loc.machtiging_rvo_gegeven !== null)) {
      return;
    }

    // Small note here, a background task that has been run will live for 60 min. So for the next 60 min we can check the status of the import
    const promise = HorsesService.horsesGetHorsesFromRvoList({
      organisationUid: selectedOrganization?.uid ?? '',
    });

    // Possible results
    //   [{
    //     "location_uid": "COcWbWQ",
    //     "background_task": null,
    //     "get_horses_from_rvo_exception": null
    //   }]
    //   [{
    //     "location_uid": "COcWbWQ",
    //     "background_task": {
    //         "task_id": "ccc8eee2-64b2-4df1-a477-023491768bb2",
    //         "state": "PENDING",
    //         "result": "None",
    //         "progress": {
    //             "percent": 0.0,
    //             "description": "Pending"
    //         }
    //     },
    //     "get_horses_from_rvo_exception": null
    //   }]
    //   [{
    //     "location_uid": "COcWbWQ",
    //     "background_task": {
    //         "task_id": "ccc8eee2-64b2-4df1-a477-023491768bb2",
    //         "state": "FAILURE",
    //         "result": "'Horse' object has no attribute 'dierVerblijfplaats'"
    //     },
    //     "get_horses_from_rvo_exception": null
    //   }]
    //   [{
    //     "location_uid": "CO2gQnh",
    //     "background_task": {
    //         "task_id": "9ab5cc90-f7b6-4ec7-9444-edb8826e5601",
    //         "state": "SUCCESS",
    //         "result": "Successfully synced"
    //     },
    //     "get_horses_from_rvo_exception": null
    //   }]

    promise
      .then(results => {
        // detect if the import is running
        const importIsRunning =
          results.filter(
            result =>
              result.background_task !== null &&
              result.background_task?.state &&
              [StateEnum.PENDING, StateEnum.PROGRESS, StateEnum.STARTED].includes(result.background_task.state),
          ).length > 0;

        // update the state of the import
        setIsImporting(importIsRunning);

        // fetch the messages from the tasks
        // which can be multiple for every location
        const importMessages: ReactNode[] = [];
        for (const result of results) {
          if (result.background_task?.state === StateEnum.FAILURE) {
            importMessages.push(formatErrorMessage(result));
          }

          if (result.background_task?.state === StateEnum.SUCCESS) {
            importMessages.push(
              <p className='text-green-700' key='success'>
                <Check className='inline' /> {t('rvo-import-successfully-executed', 'Import successfully executed')}
              </p>,
            );
          }
        }

        setPreviousImportMessages(importMessages);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          const error = new ApiErrorParser<LocationResponse>(e);
          setPreviousImportMessages(
            error.nonFieldErrorsStrings().map(message => (
              <p className='text-red-500' key={message}>
                <strong>
                  {t('rvo-import-failed-due-to', 'Import failed due to')}
                  {': '}
                </strong>{' '}
                {message}
              </p>
            )),
          );
        }
      });

    return promise;
  }, [formatErrorMessage, locations, selectedOrganization?.uid, t]);

  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadStatus();
      return () => promise?.cancel();
    }
  }, [loadStatus, selectedOrganization]);

  /**
   * Check the status with an interval
   */
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (isImporting) {
      interval = setInterval(loadStatus, 1000);
    }

    return () => clearInterval(interval);
  }, [isImporting, loadStatus]);

  return {
    previousImportMessages,
    isImporting,
    showImportModal: showRvoImportModal,
    importModalElement: (
      <RvoImportHorseLocationModal
        isVisible={rvoImportModalIsVisible}
        onRequestCloseModal={closeRvoImportModal}
        onStarted={() => {
          setPreviousImportMessages(undefined);
          setIsImporting(true);
        }}
      />
    ),
  };
};

export default useRvoImportStatus;
