import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import RRuleWeeklyComponent from './RRuleWeeklyComponent';
import { WeekDay } from '.';

export type RRuleOption = {
  id: any; //eslint-disable-line
  name: string;
  icon?: ReactNode;
};

export type RRuleProps<T extends FieldValues> = {
  referenceDate: Date; // The selected date to base the RRule on.
  control: Control<T>;
  name: Path<T>;
  label?: string;
  error?: string;
  required?: boolean;
  hint?: ReactNode;
  className?: string;
  availableDays: WeekDay[];
};

// Rrule component but only offers weekly rules
// This is used for facility planning
export default function RRuleWeeklyInput<T extends FieldValues>({
  name,
  control,
  label,
  error,
  required,
  hint,
  className,
  referenceDate,
  availableDays,
}: RRuleProps<T>): JSX.Element {
  return (
    <div className={classNames('group relative', className)}>
      {label && (
        <label className='block text-sm font-medium leading-4 text-gray-600 mb-2'>
          {label} {required && '*'}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RRuleWeeklyComponent
            availableDays={availableDays}
            referenceDate={referenceDate}
            rRule={field.value}
            onRRuleUpdated={rrule => field.onChange(rrule)}
          />
        )}
      />
      {hint && <label className='block text-xs leading-4 text-gray-500 mt-1'>{hint}</label>}
      <label
        className={classNames('block text-xs leading-4 text-red-500 transition-all duration-200', {
          'h-auto opacity-100  mt-1': !!error,
          'h-0 opacity-0': !error,
        })}
      >
        {error || ''}
      </label>
    </div>
  );
}
