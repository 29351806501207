import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { FeedType, FeedtypesService } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  open: boolean;
  feedType?: FeedType;
  requestClose: () => void;
  onRemoved: () => void;
}

export default function RemoveFeedTypeModal({ open, feedType, requestClose, onRemoved }: Props): JSX.Element {
  const [removeFeedTypeDialogError, setRemoveFeedTypeDialogError] = useState<ApiErrorParser<void> | undefined>();
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  return (
    <ActionModal
      open={open}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: () => {
            requestClose();
            setRemoveFeedTypeDialogError(undefined);
          },
        },
        {
          text: t('remove', 'Remove'),
          variant: ButtonVariant.PrimaryDanger,
          onClick: async () => {
            try {
              await FeedtypesService.feedtypesDestroy({
                organisationUid: selectedOrganization?.uid ?? '',
                uid: feedType?.uid ?? '',
              });
              requestClose();
              onRemoved();
            } catch (e) {
              setRemoveFeedTypeDialogError(new ApiErrorParser(e));
            }
          },
        },
      ]}
      title={t('remove-feed-type-confirm-title', 'Remove feed type')}
    >
      <>
        <ErrorSection errors={removeFeedTypeDialogError} />
        <p>
          {t('remove-feed-type-confirm-text', 'Are you sure you want to remove the "{{category_name}}" feed type?', {
            category_name: feedType?.display_name,
          })}
        </p>
      </>
    </ActionModal>
  );
}
