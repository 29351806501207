import { useAccount } from 'context/AccountContext';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Frequency, RRule, RRuleSet, rrulestr } from 'rrule';
import Button, { ButtonSize } from 'ui/Button';
import { CaretDown } from '@phosphor-icons/react';
import { DateInputRaw } from '../DateInput/DateInput';
import { frequencyToStringPlural, frequencyToStringSingle, WeekDay, weekdaysToByWeekDay } from '.';
import { addYears } from 'date-fns';

interface RRuleComponentProps {
  rRule?: string;
  onRRuleUpdated: (rRule: string | undefined) => void;
  referenceDate: Date; // The selected date to base the RRule on.
}

// A component that can create/edit an rRule. RRules are recurrence rules for calendar activities: https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html
export default function RRuleComponent({ referenceDate, rRule: rRuleString, onRRuleUpdated }: RRuleComponentProps): JSX.Element {
  const { formatDateIntl } = useAccount();
  const { t } = useTranslation();
  const [frequency, setFrequency] = useState<Frequency | undefined>();
  const [interval, setInterval] = useState<number>(1);
  const [weekDays, setWeekDays] = useState<WeekDay[]>([referenceDate.getDay()]); // Default to monday
  const [until, setUntil] = useState<Date | undefined>();
  const [hasInitialRRuleString] = useState<boolean>(!!rRuleString);

  // Show the fully customizable input or just a simple dropdown.
  const [showCustom, setShowCustom] = useState<boolean>(!!rRuleString);

  const refFrequency = useRef<HTMLSelectElement>(null);
  const refInterval = useRef<HTMLSelectElement>(null);

  const buildRRule = (
    frequency: Frequency | undefined,
    interval: number,
    weekDays: WeekDay[],
    until: Date | undefined,
  ): RRule | undefined => {
    if (!frequency) {
      return undefined;
    }

    // Little workaround for very early years.
    // The RRule builder doesn't like it when you fill in 01-01-0001
    // Just make the until empty when we're below 1900.
    if ((until?.getFullYear() ?? 0) < 1900) {
      until = undefined;
    }

    return new RRule({
      freq: frequency,
      interval,
      byweekday: frequency === Frequency.WEEKLY ? weekdaysToByWeekDay(weekDays) : undefined,
      until,
    });
  };

  // Set the correct values in init
  useEffect(() => {
    if (rRuleString) {
      // The RRule may come with e.g. EXDATE. Use rrulestr method to be safe.
      const rRuleSet = rrulestr(rRuleString, {
        forceset: true,
      }) as RRuleSet;
      const rRule = rRuleSet.rrules()[0];

      // Set the frequency
      setFrequency(rRule.options.freq);

      // Set the interval
      setInterval(rRule.options.interval);
      if (refInterval.current) {
        refInterval.current.value = rRule.options.interval.toString();
      }

      // Set week days
      if (rRule.options.freq === Frequency.WEEKLY) {
        const weekDays: WeekDay[] = [];
        for (const weekdayIndex of rRule.options.byweekday ?? []) {
          switch (weekdayIndex) {
            case 0:
              weekDays.push(WeekDay.Mo);
              break;
            case 1:
              weekDays.push(WeekDay.Tu);
              break;
            case 2:
              weekDays.push(WeekDay.We);
              break;
            case 3:
              weekDays.push(WeekDay.Th);
              break;
            case 4:
              weekDays.push(WeekDay.Fr);
              break;
            case 5:
              weekDays.push(WeekDay.Sa);
              break;
            case 6:
              weekDays.push(WeekDay.Su);
              break;
          }
        }
        setWeekDays(weekDays);
      } else {
        // Set default to monday when WEEKLY gets selected.
        setWeekDays([referenceDate.getDay()]);
      }

      // Set until
      if (rRule.options.until) {
        setUntil(rRule.options.until);
      }
    }
  }, [rRuleString, referenceDate]);

  return (
    <div
      className={classNames('border rounded-lg space-y-2 pr-2', {
        block: showCustom,
        'inline-block': !showCustom,
        'pt-2': hasInitialRRuleString && showCustom,
      })}
    >
      {!hasInitialRRuleString && (
        <div className='flex items-center'>
          <select
            className='text-sm mr-2 p-2 pr-6 bg-transparent appearance-none'
            ref={refFrequency}
            onChange={e => {
              if (e.target.value === '') {
                setShowCustom(true);
                setFrequency(Frequency.DAILY);
                onRRuleUpdated(buildRRule(Frequency.DAILY, interval, weekDays, until)?.toString());
              } else {
                setShowCustom(false);
                const val: number = parseInt(e.target.value);
                setFrequency(isNaN(val) ? undefined : val);
                setUntil(undefined);
                setWeekDays([referenceDate.getDay()]);
                setInterval(1);
                onRRuleUpdated(buildRRule(val, 1, [referenceDate.getDay()], undefined)?.toString());
              }
            }}
          >
            <option value={undefined}>{t('none', 'None')}</option>
            <option value={Frequency.DAILY}>{t('daily', 'Daily')}</option>
            <option value={Frequency.WEEKLY}>{t('weekly', 'Weekly')}</option>
            <option value={Frequency.MONTHLY}>{t('monthly', 'Monthly')}</option>
            <option value={Frequency.YEARLY}>{t('yearly', 'Yearly')}</option>
            <option value=''>{t('custom', 'Custom')}</option>
          </select>
          <CaretDown className='-ml-8 pointer-events-none' />
        </div>
      )}
      {frequency !== undefined && showCustom && (
        <div className='ml-2 space-y-3 pb-2'>
          <p>
            <span className='text-sm'>{t('rrule-frequency-label', 'Frequency')}</span>
            <span className='inline-flex items-center mr-3 ml-1'>
              <select
                className='text-center text-sm border rounded mr-2 p-0.5 pr-6 bg-transparent appearance-none'
                value={frequency}
                onChange={e => {
                  const val: number = parseInt(e.target.value);
                  setFrequency(isNaN(val) ? undefined : val);
                  onRRuleUpdated(buildRRule(val, interval, weekDays, until)?.toString());
                }}
              >
                <option value={Frequency.DAILY}>{t('daily', 'Daily')}</option>
                <option value={Frequency.WEEKLY}>{t('weekly', 'Weekly')}</option>
                <option value={Frequency.MONTHLY}>{t('monthly', 'Monthly')}</option>
                <option value={Frequency.YEARLY}>{t('yearly', 'Yearly')}</option>
              </select>
              <CaretDown className='-ml-8 pointer-events-none' />
            </span>
          </p>

          <p>
            <span className='text-sm'>{t('rrule-interval-label-pre', 'Repeat every')}</span>
            <span className='inline-flex items-center mr-3 ml-1'>
              <select
                className='text-center border rounded mr-2 text-sm p-0.5 pr-6 bg-transparent appearance-none'
                ref={refInterval}
                value={interval}
                onChange={e => {
                  const val: number = parseInt(e.target.value);
                  setInterval(isNaN(val) ? 1 : val);
                  onRRuleUpdated(buildRRule(frequency, val, weekDays, until)?.toString());
                }}
              >
                {[...Array(100)].map((element, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
              <CaretDown className='-ml-8 pointer-events-none' />
            </span>
            <span className='text-sm'>
              {interval === 1 ? frequencyToStringSingle(t, frequency).toLowerCase() : frequencyToStringPlural(t, frequency).toLowerCase()}.
            </span>
          </p>
          {frequency === Frequency.WEEKLY && (
            <div className='space-x-1'>
              {Object.values(WeekDay)
                .filter(value => typeof value !== 'string')
                .map(element => (
                  <div key={element} className='inline-block relative w-7 h-7'>
                    <input
                      type='checkbox'
                      key={element}
                      name={`weekday.${element}`}
                      className='peer w-full h-full appearance-none border cursor-pointer rounded-full bg-gray-200 checked:bg-blue-500 border-none'
                      checked={weekDays.includes(element)}
                      onChange={e => {
                        const res = e.target.checked ? [...weekDays, element] : weekDays.filter(day => day !== element);
                        setWeekDays(res);
                        onRRuleUpdated(buildRRule(frequency, interval, res, until)?.toString());
                      }}
                    />
                    <span className='absolute inset-0 text-center pointer-events-none text-xs flex items-center justify-center text-gray-500 peer-checked:text-white'>
                      {formatDateIntl(new Date(Date.UTC(2024, 9, 6 + element)), { weekday: 'narrow' })}
                    </span>
                  </div>
                ))}
            </div>
          )}
          {!until && (
            <Button
              type='button'
              size={ButtonSize.XSmall}
              onClick={() => {
                const date = addYears(new Date(), 1);
                setUntil(date);
                onRRuleUpdated(buildRRule(frequency, interval, weekDays, date)?.toString());
              }}
            >
              {t('rrule-set-end-date', 'Set end date')}
            </Button>
          )}
          {until && (
            <>
              <DateInputRaw
                label={t('rrule-end-date', 'End date')}
                dateValue={until}
                onDateChange={date => {
                  setUntil(date);
                  onRRuleUpdated(buildRRule(frequency, interval, weekDays, date)?.toString());
                }}
              />
              <Button
                type='button'
                size={ButtonSize.XSmall}
                onClick={() => {
                  setUntil(undefined);
                  onRRuleUpdated(buildRRule(frequency, interval, weekDays, undefined)?.toString());
                }}
              >
                {t('rrule-remove-end-date', 'Remove end date')}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
