import { SemenTypeEnumToString, SemenUsageToString } from 'components/Breeding/Helpers';
import { getStudbookByCode } from 'components/Breeding/Studbook';
import { CatalogueProduct, PurchaserOrderItem, Horse } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionList from 'ui/Layout/DescriptionList';
import { Tile } from 'ui/Layout/Tile';

interface Props {
  orderItem: PurchaserOrderItem;
  products?: CatalogueProduct[];
  horses: Horse[];
}

export default function WebshopOrderItemDetails({ products, orderItem, horses }: Props): JSX.Element {
  const { t } = useTranslation();

  const product = useMemo(() => {
    return products?.find(prod => prod.uid === orderItem.product_uid);
  }, [products, orderItem]);

  const mareName = useMemo(() => {
    return horses.find(horse => horse.uid === orderItem.mare_uid)?.name ?? t('unknown', 'Unknown');
  }, [horses, orderItem, t]);

  if (!product) {
    return <></>;
  }

  if (product.category.default === 'BREEDING') {
    return (
      <Tile
        key={orderItem.uid}
        title={`${t('semen-from', 'Semen from')} ${product.stallion?.name ?? t('unknown-stallion', 'Unknown stallion')}`}
      >
        <DescriptionList
          list={[
            { term: t('stallion', 'Stallion'), definition: product.stallion?.name ?? t('unknown-stallion', 'Unknown stallion') },
            {
              term: t('mare', 'Mare'),
              definition: mareName,
            },
            {
              term: t('semen-type', 'Semen type'),
              definition: SemenTypeEnumToString(t, orderItem.semen_type),
            },
            {
              term: t('semen-usage-type', 'Usage type'),
              definition: orderItem.usage_type ? SemenUsageToString(t, orderItem.usage_type) : t('not-set', 'Not set'),
            },
            {
              term: t('studbook', 'Studbook'),
              definition: orderItem.studbook ? getStudbookByCode(orderItem.studbook)?.name : t('not-set', 'Not set'),
            },
            {
              term: t('repeat-order', 'Repeat order'),
              definition: orderItem.parent_semen_order_item_uid ? t('yes', 'Yes') : t('no', 'No'),
            },
          ]}
        />
      </Tile>
    );
  }
  if (product.category.default === 'SHIPPING') {
    return (
      <Tile key={orderItem.uid} title={t('shipping', 'Shipping')}>
        <DescriptionList
          list={[
            { term: t('shipping-provider-name', 'Provider name'), definition: product.shipping_provider_name },
            {
              term: t('shipping-service-type', 'Service type'),
              definition: product.shipping_service_type?.toString().replaceAll('_', ' '),
            },
          ]}
        />
      </Tile>
    );
  }

  return <>{t('unknown-product', 'Unknown product')}</>;
}
