/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `FROZEN` - frozen
 * * `FRESH` - fresh
 * * `UNDILUTED` - UNDILUTED
 */
export enum SemenTypeEnum {
  FROZEN = 'FROZEN',
  FRESH = 'FRESH',
  UNDILUTED = 'UNDILUTED',
}
