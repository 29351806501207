import classNames from 'classnames';
import React, { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes, ReactNode } from 'react';
import { Hint } from 'ui/Hint';
import { InputError } from 'ui/InputError';

export enum InputSize {
  XSmall,
  Small,
  Normal,
  Large,
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelElement?: ReactNode;
  error?: string;
  invalid?: boolean;
  hint?: ReactNode;
  size?: InputSize;
}

const CheckboxInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { label, invalid = false, error, hint, labelElement, size = InputSize.Normal, onChange, ...props },
  ref,
) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  const rootClassName = classNames('group relative');
  const inputRootClassName = classNames('group w-full flex items-center space-x-1.5 relative rounded-md transition-all duration-200', {
    'bg-gray-100 cursor-not-allowed': props.disabled,
    'bg-white': !props.disabled,
    'py-1 pr-1.5 ': size === InputSize.XSmall,
    'py-1 pr-2 ': size === InputSize.Small,
    'py-1.5 pr-2': size === InputSize.Normal,
    'py-2 pr-2.5': size === InputSize.Large,
  });
  const inputClassName = classNames('block bg-transparent rounded-md border-0 text-gray-900 placeholder:text-gray-400', {
    'text-xs leading-5': size === InputSize.XSmall,
    'placeholder:text-rose-300': invalid,
    'cursor-not-allowed': props.disabled,
    'text-sm leading-6': size === InputSize.Small,
    'sm:text-sm sm:leading-6': size === InputSize.Normal,
    'text-large sm:text-base leading-7 sm:leading-6': size === InputSize.Large,
  });
  return (
    <div className={rootClassName}>
      <div className={inputRootClassName}>
        <label className='flex flex-row gap-3 md:gap-2 text-sm justify-center items-center leading-4 text-gray-900'>
          <input ref={ref} type={'checkbox'} onChange={handleChange} {...props} className={inputClassName} />
          {label && (
            <div>
              {label} {props.required && '*'}
            </div>
          )}
          {labelElement && (
            <div>
              {labelElement} <span>{props.required && '*'}</span>
            </div>
          )}
        </label>
      </div>
      {hint && <Hint>{hint}</Hint>}
      {error && <InputError>{error}</InputError>}
    </div>
  );
};
export default forwardRef<HTMLInputElement, Props>(CheckboxInput);
