import { IconContext } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Tooltip from 'ui/Tooltip';

export interface ButtonGroupItem {
  onClick?: () => void;
  icon?: ReactNode;
  text?: ReactNode;
  selected?: boolean;
}

interface Props {
  items: ButtonGroupItem[];

  // Only show the icons when they're provided in the items.
  iconOnly?: boolean;
}

export default function ButtonGroup({ items, iconOnly }: Props): JSX.Element {
  return (
    <IconContext.Provider
      value={{
        size: 20,
        weight: 'regular',
      }}
    >
      <div className='flex flex-row text-sm font-medium select-none divide-x border rounded-lg bg-white'>
        {items.map((item, index) => (
          <Tooltip key={index} disabled={!iconOnly} content={item.text}>
            <button
              className={classNames(
                'px-1 md:px-3 h-10 focus:outline-none focus:ring-0 first:rounded-l-lg last:rounded-r-lg hover:bg-gray-100 flex items-center justify-center',
                {
                  'bg-blue-600/5 shadow-inner text-blue-600': item.selected,
                  'text-gray-600': !item.selected,
                },
              )}
              onClick={item.onClick}
              title={item.text?.toString()}
            >
              {(!iconOnly || !item.icon) && <>{item.text}</>}
              {iconOnly && item.icon && <>{item.icon}</>}
            </button>
          </Tooltip>
        ))}
      </div>
    </IconContext.Provider>
  );
}

// Same as the button group above, but for the mobile dark header.
export function ButtonGroupMobileToolbar({ items }: Props): JSX.Element {
  return (
    <IconContext.Provider
      value={{
        size: 20,
        weight: 'regular',
      }}
    >
      <div className='flex flex-row text-sm font-medium select-none divide-x divide-white/20 border rounded-lg border-white/20 overflow-hidden'>
        {items.map((item, index) => (
          <button
            key={index}
            className={classNames('px-3 md:px-3 h-10 flex items-center justify-center', {
              'bg-white/20 shadow-inner text-white': item.selected,
              'text-white': !item.selected,
            })}
            onClick={item.onClick}
            title={item.text?.toString()}
          >
            {item.icon}
          </button>
        ))}
      </div>
    </IconContext.Provider>
  );
}
