import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { Feeding, FeedingsService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { transformEmptyToUndefined } from 'utilities/zod';

interface Props {
  visible: boolean;
  closeModal: () => void;
  onClosed: () => void;
  existingFeeding?: Feeding;
  onSaved?: (feeding: Feeding) => void;
}

export default function SaveFeedingModal({ visible, closeModal, onClosed, existingFeeding, onSaved }: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return schemas.Feeding.omit({
      uid: true,
      default_id: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Feeding>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const closed = () => {
    setApiError(undefined);
    onClosed();
  };

  const onSubmit = async (data: Feeding) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    try {
      let savedFeeding: Feeding | undefined = undefined;
      if (existingFeeding) {
        const promiseUpdate = FeedingsService.feedingsPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: existingFeeding.uid,
          requestBody: data,
        });
        savedFeeding = (await promiseUpdate) as Feeding;
      } else {
        const promiseCreate = FeedingsService.feedingsCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: data,
        });
        savedFeeding = (await promiseCreate) as Feeding;
      }
      closeModal();
      onSaved?.(savedFeeding);
    } catch (error) {
      setApiError(new ApiErrorParser<Feeding>(error));
    }
  };

  // Reset when the modal visibility is changed or when the existingFeeding is changed.
  useEffect(() => {
    if (existingFeeding) {
      reset(existingFeeding);
    } else {
      reset({});
    }
  }, [reset, existingFeeding, visible]);

  return (
    <PageModal open={visible} width={PageModalWidth.Xs} onClosed={closed}>
      <PageModalTitle
        title={existingFeeding ? t('edit-feeding-title', 'Edit feeding') : t('new-feeding-title', 'New feeding')}
        onClose={closeModal}
      />
      <PageModalContent>
        <ErrorSection errors={nonFieldErrors} className='mb-1' />
        <form noValidate={true} id='SaveFeedingForm' onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4 grow'>
            <TextInput
              required={true}
              label={t('name', 'Name')}
              {...register('name', {
                setValueAs: transformEmptyToUndefined(),
              })}
              error={fieldError('name')}
            />
            <TextInput
              required={true}
              type='time'
              label={t('time', 'Time')}
              {...register('time', {
                setValueAs: transformEmptyToUndefined(),
              })}
              error={fieldError('time')}
            />
          </div>
        </form>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'SaveFeedingForm',
          },
        ]}
      />
    </PageModal>
  );
}
