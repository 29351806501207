import { CancelablePromise, PublicOrganisation, PublicService } from 'openapi';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useConfig } from '../../context/ConfigContext';
import { useWebshopContext } from 'context/WebshopContext';

interface ReturnType {
  webshopOrganization: PublicOrganisation | undefined;
  loading: boolean;
  error: string | undefined;
  refresh: () => void;
}

/**
 * The hook can be used inside the webshop routes. It will provide tools to use
 * inside the webshop. I.e. you can get the organization name of the webshop
 * owner.
 *
 * @param init this flag indicate if we need to add a useEffect for reload the org when the publicAccessUuid changed
 */
export default function useWebshop(init = false): ReturnType {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const { publicAccessUuid } = useParams();
  const { setWebshopOrganization, webshopOrganization } = useWebshopContext();
  const { config } = useConfig();

  const loadWebshopOrg = useCallback((): CancelablePromise<PublicOrganisation> => {
    setLoading(true);
    const promise = PublicService.apiV5PublicOrganisationsRetrieve({
      publicAccessUuid: publicAccessUuid ?? '',
    });
    promise
      .then(org => setWebshopOrganization(org))
      .catch(() => {
        if (!promise.isCancelled) {
          setError('Unable to load webshop organization');
        }
      });

    setLoading(false);
    return promise;
  }, [publicAccessUuid, setWebshopOrganization]);

  useEffect(() => {
    // We need to have a publicAccessUuid.
    if (init && publicAccessUuid && config && !webshopOrganization) {
      const promise = loadWebshopOrg();
      return () => promise.cancel();
    }
  }, [publicAccessUuid, config]); //eslint-disable-line

  return {
    webshopOrganization,
    loading,
    error,
    refresh: loadWebshopOrg,
  };
}
