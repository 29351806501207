import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

function InputError({ children, className }: Props): JSX.Element {
  return (
    <label className={classNames('block text-xs leading-4 text-red-500 transition-all duration-200 mt-1', className)}>{children}</label>
  );
}

export default InputError;
