import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { Horse, HorseLocationMove, HorsesService, SexEnum } from 'openapi';
import { HorseDetail } from 'openapi/models/HorseDetail';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { DateInput, TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { today } from 'utilities/date.utilities';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';

interface Props {
  open: boolean;
  onRequestCloseModal: () => void;
  onCreated: (horse: HorseDetail) => Promise<void> | void;
  // this value is used when we open the modal from the horse input select
  defaultName?: string;
  // optional, the owner that will be used to set a 100% ownership
  ownerUid?: string;
  // Optional contact uid of the mare location.
  locationUid?: string;
}

function CreateMareModal({ open, onRequestCloseModal, onCreated, defaultName, ownerUid, locationUid }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>();

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  // Form validation
  const schema = useMemo(() => {
    return schemas.Horse.pick({
      name: true,
      UELN: true,
      chip_nr: true,
      date_of_birth: true,
      sire: true,
      damsire: true,
      dam: true,
    }).required({
      name: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<Horse>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: Horse) => {
    if (!selectedOrganization) return;

    setSubmitting(true);

    try {
      let move: HorseLocationMove | undefined = undefined;
      if (locationUid) {
        move = {
          date: today(),
          destination_uid: locationUid,
        };
      }

      const createHorsePromise = HorsesService.horsesCreate({
        organisationUid: selectedOrganization.uid,
        requestBody: {
          new_owners: ownerUid ? [ownerUid] : undefined,
          name: data.name,
          UELN: data.UELN,
          chip_nr: data.chip_nr,
          date_of_birth: data.date_of_birth,
          sire: data.sire,
          damsire: data.damsire,
          dam: data.dam,
          sex: SexEnum._2, // mare
          register_move: move,
        } as HorseDetail,
      });

      const createdHorse = await createHorsePromise;

      // fire the onCreated event
      await onCreated(createdHorse);

      // close the modal
      onRequestCloseModal();
    } catch (error: unknown) {
      setApiError(new ApiErrorParser(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * event that will be fired after the modal has been closed
   */
  const onClosed = () => {
    setApiError(undefined);
    reset();
  };

  /**
   * set the defaultName value to the name input
   */
  useEffect(() => {
    setValue('name', defaultName ?? '');
  }, [defaultName, setValue]);

  return (
    <>
      <PageModal width={PageModalWidth.Sm} open={open} onClosed={onClosed}>
        <PageModalTitle title={t('add-mare', 'Add mare')} onClose={onRequestCloseModal} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <form className='w-full flex flex-col gap-4' id='addHorseForm' noValidate={true} onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              error={fieldError('name')}
              required={zodInputIsRequired<Horse>(schema, 'name')}
              label={t('name', 'Name')}
              {...register('name', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('UELN')}
              required={zodInputIsRequired<Horse>(schema, 'UELN')}
              label={t('horse_ueln', 'UELN')}
              {...register('UELN', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              // Chrome does not respect the autocomplete="off" attribute on the input field.
              // So in this case Chrome think that chip number (translated in NL to chipnummer) is a cc field (match to trigger the cc autofill is the label name "*nummer")
              // To prevent Chrome from autocomplete as CC we e use the "bday-day" autocomplete. bday-day is a numeric field (chip number is also numeric)
              // @see https://issues.chromium.org/issues/41163264#comment165
              // @see https://issues.chromium.org/issues/339033226
              // @see https://gitlab.qubis.nl/equinem/equiapp/-/issues/202
              autoComplete='bday-day'
              error={fieldError('chip_nr')}
              required={zodInputIsRequired<Horse>(schema, 'chip_nr')}
              label={t('horse_chip_nr', 'Chip number')}
              {...register('chip_nr', { setValueAs: transformEmptyToUndefined() })}
            />
            <DateInput
              autoComplete='bday'
              control={control}
              error={fieldError('date_of_birth')}
              required={zodInputIsRequired<Horse>(schema, 'date_of_birth')}
              label={t('date_of_birth', 'Date of birth')}
              {...register('date_of_birth', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('sire')}
              required={zodInputIsRequired<Horse>(schema, 'sire')}
              label={t('sire', 'Sire')}
              {...register('sire', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('dam')}
              required={zodInputIsRequired<Horse>(schema, 'dam')}
              label={t('dam', 'Dam')}
              {...register('dam', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('damsire')}
              required={zodInputIsRequired<Horse>(schema, 'damsire')}
              label={t('damsire', 'Damsire')}
              {...register('damsire', { setValueAs: transformEmptyToUndefined() })}
            />
          </form>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: submitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'addHorseForm',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default CreateMareModal;
