import { Check, IconContext } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Badge from 'ui/Badge';
import { BadgeProps, BadgeSize } from 'ui/Badge/Badge';

export interface LicenseTileProps {
  name: ReactNode;
  price: string;
  isSelected?: boolean;
  icon: ReactNode;
  onClick?: () => void;
  showCheckbox?: boolean;
  badgeProps?: () => BadgeProps | undefined;
  type?: 'radio' | 'checkbox';
  disabled?: boolean;
  className?: string;
}

function LicenseTile({
  isSelected,
  icon,
  name,
  price,
  onClick,
  showCheckbox = true,
  badgeProps,
  type = 'radio',
  disabled,
  className,
}: LicenseTileProps): JSX.Element {
  const renderdBadgeProps = badgeProps && badgeProps();

  return (
    <div
      onClick={onClick}
      className={classNames(
        'w-32 relative border rounded-lg px-4 py-5 flex flex-col items-center justify-center gap-2 text-sm bg-white',
        className,
        {
          'border-gray-300': !isSelected && !disabled,
          'border-blue-500': isSelected && !disabled,
          'border-gray-300 text-gray-400': disabled,
          'hover:border-blue-500 cursor-pointer': !disabled,
        },
      )}
    >
      {renderdBadgeProps?.children && (
        <div className='absolute left-1 top-1'>
          <Badge {...renderdBadgeProps} size={BadgeSize.Small}>
            {renderdBadgeProps?.children}
          </Badge>
        </div>
      )}

      {showCheckbox && (
        <div
          className={classNames('absolute w-5 h-5 p-1 -top-2 -right-2 border flex justify-center items-center', {
            'rounded-full': type === 'radio',
            'rounded-md': type === 'checkbox',
            'bg-white border-gray-300 ': !isSelected && !disabled,
            'bg-blue-500 border-blue-500 text-white': isSelected && !disabled,

            'bg-gray-50 border-gray-300 ': !isSelected && disabled,
            'bg-gray-400 border-gray-400 text-white': isSelected && disabled,
          })}
        >
          {isSelected && <Check size={15} weight='bold' />}
        </div>
      )}

      <IconContext.Provider
        value={{
          size: 35,
          weight: 'light',
        }}
      >
        {icon}
      </IconContext.Provider>
      <span className='text-center leading-4'>{name}</span>
      <span className='text-blue-500'>{price}</span>
    </div>
  );
}

export default LicenseTile;
