import { Eye, EyeSlash, X } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes, ReactNode, useState } from 'react';
import { Hint } from 'ui/Hint';
import { InputError } from 'ui/InputError';
import { Label } from 'ui/Label';

export enum InputSize {
  XSmall,
  Small,
  Normal,
  Large,
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  hint?: ReactNode;
  postText?: ReactNode;
  error?: string;
  preText?: string;
  allowClear?: boolean;
  invalid?: boolean;
  size?: InputSize;
}

const TextInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    label,
    error,
    preText,
    postText,
    allowClear = false,
    invalid = false,
    size = InputSize.Normal,
    onChange,
    onBlur,
    onFocus,
    hint,
    className,
    ...props
  },
  ref,
) => {
  const [focused, setFocused] = useState(false);
  const [obstructInput, setObstructInput] = useState(props.type === 'password');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleClear = () => {
    // setValue('');
  };
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };
  const rootClassName = classNames('group relative', className);
  const inputRootClassName = classNames(
    'group w-full border flex items-center space-x-1.5 relative rounded-md border transition-all duration-200',
    {
      'border-primary': focused,
      'bg-gray-100 border-gray-200 cursor-not-allowed': props.disabled,
      'border-rose-400': error || invalid,
      'bg-white': !props.disabled,
      'py-1 px-1.5 ': size === InputSize.XSmall,
      'py-1 px-2 ': size === InputSize.Small,
      'py-1.5 px-2': size === InputSize.Normal,
      'py-2 px-2.5': size === InputSize.Large,
    },
  );
  const inputClassName = classNames(
    'flex-1 min-w-0 rounded-md border-0 focus:ring-0 focus:outline-none text-gray-900 ring-0 placeholder:text-gray-400',
    {
      'text-xs leading-5': size === InputSize.XSmall,
      'placeholder:text-rose-300': error || invalid,
      'cursor-not-allowed': props.disabled,
      'text-sm leading-6': size === InputSize.Small,
      'sm:text-sm sm:leading-6': size === InputSize.Normal,
      'text-large sm:text-base leading-7 sm:leading-6': size === InputSize.Large,
      'bg-white': props.type === 'time',
      'bg-transparent': props.type !== 'time',
    },
  );
  return (
    <div className={rootClassName}>
      {label && (
        <Label className='mb-2'>
          {label} {props.required && '*'}
        </Label>
      )}
      <div className={inputRootClassName}>
        {preText && (
          <div className='pointer-events-none inset-y-0 flex items-center'>
            <span className='text-gray-500'>{preText}</span>
          </div>
        )}
        <input
          ref={ref}
          {...props}
          type={props.type === 'password' ? (obstructInput ? 'password' : 'text') : props.type}
          onFocus={handleFocus}
          autoComplete={props.autoComplete ?? 'off'}
          onBlur={handleBlur}
          onChange={handleChange}
          className={inputClassName}
        />
        {postText && (
          <div className='pointer-events-none inset-y-0 flex items-center'>
            <span className='text-gray-500 text-sm'>{postText}</span>
          </div>
        )}
        {allowClear && props.value && (
          <div onClick={handleClear} className={'bg-neutral-50 rounded-full p-1'}>
            <X className='cursor-pointer h-3 w-3 text-gray-400' />
          </div>
        )}
        {props.type === 'password' && (
          <div onClick={() => setObstructInput(!obstructInput)} className={'rounded-full'}>
            {obstructInput ? (
              <Eye className='cursor-pointer h-5 w-5 text-gray-400' />
            ) : (
              <EyeSlash className='cursor-pointer h-5 w-5 text-gray-400' />
            )}
          </div>
        )}
      </div>
      {hint && <Hint>{hint}</Hint>}
      {error && <InputError>{error}</InputError>}
    </div>
  );
};
export default forwardRef<HTMLInputElement, Props>(TextInput);
