import React from 'react';
import colors from 'tailwindcss/colors';
import { AllColors } from '../../utilities/colors';
import classNames from 'classnames';

export enum BadgeSize {
  Normal,
  Large,
  Small,
}

export interface BadgeProps {
  color?: AllColors;
  size?: BadgeSize;
  rounded?: boolean;
  children: React.ReactNode;
  className?: string;
  tooltip?: string;
  onClick?: () => void;
}

const Badge: React.FC<BadgeProps> = ({
  children,
  color = AllColors.Blue,
  rounded = false,
  size = BadgeSize.Normal,
  className,
  tooltip,
  onClick,
}) => {
  const bgColorClasses = colors[color]['100'];
  const textColorClasses = colors[color]['800'];
  let sizeClasses = '';

  switch (size) {
    case BadgeSize.Large:
      sizeClasses = 'px-3 leading-tight py-1 font-medium';
      break;
    case BadgeSize.Normal:
    default:
      sizeClasses = 'px-2 leading-tight py-0.5 text-sm font-medium';
      break;
    case BadgeSize.Small:
      sizeClasses = 'px-0.5 leading-tight py-0.5 text-xs';
      break;
  }

  return (
    <span
      title={tooltip}
      className={classNames(className, 'inline-block select-none cursor-default', sizeClasses, {
        'rounded-full': rounded,
        rounded: !rounded,
      })}
      style={{ color: textColorClasses, backgroundColor: bgColorClasses }}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default Badge;
