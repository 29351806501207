/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DailyNote } from '../models/DailyNote';
import type { DailyNoteSetDoneAndUnDone } from '../models/DailyNoteSetDoneAndUnDone';
import type { PatchedDailyNote } from '../models/PatchedDailyNote';
import type { PatchedDailyNoteSetDoneAndUnDone } from '../models/PatchedDailyNoteSetDoneAndUnDone';
import type { PatchedEventDestroy } from '../models/PatchedEventDestroy';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DailynoteService {
  /**
   * List all DailyJobs.
   *
   * If no filter is included at all, it only shows today.
   * @returns DailyNote
   * @throws ApiError
   */
  public static dailynoteList({
    organisationUid,
    dtendGt,
    dtstartLt,
    executable,
    noStable,
    o,
    stableUid,
    stableUidIn,
    statusIn,
  }: {
    organisationUid: string,
    dtendGt?: string,
    dtstartLt?: string,
    executable?: boolean,
    noStable?: boolean,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    stableUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    stableUidIn?: Array<string>,
    /**
     * Multiple values may be separated by commas.
     */
    statusIn?: Array<string>,
  }): CancelablePromise<Array<DailyNote>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/dailynote',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'dtend__gt': dtendGt,
        'dtstart__lt': dtstartLt,
        'executable': executable,
        'no_stable': noStable,
        'o': o,
        'stable__uid': stableUid,
        'stable__uid__in': stableUidIn,
        'status__in': statusIn,
      },
    });
  }
  /**
   * List all DailyJobs.
   *
   * If no filter is included at all, it only shows today.
   * @returns DailyNote
   * @throws ApiError
   */
  public static dailynoteCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: DailyNote,
  }): CancelablePromise<DailyNote> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/dailynote',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List all DailyJobs.
   *
   * If no filter is included at all, it only shows today.
   * @returns DailyNote
   * @throws ApiError
   */
  public static dailynoteRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<DailyNote> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/dailynote/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * List all DailyJobs.
   *
   * If no filter is included at all, it only shows today.
   * @returns DailyNote
   * @throws ApiError
   */
  public static dailynoteUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: DailyNote,
  }): CancelablePromise<DailyNote> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/dailynote/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List all DailyJobs.
   *
   * If no filter is included at all, it only shows today.
   * @returns DailyNote
   * @throws ApiError
   */
  public static dailynotePartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedDailyNote,
  }): CancelablePromise<DailyNote> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/dailynote/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List all DailyJobs.
   *
   * If no filter is included at all, it only shows today.
   * @returns any No response body
   * @throws ApiError
   */
  public static dailynoteDeletePartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedEventDestroy,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/dailynote/{uid}/delete',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Set the daily job done.
   * @returns DailyNoteSetDoneAndUnDone
   * @throws ApiError
   */
  public static dailynoteSetDonePartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedDailyNoteSetDoneAndUnDone,
  }): CancelablePromise<DailyNoteSetDoneAndUnDone> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/dailynote/{uid}/set_done',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Set the daily job done.
   * @returns DailyNoteSetDoneAndUnDone
   * @throws ApiError
   */
  public static dailynoteSetUndonePartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedDailyNoteSetDoneAndUnDone,
  }): CancelablePromise<DailyNoteSetDoneAndUnDone> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/dailynote/{uid}/set_undone',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
