import { useAccount } from 'context/AccountContext';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Frequency, RRule, RRuleSet, rrulestr } from 'rrule';
import Button, { ButtonSize } from 'ui/Button';
import { CaretDown } from '@phosphor-icons/react';
import { DateInputRaw } from '../DateInput/DateInput';
import { frequencyToStringPlural, frequencyToStringSingle, WeekDay, weekdaysToByWeekDay } from '.';
import { addYears } from 'date-fns';

interface RRuleComponentProps {
  rRule?: string;
  availableDays: WeekDay[];
  onRRuleUpdated: (rRule: string | undefined) => void;
  referenceDate: Date; // The selected date to base the RRule on.
}

// A component that can create/edit an rRule. RRules are recurrence rules for calendar activities: https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html
// It's tailor for weekly recurrence only (for facility planning)
export default function RRuleWeeklyComponent({
  referenceDate,
  availableDays,
  rRule: rRuleString,
  onRRuleUpdated,
}: RRuleComponentProps): JSX.Element {
  const { formatDateIntl } = useAccount();
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState<boolean>(false);
  const [interval, setInterval] = useState<number>(1);
  const [weekDays, setWeekDays] = useState<WeekDay[]>([referenceDate.getDay()]); // Default to monday
  const [until, setUntil] = useState<Date | undefined>();
  const [hasInitialRRuleString] = useState<boolean>(!!rRuleString);

  const refFrequency = useRef<HTMLInputElement>(null);
  const refInterval = useRef<HTMLSelectElement>(null);

  const buildRRule = (interval: number, weekDays: WeekDay[], until: Date | undefined): RRule | undefined => {
    // Little workaround for very early years.
    // The RRule builder doesn't like it when you fill in 01-01-0001
    // Just make the until empty when we're below 1900.
    if ((until?.getFullYear() ?? 0) < 1900) {
      until = undefined;
    }

    return new RRule({
      freq: Frequency.WEEKLY,
      interval,
      byweekday: weekdaysToByWeekDay(weekDays),
      until,
    });
  };

  // Set the correct values in init
  useEffect(() => {
    if (!rRuleString) {
      return;
    }

    // The RRule may come with e.g. EXDATE. Use rrulestr method to be safe.
    const rRuleSet = rrulestr(rRuleString, {
      forceset: true,
    }) as RRuleSet;
    const rRule = rRuleSet.rrules()[0];

    setEnabled(true);

    // Set the interval
    setInterval(rRule.options.interval);
    if (refInterval.current) {
      refInterval.current.value = rRule.options.interval.toString();
    }

    const weekDays: WeekDay[] = [];
    for (const weekdayIndex of rRule.options.byweekday ?? []) {
      switch (weekdayIndex) {
        case 0:
          weekDays.push(WeekDay.Mo);
          break;
        case 1:
          weekDays.push(WeekDay.Tu);
          break;
        case 2:
          weekDays.push(WeekDay.We);
          break;
        case 3:
          weekDays.push(WeekDay.Th);
          break;
        case 4:
          weekDays.push(WeekDay.Fr);
          break;
        case 5:
          weekDays.push(WeekDay.Sa);
          break;
        case 6:
          weekDays.push(WeekDay.Su);
          break;
      }
    }
    setWeekDays(weekDays);

    // Set until
    if (rRule.options.until) {
      setUntil(rRule.options.until);
    }
  }, [rRuleString, referenceDate]);

  return (
    <div
      className={classNames('space-y-2', {
        'block border rounded-lg pt-2 px-2': enabled,
        'inline-block': !enabled,
      })}
    >
      {!hasInitialRRuleString && (
        <div className='flex items-center'>
          <label className='text-sm flex items-center gap-1'>
            <input
              type='checkbox'
              ref={refFrequency}
              onChange={e => {
                setEnabled(e.target.checked);
                if (e.target.checked) {
                  onRRuleUpdated(buildRRule(interval, weekDays, until)?.toString());
                } else {
                  onRRuleUpdated(undefined);
                }
              }}
            />
            <span className='cursor-pointer select-none'>{t('enable-recurrence', 'Enable recurrence')}</span>
          </label>
        </div>
      )}
      {enabled && (
        <div className='space-y-3 pb-2'>
          <p>
            <span className='text-sm'>{t('rrule-interval-label-pre', 'Repeat every')}</span>
            <span className='inline-flex items-center mr-3 ml-1'>
              <select
                className='text-center border rounded mr-2 text-sm p-0.5 pr-6 bg-transparent appearance-none'
                ref={refInterval}
                value={interval}
                onChange={e => {
                  const val: number = parseInt(e.target.value);
                  setInterval(isNaN(val) ? 1 : val);
                  onRRuleUpdated(buildRRule(val, weekDays, until)?.toString());
                }}
              >
                {[...Array(100)].map((element, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
              <CaretDown className='-ml-8 pointer-events-none' />
            </span>
            <span className='text-sm'>
              {interval === 1
                ? frequencyToStringSingle(t, Frequency.WEEKLY).toLowerCase()
                : frequencyToStringPlural(t, Frequency.WEEKLY).toLowerCase()}
              .
            </span>
          </p>
          <div className='space-x-1'>
            {Object.values(WeekDay)
              .filter(value => typeof value !== 'string')
              .map(element => (
                <div key={element} className='inline-block relative w-7 h-7'>
                  <input
                    type='checkbox'
                    key={element}
                    name={`weekday.${element}`}
                    className={classNames(
                      'peer w-full h-full appearance-none border rounded-full bg-gray-200 checked:bg-blue-500 border-none',
                      { 'opacity-20 cursor-not-allowed': !availableDays.includes(element) },
                      { 'cursor-pointer': availableDays.includes(element) },
                    )}
                    checked={weekDays.includes(element)}
                    onChange={e => {
                      const res = e.target.checked ? [...weekDays, element] : weekDays.filter(day => day !== element);
                      setWeekDays(res);
                      onRRuleUpdated(buildRRule(interval, res, until)?.toString());
                    }}
                  />
                  <span
                    className={classNames(
                      'absolute inset-0 text-center pointer-events-none text-xs flex items-center justify-center text-gray-500 peer-checked:text-white',
                      { 'opacity-20': !availableDays.includes(element) },
                    )}
                  >
                    {formatDateIntl(new Date(Date.UTC(2024, 9, 6 + element)), { weekday: 'narrow' })}
                  </span>
                </div>
              ))}
          </div>

          {!until && (
            <Button
              type='button'
              size={ButtonSize.XSmall}
              onClick={() => {
                const date = addYears(new Date(), 1);
                setUntil(date);
                onRRuleUpdated(buildRRule(interval, weekDays, date)?.toString());
              }}
            >
              {t('rrule-set-end-date', 'Set end date')}
            </Button>
          )}
          {until && (
            <>
              <DateInputRaw
                label={t('rrule-end-date', 'End date')}
                dateValue={until}
                onDateChange={date => {
                  setUntil(date);
                  onRRuleUpdated(buildRRule(interval, weekDays, date)?.toString());
                }}
              />
              <Button
                type='button'
                size={ButtonSize.XSmall}
                onClick={() => {
                  setUntil(undefined);
                  onRRuleUpdated(buildRRule(interval, weekDays, undefined)?.toString());
                }}
              >
                {t('rrule-remove-end-date', 'Remove end date')}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
