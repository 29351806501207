/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `fcm_equinem` - Equinem
 */
export enum ApplicationIdEnum {
  FCM_EQUINEM = 'fcm_equinem',
}
