import React, { ReactNode } from 'react';
import { Control, FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import Toggle from 'ui/Toggle';
import { Hint } from 'ui/Hint';
import { Label } from 'ui/Label';
import { InputError } from 'ui/InputError';

/**
 * Complex type that extend some magic types from React Form hooks and include own fields
 *
 * See --> https://github.com/orgs/react-hook-form/discussions/7851#discussioncomment-2219298
 */
interface Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  extends UseControllerProps<TFieldValues, TName> {
  name: TName; // override from UseControllerProps and make it required
  control: Control<TFieldValues>; // override from UseControllerProps and make it required
  label?: string;
  hint?: ReactNode;
  error?: string;
  required?: boolean;
  className?: string;
  asRow?: boolean;
}

/**
 * Create a Toggle input
 */
function TogggleInput<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  label,
  hint,
  error,
  required,
  className,
  asRow,
  disabled,
  ...props
}: Props<TFieldValues, TName>): JSX.Element {
  const { field } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  if (asRow) {
    return (
      <div className={className}>
        <div className='flex justify-between gap-x-5'>
          <div>
            {label && (
              <Label>
                {label} {required && '*'}
              </Label>
            )}

            {hint && <Hint>{hint}</Hint>}
          </div>

          <div>
            <Toggle className='my-1' active={field.value} disabled={disabled} onToggle={active => field.onChange(active)} />
          </div>
        </div>

        {error && <InputError>{error}</InputError>}
      </div>
    );
  }

  return (
    <div className={className}>
      {label && (
        <Label>
          {label} {required && '*'}
        </Label>
      )}

      <Toggle className='my-1' active={field.value} disabled={disabled} onToggle={active => field.onChange(active)} />

      {hint && <Hint>{hint}</Hint>}
      {error && <InputError>{error}</InputError>}
    </div>
  );
}

export default TogggleInput;
