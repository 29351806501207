import { Clock, DotsThreeVertical, Plus } from '@phosphor-icons/react';
import useApiPromises from 'api/hooks/useApiPromises';
import { useOrganization } from 'context/OrganizationContext';
import { FeedingsService, Feeding } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { table, tableHiddenHeaderSm, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import DropdownMenu from 'ui/DropdownMenu';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';
import SaveFeedingModal from './SaveFeedingModal';
import RemoveFeedingModal from './RemoveFeedingModal';

export default function FeedingSettingsTile(): JSX.Element {
  const [feedings, setFeedings] = useState<Feeding[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { t } = useTranslation();
  const { loading: loadingApiPromises } = useApiPromises({ apiPromises });
  const { selectedOrganization } = useOrganization();
  const { showModal: showSaveFeedingModal, closeModal: closeSaveFeedingModal, modalIsVisible: saveFeedingModalIsVisible } = useModal();
  const {
    showModal: showRemoveFeedingModal,
    closeModal: closeRemoveFeedingModal,
    modalIsVisible: removeFeedingModalIsVisible,
  } = useModal();
  const [edit, setEdit] = useState<Feeding>();
  const [removeFeeding, setRemoveFeeding] = useState<Feeding>();

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganization) {
      return promises;
    }
    promises.appendList<Feeding>(
      'feedings',
      () =>
        FeedingsService.feedingsList({
          organisationUid: selectedOrganization.uid,
        }),
      setFeedings,
    );
    setApiPromises(promises);
    return promises;
  }, [selectedOrganization]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const tileActions = useMemo(() => {
    return [
      {
        onClick: () => {
          setEdit(undefined);
          showSaveFeedingModal();
        },
        buttonVariant: ButtonVariant.Default,
        icon: <Plus />,
        text: t('add-feeding', 'Add feeding'),
      },
    ];
  }, [t, showSaveFeedingModal]);

  return (
    <Tile title={t('feedings', 'Feedings')} loading={loadingApiPromises} actions={tileActions}>
      <table className={table}>
        <thead className={tableThead}>
          <tr className={tableHiddenHeaderSm}>
            <td className='w-10' />

            <td className={tableTheadTd}>{t('name', 'Name')}</td>
            <td className={tableTheadTd}>{t('time', 'Time')}</td>
            <td className='w-10 md:hidden' />
          </tr>
        </thead>
        <tbody className={tableTbody}>
          {feedings?.length === 0 && (
            <tr>
              <td colSpan={100} className='text-center p-16 opacity-70'>
                {t('no-feeding', 'No feeding created.')}
              </td>
            </tr>
          )}
          {(feedings ?? []).map(feeding => {
            const items = feeding.time.split(':');
            const timeString = `${items[0]}:${items[1]}`;
            return (
              <tr className={tableTbodyTrNoClick} key={feeding.uid}>
                <td className='text-center w-10'>
                  <Clock size={22} weight='light' className='inline' />
                </td>
                <td>{feeding.name}</td>
                <td>{timeString}</td>
                <td className='w-14 pr-2'>
                  <DropdownMenu
                    menuPlacement='bottom-end'
                    menuItems={[
                      [
                        {
                          element: t('edit', 'Edit'),
                          onClick: () => {
                            setEdit(feeding);
                            showSaveFeedingModal();
                          },
                        },
                        {
                          element: t('remove', 'Remove'),
                          className: 'text-red-600',
                          onClick: () => {
                            setRemoveFeeding(feeding);
                            showRemoveFeedingModal();
                          },
                        },
                      ],
                    ]}
                  >
                    <div className='w-full text-right cursor-pointer'>
                      <DotsThreeVertical size={24} weight='bold' className='inline' />
                    </div>
                  </DropdownMenu>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <SaveFeedingModal
        onSaved={loadApiData}
        existingFeeding={edit}
        visible={saveFeedingModalIsVisible}
        closeModal={closeSaveFeedingModal}
        onClosed={() => {
          setEdit(undefined);
        }}
      />
      <RemoveFeedingModal
        feeding={removeFeeding}
        open={removeFeedingModalIsVisible}
        onRemoved={loadApiData}
        requestClose={closeRemoveFeedingModal}
      />
    </Tile>
  );
}
