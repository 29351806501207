/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ROUGHAGE` - roughage
 * * `CONCENTRATE` - concentrate
 * * `SUPPLEMENT` - supplement
 * * `MEDICINE` - medicine
 */
export enum FeedTypeCategoryEnum {
  ROUGHAGE = 'ROUGHAGE',
  CONCENTRATE = 'CONCENTRATE',
  SUPPLEMENT = 'SUPPLEMENT',
  MEDICINE = 'MEDICINE',
}
