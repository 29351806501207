import './wdyr'; // Need to be the first import!!
import './index.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/react';
import i18next, { t } from 'i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import zod_de_override from 'utilities/zod/translationOverrides/de.json';
import zod_en_override from 'utilities/zod/translationOverrides/en.json';
import zod_es_override from 'utilities/zod/translationOverrides/es.json';
import zod_fi_override from 'utilities/zod/translationOverrides/fi.json';
import zod_fr_override from 'utilities/zod/translationOverrides/fr.json';
import zod_it_override from 'utilities/zod/translationOverrides/it.json';
import zod_nl_override from 'utilities/zod/translationOverrides/nl.json';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';
import zod_de from 'zod-i18n-map/locales/de/zod.json';
import zod_en from 'zod-i18n-map/locales/en/zod.json';
import zod_es from 'zod-i18n-map/locales/es/zod.json';
import zod_fi from 'zod-i18n-map/locales/fi/zod.json';
import zod_fr from 'zod-i18n-map/locales/fr/zod.json';
import zod_it from 'zod-i18n-map/locales/it/zod.json';
import zod_nl from 'zod-i18n-map/locales/nl/zod.json';
import { Config } from './api/Config';
import App from './App';
import { AccountProvider } from './context/AccountContext';
import { ConfigProvider } from './context/ConfigContext';
import { OrganizationProvider } from './context/OrganizationContext';
import { PageProvider } from './context/PageContext';
import { de, en, es, fi, fr, hasFailed, it, nl } from './locales';
import { HelmetProvider } from 'react-helmet-async';
import LanguageDetector from 'i18next-browser-languagedetector';
import { InvitationProvider } from 'context/InvitationContext';
import { DeploymentProvider } from 'context/DeploymentContext';
import { PushNotificationProvider } from 'context/PushNotificationContext';
import { WebshopProvider } from 'context/WebshopContext';

let config: Config | undefined = undefined;

try {
  config = Config.getConfigFromEnv();
} catch (e) {
  console.error(e);
}

// Init sentry
if (config && config.isDevelopmentEnv === false) {
  Sentry.init({
    dsn: config.isGat
      ? 'https://299b13da507920eeaf907a080a9581b1@o4506257175347200.ingest.us.sentry.io/4508697952649216' // GAT
      : 'https://64c45440c2e238e394c26d64a0586466@o4506031623372800.ingest.sentry.io/4506031625076736', // production
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      Sentry.browserTracingIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!); //eslint-disable-line

if (config) {
  if (hasFailed && config.isDevelopmentEnv === false) {
    throw Error('Locales not found');
  }

  if (hasFailed && config.isDevelopmentEnv === true) {
    // @todo Make sure the developer sees this message. Maybe print it to
    // webpack so it's visible in the developers command line.
    console.warn('No locales loaded');
  }

  // Init translations
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init(
      {
        // debug: true, // Useful for debugging, displays which key is missing
        fallbackLng: 'en', // If translation key is missing, which lang use instead
        returnEmptyString: false, // Empty strings in translation will go to the fallbackLng
        resources: {
          // Load the translation files
          en: { translation: en, zod: zod_en, overrides: zod_en_override },
          de: { translation: de, zod: zod_de, overrides: zod_de_override },
          es: { translation: es, zod: zod_es, overrides: zod_es_override },
          fi: { translation: fi, zod: zod_fi, overrides: zod_fi_override },
          fr: { translation: fr, zod: zod_fr, overrides: zod_fr_override },
          it: { translation: it, zod: zod_it, overrides: zod_it_override },
          nl: { translation: nl, zod: zod_nl, overrides: zod_nl_override },
        },
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
          lookupQuerystring: 'lng', // Get language from query param
          order: ['querystring', 'navigator'], // Only detect the language from the browser navigator.
          caches: [], // Don't store the language in i.e. local-storage
        },
      },
      err => {
        if (err) {
          console.error('Failed to load i18next', err);
        }
      },
    );

  // First we use the overrides namespace, then we should use our translations that are
  // are translated by i18n to eventually fallback to the default ZOD translations
  z.setErrorMap(makeZodI18nMap({ ns: ['overrides', 'translation', 'zod'] }));

  // Installing the service worker
  if ('serviceWorker' in navigator) {
    // Register a service worker hosted at the root of the site using the
    // default scope.
    navigator.serviceWorker
      .register(`/service-worker.js`)
      .then(registration => {
        console.log('Service worker registration succeeded.', registration);
      })
      .catch(error => {
        console.error(`Service worker registration failed: ${error}`);
      });
  } else {
    console.error('Service workers are not supported.');
  }

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ConfigProvider config={config}>
          <PushNotificationProvider>
            <DeploymentProvider>
              <AccountProvider>
                <OrganizationProvider>
                  <PageProvider>
                    <HelmetProvider>
                      <InvitationProvider>
                        <WebshopProvider>
                          <App />
                        </WebshopProvider>
                      </InvitationProvider>
                    </HelmetProvider>
                  </PageProvider>
                </OrganizationProvider>
              </AccountProvider>
            </DeploymentProvider>
          </PushNotificationProvider>
        </ConfigProvider>
      </BrowserRouter>
    </React.StrictMode>,
  );
} else {
  root.render(
    <React.StrictMode>
      <p>{t('app.conf.failed', 'Failed to load configuration.')}</p>
    </React.StrictMode>,
  );
}
