// How many items should we fetch per page.
export const defaultApiPageSize = 25;

export const table = 'w-full table-auto';
export const tableThead = 'border-b select-none text-neutral-700 font-medium';

export const tableTheadTd = 'text-left h-12';
// Border-b on sticky headers don't work. We use the after pseudo element to create a border instead
// Works on all major browsers.
// Note: We put the sticky on the TD as this is the only way to make it work in safari.
export const tableTheadTdSticky =
  'md:sticky md:z-10 md:top-0 md:bg-white after:absolute after:border-b after:w-full after:left-0 after:right-0 after:z-10 after:h-[1px] after:-bottom-[1px] after:content-[""]';
export const tableTheadThSelect = 'pr-2 w-8';
export const tableTheadThName = 'w-6/12 2xl:w-8/12';
export const tableTheadThSortIcon = 'inline ml-1';
export const tableTbody = '';
export const tableTbodyTrNoClick = 'border-b border-neutral-100 h-16 last:border-b-0';
export const tableTbodyTr = tableTbodyTrNoClick + ' select-none cursor-pointer hover:bg-neutral-100';

// Hide a column on smaller display sizes.
export const tableHiddenColumnXs = 'hidden xs:table-cell';
export const tableHiddenColumnSm = 'hidden sm:table-cell';
export const tableHiddenColumnMd = 'hidden md:table-cell';
export const tableHiddenColumnLg = 'hidden lg:table-cell';
export const tableHiddenColumnXl = 'hidden xl:table-cell';

// Hide a header on smaller display sizes.
export const tableHiddenHeaderXs = 'hidden xs:table-row';
export const tableHiddenHeaderSm = 'hidden sm:table-row';
export const tableHiddenHeaderMd = 'hidden md:table-row';
export const tableHiddenHeaderLg = 'hidden lg:table-row';

export const FALLBACK_ACTIVITY_PRIMARY_COLOR = '#9F9F9F';
export const FALLBACK_ACTIVITY_SECONDARY_COLOR = '#C7C7C7';

// Subscriptions are managed by the Equinem organization. This is the UID of the Equinem organization.
// We use this UID for all subscription-related calls.
export const EQUINEM_PUBLIC_ORG_UID = '00000000-0000-0000-0000-000000000000';
