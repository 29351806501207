import { usePlanning } from 'hooks/UsePlanning';
import React from 'react';
import useCalendarRow from './UseCalendarRow';
import { CalendarCluster, equalGroupByApplied } from 'context/Calendar';
import classNames from 'classnames';
import ColumnHeaderContent from './ColumnHeaderContent';
import { ArrowsVertical } from '@phosphor-icons/react';
import { DayGrid, DayGridScale } from '../DayGrid';
import { DayNowIndicator, Shape } from '../DayNowIndicator';
import { TimeScale } from 'utilities/Planning';

interface ColHeaderProps {
  calendarRow: CalendarCluster;
  columnHeaderWidth: number;
  hideColumnHeaderLabel: boolean;
  activityHeight: number;
  enableClickToExpand: boolean;
  spacious: boolean;
  showTopBorder: boolean;
  now: Date;
  isMobileView: boolean;
}

export default function ColumnHeader({
  calendarRow,
  columnHeaderWidth,
  hideColumnHeaderLabel,
  activityHeight,
  spacious,
  enableClickToExpand,
  showTopBorder,
  now,
  isMobileView,
}: ColHeaderProps): JSX.Element {
  const { timeScale, dayParts, selectedGroupBy, setSelectedGroupBy, unsetBluePrint, roles } = usePlanning();

  const { calendarRowDaySections, groupedByTimeScale } = useCalendarRow({ spacious });

  const sections = calendarRowDaySections(calendarRow);
  const subjectTimeScale = groupedByTimeScale(calendarRow.groupBy);
  // All heights together.
  const visibleHeights = sections.heights?.slice(sections.visibleRange?.start ?? 0, sections.visibleRange?.end ?? sections.heights.length);
  const fullHeight = visibleHeights.reduce((partialSum, height) => partialSum + height, 0);
  return (
    <div style={{ width: columnHeaderWidth }} className={classNames('overflow-visible', { 'pt-8 md:pt-0': !hideColumnHeaderLabel })}>
      {!hideColumnHeaderLabel && (
        <div className='flex md:hidden w-screen border-b items-end max-h-8 h-8 -mt-8 sticky top-0 bg-neutral-50 z-10'>
          <ColumnHeaderContent
            orientation='row'
            calendarRow={calendarRow}
            roles={roles ?? []}
            fullHeight={fullHeight}
            activityHeight={activityHeight}
          />
        </div>
      )}
      <div
        className={classNames('flex border-r shrink-0 relative items-start justify-end', {
          'hover:bg-gray-100 group hover:cursor-pointer overflow-hidden': timeScale !== TimeScale.TimeScale && enableClickToExpand,
        })}
        style={{
          width: columnHeaderWidth,
          height: fullHeight,
        }}
        onClick={() => {
          if (enableClickToExpand && timeScale !== TimeScale.TimeScale) {
            unsetBluePrint(0);

            setSelectedGroupBy(equalGroupByApplied(selectedGroupBy, calendarRow.groupBy) ? undefined : calendarRow.groupBy);
          }
        }}
      >
        {!hideColumnHeaderLabel && !isMobileView && (
          <ColumnHeaderContent
            orientation='col'
            calendarRow={calendarRow}
            roles={roles ?? []}
            fullHeight={fullHeight}
            activityHeight={activityHeight}
          />
        )}
        {timeScale !== TimeScale.TimeScale && (
          <div
            className={classNames('text-white group-hover:visible rounded-full p-1 absolute bottom-2 left-2 bg-blue-500', {
              visible: equalGroupByApplied(selectedGroupBy, calendarRow.groupBy),
              invisible: !equalGroupByApplied(selectedGroupBy, calendarRow.groupBy),
            })}
          >
            <ArrowsVertical />
          </div>
        )}
        {!hideColumnHeaderLabel && <div className={classNames('absolute inset-x-0 bottom-0 border-b h-1')} />}
        {subjectTimeScale !== TimeScale.FullDay && (
          <>
            <DayGridScale
              heights={sections.heights}
              visibleRange={sections.visibleRange}
              dayParts={subjectTimeScale === TimeScale.DayParts ? dayParts : undefined}
            />
            <DayGrid
              showFirstGridLine={showTopBorder}
              className='w-1.5 min-w-1.5'
              heights={sections.heights}
              visibleRange={sections.visibleRange}
            />
            {subjectTimeScale === TimeScale.TimeScale && (
              <DayNowIndicator
                heights={sections.heights}
                visibleRange={sections.visibleRange}
                day={new Date()}
                now={now}
                shape={Shape.Triangle}
                className='absolute inset-y-0 right-0 w-[7px]'
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
