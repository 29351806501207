/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `view_own_horses` - View own horse(s)
 * * `view_horses` - View horses
 * * `manage_horses` - Manage horses
 * * `view_horse_files` - View horse media & documents
 * * `edit_horse_files` - Edit horse media & documents
 * * `view_horse_owners` - View horse owners
 * * `view_veterinary_activities` - View veterinary activities
 * * `medical_files` - View medical documents and media
 * * `view_schedule` - View schedule
 * * `set_to_done` - Check activities
 * * `manage_schedule` - Manage schedule
 * * `manage_semen_journal` - Manage breeding
 * * `manage_orders` - Manage orders
 * * `pick_orders` - Pick orders
 * * `manage_financial` - Manage financial administration
 * * `view_contacts` - View contacts
 * * `manage_contacts` - Manage contacts
 * * `view_feed` - View feed
 * * `manage_feed` - Manage feed
 * * `plan_own_facilities_events` - Book facilities
 * * `manage_facilities` - Manage facilities
 */
export enum ModulePermissionsEnum {
  VIEW_OWN_HORSES = 'view_own_horses',
  VIEW_HORSES = 'view_horses',
  MANAGE_HORSES = 'manage_horses',
  VIEW_HORSE_FILES = 'view_horse_files',
  EDIT_HORSE_FILES = 'edit_horse_files',
  VIEW_HORSE_OWNERS = 'view_horse_owners',
  VIEW_VETERINARY_ACTIVITIES = 'view_veterinary_activities',
  MEDICAL_FILES = 'medical_files',
  VIEW_SCHEDULE = 'view_schedule',
  SET_TO_DONE = 'set_to_done',
  MANAGE_SCHEDULE = 'manage_schedule',
  MANAGE_SEMEN_JOURNAL = 'manage_semen_journal',
  MANAGE_ORDERS = 'manage_orders',
  PICK_ORDERS = 'pick_orders',
  MANAGE_FINANCIAL = 'manage_financial',
  VIEW_CONTACTS = 'view_contacts',
  MANAGE_CONTACTS = 'manage_contacts',
  VIEW_FEED = 'view_feed',
  MANAGE_FEED = 'manage_feed',
  PLAN_OWN_FACILITIES_EVENTS = 'plan_own_facilities_events',
  MANAGE_FACILITIES = 'manage_facilities',
}
