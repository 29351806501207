import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../ui/Layout';
import { PageMaxWidth } from 'ui/Layout/Page';
import FeedTypesTile from 'components/Feed/FeedTypesTile';
import FeedingSettingsTile from 'components/Feed/FeedingSettingsTile';

export default function FeedSettings(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Page title={t('settings', 'Settings')} maxWidth={PageMaxWidth.Tile}>
      <div className='space-y-4'>
        <FeedTypesTile />
        <FeedingSettingsTile />
      </div>
    </Page>
  );
}
