import classNames from 'classnames';
import { usePlanning } from 'hooks/UsePlanning';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import Calendar from 'context/Calendar';
import { CalendarView, SelectedActivityState } from 'utilities/Planning';
import { flushSync } from 'react-dom';
import useUserAgent from 'api/hooks/useUserAgent';
import GestureWrapper from 'ui/GestureWrapper';

interface Props {
  className?: string;

  // Render a calendar within the view
  render: (calendar: Calendar, availableWidth: number) => ReactNode;

  // The source calendars
  calendarView: CalendarView;
}

enum Direction {
  Forward,
  Backward,
  Still,
}

/**
 * A carousel-like slidable calendar view.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * The view uses a SlidableCalendarView for extra smoothness.
 *
 *                                      ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 *                                      │  12  │  13  │  14  │  15  │  16  │  17  │  18  │
 *                                      │      │      │      │      │      │      │      │
 *                                      ┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 *                                      └──────└──────└──────└──────└──────└──────└──────┘
 */
export default function SlidableCalendarView({ className, render, calendarView }: Props): JSX.Element {
  const { offset, setOffset, bluePrint, selectedActivity } = usePlanning();
  const ref = useRef<HTMLDivElement>(null);
  const [canvasWidth, setCanvasWidth] = useState<number>(0);

  // Is the print media query active.
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const { animationsEnabled } = useUserAgent();

  const direction = useMemo((): Direction => {
    if (
      calendarView.previousOffset === undefined ||
      calendarView.left === undefined ||
      calendarView.offset === calendarView.previousOffset
    ) {
      return Direction.Still;
    }
    return calendarView.offset > calendarView.previousOffset ? Direction.Forward : Direction.Backward;
  }, [calendarView]);

  const startPosX = useMemo(() => {
    switch (direction) {
      case Direction.Still:
        return canvasWidth * -1;
      case Direction.Backward:
        return canvasWidth * 2 * -1;
      case Direction.Forward:
        return 0;
    }
  }, [direction, canvasWidth]);

  // Check if we're are printing (on paper) this page. Update the isPrinting boolean accordingly.
  // https://react.dev/reference/react-dom/flushSync#usage
  // This is the only way (I found on the interwebz) to check if we're print(preview)ing and communicate it to React.
  useEffect(() => {
    function handleBeforePrint() {
      flushSync(() => {
        setIsPrinting(true);
      });
    }

    function handleAfterPrint() {
      setIsPrinting(false);
    }

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);
    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

  // Keep track of thw width of the div. We can pass this down to the render items so they know the available width of their component.
  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setCanvasWidth(ref.current?.clientWidth ?? 0);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [calendarView]);

  // Do an animated slide?
  const animated = !isPrinting || !animationsEnabled;
  return (
    <GestureWrapper
      onSwipeLeft={() => setOffset(offset + 1)}
      onSwipeRight={() => setOffset(offset - 1)}
      // Have a default w-0 so that the it wants to be as small as it can.
      className={classNames(className, 'overflow-x-hidden w-0')}
      ref={ref}
      enabled={
        !(
          bluePrint ||
          selectedActivity?.selectedActivityState === SelectedActivityState.Edit ||
          selectedActivity?.selectedActivityState === SelectedActivityState.Info
        )
      }
    >
      <div
        key={`${calendarView?.offset}`}
        style={{ transform: animated ? `translateX(${startPosX}px)` : undefined, width: animated ? canvasWidth * 3 : undefined }}
        className={classNames('flex h-full', {
          // This class is defined in index.css
          'slidable-calendar-view-animate-to-current': direction !== Direction.Still && animated,
        })}
      >
        {canvasWidth !== 0 && calendarView && (
          <>
            {animated && <div className='grow'>{render(calendarView.left, canvasWidth)}</div>}
            <div className='grow'>{render(calendarView.current, canvasWidth)}</div>
            {animated && <div className='grow'>{render(calendarView.right, canvasWidth)}</div>}
          </>
        )}
      </div>
    </GestureWrapper>
  );
}
