import { useOrganization } from 'context/OrganizationContext';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Horse,
  PurchaserOrder,
  PurchaserOrderItem,
  OrdersService,
  PublicService,
  SemenTypeEnum,
  SexEnum,
  CatalogueProduct,
  StudbookEnum,
  UsageTypeEnum,
  CountryEnum,
  Redirect,
  ApiError,
  Contact,
  ShippingServiceTypeEnum,
  ContactsService,
  PurchaserOrderDetail,
} from 'openapi';
import { generatePath, useParams } from 'react-router-dom';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { ErrorSection } from 'ui/Error';
import { transformEmptyToUndefined, transformValue, zodInputIsRequired } from 'utilities/zod';
import { CheckboxInput, DateInput, SelectInput, TextAreaInput, TextInput } from 'ui/Inputs';
import { ButtonVariant } from 'ui/Button';
import { useAccount } from 'context/AccountContext';
import RadioButtonGroup from 'ui/Inputs/RadioGroupInput';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import useWebshop from 'api/hooks/useWebshop';
import PageModal, { ActionProps, PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { CatalogueShippingOptions, orderRequiresPayment, SemenTypeOptions, SemenUsageTypeOptions } from 'components/Breeding/Helpers';
import HorseInputSelect from 'components/Horses/HorseInputSelect';
import Stepper from 'ui/Stepper';
import { Alert } from 'ui/Alert';
import OrderSummaryTable from 'components/Breeding/OrderSummaryTable';
import { Severity } from 'utilities/severity';
import { generateWebshopPath, WebshopRoutes } from 'AppRoutes';
import { today } from 'utilities/date.utilities';
import useStables from 'api/hooks/useStables';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import ContactAddress from 'components/Contacts/ContactAddress';
import useModal from 'ui/Modals/UseModal';
import UpdateAddressModal from 'components/Contacts/Modals/UpdateAddressModal';
import { z } from 'zod';
import { schemas } from 'openapi/zod-schemas';
import useCountries from 'hooks/UseCountries';
import StudbookInputSelect from 'components/Breeding/StudbookInputSelect';
import { studBookMap } from 'components/Breeding/Studbook';
import { contactName } from 'utilities/Contact';
import classNames from 'classnames';

interface Props {
  // A new order can be a followup on a previous order. This happens when the
  // mare doesn't get pregnant. The breeding costs of a repeat order are 0.
  // The field is the base order to followup on.
  repeatOrder?: PurchaserOrder;
  horses: Horse[];
  contacts: Contact[];
  open: boolean;
  catalogue?: CatalogueProduct[];
  onRequestClose: (newOrderUid?: string) => void;
  onHorsesUpdate: () => void;
  onContactUpdate: () => void;
  onPaymentLinkFailure: (newOrderUid: string, error: ApiErrorParser<Redirect>) => void;
}

export const NewOrderSchema = z.object({
  product: z.string(),
  mare: z.string(),
  customer_note: z.string().optional(),
  semen_type: schemas.SemenTypeEnum,
  usage_type: schemas.UsageTypeEnum,
  studbook: schemas.StudbookEnum,
  // Customer
  customer_uid: z.string(),
  vat_number: z.string().optional(),

  // Shipping
  shipping_name: z.string().max(255).optional(),
  shipping_address_line1: z.string().max(255).optional(),
  shipping_address_line2: z.string().max(255).optional(),
  shipping_address_line3: z.string().max(255).optional(),
  shipping_city: z.string().max(255).optional(),
  shipping_state: z.string().max(255).optional(),
  shipping_postcode: z.string().max(64).optional(),
  shipping_country: z.string().optional(),
  shipping_date: z.string(),
  shippingProduct: z.string(),

  terms_accepted: z.boolean(),
});

export type NewOrder = z.infer<typeof NewOrderSchema>;

export default function NewOrderModal({
  horses,
  contacts,
  repeatOrder,
  open,
  onRequestClose,
  onPaymentLinkFailure,
  onHorsesUpdate,
  onContactUpdate,
  catalogue,
}: Props): JSX.Element {
  const { selectedOrganization, selectedOrganizationDetails, selectedOrganizationPrimaryStableLocation } = useOrganization();
  const { accountDetails } = useAccount();
  const { webshopOrganization } = useWebshop();
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(1);
  const [stepsWithError, setStepsWithError] = useState<number[]>([]);
  const { publicAccessUuid } = useParams();
  const { stables } = useStables();
  const { countryById, countries } = useCountries();

  const {
    modalIsVisible: customerAddressModalIsVisible,
    closeModal: closeCustomerAddressModal,
    showModal: showCustomerAddressModal,
  } = useModal();

  // We use dry-run to post an order to the api but not store it. The result of
  // the order has all vat and breeding values correctly set. This way we can
  // show a summary of the prices before submitting.
  const [dryRun, setDryRun] = useState<PurchaserOrderDetail | undefined>();

  const mares = useMemo(() => {
    if (!horses) return [];
    return horses.filter(horse => horse.sex === SexEnum._2 && !horse.hidden);
  }, [horses]);

  const schema = useMemo(() => {
    return NewOrderSchema;
  }, []);

  const defaultValues = useMemo((): Partial<NewOrder> => {
    const myselfContact = accountDetails ? contacts.find(contact => contact.user_uid === accountDetails?.uid) : undefined;
    if (repeatOrder) {
      if (!catalogue) {
        return {};
      }
      const breedingItems = (repeatOrder.order_items ?? []).filter(
        item => item.product_uid === catalogue.find(prod => prod.uid === item.product_uid && prod.category.default === 'BREEDING')?.uid,
      );
      if (breedingItems.length > 1) {
        console.error('Repeat order with multiple breeding products is not supported. Taking the first from the list.');
      }
      if (breedingItems.length === 0) {
        console.error(
          'Breeding product for repeat order not found in order items list. Fill in as much as possible to still show the user something.',
        );
      }

      const breedingItem = breedingItems[0];

      if (!breedingItem) {
        return {};
      }

      return {
        product: breedingItem.product_uid,
        mare: breedingItem.mare_uid ?? undefined,
        semen_type: breedingItem.semen_type,
        usage_type: breedingItem.usage_type,
        studbook: breedingItem.studbook,
        customer_uid: myselfContact?.uid,
        shipping_name: myselfContact ? contactName(myselfContact) : undefined,
        shipping_address_line1: myselfContact?.address_line1,
        shipping_address_line2: myselfContact?.address_line2,
        shipping_address_line3: myselfContact?.address_line3,
        shipping_city: myselfContact?.city,
        shipping_state: myselfContact?.state,
        shipping_postcode: myselfContact?.postcode,
        shipping_country: myselfContact?.country,
        vat_number: myselfContact?.vat_number,
        shipping_date: today(),
      };
    }
    return {
      customer_uid: myselfContact?.uid,
      shipping_name: myselfContact ? contactName(myselfContact) : undefined,
      shipping_address_line1: myselfContact?.address_line1,
      shipping_address_line2: myselfContact?.address_line2,
      shipping_address_line3: myselfContact?.address_line3,
      shipping_city: myselfContact?.city,
      shipping_state: myselfContact?.state,
      shipping_postcode: myselfContact?.postcode,
      shipping_country: myselfContact?.country,
      vat_number: myselfContact?.vat_number,
      shipping_date: today(),
    };
  }, [accountDetails, contacts, catalogue, repeatOrder]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
    reset,
    setValue,
    trigger,
    getFieldState,
  } = useForm<NewOrder>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });

  const selectedMareUid = watch('mare');

  const selectedCustomerUid = watch('customer_uid');
  const shippingProduct = watch('shippingProduct');
  const selectedShippingCountry = watch('shipping_country');

  // Get the shipping product type so we can determine if we need to show the
  // shipping address div.
  const shippingServiceType = useMemo((): ShippingServiceTypeEnum | undefined => {
    return catalogue?.find(prod => prod.uid === shippingProduct)?.shipping_service_type;
  }, [catalogue, shippingProduct]);

  // const receiverContact = useMemo((): Contact | undefined => {
  //   if (selectedReceiverUid) {
  //     return contacts.find(contact => contact.uid === selectedReceiverUid);
  //   }
  // }, [selectedReceiverUid, contacts]);

  const customerContact = useMemo((): Contact | undefined => {
    if (selectedCustomerUid) {
      return contacts.find(contact => contact.uid === selectedCustomerUid);
    }
  }, [selectedCustomerUid, contacts]);

  const { fieldError, nonFieldErrors, setApiError, hasApiError } = useFormError(schema, errors);

  const onSubmit = async (data: NewOrder) => {
    if (!selectedOrganization) {
      console.error('Cannot load invoice without a selected organization');
      return;
    }

    // We do a dry run to get the finalized order including all the selected
    // vat percentages and prices. This is used to show in the summary before
    // finalizing the order.
    const doDryRun = step !== 4;

    /**
     * The NewOrder type is generated by Zod. After submitting the form we want
     * to transform it to a PurchaserOrder so we can send it to the api.
     */
    const transformToOrder = async (data: NewOrder): Promise<Partial<PurchaserOrderDetail>> => {
      const catalogueItem = catalogue?.find(item => item.uid === data.product);
      const catalogueShippingItem = catalogue?.find(item => item.uid === data.shippingProduct);
      if (!catalogueItem) {
        throw Error('Catalogue item not found');
      }
      if (!catalogueShippingItem) {
        throw Error('Catalogue shipping item not found');
      }
      const mare = mares?.find(horse => horse.uid === data.mare);
      if (!mare) {
        throw Error('Mare item not found with id ' + data.mare);
      }

      if (!accountDetails) {
        throw Error('AccountDetails object is not set');
      }

      const hasShipping = catalogueShippingItem.shipping_service_type !== ShippingServiceTypeEnum.PICK_UP;

      // If it's a repeat order, then get the original order item this order is based upon.
      let repeatOrderItemUid: string | undefined = undefined;
      if (repeatOrder) {
        const repeatOrderItem = repeatOrder.order_items?.find(item => item.mare_uid !== undefined);
        if (!repeatOrderItem) {
          throw Error('Trying to create a repeat order but the original order item cannot be found.');
        }
        repeatOrderItemUid = repeatOrderItem.uid;
      }

      const orderItems: Partial<PurchaserOrderItem>[] = [
        {
          product_uid: catalogueItem.uid,
          quantity: 1, // @NOTE For webshop order the amount is 1.
          semen_type: data.semen_type as SemenTypeEnum,
          usage_type: data.usage_type as UsageTypeEnum,
          studbook: data.studbook as StudbookEnum,
          mare_uid: mare.uid,
          parent_semen_order_item_uid: repeatOrderItemUid,
        },
        {
          product_uid: data.shippingProduct,
        },
      ];
      return {
        customer_note: data.customer_note,
        supplier: publicAccessUuid,
        customer_uid: data.customer_uid,

        shipping_name: hasShipping ? data.shipping_name : undefined,
        shipping_address_line1: hasShipping ? data.shipping_address_line1 : undefined,
        shipping_address_line2: hasShipping ? data.shipping_address_line2 : undefined,
        shipping_address_line3: hasShipping ? data.shipping_address_line3 : undefined,
        shipping_city: hasShipping ? data.shipping_city : undefined,
        shipping_state: hasShipping ? data.shipping_state : undefined,
        shipping_postcode: hasShipping ? data.shipping_postcode : undefined,
        shipping_country: hasShipping ? (data.shipping_country as CountryEnum) : undefined,

        shipping_date: data.shipping_date,
        order_items: orderItems as PurchaserOrderItem[],
        dry_run: doDryRun,
        terms_accepted: data.terms_accepted,
      };
    };

    setApiError(undefined);
    if (doDryRun) {
      // Only unset the dry run when we're generating a new one. Otherwise the
      // the gui flickers
      setDryRun(undefined);
    }

    try {
      if (customerContact?.vat_number !== data.vat_number) {
        // Update the vat number of the contact.
        await ContactsService.contactsPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: data.customer_uid,
          requestBody: { vat_number: data.vat_number },
        });
      }

      const order = await OrdersService.ordersPurchasedCreate({
        purchaserUid: selectedOrganization.uid,
        requestBody: (await transformToOrder(data)) as PurchaserOrderDetail,
      });

      // No more errors. We have a result.
      setStepsWithError([]);

      if (doDryRun) {
        setDryRun(order);
      } else {
        // If we don't need to pay, then close the modal.
        if (!orderRequiresPayment(order)) {
          onRequestClose(order.uid);
          return;
        }

        // Create a payment link for the order and redirect to it.
        if (selectedOrganizationDetails?.can_create_payment_link) {
          try {
            const url = new URL(
              generateWebshopPath(generatePath(WebshopRoutes.OrderDetails.path, { uid: order.uid }), publicAccessUuid ?? ''),
              window.location.href,
            );
            const paymentLink = await PublicService.apiV5OrdersMolliePaymentLinkCreate({
              publicAccessUuid: order.public_access_uuid ?? '',
              requestBody: {
                // Redirect to the new and payed order.
                redirectUrl: url.toString(),
              },
            });
            window.location.href = paymentLink.redirect_uri;
          } catch (e) {
            onRequestClose(order.uid);
            onPaymentLinkFailure(order.uid, new ApiErrorParser<Redirect>(e));
          }
        } else {
          onRequestClose(order.uid);
        }
      }
    } catch (e) {
      if (e instanceof ApiError) {
        setApiError(new ApiErrorParser<PurchaserOrderDetail>(e));
      } else {
        console.error(e);
      }
      throw e;
    }
  };

  // Check if a step has field errors.
  const stepErrors = useCallback(
    async (step: number, includeApiErrors = true): Promise<boolean> => {
      const fields: (keyof NewOrder)[][] = [];
      fields[1] = ['product', 'mare', 'customer_note', 'semen_type', 'usage_type', 'studbook'];
      fields[2] = [
        'shipping_date',
        'shippingProduct',
        'shipping_name',
        'shipping_address_line1',
        'shipping_address_line2',
        'shipping_address_line3',
        'shipping_city',
        'shipping_state',
        'shipping_postcode',
        'shipping_country',
      ];
      fields[3] = ['customer_uid', 'customer_note', 'vat_number', 'terms_accepted'];

      for (const field of fields[step]) {
        await trigger(field);
        if ((includeApiErrors && fieldError(field)) || getFieldState(field).invalid) {
          return true;
        }
      }
      return false;
    },
    [fieldError, trigger, getFieldState],
  );

  const pageActions = useMemo((): ActionProps[] => {
    const availableActions: ActionProps[] = [];
    if (step > 1) {
      availableActions.push({
        variant: ButtonVariant.Default,
        text: t('back', 'Back'),
        type: 'button',
        onClick: () => setStep(step - 1),
      });
    }
    if (step < 4) {
      availableActions.push({
        variant: ButtonVariant.Primary,
        text: t('next-step', 'Next step'),
        type: 'button',
        loading: isSubmitting,
        onClick: () => {
          stepErrors(step, false).then(res => {
            if (!res) {
              if (step === 3) {
                handleSubmit(onSubmit)()
                  .then(() => {
                    // We have no error is the current step. We can move to the next.
                    setStep(step + 1);
                  })
                  .catch(e => {
                    console.error('Failed to go to final step in order process', e);
                  });
              } else {
                // We have no error is the current step. We can move to the next.
                setStep(step + 1);
              }
            }
          });
        },
      });
    } else if (step === 4) {
      availableActions.push({
        variant: ButtonVariant.Primary,
        text:
          selectedOrganizationDetails?.can_create_payment_link && dryRun && orderRequiresPayment(dryRun)
            ? t('place-order-and-pay', 'Place order and pay')
            : t('place-order', 'Place order'),
        type: 'submit',
        formId: 'PurchaserOrder',
        loading: isSubmitting,
      });
    }
    return availableActions;
  }, [step, stepErrors, t, isSubmitting, handleSubmit, dryRun, selectedOrganizationDetails]); //eslint-disable-line

  const semenTypeOptions = useMemo(() => {
    return SemenTypeOptions(t);
  }, [t]);

  /**
   * Build a list of usage type options to show in the dropdown.
   */
  const usageTypeOptions = useMemo(() => {
    return SemenUsageTypeOptions(t);
  }, [t]);

  // Build a list of breeding products to show in the dropdown.
  const productOptions = useMemo((): OptionItemInterface[] => {
    const productName = (product: CatalogueProduct) => {
      const stallionName = product.stallion?.name ?? t('unnamed-stallion', 'Unnamed stallion');
      const price = product.price
        ? Intl.NumberFormat(selectedOrganizationPrimaryStableLocation?.country, {
            style: 'currency',
            currency: product.price_currency ?? selectedOrganizationDetails?.currency ?? 'EUR',
          }).format(Number(product.price))
        : t('no-price', 'No price');
      return `${stallionName} (${price})`;
    };
    if (!catalogue) {
      return [];
    }
    return catalogue
      .filter(product => product.category.default === 'BREEDING')
      .map(product => ({ id: product.uid, name: productName(product) }));
  }, [catalogue, t, selectedOrganizationDetails, selectedOrganizationPrimaryStableLocation]);

  const stallionLabelText = webshopOrganization
    ? `${t('stallion-from', 'Stallion from')} ${webshopOrganization.name}`
    : t('stallion', 'Stallion');

  // Make a list of shipping products to choose from. You can only choose shipping
  // options that match delivery countries of the shipping country.
  const shippingOptions = useMemo((): OptionItemInterface[] => {
    return CatalogueShippingOptions(
      catalogue ?? [],
      selectedShippingCountry as CountryEnum,
      selectedOrganizationPrimaryStableLocation?.country,
      selectedOrganizationDetails?.currency,
    );
  }, [catalogue, selectedOrganizationDetails, selectedShippingCountry, selectedOrganizationPrimaryStableLocation]);

  // Name of the mare that is set in the form.
  const selectedMareName = useMemo(() => {
    if (!selectedMareUid) {
      return t('not-selected', 'Not selected');
    }
    const horse = horses.find(horse => horse.uid === selectedMareUid);
    if (horse) {
      return horse.UELN ? `${horse.name} (${horse.UELN})` : horse.name;
    }
    return selectedMareUid;
  }, [selectedMareUid, t, horses]);

  /**
   * event that will be fired after the modal has been closed
   */
  const onClosed = () => {
    setApiError(undefined);
    reset(defaultValues);
    setStep(1);
    setStepsWithError([]);
  };

  // Set the vat number field with the vat number of the selected customer.
  useEffect(() => {
    if (customerContact?.vat_number) {
      setValue('vat_number', customerContact.vat_number);
    }
  }, [customerContact, setValue]);

  // Update the steppers errors when we've gotten a validation error.
  useEffect(() => {
    const validateSteps = async (): Promise<number[]> => {
      const stepsErr: number[] = [];
      if (await stepErrors(1)) stepsErr.push(1);
      if (await stepErrors(2)) stepsErr.push(2);
      if (await stepErrors(3)) stepsErr.push(3);
      return stepsErr;
    };
    // We only set the steps with errors when we've gotten an api error. Otherwise
    // the zod form validation does it's job.
    if (hasApiError) {
      validateSteps().then(res => {
        if (res.length > 0) {
          setStepsWithError(res);
          // Go to the first step/group with an error.
          setStep(res[0]);
        }
      });
    }
  }, [hasApiError, setStepsWithError]); //eslint-disable-line

  /**
   * Reset the form when a new 'repeat order' is set.
   */
  useEffect(() => {
    if (open) {
      setApiError(undefined);
      setStep(1);
      setStepsWithError([]);
      reset(defaultValues);
    }
  }, [reset, setApiError, open]); //eslint-disable-line

  return (
    <PageModal
      open={open}
      onClosed={onClosed}
      width={PageModalWidth.Sm}
      parentElement='form'
      parentProps={{ className: 'space-y-4', id: 'PurchaserOrder', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle title={t('add-order', 'New order')} onClose={onRequestClose} />
      <PageModalContent>
        <ErrorSection errors={nonFieldErrors} />
        <Stepper stepsWithErrors={stepsWithError} totalSteps={4} step={1} expanded={step === 1}>
          {repeatOrder && (
            <p>
              {t('repeat-order-intro-purchaser', 'You are creating a repeat order for "{{selectedMareName}}".', {
                selectedMareName,
              })}
              <ErrorSection errors={fieldError('mare')} />
            </p>
          )}

          <SelectInput
            label={stallionLabelText}
            nullable={true}
            required={zodInputIsRequired<NewOrder>(schema, 'product')}
            options={productOptions}
            error={fieldError('product')}
            nullableValue=''
            {...register('product', { setValueAs: transformEmptyToUndefined() })}
          />

          {!repeatOrder && (
            <HorseInputSelect
              name='mare'
              label={t('your-mare', 'Your mare')}
              required={zodInputIsRequired<NewOrder>(schema, 'mare')}
              control={control}
              horses={mares}
              error={fieldError('mare') ?? (stables?.length ? undefined : t('no-stable-set', 'No stable is set'))}
              onCreated={newHorse => {
                onHorsesUpdate();
                setValue('mare', newHorse.uid);
              }}
              createMareOnly={true}
              locationUid={stables?.length ? stables[0].location_uid : undefined}
            />
          )}
          <RadioButtonGroup<NewOrder>
            name='semen_type'
            control={control}
            required={zodInputIsRequired<NewOrder>(schema, 'semen_type')}
            options={semenTypeOptions}
            error={fieldError('semen_type')}
            label={t('semen-type', 'Semen type')}
          />
          <SelectInput
            nullable={true}
            options={usageTypeOptions}
            error={fieldError('usage_type')}
            label={t('semen-usage-type', 'Usage type')}
            required={zodInputIsRequired<NewOrder>(schema, 'usage_type')}
            nullableValue=''
            {...register('usage_type', { setValueAs: transformEmptyToUndefined() })}
          />

          <StudbookInputSelect
            name='studbook'
            label={t('studbook', 'Studbook')}
            required={zodInputIsRequired<NewOrder>(schema, 'studbook')}
            control={control}
            studbookOptions={studBookMap}
            error={fieldError('studbook')}
            setValueAs={transformEmptyToUndefined()}
          />
        </Stepper>
        <Stepper stepsWithErrors={stepsWithError} totalSteps={4} step={2} expanded={step === 2}>
          <SelectInput
            error={fieldError('shipping_country')}
            required={false}
            options={countries}
            nullable={true}
            nullableValue=''
            label={t('shipping-country', 'Delivery country')}
            {...register('shipping_country', { setValueAs: transformEmptyToUndefined() })}
          />
          <SelectInput
            label={t('shipping-method', 'Shipping method')}
            nullable={true}
            required={zodInputIsRequired<NewOrder>(schema, 'shippingProduct')}
            options={shippingOptions}
            error={fieldError('shippingProduct')}
            nullableValue=''
            {...register('shippingProduct', { setValueAs: transformEmptyToUndefined() })}
          />

          <div
            className={classNames('grid grid-cols-1 md:grid-cols-2 gap-4', {
              hidden: !shippingServiceType || shippingServiceType === ShippingServiceTypeEnum.PICK_UP,
            })}
          >
            <TextInput
              error={fieldError('shipping_name')}
              required={false}
              label={t('name', 'Name')}
              {...register('shipping_name', { setValueAs: transformEmptyToUndefined() })}
            />
            <div className='flex flex-col md:flex-row gap-3'>
              <TextInput
                className='grow'
                error={fieldError('shipping_address_line1')}
                required={false}
                label={t('address-line1', 'Street')}
                {...register('shipping_address_line1', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                className='md:w-1/3'
                error={fieldError('shipping_address_line3')}
                required={false}
                label={t('house-number', 'House number')}
                {...register('shipping_address_line3', { setValueAs: transformEmptyToUndefined() })}
              />
            </div>

            <TextInput
              error={fieldError('shipping_address_line2')}
              required={false}
              label={t('address-line2', 'Address line 2')}
              {...register('shipping_address_line2', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('shipping_postcode')}
              required={false}
              label={t('postcode', 'Postcode')}
              {...register('shipping_postcode', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('shipping_city')}
              required={false}
              label={t('city', 'City')}
              {...register('shipping_city', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('shipping_state')}
              required={false}
              label={t('stateOrCounty', 'State or province')}
              {...register('shipping_state', { setValueAs: transformEmptyToUndefined() })}
            />
          </div>
          {shippingOptions.length === 0 && selectedShippingCountry && (
            <Alert
              message={t(
                'no-shipping-options-purchaser-order-webshop',
                'No shipping options available for {{country}}. Please contact us.',
                {
                  country: countryById(selectedShippingCountry)?.name ?? selectedShippingCountry,
                },
              )}
              severity={Severity.Danger}
            />
          )}
          <DateInput
            control={control}
            required={zodInputIsRequired<NewOrder>(schema, 'shipping_date')}
            label={
              shippingServiceType === ShippingServiceTypeEnum.PICK_UP
                ? t('desired-pickup-date', 'Desired pick up date')
                : t('desired-shipping-date', 'Desired shipping date')
            }
            name='shipping_date'
            error={fieldError('shipping_date')}
          />
        </Stepper>
        <Stepper stepsWithErrors={stepsWithError} totalSteps={4} step={3} expanded={step === 3}>
          <ContactInputSelect
            name='customer_uid'
            control={control}
            contacts={contacts}
            required={true}
            onCreated={contact => {
              onContactUpdate(); // Update the list of contacts.
              setValue('customer_uid', contact.uid);
            }}
            label={t('order-invoice-address', 'Invoice address')}
            error={fieldError('customer_uid')}
          />
          {customerContact !== undefined && (
            <ContactAddress
              contact={customerContact}
              // Only show the edit button when the customer contact differs from the shipping contact. Otherwise we're
              // changing the shipping address to when changing the customer address. When the customer doesn't require
              // shipping, then show the edit button too.
              onEditAddress={showCustomerAddressModal}
            />
          )}
          <TextAreaInput
            label={t('note', 'Note')}
            required={zodInputIsRequired<NewOrder>(schema, 'customer_note')}
            {...register('customer_note', { setValueAs: transformEmptyToUndefined() })}
            error={fieldError('customer_note')}
          />
          <TextInput
            error={fieldError('vat_number')}
            required={zodInputIsRequired<NewOrder>(schema, 'vat_number')}
            label={t('vat-number', 'VAT number')}
            {...register('vat_number', { setValueAs: transformEmptyToUndefined() })}
            hint={t('vat-hint-purchaser', 'Optional VAT-number of your organization for international VAT purposes.')}
          />
          <CheckboxInput
            required={zodInputIsRequired<NewOrder>(schema, 'terms_accepted')}
            {...register('terms_accepted', { setValueAs: transformValue(false, undefined) })}
            error={fieldError('terms_accepted')}
            labelElement={
              <span>
                {t('signup-agree-terms', 'Yes, I agree with the')}{' '}
                {webshopOrganization?.shop_terms_conditions ? (
                  <a href={webshopOrganization.shop_terms_conditions} target={'_blank'} className={'text-blue-500'} rel='noreferrer'>
                    {t('signup-terms', 'terms and conditions')}
                  </a>
                ) : (
                  <>{t('signup-terms', 'terms and conditions')}</>
                )}{' '}
                {t('of-org', 'of {{organizationName}}.', { organizationName: webshopOrganization?.name })}
              </span>
            }
          />
        </Stepper>
        <Stepper stepsWithErrors={stepsWithError} totalSteps={4} step={4} expanded={step === 4}>
          <div className='my-4 space-y-4'>{dryRun && <OrderSummaryTable order={dryRun} catalogue={catalogue} />}</div>
        </Stepper>
      </PageModalContent>
      <PageModalActions actions={pageActions} />
      {customerContact && (
        // Modal for quickly updating the customer address inside the order modal.
        <UpdateAddressModal
          isVisible={customerAddressModalIsVisible}
          onRequestCloseModal={closeCustomerAddressModal}
          contact={customerContact}
          onUpdated={() => onContactUpdate()}
        />
      )}
    </PageModal>
  );
}
