import { zodResolver } from '@hookform/resolvers/zod';
import {
  ApiError,
  CancelablePromise,
  CatalogueProduct,
  ModuleEnum,
  OrdersService,
  Plan,
  PricingModelEnum,
  PublicService,
  PurchaserOrderDetail,
  PurchaserOrderItem,
  PurchaserServiceContract,
  ServiceContractsService,
  TimeFrameEnum,
} from 'openapi';
import useFormError from 'api/hooks/useFormError';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { ButtonVariant } from 'ui/Button';
import { useOrganization } from 'context/OrganizationContext';
import { EQUINEM_PUBLIC_ORG_UID } from 'ui/Const';
import ApiErrorParser from 'api/ApiErrorParser';
import SubscriptionTable from './SubscriptionTable';
import PlanStairStep, { StairStepProduct } from 'components/Subscription/PlanStairStep';
import PlanFlatFee from 'components/Subscription/PlanFlatFee';
import {
  getModuleIcon,
  getModuleNameFromModuleEnum,
  horseServicesFormatExtendAutomaticallyAndEndDt,
  stairStepServiceContractIsActive,
  SubscriptionState,
  translatedMaxUnitCount,
} from 'components/Subscription/Helper';
import { AllColors } from 'utilities/colors';
import LicenseTile, { LicenseTileProps } from 'components/Subscription/Tile';
import { Users } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import { AppRoutes } from 'AppRoutes';
import { Label } from 'ui/Label';
import { Hint } from 'ui/Hint';
import useSubscriptionUserContractFollowUp from 'hooks/UseSubscriptionUserContractFollowUp';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  // onSuccess is only called when there are not orders ordered, but only the service contracts are updated
  onSuccess: (hash: SubscriptionState) => void;
  products: CatalogueProduct[] | undefined;
  plans: Plan[] | undefined;
  currentModuleServiceContracts: PurchaserServiceContract[] | undefined;
  currentUserServiceContract: PurchaserServiceContract | undefined;
  currentHorseCareServiceContract: PurchaserServiceContract | undefined;
  currentHorseFullServiceContract: PurchaserServiceContract | undefined;
  // this is a module/product that is added buy the upp-selling
  addAdditionalModule: CatalogueProduct | undefined;
}

const schema = schemas.PurchaserOrderDetail.pick({
  // TODO
  // terms_accepted: true,
});

function SaveSubscriptionModal({
  products,
  isVisible,
  onRequestCloseModal,
  onSuccess,
  plans,
  currentModuleServiceContracts,
  currentUserServiceContract,
  currentHorseCareServiceContract,
  currentHorseFullServiceContract,
  addAdditionalModule,
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedModuleProducts, setSelectedModuleProducts] = useState<CatalogueProduct[]>();
  const [selectedUserProduct, setSelectedUserProduct] = useState<StairStepProduct>();
  const [purchaserOrderDetail, setPurchaserOrderDetail] = useState<PurchaserOrderDetail>();
  // this are the legacy user accounts, e.g. when the user has an current SC but that is not provided anymore
  const [legacyUserProduct, setLegacyUserProduct] = useState<LicenseTileProps>();
  // this state will be set only in case the user is on a free plan
  const [activityPlanningSelected, setActivityPlanningSelected] = useState<boolean>(false);

  // in case the user contact is ending, there should be a follow up contract
  const { followUpUserServiceContract } = useSubscriptionUserContractFollowUp({ currentUserServiceContract });

  const { t } = useTranslation();
  const { parseAndFormatMoney } = useAccount();
  const { selectedOrganizationUid, refresh, selectedOrganizationPrimaryStableLocation } = useOrganization();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    clearErrors,
  } = useForm<PurchaserOrderDetail>({
    resolver: zodResolver(schema),
  });

  const { nonFieldErrors, setApiError } = useFormError(schema, errors);

  // List the flat fee plans, e.g. the modules
  const flatFeePlans = plans?.filter(plan => plan.pricing_model === PricingModelEnum.FLAT_FEE);

  // get the user account plan
  const userAccountPlan = plans?.find(
    plan => plan.pricing_model === PricingModelEnum.STAIR_STEP && plan.module === ModuleEnum.USER_ACCOUNTS,
  );

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    clearErrors();
    setApiError(undefined);
    setSelectedModuleProducts(undefined);
    setSelectedUserProduct(undefined);
    setPurchaserOrderDetail(undefined);
    setLoading(false);
    setActivityPlanningSelected(false);
  };

  /**
   * Order products via the order purchased endpoint
   * We do this only for the modules as the user account is already included in the service_contract
   */
  const orderProducts = useCallback(
    (dryRun: boolean) => {
      if (!selectedOrganizationUid || !products || !selectedOrganizationPrimaryStableLocation) return;

      // clear previous errors
      setApiError(undefined);

      let orderedProducts: PurchaserOrderItem[] = [];
      if (selectedModuleProducts) {
        orderedProducts = selectedModuleProducts
          // filter out the products that are already in the service contract
          .filter(product => currentModuleServiceContracts?.find(prod => prod.product_uid === product.uid) === undefined)
          .map(product => ({ product_uid: product.uid, quantity: 1, invoice_period: TimeFrameEnum.MONTH }) as PurchaserOrderItem);
      }

      // it could be that the user has a not service contract running for the user accounts
      // mostly due to the migration from the old style to the new style
      // in such case we should add the user accounts product to the order
      if (!currentUserServiceContract && selectedUserProduct) {
        orderedProducts.push({
          product_uid: selectedUserProduct.product.uid,
          quantity: 1,
          invoice_period: TimeFrameEnum.MONTH,
          price_point_unit_count: selectedUserProduct.pricePoint.max_units,
        } as PurchaserOrderItem);
      }

      // add the horse services if selected and the is no current contract
      if (activityPlanningSelected && !currentHorseCareServiceContract && !currentHorseFullServiceContract) {
        const horseCarePlan = plans?.find(plan => plan.module === ModuleEnum.HORSE_SERVICES_CARE);
        const horseCareProduct = products?.find(product => product.plan === horseCarePlan?.uid);
        const horseFullPlan = plans?.find(plan => plan.module === ModuleEnum.HORSE_SERVICES_FULL);
        const horseFullProduct = products?.find(product => product.plan === horseFullPlan?.uid);

        if (horseCareProduct) {
          orderedProducts.push({
            product_uid: horseCareProduct.uid,
            invoice_period: TimeFrameEnum.MONTH,
            price_point_unit_count: null,
          } as PurchaserOrderItem);
        }

        if (horseFullProduct) {
          orderedProducts.push({
            product_uid: horseFullProduct.uid,
            invoice_period: TimeFrameEnum.MONTH,
            price_point_unit_count: null,
          } as PurchaserOrderItem);
        }
      }

      // no items, bail out
      if (orderedProducts.length === 0) {
        return;
      }

      const promise = OrdersService.ordersPurchasedCreate({
        purchaserUid: selectedOrganizationUid,
        requestBody: {
          customer_uid: selectedOrganizationPrimaryStableLocation.uid,
          supplier: EQUINEM_PUBLIC_ORG_UID,
          dry_run: dryRun,
          order_items: orderedProducts,
          terms_accepted: true,
        } as PurchaserOrderDetail,
      });

      return promise;
    },
    [
      activityPlanningSelected,
      currentHorseCareServiceContract,
      currentHorseFullServiceContract,
      currentModuleServiceContracts,
      currentUserServiceContract,
      plans,
      products,
      selectedModuleProducts,
      selectedOrganizationPrimaryStableLocation,
      selectedOrganizationUid,
      selectedUserProduct,
      setApiError,
    ],
  );

  /**
   * Run a dry run of the order
   */
  const dryRun = useCallback((): CancelablePromise<PurchaserOrderDetail> | undefined => {
    const promise = orderProducts(true);

    if (!promise) return;

    setLoading(true);

    promise
      .then(result => setPurchaserOrderDetail(result))
      .catch(error => {
        if (error instanceof ApiError) {
          setApiError(new ApiErrorParser<PurchaserOrderDetail>(error));
        } else {
          console.error(error);
        }
      })
      .finally(() => setLoading(false));

    return promise;
  }, [orderProducts, setApiError]);

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<PurchaserOrderDetail> = async () => {
    if (!selectedOrganizationUid) return;

    try {
      // detect if the userProduct pricepoint has been changed
      // if so, update the service contract with the new max_amount of that price_point
      const changedUser =
        selectedUserProduct &&
        selectedUserProduct &&
        !stairStepServiceContractIsActive(currentUserServiceContract, selectedUserProduct.product, selectedUserProduct.pricePoint);
      if (changedUser && currentUserServiceContract) {
        await ServiceContractsService.serviceContractsPurchasedChangePartialUpdate({
          purchaserUid: selectedOrganizationUid,
          uid: currentUserServiceContract.uid,
          requestBody: {
            max_amount: selectedUserProduct.pricePoint.max_units,
          },
        });
      }

      // List the cancelled module products
      const cancelledServiceContract = currentModuleServiceContracts?.filter(
        contract =>
          !selectedModuleProducts?.some(selectedProduct => selectedProduct.uid === contract.product_uid) &&
          contract.extend_automatically === true,
      );

      // and cancel them
      if (cancelledServiceContract) {
        for (const serviceContract of cancelledServiceContract) {
          await ServiceContractsService.serviceContractsPurchasedCancelPartialUpdate({
            purchaserUid: selectedOrganizationUid,
            uid: serviceContract.uid,
          });
        }
      }

      // list serviceContracts that where previously cancelled
      const reactivatedServiceContract = currentModuleServiceContracts?.filter(
        contract =>
          selectedModuleProducts?.some(selectedProduct => selectedProduct.uid === contract.product_uid) &&
          contract.extend_automatically === false,
      );
      // and reactivate them
      if (reactivatedServiceContract) {
        for (const serviceContract of reactivatedServiceContract) {
          await ServiceContractsService.serviceContractsPurchasedReactivatePartialUpdate({
            purchaserUid: selectedOrganizationUid,
            uid: serviceContract.uid,
          });
        }
      }

      // also check if the current service contract for the horses should be reactived
      if (activityPlanningSelected && currentHorseCareServiceContract && currentHorseCareServiceContract.extend_automatically === false) {
        await ServiceContractsService.serviceContractsPurchasedReactivatePartialUpdate({
          purchaserUid: selectedOrganizationUid,
          uid: currentHorseCareServiceContract.uid,
        });
      }
      if (activityPlanningSelected && currentHorseFullServiceContract && currentHorseFullServiceContract.extend_automatically === false) {
        await ServiceContractsService.serviceContractsPurchasedReactivatePartialUpdate({
          purchaserUid: selectedOrganizationUid,
          uid: currentHorseFullServiceContract.uid,
        });
      }

      // call in the order endpoint so we can add newly modules
      const orderDetail = await orderProducts(false);
      if (orderDetail) {
        try {
          const url = new URL(`${AppRoutes.Subscription.path}#${SubscriptionState.SUBSCRIPTION_UPDATED}`, window.location.href);
          const paymentLink = await PublicService.apiV5OrdersMolliePaymentLinkCreate({
            publicAccessUuid: orderDetail.public_access_uuid ?? '',
            requestBody: {
              redirectUrl: url.toString(),
            },
          });
          window.location.href = paymentLink.redirect_uri;
        } catch (e) {
          console.info('Skipping payment due to an error', e);
        }
      }

      // refresh the organization
      refresh();

      // and close the modal if all went well
      onRequestCloseModal();

      // fire the success handler
      onSuccess(SubscriptionState.SUBSCRIPTION_UPDATED);
    } catch (error) {
      if (error instanceof ApiError) {
        setApiError(new ApiErrorParser<PurchaserOrderDetail>(error));
      } else {
        console.error(error);
      }
    }
  };

  /**
   * Return the horse services extend automatically and the end date
   */
  const horseServicesExtendAutomaticallyAndEndDt = useMemo(() => {
    return horseServicesFormatExtendAutomaticallyAndEndDt(currentHorseCareServiceContract, currentHorseFullServiceContract);
  }, [currentHorseCareServiceContract, currentHorseFullServiceContract]);

  /**
   * We do a dry run when the modal is opened and if the flat fee products are changed
   */
  useEffect(() => {
    if (isVisible && selectedModuleProducts) {
      const promise = dryRun();
      return () => promise?.cancel();
    }
  }, [dryRun, isVisible, selectedModuleProducts]);

  /**
   * Set the current module products when the modal is opened
   */
  useEffect(() => {
    if (isVisible) {
      const currentModuleProducts = currentModuleServiceContracts
        ?.filter(contract => contract.extend_automatically === true)
        .map(contract => products?.find(product => product.uid === contract.product_uid))
        .filter((product): product is CatalogueProduct => !!product);

      if (currentModuleProducts) {
        setSelectedModuleProducts(currentModuleProducts);
      }
    }
  }, [currentModuleServiceContracts, isVisible, products]);

  /**
   * Set the current module products when the modal is opened
   */
  useEffect(() => {
    if (addAdditionalModule && isVisible) {
      setSelectedModuleProducts(prevState => [...(prevState ?? []), addAdditionalModule]);
    }
    //  setSelectedFlatFeeProducts
  }, [addAdditionalModule, isVisible, products]);

  /**
   * Set the current user product when the modal is opened
   */
  useEffect(() => {
    if (isVisible) {
      const currentUserProduct = products?.find(product => product.uid === currentUserServiceContract?.product_uid);
      if (currentUserProduct) {
        const activePricePoint = currentUserProduct.price_points.find(pricePoint => {
          // in case the follow up contract is set, we should check if the follow up contract is active
          if (followUpUserServiceContract) {
            return stairStepServiceContractIsActive(followUpUserServiceContract, currentUserProduct, pricePoint);
          }
          return stairStepServiceContractIsActive(currentUserServiceContract, currentUserProduct, pricePoint);
        });
        if (activePricePoint) {
          setSelectedUserProduct({ product: currentUserProduct, pricePoint: activePricePoint });
        } else {
          // it could that we have a legacy user product, e.g. the user has a service contract but the product is not available anymore
          // in that case we set this one but as a sort of special tile, e.g. not selecteable, but visible
          setLegacyUserProduct({
            icon: <Users />,
            name: translatedMaxUnitCount(t, currentUserServiceContract?.maximum_unit_count) + ' ' + t('users_lower', 'users'),
            price:
              Number(currentUserServiceContract?.fixed_price) > 0
                ? parseAndFormatMoney(currentUserServiceContract?.fixed_price ?? '0', currentUserServiceContract?.currency ?? 'EUR')
                : 'free',
            badgeProps: () => ({
              children: 'Active',
              color: AllColors.Blue,
            }),
          });
        }
      }
    }
  }, [
    currentModuleServiceContracts,
    currentUserServiceContract,
    currentUserServiceContract?.product_uid,
    followUpUserServiceContract,
    isVisible,
    parseAndFormatMoney,
    products,
    t,
  ]);

  return (
    <PageModal
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'saveSubscription', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
      width={PageModalWidth.Base}
    >
      <PageModalTitle title={t('update-subscription', 'Update subscription')} onClose={onRequestCloseModal} />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />

        <div className='space-y-4'>
          {/* We show the activity planning option only when the current service_contract is not extend or when no service_contract is available  */}
          {((!currentHorseCareServiceContract && !currentHorseFullServiceContract) ||
            horseServicesExtendAutomaticallyAndEndDt?.extendAutomatically === false) && (
            <div>
              <Label>{t('activity-planning', 'Activity planning')}</Label>

              <Hint>
                <Trans
                  i18nKey='activity-planning-info-text-subscription'
                  defaults='Do you need activity planning for your horses? Please note that the cost of this service will be collected at the end of each month. More info can be found on <0>equinem.com/pricing</0>'
                  components={[
                    <a
                      key='link'
                      href='https://equinem.com/pricing/'
                      target='_blank'
                      rel='noreferrer noopener'
                      className='text-blue-500 underline'
                    >
                      equinem.com/pricing
                    </a>,
                  ]}
                />
              </Hint>

              <LicenseTile
                className='mt-4'
                onClick={() => setActivityPlanningSelected(prevState => !prevState)}
                icon={getModuleIcon(ModuleEnum.HORSE_SERVICES_CARE)}
                name={getModuleNameFromModuleEnum(ModuleEnum.HORSE_SERVICES_CARE, t)}
                isSelected={activityPlanningSelected}
                price={t('pay-per-horse', 'Pay per horse').toLowerCase()}
                showCheckbox={true}
                type='checkbox'
                badgeProps={() => {
                  if (horseServicesExtendAutomaticallyAndEndDt) {
                    return {
                      children:
                        horseServicesExtendAutomaticallyAndEndDt.endDt !== null &&
                        horseServicesExtendAutomaticallyAndEndDt.extendAutomatically === true
                          ? t('active', 'Active')
                          : t('ending', 'Ending'),
                      color:
                        horseServicesExtendAutomaticallyAndEndDt.endDt !== null &&
                        horseServicesExtendAutomaticallyAndEndDt.extendAutomatically === true
                          ? AllColors.Blue
                          : AllColors.Orange,
                    };
                  }
                }}
              />
            </div>
          )}

          <div>
            <Label>{t('user-accounts', 'User accounts')}</Label>
            <Hint>{t('user-accounts-info-text-subscription', 'How many people should be able to login to your organisation?')}</Hint>
            {userAccountPlan && products && (
              <PlanStairStep
                className='mt-4'
                plan={userAccountPlan}
                products={products}
                selectedProduct={selectedUserProduct}
                onClick={(selected, pricePoint) => setSelectedUserProduct({ product: selected, pricePoint })}
                badgeProps={(product, pricePoint) => {
                  // find the current active price point
                  if (stairStepServiceContractIsActive(currentUserServiceContract, product, pricePoint)) {
                    // we should also check if the follow_up is set, if so the contract is ending
                    return {
                      children: currentUserServiceContract?.followup_contract_uid === null ? t('active', 'Active') : t('ending', 'Ending'),
                      color: currentUserServiceContract?.followup_contract_uid === null ? AllColors.Blue : AllColors.Orange,
                    };
                  }

                  // check if the follow up contract is set
                  if (followUpUserServiceContract && stairStepServiceContractIsActive(followUpUserServiceContract, product, pricePoint)) {
                    return {
                      children: t('next', 'Next'),
                      color: AllColors.Green,
                    };
                  }
                }}
                emptyLicenseTile={legacyUserProduct}
              />
            )}
          </div>

          <div>
            <Label>{t('additonal-modules', 'Additional modules')}</Label>
            <Hint>{t('additional-modules-info-text-subscription', 'What other functionality would you like to use?')}</Hint>
            {flatFeePlans && products && (
              <PlanFlatFee
                onClick={selected => setSelectedModuleProducts(selected)}
                selectedProducts={selectedModuleProducts}
                plans={flatFeePlans}
                products={products}
                badgeProps={(isSelected, product) => {
                  const contract = currentModuleServiceContracts?.find(contract => contract.product_uid === product.uid);
                  if (contract) {
                    return {
                      children:
                        contract.end_dt !== null && contract.extend_automatically === true ? t('active', 'Active') : t('ending', 'Ending'),
                      color: contract.end_dt !== null && contract.extend_automatically === true ? AllColors.Blue : AllColors.Orange,
                    };
                  }
                }}
              />
            )}
          </div>

          <SubscriptionTable
            plans={plans}
            purchaserOrderDetail={purchaserOrderDetail}
            loading={loading}
            selectedModuleProducts={selectedModuleProducts}
            selectedUserProduct={selectedUserProduct}
            currentModuleServiceContracts={currentModuleServiceContracts}
            currentUserServiceContract={currentUserServiceContract}
            followUpUserServiceContract={followUpUserServiceContract}
            currentHorseCareServiceContract={currentHorseCareServiceContract}
            currentHorseFullServiceContract={currentHorseFullServiceContract}
            showEmptyHorseServiceRow={activityPlanningSelected}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: isSubmitting,
            disabled: loading,
            formId: 'saveSubscription',
            variant: ButtonVariant.Primary,
            type: 'submit',
            text: t('update-subscription', 'Update subscription'),
          },
        ]}
      />
    </PageModal>
  );
}

export default SaveSubscriptionModal;
