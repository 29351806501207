import { ModuleEnum } from 'openapi';
import { useOrganization } from 'context/OrganizationContext';
import { useMemo } from 'react';
import usePermissions from './UsePermissions';

interface ReturnType {
  isInTrial: boolean;
  trialIsExpired: boolean;
  isAdmin: boolean;
}

const useTrialExpired = (): ReturnType => {
  const { selectedOrganizationServiceContracts, selectedOrganization } = useOrganization();
  const { isAdmin } = usePermissions();

  /**
   * List only the service contracts for the user accounts module to determine if the trial is expired
   */
  const currentUserServiceContract = selectedOrganizationServiceContracts?.find(contract => contract.module === ModuleEnum.USER_ACCOUNTS);

  /**
   * Flag that indicate if the organization is in trial
   */
  const isInTrial = selectedOrganization?.is_in_trial ?? false;

  /**
   * Determine if the trial is expired
   */
  const trialIsExpired = useMemo(() => {
    if (!currentUserServiceContract || !currentUserServiceContract.end_dt) return false;

    const now = new Date();
    const endDt = new Date(currentUserServiceContract.end_dt);
    return now.getTime() > endDt.getTime();
  }, [currentUserServiceContract]);

  return {
    isInTrial,
    trialIsExpired,
    isAdmin,
  };
};

export default useTrialExpired;
