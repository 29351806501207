import { zodResolver } from '@hookform/resolvers/zod';
import { DotsThreeVertical, Plus } from '@phosphor-icons/react';
import { ApiError } from 'api/ApiClient';
import { EmailActions, EmailObject, useAccount } from 'context/AccountContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import { ButtonVariant } from 'ui/Button';
import DropdownMenu from 'ui/DropdownMenu';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { Tile, TileList, TileRow } from 'ui/Layout/Tile';
import { ActionModal, PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { AllColors } from 'utilities/colors';
import { Severity } from 'utilities/severity';
import { z } from 'zod';

/**
 * Validation schema for adding a new email
 */
const schema = z.object({
  email: z.string().email(),
});

export interface IEmailUpdateForm {
  email: string;
}

export default function EmailForm(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [formApiErrors, setFormApiErrors] = useState<string>();
  const [apiError, setApiError] = useState<string>();
  const [emails, setEmails] = useState<EmailObject[]>([]);

  const [action, setAction] = useState<{ action: EmailActions; email: string }>();

  const { t } = useTranslation();
  const { listEmails, updateEmail } = useAccount();
  const { showModal, closeModal, modalIsVisible } = useModal();
  const { showModal: showRowModal, closeModal: closeRowModal, modalIsVisible: rowModalIsVisible } = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<IEmailUpdateForm>({
    resolver: zodResolver(schema),
  });

  /**
   * Submit handler for adding a new email address
   */
  const onSubmit = async ({ email }: IEmailUpdateForm) => {
    try {
      await emailAction(email, EmailActions.Add);
      onCloseModal();
    } catch (error) {
      if (error instanceof Error) {
        setFormApiErrors(error.message);
      } else {
        setFormApiErrors('Unable to perform this action');
      }
    }
  };

  /**
   * Email action per email
   */
  const emailAction = useCallback(
    async (email: string, action: EmailActions) => {
      setLoading(true);
      setApiError('');

      try {
        const { data } = await updateEmail(email, action);
        setEmails(data);
      } catch (error) {
        if (error instanceof ApiError) {
          throw new Error(error.errorObject.email.errors[0]);
        } else {
          throw new Error('Unable to perform this action');
        }
      } finally {
        setLoading(false);
      }
    },
    [updateEmail],
  );

  /**
   * Load the emails
   */
  const loadEmails = useCallback(
    async (signal?: AbortSignal) => {
      try {
        const { data } = await listEmails(signal);
        setEmails(data);
      } catch (error) {
        if (!signal?.aborted) {
          if (error instanceof ApiError) {
            setApiError(error.message);
          } else {
            setApiError('Unknown error, please try again later or contact support');
          }
        }
      }
    },
    [listEmails],
  );

  /**
   * Event when closing the addEmail modal
   */
  const onCloseModal = () => {
    clearErrors();
    setApiError(undefined);
    setFormApiErrors(undefined);
    setAction(undefined);
    reset();
    closeModal();
  };

  /**
   * Load the emails when we construct this component
   */
  useEffect(() => {
    const abortController = new AbortController();
    loadEmails();
    return () => abortController.abort();
  }, [loadEmails]);

  /**
   * Open the modal action modal per row based on the given action
   */
  useEffect(() => {
    if (action !== undefined) {
      showRowModal();
    } else {
      closeRowModal();
    }
  }, [action, closeRowModal, showRowModal]);

  return (
    <>
      <Tile
        title={t('email-adressess', 'Email addresses')}
        actions={[{ onClick: showModal, buttonVariant: ButtonVariant.Default, icon: <Plus />, text: t('add', 'Add') }]}
        alert={apiError ? { message: apiError, severity: Severity.Warning } : undefined}
      >
        <TileList>
          {emails
            .sort((a, b) => +b.primary - +a.primary) // make sure the primary is on top
            .map(email => (
              <EmailRow
                onAction={(email, action) => setAction({ email, action })}
                key={email.id}
                label={email.primary ? t('primary', 'primary') : t('secondary', 'secondary')}
                email={email}
              />
            ))}
        </TileList>
      </Tile>

      {/* per email action based on the given action */}

      <ActionModal
        open={rowModalIsVisible}
        onClose={onCloseModal}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: () => setAction(undefined),
          },
          {
            text: t('yes', 'Yes'),
            loading: loading,
            variant: ButtonVariant.Primary,
            onClick: () => {
              if (!action) return;
              emailAction(action.email, action.action).then(() => setAction(undefined));
            },
          },
        ]}
        title={t('email-action', `Email Action`)}
      >
        <p className='mt-2  w-full'>
          {/* Display confirmation message for making an email primary */}
          {action &&
            action.action === EmailActions.Primary &&
            t('email-form-make-primary-desc', 'Are you sure you want to make this email primary?')}
          {/* Display confirmation message for removing an email */}
          {action && action.action === EmailActions.Remove && t('email-form-remove-desc', 'Are you sure you want to remove this email?')}
          {/* Display confirmation message for sending a verification email */}
          {action && action.action === EmailActions.Send && t('email-form-verify-desc', 'Send verification email?')}
        </p>
      </ActionModal>

      {/* Modal for adding a new email */}
      <PageModal
        open={modalIsVisible}
        width={PageModalWidth.Sm}
        parentElement='form'
        parentProps={{ id: 'updateEmailForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('add-new-email-address', 'Add new e-mail address')} onClose={onCloseModal} />
        <PageModalContent>
          {formApiErrors && <ErrorSection errors={[formApiErrors]} />}

          <div className='py-3'>
            <TextInput
              error={errors.email?.message}
              type={'email'}
              required={true}
              label={t('email-address', 'Email address')}
              {...register('email')}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: onCloseModal,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              loading: loading,
              variant: ButtonVariant.Primary,
              type: 'submit',
              formId: 'updateEmailForm',
              text: t('add-email-address', 'Add email address'),
            },
          ]}
        />
      </PageModal>
    </>
  );
}

interface PropsRow {
  label: string;
  email: EmailObject;
  onAction: (email: string, action: EmailActions) => void;
}

/**
 * Small component that render a single row for an email
 */
function EmailRow({ label, email, onAction }: PropsRow): JSX.Element {
  const { t } = useTranslation();

  const contextElement = useMemo((): JSX.Element | undefined => {
    if (email.primary) return;

    return (
      <DropdownMenu
        menuItems={[
          [
            {
              isVisible: email.verified,
              element: t('email-form-make-primary', 'Make primary'),
              onClick: () => onAction(email.email, EmailActions.Primary),
            },
            {
              isVisible: !email.verified,
              element: t('email-form-verify-email', 'Verify Email'),
              onClick: () => onAction(email.email, EmailActions.Send),
            },
          ],
          [
            {
              element: t('remove', 'Remove'),
              className: 'text-red-600',
              onClick: () => onAction(email.email, EmailActions.Remove),
            },
          ],
        ]}
      >
        <button className='px-2'>
          <DotsThreeVertical size={20} />
        </button>
      </DropdownMenu>
    );
  }, [email, onAction, t]);

  return (
    <TileRow as='li' customIconElement={contextElement}>
      <div className='flex flex-col md:flex-row md:items-center gap-x-3 w-full'>
        <span className='md:basis-24 font-light text-sm'>{label}</span>
        <div className='flex flex-row gap-x-3 items-center'>
          <span className='break-all'>{email.email}</span>
          {email.verified && (
            <Badge size={BadgeSize.Small} color={AllColors.Green}>
              {t('verified', 'Verified')}
            </Badge>
          )}
        </div>
      </div>
    </TileRow>
  );
}
