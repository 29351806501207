import { zodResolver } from '@hookform/resolvers/zod';
import { Plus, Trash } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import useCountries from 'hooks/UseCountries';
import { TFunction } from 'i18next';
import { CategoriesService, Category, ProviderEnum, PSTypeEnum, VATPercentage } from 'openapi';
import { VatLevelCountry } from 'openapi/models/VatLevelCountry';
import { VATPercentageVatLevelEnum } from 'openapi/models/VATPercentageVatLevelEnum';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useForm, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextInput } from 'ui/Inputs';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { integrationName } from 'utilities/Integrations';
import { VATPercentageVatLevelToString } from 'utilities/VATPercentageVatLevelEnumToString';
import { transformEmptyToUndefined } from 'utilities/zod';

interface Props {
  visible: boolean;
  enabledBookkeepingIntegrations: ProviderEnum[];
  vatPercentages: VATPercentage[];
  closeModal: () => void;
  onClosed: () => void;
  existingCategory?: Category;
  onSaved?: (category: Category) => void;
}

export default function SaveCategoryModal({
  visible,
  enabledBookkeepingIntegrations,
  vatPercentages,
  closeModal,
  onClosed,
  existingCategory,
  onSaved,
}: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { countries } = useCountries();

  const schema = useMemo(() => {
    return schemas.Category.omit({
      uid: true,
      default: true,
      name: existingCategory && existingCategory.default ? true : undefined,
      is_subscription_product: true,
      p_s_type: existingCategory !== undefined ? true : undefined,
      exactnl_ledger_code: !enabledBookkeepingIntegrations.includes(ProviderEnum.EXACTNL) ? true : undefined,
      moneybird_ledger_code: !enabledBookkeepingIntegrations.includes(ProviderEnum.MONEYBIRD) ? true : undefined,
      yuki_ledger_code: !enabledBookkeepingIntegrations.includes(ProviderEnum.YUKI) ? true : undefined,
    });
  }, [existingCategory, enabledBookkeepingIntegrations]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
  } = useForm<Category>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  // Use a fieldArray for the invoice items.
  const { fields, append, remove } = useFieldArray({
    name: 'vatLevelCountry',
    control,
  });

  const closed = () => {
    setApiError(undefined);
    remove(0); // Because the default value is {null, null} we need to manually trigger a remove.
    onClosed();
  };

  const watchVatLvlCountry = watch('vatLevelCountry', fields);

  const onSubmit = async (data: Category) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    try {
      let savedCategory: Category | undefined;

      if (existingCategory) {
        const promiseUpdate = CategoriesService.categoriesPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: existingCategory.uid,
          requestBody: data,
        });
        savedCategory = (await promiseUpdate) as Category;
      } else {
        const promiseCreate = CategoriesService.categoriesCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: data,
        });
        savedCategory = (await promiseCreate) as Category;
      }

      closeModal();
      onSaved?.(savedCategory);
    } catch (error) {
      setApiError(new ApiErrorParser<Category>(error));
    }
  };

  const hasIntegration = (provider: ProviderEnum): boolean => {
    return enabledBookkeepingIntegrations.includes(provider);
  };

  const typeOptions = useMemo((): OptionItemInterface[] => {
    return [
      {
        id: PSTypeEnum.PURCHASE,
        name: t('purchases', 'Purchases'),
      },
      {
        id: PSTypeEnum.SALE,
        name: t('sales', 'Sales'),
      },
    ];
  }, [t]);

  // Create an OptionItem list of Vat Level options.
  const vatLevelOptions = useCallback(
    (t: TFunction, index: number): OptionItemInterface[] => {
      const vatLvlCountry = (watchVatLvlCountry as VatLevelCountry[])[index];
      if (!vatLvlCountry.country) {
        return [];
      }
      const found = vatPercentages.filter(
        vatPerc => vatPerc.country === vatLvlCountry.country && vatPerc.vat_level !== VATPercentageVatLevelEnum.STANDARD,
      );
      const result: OptionItemInterface[] = found.map(vatPerc => {
        return { id: vatPerc.vat_level ?? '', name: vatPerc.vat_level ? VATPercentageVatLevelToString(t, vatPerc.vat_level) : '' };
      });

      if (vatLvlCountry.vat_level && !result.find(item => item.id === vatLvlCountry.vat_level)) {
        result.push({
          id: vatLvlCountry.vat_level,
          name: `${VATPercentageVatLevelToString(t, vatLvlCountry.vat_level as unknown as VATPercentageVatLevelEnum)} (${t('vat-percentage-not-available-anymore', 'Removed')})`,
        });
      }
      return result;
    },
    [watchVatLvlCountry, vatPercentages],
  );

  // Reset when the modal visibility is changed or when the existingCategory is changed.
  useEffect(() => {
    if (existingCategory) {
      reset(existingCategory);
    } else {
      reset({});
    }
  }, [reset, existingCategory, visible]);

  return (
    <PageModal open={visible} width={PageModalWidth.Xs} onClosed={closed}>
      <PageModalTitle
        title={existingCategory ? t('edit-category-title', 'Edit category') : t('new-category-title', 'New category')}
        onClose={closeModal}
      />
      <PageModalContent>
        <ErrorSection errors={nonFieldErrors} className='mb-1' />
        <form noValidate={true} id='SaveCategoryForm' onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4 grow'>
            {!existingCategory && (
              <SelectInput
                label={t('type', 'Type')}
                nullable={true}
                options={typeOptions}
                error={fieldError('p_s_type')}
                required={true}
                nullableValue=''
                {...register('p_s_type', { setValueAs: transformEmptyToUndefined() })}
              />
            )}
            <TextInput
              required={true}
              label={t('name', 'Name')}
              {...register('name', {
                setValueAs: transformEmptyToUndefined(),
              })}
              error={fieldError('name')}
            />
            <div>
              <p className='text-sm font-medium text-gray-600 pb-1'>{t('custom-vat-levels', 'Custom VAT levels')}</p>
              <div className='p-2 rounded border'>
                <table className='w-full'>
                  <thead>
                    <tr>
                      <td className='text-sm font-medium text-gray-600 pb-1'>{t('country', 'Country')}</td>
                      <td className='text-sm font-medium text-gray-600 pl-2 pb-1'>{t('vat-level', 'Vat level')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border-t text-sm'>
                      <td className='py-3'>{t('category-all-countries', 'All countries (catch all)')}</td>
                      <td className='py-3 pl-2'>{t('vat-level-standard', 'Standard')}</td>
                      <td />
                    </tr>
                    {fields.map((field, index) => {
                      return (
                        <tr key={field.id} className='border-t'>
                          <td className='py-2'>
                            <SelectInput
                              nullable={true}
                              options={countries}
                              required={true}
                              nullableValue=''
                              error={get(errors, `vatLevelCountry.[${index}].country`)?.message}
                              {...register(`vatLevelCountry.${index}.country`, { setValueAs: transformEmptyToUndefined() })}
                            />
                          </td>
                          <td className='p-2'>
                            <SelectInput
                              className='w-40' // Have a fixed with so it doesn't change the width of the input when changing countries.
                              nullable={true}
                              options={vatLevelOptions(t, index)}
                              required={true}
                              nullableValue=''
                              error={get(errors, `vatLevelCountry.[${index}].vat_level`)?.message}
                              {...register(`vatLevelCountry.${index}.vat_level`, { setValueAs: transformEmptyToUndefined() })}
                            />
                          </td>
                          <td>
                            <button
                              type='button'
                              onClick={() => remove(index)}
                              className='inline text-center cursor-pointer rounded hover:bg-red-700 hover:text-white px-1 py-1 focus:ring-0 focus:ring-offset-0'
                            >
                              <Trash className='inline' />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className='pt-2'>
                        <Button
                          icon={<Plus />}
                          type='button'
                          onClick={() => {
                            // Ignore ts error to have the newly added line show empty dropdown values.
                            // eslint-disable-next-line
                            // @ts-ignore
                            append({ country: null, vat_level: null });
                          }}
                        >
                          {t('add-row', 'Add row')}
                        </Button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <p>{fieldError('vatLevelCountry')}</p>
            </div>
            {hasIntegration(ProviderEnum.MONEYBIRD) && (
              <TextInput
                hint={t(
                  'moneybord-ledger-hint',
                  'Fill in the general ledger code of the corresponding category (general ledger account) in Moneybird.',
                )}
                label={`${integrationName(t, ProviderEnum.MONEYBIRD)} ${t('ledger-id', 'ledger code')}`}
                {...register('moneybird_ledger_code')}
                error={fieldError('moneybird_ledger_code')}
              />
            )}
            {hasIntegration(ProviderEnum.EXACTNL) && (
              <TextInput
                hint={t('exactnl-ledger-hint', 'Fill in the account code of the corresponding general ledger account in Exact.')}
                label={`${integrationName(t, ProviderEnum.EXACTNL)} ${t('ledger-id', 'ledger code')}`}
                {...register('exactnl_ledger_code')}
                error={fieldError('exactnl_ledger_code')}
              />
            )}
            {hasIntegration(ProviderEnum.YUKI) && (
              <TextInput
                hint={t('yuki-ledger-hint', 'Fill in the account code of the corresponding general ledger account in Yuki.')}
                label={`${integrationName(t, ProviderEnum.YUKI)} ${t('ledger-id', 'ledger code')}`}
                {...register('yuki_ledger_code')}
                error={fieldError('yuki_ledger_code')}
              />
            )}
          </div>
        </form>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'SaveCategoryForm',
          },
        ]}
      />
    </PageModal>
  );
}
