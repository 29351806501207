import classNames from 'classnames';
import { InvoiceDetail, VatCategoryEnum } from 'openapi';
import { InvoiceItem, NestedInvoiceItem } from 'openapi';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  fallbackCurrency?: string;
  fallbackLanguage?: string;
  invoice?: InvoiceDetail;
}

interface TaxTotal {
  percentage: number;
  taxTotal: number;
}

interface GroupedByHorse {
  horseUid?: string;
  horseName?: string;
  lines: NestedInvoiceItem[];
}

// Get the price of an invoice item without vat.
const unitPriceWithoutVat = (item: NestedInvoiceItem): number => {
  return item.vat_included_in_price ? Number(item.unit_price) - Number(item.total_vat.amount) : Number(item.unit_price);
};

function InvoiceItemsBlock({ invoice, fallbackCurrency = 'EUR', fallbackLanguage = 'EN' }: Props): JSX.Element {
  // Translate to the language of the invoice.
  const { t: translate, i18n } = useTranslation();
  const t = invoice ? i18n.getFixedT(invoice.language) : translate;

  const lines = useMemo((): NestedInvoiceItem[] => {
    if (!invoice || !invoice.items || invoice.items.length === 0) {
      // Create a fake invoice line as placeholder
      return [
        {
          uid: '',
          invoice_uid: '',
          description: t('no-invoice-lines-yet', 'No invoice lines added yet'),
          unit_price: '0',
          unit_price_currency: invoice?.currency ?? fallbackCurrency,
          quantity: '1',
          qualifier: '',
          vat_percentage: { percentage: '0', vat_category: VatCategoryEnum.S },
          vat_included_in_price: false,
          deferred: false,
          realactivities: [],
          service_uid: '',
          created_on: '',
          transactionitems: [],
          last_modified_on: '',
          horse_uid: undefined,
          order: 0,
          category_uid: '', // TODO
          total_vat: {
            currency: '',
            amount: '0',
          },
          horse: { uid: '', name: '' },
        } as InvoiceItem,
      ];
    }
    return invoice.items;
  }, [invoice, fallbackCurrency, t]);

  const groupedLines = useMemo((): GroupedByHorse[] => {
    const grouped: GroupedByHorse[] = [];
    for (const line of lines) {
      const foundGroup = grouped.find(group => group.horseUid === line.horse_uid || (!group.horseUid && !line.horse_uid));
      if (foundGroup) {
        foundGroup.lines.push(line);
      } else {
        grouped.push({ horseUid: line.horse_uid ?? undefined, horseName: line.horse ? line.horse.name : '', lines: [line] });
      }
    }
    return grouped;
  }, [lines]);

  const formatCurrency = useCallback(
    (amount: number): string => {
      if (isNaN(amount)) {
        return '-';
      }
      const locale = invoice?.language || fallbackLanguage || undefined;
      return new Intl.NumberFormat(locale, { style: 'currency', currency: invoice?.currency ?? fallbackCurrency }).format(amount);
    },
    [invoice?.language, invoice?.currency, fallbackLanguage, fallbackCurrency],
  );

  // Format number based on the invoice locale (with org locale fallback).
  const formatNumber = useCallback(
    (number: number): string => {
      if (isNaN(number)) {
        return '-';
      }
      const locale = invoice?.language || fallbackLanguage || undefined;
      return new Intl.NumberFormat(locale).format(number);
    },
    [invoice, fallbackLanguage],
  );

  // Sum all vat amounts grouped by vat percentage
  const taxTotals = useMemo((): TaxTotal[] => {
    const taxTotals = new Map<string, TaxTotal>();
    invoice?.items?.forEach(item => {
      const existing = taxTotals.get(item.vat_percentage.percentage);
      if (existing) {
        existing.taxTotal = existing.taxTotal + Number(item.total_vat.amount);
        taxTotals.set(item.vat_percentage.percentage, existing);
      } else {
        taxTotals.set(item.vat_percentage.percentage, {
          percentage: Number(item.vat_percentage.percentage),
          taxTotal: Number(item.total_vat.amount),
        });
      }
    });
    return [...taxTotals.values()];
  }, [invoice]);

  return (
    <table className={classNames('w-full mt-2 border-separate border-spacing-y-2', { 'opacity-50': !invoice?.items })}>
      <thead className='border-b-2 border-black mb-1'>
        <tr>
          <td className='border-b-2 border-black font-medium' />
          <td className='border-b-2 border-black font-medium'>{t('description', 'Description')}</td>
          <td className='border-b-2 border-black font-medium text-right'>{t('price', 'Price')}</td>
          <td className='border-b-2 border-black font-medium text-right'>{t('amount', 'Amount')}</td>
          <td className='border-b-2 border-black font-medium text-right'>{t('vat', 'VAT')}</td>
        </tr>
      </thead>
      {groupedLines.map(group => (
        <tbody key={group.horseUid ?? 'no-horse'}>
          {group.horseUid && (
            <tr>
              <td className='pt-2 font-medium' colSpan={5}>
                {group.horseName ?? group.horseUid}
              </td>
            </tr>
          )}
          {group.lines.map(item => {
            const unitPrice = unitPriceWithoutVat(item);
            return (
              <tr key={item.uid}>
                <td className='border-b border-gray-400 whitespace-nowrap pr-2 align-top'>
                  {formatNumber(Number(item.quantity))}
                  {' x'}
                </td>
                <td className='border-b border-gray-400 align-top'>{item.description}</td>
                <td className='border-b border-gray-400 whitespace-nowrap text-right align-top'>{formatCurrency(unitPrice)}</td>
                <td className='border-b border-gray-400 whitespace-nowrap pl-2 text-right align-top'>
                  {formatCurrency(unitPrice * Number(item.quantity))}
                </td>
                <td className='border-b border-gray-400 whitespace-nowrap pl-2 text-right align-top'>
                  {formatNumber(Number(item.vat_percentage.percentage))}
                  {'%'}
                </td>
              </tr>
            );
          })}
        </tbody>
      ))}
      {/* We don't wan't the invoice totals footer to be the only thing shown on a new page. Therefor the break-before-avoid break-inside-avoid */}
      <tbody className='break-before-avoid break-inside-avoid'>
        <tr>
          <td colSpan={3} className='text-right pl-2 pt-4'>
            {t('subtotal', 'Total (excl. VAT)')}
          </td>
          <td className='text-right pl-2 pt-4'>{formatCurrency(Number(invoice ? invoice.total : 0))}</td>
          <td />
        </tr>
        {taxTotals.map(taxTotal => {
          return (
            <tr key={taxTotal.percentage}>
              <td colSpan={3} className='text-right'>{`${t('vat', 'VAT')} ${taxTotal.percentage}% `}</td>
              <td className='text-right pl-2'>{formatCurrency(taxTotal.taxTotal)}</td>
              <td />
            </tr>
          );
        })}
        <tr>
          <td />
          <td />
          <td className='text-right border-t-2 border-black whitespace-nowrap font-medium'>{t('total-to-pay', 'Total to pay')}</td>
          <td className='text-right border-t-2 border-black whitespace-nowrap font-medium pl-2'>
            {formatCurrency(Number(invoice ? invoice.total_incl_vat : 0))}
          </td>
          <td />
        </tr>
      </tbody>
    </table>
  );
}
export default InvoiceItemsBlock;
