/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `nl` - Dutch
 * * `en` - English
 * * `de` - German
 * * `` - ---
 */
export enum InvoiceLanguageEnum {
  NL = 'nl',
  EN = 'en',
  DE = 'de',
}
