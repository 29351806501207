import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import {
  CancelablePromise,
  Contact,
  ContactDetail,
  ContactsService,
  InvitationsService,
  Role,
  RolesService,
  SentInvitation,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import Button, { ButtonSize, ButtonVariant } from '../../../ui/Button';
import { TextInput } from 'ui/Inputs';
import { schemas } from 'openapi/zod-schemas';
import useFormError from 'api/hooks/useFormError';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import MultiSelectInput from 'ui/Inputs/MultiSelectInput';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import useStables from 'api/hooks/useStables';
import classNames from 'classnames';
import { z } from 'zod';
import RadioButtonGroup, { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import { Label } from 'ui/Label';
import { User, X } from '@phosphor-icons/react';
import { contactAddress, contactName } from 'utilities/Contact';
import useCountries from 'hooks/UseCountries';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onInvited?: () => void;
  modalTitle: string;
  contacts?: Contact[];
}

enum ContactType {
  Personal,
  Business,
}

interface FoundContact {
  details: Contact;
  selected: boolean;
}

interface NewContact extends ContactDetail {
  type_of_contact: ContactType;
}

function InviteAccountModal({ isVisible, onRequestCloseModal, onInvited, modalTitle, contacts }: Props): JSX.Element {
  const [typeOfContactFormValue, setTypeOfContactFormValue] = useState<ContactType>();
  const [loadingRoles, setLoadingRoles] = useState<boolean>(false);
  const [roles, setRoles] = useState<Role[] | undefined>();
  const [apiErrorRole, setApiErrorRole] = useState<ApiErrorParser<Role[]> | undefined>();
  const [success, setSuccess] = useState<boolean>();
  const [foundContact, setFoundContact] = useState<FoundContact | undefined>();

  const { selectedOrganization } = useOrganization();
  const { stables, error: apiErrorStable } = useStables();
  const { t } = useTranslation();
  const { countries } = useCountries();

  // Form validation
  const schema = useMemo(() => {
    let fields: { [k in keyof Contact]?: true } = { stables: true, roles: true };

    // no found contact selected? then we need to validate the form fields as default
    // company -> company name
    // personal --> first and last name
    if (!foundContact?.selected) {
      if (typeOfContactFormValue === ContactType.Business) {
        fields = {
          business_name: true,
        };
      } else {
        fields = {
          first_name: true,
          last_name: true,
        };
      }

      return schemas.Contact.pick(fields)
        .extend({
          type_of_contact: z.union([z.literal(ContactType.Personal), z.literal(ContactType.Business)]),
          email: z.string().max(254).email(),
        })
        .required();
    }

    // If the foundContact has been selected, we should only check if the email should be required
    // We required it when the email is not set on the Contact object
    const _schema = schemas.Contact.pick(fields).required();

    if (!foundContact?.details.email) {
      return _schema.extend({
        email: z.string().max(254).email(),
      });
    }

    return _schema;
  }, [foundContact, typeOfContactFormValue]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    clearErrors,
    setValue,
    reset,
    watch,
  } = useForm<NewContact>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const firstNameFormValue = watch('first_name');
  const lastNameFormValue = watch('last_name');
  const businessNameFormValue = watch('business_name');
  const emailFormValue = watch('email');

  /**
   * Build a list with options based on the roles
   */
  const roleOptions = (roles ?? []).map<OptionItemInterface>(role => ({
    id: role.uid,
    name: role.name ?? '',
  }));

  /**
   * build the option list for the stables
   */
  const stableOptions: OptionItemInterface[] = (stables ?? []).map(stable => ({
    id: stable.uid,
    name: stable.location.business_name,
  }));

  /**
   * Close modal action
   */
  const close = useCallback(() => {
    onRequestCloseModal();
  }, [onRequestCloseModal]);

  /**
   * Event that is invoked when the modal is closed
   */
  const onClosed = useCallback(() => {
    setApiError(undefined);
    clearErrors();
    reset();
    setSuccess(false);
  }, [clearErrors, reset, setApiError]);

  /**
   * Submit handler
   */
  const onSubmit = async ({ type_of_contact, ...data }: NewContact) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');

    // ignore the type_of_contact as it is not part of the contact schema
    void type_of_contact;

    try {
      if (foundContact?.selected) {
        await InvitationsService.invitationsCreate({
          inviterOrganisationUid: selectedOrganization.uid,
          requestBody: {
            invitee_email: foundContact.details.email || (data.email as string),
            contact_uid: foundContact.details.uid,
            roles: data.roles as string[],
            stables: data.stables as string[],
          } as SentInvitation,
        });
      } else {
        await ContactsService.contactsCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: {
            ...data,
            create_user_invitation: true,
          },
        });
      }

      clearErrors();
      setSuccess(true);
      setApiError(undefined);
      onInvited?.();
    } catch (error) {
      setApiError(new ApiErrorParser<SentInvitation>(error));
    }
  };

  /**
   * Load the roles from api
   */
  const loadRoles = useCallback(
    (organisationUid: string): CancelablePromise<Role[]> => {
      setLoadingRoles(true);
      const promise = RolesService.rolesList({
        organisationUid,
      });

      promise
        .then(res => setRoles(res))
        .catch(e => setApiErrorRole(new ApiErrorParser<Role>(e)))
        .finally(() => setLoadingRoles(false));
      return promise;
    },
    [setApiErrorRole],
  );

  /**
   * Render for both success and the default state the actions
   */
  const actions = useMemo((): ActionProps[] => {
    if (!success) {
      return [
        {
          loading: isSubmitting,
          disabled: loadingRoles,
          variant: ButtonVariant.Primary,
          text: t('invite', 'Invite'),
          type: 'submit',
          formId: 'inviteAccountForm',
        },
      ];
    } else {
      return [
        {
          variant: ButtonVariant.Primary,
          text: t('close', 'Close'),
          onClick: close,
        },
      ];
    }
  }, [close, isSubmitting, loadingRoles, success, t]);

  // render a list of contact types
  const typeOfContact = useMemo((): RadioButtonGroupOption[] => {
    return [
      { id: ContactType.Personal, name: t('personal', 'Personal') },
      { id: ContactType.Business, name: t('business', 'Business') },
    ];
  }, [t]);

  /**
   * Toggle a foundHorse as selected or not
   */
  const toggleFounContactSelect = (selected: boolean) => {
    setFoundContact(prevState => (prevState ? { ...prevState, selected } : undefined));
  };

  /**
   * Check if we have a contact with the same name
   * If so, we set this as the found contact so we can show it to the user
   */
  useEffect(() => {
    if (!contacts || contacts.length === 0 || foundContact?.selected) return;
    const found = contacts
      // filter out all contacts that are users and contact that are already invited
      .filter(contact => contact.user_uid === null && contact.invitation === null)
      .find(
        contact =>
          (typeOfContactFormValue === ContactType.Personal &&
            firstNameFormValue !== '' &&
            contact.first_name?.toLowerCase() === firstNameFormValue?.toLowerCase() &&
            lastNameFormValue !== '' &&
            contact.last_name?.toLowerCase() === lastNameFormValue?.toLowerCase()) ||
          (typeOfContactFormValue === ContactType.Business &&
            businessNameFormValue !== '' &&
            contact.business_name?.toLowerCase() === businessNameFormValue?.toLowerCase()) ||
          (emailFormValue !== '' && contact.email?.toLowerCase() === emailFormValue?.toLowerCase()),
      );

    if (found) {
      setFoundContact({ details: found, selected: false });
    } else {
      setFoundContact(undefined);
    }
  }, [
    businessNameFormValue,
    contacts,
    emailFormValue,
    firstNameFormValue,
    foundContact?.selected,
    lastNameFormValue,
    typeOfContactFormValue,
  ]);

  /**
   * Watch the type_of_contact field and set the form value
   */
  useEffect(() => {
    const subscription = watch(({ type_of_contact }, { name }) => {
      if (name === 'type_of_contact') {
        setTypeOfContactFormValue(type_of_contact);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * Load the Roles
   */
  useEffect(() => {
    if (!isVisible) return;
    if (!selectedOrganization) return;
    const promise = loadRoles(selectedOrganization.uid);
    return () => promise.cancel();
  }, [isVisible, loadRoles, selectedOrganization]);

  /**
   * In case we just have one value for Stables,
   * we can use that first value and hide the form field but on only when we don't have a contact
   */
  useEffect(() => {
    if (!isVisible) return;
    if (stables && stables.length === 1) {
      setValue('stables', [stables[0].uid]);
    }
  }, [isVisible, setValue, stables]);

  /**
   * Set the type of contact
   */
  useEffect(() => {
    setValue('type_of_contact', ContactType.Personal);
  }, [isVisible, setValue]);

  /**
   * Reset partially the form when the type of contact changes
   */
  useEffect(() => {
    setValue('first_name', '');
    setValue('business_name', '');
    setValue('last_name', '');
  }, [setValue, typeOfContactFormValue]);

  return (
    <PageModal
      width={success ? PageModalWidth.Sm : PageModalWidth.Base}
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'inviteAccountForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
    >
      <PageModalTitle title={modalTitle} onClose={close} />
      <PageModalContent>
        {success && (
          <p>
            {t(
              'invite-user-success',
              'User has been successfully invited to create an account in your organization. The user will receive an email with further instructions.',
            )}
          </p>
        )}
        {!success && (
          <>
            <ErrorSection errors={[apiErrorRole, apiErrorStable, ...nonFieldErrors]} className='mb-2' />
            <p className='mb-2 text-sm'>
              {t(
                'invite-user-desc-new-account',
                'Create a new account. The user will receive an email with either a link to create an account or to log in and accept the invitation.',
              )}
            </p>

            <div className='py-3 space-y-3'>
              {!foundContact?.selected && (
                <>
                  <RadioButtonGroup
                    name='type_of_contact'
                    error={fieldError('type_of_contact')}
                    required={true}
                    control={control}
                    options={typeOfContact}
                    label={t('type-of-contact', 'Type of contact')}
                  />
                  {typeOfContactFormValue === ContactType.Personal && (
                    <div className='flex gap-3 justify-evenly'>
                      <TextInput
                        className='w-full min-w-0'
                        error={fieldError('first_name')}
                        required={zodInputIsRequired<NewContact>(schema, 'first_name')}
                        label={t('first-name', 'First name')}
                        {...register('first_name')}
                      />

                      <TextInput
                        className='w-full min-w-0'
                        error={fieldError('last_name')}
                        required={zodInputIsRequired<NewContact>(schema, 'last_name')}
                        label={t('last-name', 'Last name')}
                        {...register('last_name')}
                      />
                    </div>
                  )}
                  {typeOfContactFormValue === ContactType.Business && (
                    <TextInput
                      error={fieldError('business_name')}
                      required={zodInputIsRequired<NewContact>(schema, 'business_name')}
                      label={t('name', 'Name')}
                      {...register('business_name', { setValueAs: transformEmptyToUndefined() })}
                    />
                  )}
                </>
              )}

              {/* we show the email address if we did not select the found contact */}
              {/* or if we select a foundContact but with an empty email address */}
              {(!foundContact?.selected || (foundContact?.selected && foundContact.details.email === '')) && (
                <TextInput
                  error={fieldError('email')}
                  required={zodInputIsRequired<NewContact>(schema, 'email')}
                  label={t('email-address', 'Email address')}
                  {...register('email')}
                />
              )}

              {foundContact && (
                // TODO This style is a copy from the existing horse modal (/components/Horses/CreateHorseModal.tsx)
                // We decided to not create a new component for this as this could take a separated path in the future,
                // but we should consider it
                <div>
                  {!foundContact.selected && <Label>{t('existing-contact', 'Existing contact')}</Label>}
                  {foundContact.selected && <Label>{t('contact', 'Contact')}</Label>}

                  <div
                    className={classNames('mt-1 border rounded-md p-3 text-gray-500 font-medium text-sm relative', {
                      'border-indigo-500 bg-indigo-50': !foundContact.selected,
                      'border-green-500 bg-green-50': foundContact.selected,
                    })}
                  >
                    {foundContact.selected && (
                      <button type='button' className='absolute right-2 top-2' onClick={() => toggleFounContactSelect(false)}>
                        <X size={20} />
                      </button>
                    )}

                    <div className='space-y-3'>
                      {!foundContact.selected && (
                        <>
                          <p>{t('existing-contact-title', 'We have found a contact with the same name or email.')}</p>
                          <p>{t('existing-contact-sub-title', 'Do you want to use this contact instead?')}</p>
                        </>
                      )}

                      {foundContact.selected && <p>{t('existing-contact-selected-title', 'You have selected the following contact')}</p>}

                      <div className='flex'>
                        <div className='flex-1 flex gap-3'>
                          <div className='w-16 h-16 rounded-lg overflow-hidden relative bg-gray-400 flex items-center justify-center shrink-0'>
                            <User size={35} className='text-gray-50' />
                          </div>
                          <div className='flex flex-col'>
                            <span className='text-gray-700 font-semibold'>{contactName(foundContact.details)}</span>
                            <span>
                              {t('email', 'Email')}
                              {':'} {foundContact.details.email}
                            </span>
                            <span>
                              {t('address', 'Address')}
                              {':'} {contactAddress(foundContact.details, countries).join(', ')}
                            </span>
                          </div>
                        </div>
                        {!foundContact.selected && (
                          <div className='flex space-x-2'>
                            <Button
                              size={ButtonSize.XSmall}
                              onClick={() => toggleFounContactSelect(true)}
                              variant={ButtonVariant.Primary}
                              type='button'
                            >
                              {t('use', 'Use')}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='py-3 space-y-3'>
              <MultiSelectInput<NewContact>
                className={classNames({
                  hidden: stables && stables.length === 1,
                })}
                name='stables'
                required={zodInputIsRequired<NewContact>(schema, 'stables')}
                control={control}
                error={fieldError('stables')}
                label={t('stables', 'Stables')}
                options={stableOptions}
                showToolbar={false}
              />

              <MultiSelectInput<NewContact>
                loading={loadingRoles}
                name='roles'
                required={zodInputIsRequired<NewContact>(schema, 'roles')}
                control={control}
                error={fieldError('roles')}
                label={t('roles', 'Roles')}
                options={roleOptions}
                showToolbar={false}
              />
            </div>
          </>
        )}
      </PageModalContent>
      <PageModalActions actions={actions} />
    </PageModal>
  );
}

export default InviteAccountModal;
