import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { DatetimeOrDateField, Feed, Feeding, FeedType, Horse, HorsefeedsService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { DateInput, SelectInput, TextInput } from 'ui/Inputs';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { formatDate } from 'utilities/date.utilities';
import { quantityMeasureString } from 'utilities/Feed';
import { transformEmptyToUndefined } from 'utilities/zod';
import { z } from 'zod';

interface Props {
  visible: boolean;
  feedTypes?: FeedType[];
  selectedHorse?: Horse;
  selectedFeeding?: Feeding;
  closeModal: () => void;
  onClosed: () => void;
  existingFeed?: Feed;
  onSaved?: (feed: Feed) => void;
}

const schema = schemas.Feed.omit({
  uid: true,
  start_reason: true,
  end_reason: true,
  stable_uid: true,
  horse_uid: true,
  feeding_uid: true,
  last_modified_on: true,
  last_modified_by_uid: true,
  created_on: true,
  created_by_uid: true,
  endTimeUnspecified: true,
  status: true,
  start: true,
  end: true,
}).extend({
  start_date: z.string().optional(),
  end_date: z.string().optional(),
});

type FormModel = z.infer<typeof schema>;

export default function SaveFeedModal({
  visible,
  closeModal,
  onClosed,
  existingFeed,
  feedTypes,
  onSaved,
  selectedFeeding,
  selectedHorse,
}: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { formatNumber } = useAccount();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
    setValue,
    control,
  } = useForm<FormModel>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const watchFeedType = watch('feed_type_uid');

  const selectedFeedType = useMemo((): FeedType | undefined => {
    return feedTypes?.find(feedType => feedType.uid === watchFeedType);
  }, [feedTypes, watchFeedType]);

  const selectedFeedTypeQuantityMeasure = useMemo((): string | undefined => {
    return selectedFeedType ? quantityMeasureString(t, selectedFeedType.quantity_measure).toLocaleLowerCase() : undefined;
  }, [t, selectedFeedType]);

  const closed = () => {
    setApiError(undefined);
    onClosed();
  };

  const onSubmit = async (data: FormModel) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    try {
      let savedFeed: Feed | undefined = undefined;

      // @NOTE: The date time code below is pretty weird. Backend team is working on this.
      data.start = { date: data.start_date } as DatetimeOrDateField;
      data.all_day_event = true;
      if (!data.end_date) {
        data.endTimeUnspecified = true;
      } else {
        data.end = { date: data.end_date } as DatetimeOrDateField;
      }

      data.end_date = undefined;
      data.start_date = undefined;

      if (existingFeed) {
        const promiseUpdate = HorsefeedsService.horsefeedsPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: existingFeed.uid,
          requestBody: data,
        });
        savedFeed = (await promiseUpdate) satisfies Feed;
      } else {
        data.horse_uid = selectedHorse?.uid ?? '';
        data.feeding_uid = selectedFeeding?.uid ?? '';
        data.stable_uid = selectedHorse?.stable_uid ?? '';

        const promiseCreate = HorsefeedsService.horsefeedsCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: data as unknown as Feed,
        });
        savedFeed = (await promiseCreate) satisfies Feed;
      }
      closeModal();
      onSaved?.(savedFeed);
    } catch (error) {
      setApiError(new ApiErrorParser<Feed>(error));
    }
  };

  const feedTypeOptions = useMemo((): OptionItemInterface[] => {
    if (!feedTypes) {
      return [];
    }
    return feedTypes.map(feedType => {
      return { id: feedType.uid, name: feedType.display_name };
    });
  }, [feedTypes]);

  useEffect(() => {
    if (selectedFeedType?.default_quantity) {
      setValue('quantity', formatNumber(Number(selectedFeedType?.default_quantity)));
    }
  }, [selectedFeedType, setValue, formatNumber]);

  // Reset when the modal visibility is changed or when the existingFeed is changed.
  useEffect(() => {
    if (existingFeed) {
      const val = existingFeed as unknown as FormModel;
      val.start_date = existingFeed.start.date ?? '';
      if (!existingFeed.endTimeUnspecified) {
        val.end_date = existingFeed.end?.date;
      }
      reset(val);
    } else {
      reset({ start_date: formatDate(new Date()) });
    }
  }, [reset, existingFeed, visible]);

  return (
    <PageModal open={visible} width={PageModalWidth.Xs} onClosed={closed}>
      <PageModalTitle title={existingFeed ? t('edit-feed-title', 'Edit feed') : t('new-feed-title', 'New feed')} onClose={closeModal} />
      <PageModalContent>
        <ErrorSection errors={nonFieldErrors} className='mb-1' />
        <form noValidate={true} id='SaveFeedTypeForm' onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4 grow'>
            <SelectInput
              required={true}
              nullable={true}
              options={feedTypeOptions}
              error={fieldError('feed_type_uid')}
              label={t('feed-type', 'Feed type')}
              nullableValue=''
              {...register('feed_type_uid', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              label={t('quantity', 'Quantity')}
              {...register('quantity', { setValueAs: transformEmptyToUndefined() })}
              postText={selectedFeedTypeQuantityMeasure}
              error={fieldError('quantity')}
            />
            <DateInput
              control={control}
              required={true}
              label={t('start', 'Start')}
              {...register('start_date', {
                setValueAs: transformEmptyToUndefined(),
              })}
              error={fieldError('start_date')}
            />
            <DateInput
              control={control}
              label={t('end', 'End')}
              {...register('end_date', {
                setValueAs: transformEmptyToUndefined(),
              })}
              error={fieldError('end_date')}
            />
          </div>
        </form>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'SaveFeedTypeForm',
          },
        ]}
      />
    </PageModal>
  );
}
