import { Barn, CaretRight, House } from '@phosphor-icons/react';
import classNames from 'classnames';
import { Contact } from 'openapi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../AppRoutes';
import { AvatarIcon, AvatarInitials, AvatarSize } from '../../ui/Avatar';
import Badge from '../../ui/Badge/Badge';
import {
  table,
  tableHiddenColumnMd,
  tableHiddenColumnSm,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTr,
  tableTheadTd,
  tableTheadTdSticky,
} from '../../ui/Const';
import { AllColors } from '../../utilities/colors';
import { contactAddress, contactInitials, contactName, ContactType, getContactType } from 'utilities/Contact';
import useCountries from 'hooks/UseCountries';

function ContactList({ contacts }: { contacts: Contact[] }): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { countries } = useCountries();

  return (
    <table className={table}>
      <thead>
        <tr className={tableHiddenHeaderSm}>
          <td className={classNames('w-12 md:w-14', tableTheadTdSticky)} />
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('name', 'Name')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>{t('email-address', 'Email address')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>{t('phone-number', 'Phone number')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnSm)}>{t('address', 'Address')}</td>
          <td className='w-10 md:hidden' />
        </tr>
      </thead>
      <tbody className={tableTbody}>
        {contacts.map(contact => {
          return (
            <tr
              className={tableTbodyTr}
              key={contact.uid}
              onClick={() => {
                navigate(generatePath(AppRoutes.ContactDetails.path, { uid: contact.uid }));
              }}
            >
              <td className='text-center w-12 md:w-14'>
                {getContactType(contact) === ContactType.Business && (
                  <AvatarIcon size={AvatarSize.Small} icon={<House />} uuid={contact.uid} />
                )}
                {getContactType(contact) === ContactType.Stable && (
                  <AvatarIcon size={AvatarSize.Small} icon={<Barn />} uuid={contact.uid} />
                )}
                {[ContactType.User, ContactType.Contact].includes(getContactType(contact)) && (
                  <AvatarInitials size={AvatarSize.Small} initials={contactInitials(contact)} uuid={contact.uid} />
                )}
              </td>
              <td>
                <span className='mr-2'>{contactName(contact)}</span>
                {contact.stable_location_uid && <Badge color={AllColors.Indigo}>{t('stable', 'Stable')}</Badge>}
                {getContactType(contact) === ContactType.Business && <Badge color={AllColors.Amber}>{t('business', 'Business')}</Badge>}
              </td>
              <td className={tableHiddenColumnMd}>{contact.email}</td>
              <td className={tableHiddenColumnMd}>{contact.phone_number}</td>
              <td className={tableHiddenColumnSm}>{contactAddress(contact, countries).join(', ')}</td>
              <td className={classNames('w-10 text-center md:hidden')}>
                <CaretRight size={22} weight='light' className='inline' />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
export default ContactList;
