import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

function Hint({ children }: Props): JSX.Element {
  return <label className='block text-xs leading-4 text-gray-500 mt-1'>{children}</label>;
}

export default Hint;
