import { MagnifyingGlass } from '@phosphor-icons/react';
import classNames from 'classnames';
import { EquineMHorseSearchDetail } from 'openapi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Badge, { BadgeSize } from 'ui/Badge/Badge';
import Button, { ButtonSize } from 'ui/Button';
import { bloodlineString } from 'utilities/Horse';

interface Props {
  horse: EquineMHorseSearchDetail;
  selected?: boolean;
  onSelect?: (horse: EquineMHorseSearchDetail) => void;
  onClear?: () => void;
}

/**
 * Small helper method to show the row of a searched horse from the external DB
 */
export function SearchResultSearchedHorseRow({ horse, selected, onSelect, onClear }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('mt-1 border rounded-md p-3 font-medium relative', {
        'border-indigo-500 bg-indigo-50': !selected,
        'border-green-500 bg-green-50': selected,
      })}
    >
      <div className='flex items-center'>
        <div className='grow flex items-center gap-2'>
          <MagnifyingGlass className='inline' />
          <div className='w-full'>
            <p
              className={classNames('text-sm text-gray-700 flex items-center gap-1 w-full', {
                'justify-between': !onSelect && !onClear,
              })}
            >
              <span>{horse.name}</span>
              <Badge size={BadgeSize.Small}>{horse.source_db.toString()}</Badge>
            </p>
            {bloodlineString(horse) && <p className='text-xs text-gray-500'>{bloodlineString(horse)}</p>}

            <p className='mt-0.5 text-xs text-gray-500 italic'>
              {onSelect && <>{t('search-horse-auto-fill', 'We found this horse to auto fill the form')}</>}
              {onClear && <>{t('search-horse-clear-auto-fill', 'We used this horse to auto fill the form')}</>}
            </p>
          </div>
        </div>
        {onSelect && (
          <Button size={ButtonSize.XSmall} type='button' onClick={() => onSelect(horse)}>
            {t('auto-fill', 'Auto fill')}
          </Button>
        )}
        {onClear && (
          <Button size={ButtonSize.XSmall} type='button' onClick={onClear}>
            {t('clear', 'Clear').toLowerCase()}
          </Button>
        )}
      </div>
    </div>
  );
}
