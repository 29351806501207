import { Horse, Users } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import { CatalogueProduct, ModuleEnum, Plan, PricePoint } from 'openapi';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LicenseTile, { LicenseTileProps } from './Tile';
import { BadgeProps } from 'ui/Badge/Badge';
import classNames from 'classnames';

export interface StairStepProduct {
  product: CatalogueProduct;
  pricePoint: PricePoint;
}

interface Props {
  plan: Plan;
  products: CatalogueProduct[];
  selectedProduct?: StairStepProduct;
  onClick: (product: CatalogueProduct, pricePoint: PricePoint) => void;
  badgeProps?: (product: CatalogueProduct, pricePoint: PricePoint) => BadgeProps | undefined;
  emptyLicenseTile?: LicenseTileProps;
  className?: string;
}

function PlanStairStep({
  plan,
  selectedProduct: givenSelectedProduct,
  products,
  onClick,
  badgeProps,
  emptyLicenseTile,
  className,
}: Props): JSX.Element {
  const [selectedProduct, setSelectedProduct] = useState<StairStepProduct>();

  const { t } = useTranslation();
  const { parseAndFormatMoney } = useAccount();

  const filtererdProducts = products.filter(product => product.plan === plan.uid);

  useEffect(() => {
    if (givenSelectedProduct) {
      setSelectedProduct(givenSelectedProduct);
    }
  }, [givenSelectedProduct]);

  return (
    <div className={classNames('flex flex-wrap gap-5', className)}>
      {emptyLicenseTile && <LicenseTile showCheckbox={false} disabled={true} {...emptyLicenseTile} />}

      {filtererdProducts.map(product => {
        const prefixNameSingular = plan.module === ModuleEnum.USER_ACCOUNTS ? 'user' : 'horse'; //plan.module === ModuleEnum.CARE_AND_BREEDING ? 'horse' :
        const prefixNamePlural = prefixNameSingular + 's';

        const icon = plan.module === ModuleEnum.USER_ACCOUNTS ? <Users /> : <Horse />;

        return (
          <Fragment key={product.uid}>
            {product.price_points
              .filter(price_point => typeof price_point.price === 'string')
              .sort((a, b) => parseFloat(a.price as string) - parseFloat(b.price as string))
              .map(pricePoint => {
                const name =
                  pricePoint.max_units === 1 ? (
                    t('single', 'Single') + ' ' + prefixNameSingular
                  ) : pricePoint.max_units !== null ? (
                    t('up-to-n', 'Up to {{count}}', { count: pricePoint.max_units }) + ' ' + prefixNamePlural
                  ) : (
                    <>
                      {t('unlimited', 'Unlimited')} {prefixNamePlural}
                    </>
                  );

                const isSelected =
                  selectedProduct?.product.uid === product.uid && selectedProduct?.pricePoint.max_units === pricePoint.max_units;

                return (
                  <LicenseTile
                    key={product.uid + pricePoint.price}
                    icon={icon}
                    name={name}
                    price={
                      Number(pricePoint.price) > 0
                        ? parseAndFormatMoney(pricePoint.price as string, pricePoint.price_currency as string)
                        : t('free', 'Free')
                    }
                    onClick={() => {
                      setSelectedProduct({
                        product,
                        pricePoint,
                      });
                      onClick(product, pricePoint);
                    }}
                    isSelected={isSelected}
                    badgeProps={() => badgeProps?.(product, pricePoint)}
                  />
                );
              })}
          </Fragment>
        );
      })}
    </div>
  );
}

export default PlanStairStep;
