import { CaretRight, Horse as HorseIcon, Plus } from '@phosphor-icons/react';
import { cachedPaginatedApiData } from 'api/ApiCache';
import ApiErrorParser from 'api/ApiErrorParser';
import classNames from 'classnames';
import { FilterOption, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, ColorEnum, Horse, HorsesService, SexEnum } from 'openapi';
import { PaginatedHorseList } from 'openapi/models/PaginatedHorseList';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import ColorWithName from 'ui/ColorWithName';
import {
  defaultApiPageSize,
  table,
  tableHiddenColumnLg,
  tableHiddenColumnMd,
  tableHiddenColumnSm,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTr,
  tableThead,
  tableTheadTd,
} from 'ui/Const';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { AllColors } from 'utilities/colors';
import ListFilterButton from 'components/Common/ListFilter/ListFilterButton';
import useListFilter from '../../components/Common/ListFilter/useListFilter';
import { PageAction, usePage } from '../../context/PageContext';
import { ButtonVariant } from '../../ui/Button';
import Page from '../../ui/Layout/Page';
import { age, listFilter, textFilter } from '../../utilities/Horse';
import { generateWebshopPath, WebshopRoutes } from 'AppRoutes';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import CreateMareModal from 'components/Horses/CreateMareModal';
import useStables from 'api/hooks/useStables';
import { Tile } from 'ui/Layout/Tile';

export default function WebshopMaresList(): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization, generateCacheKey: getCacheId } = useOrganization();
  const [searchText, setSearchText] = useState<string>('');
  const [horses, setHorses] = useState<Horse[]>();
  const [createMareModalVisible, setCreateMareModalVisible] = useState<boolean>(false);
  const { setApiError } = usePage();
  const [itemsVisible, setItemsVisible] = useState<number>(defaultApiPageSize);
  const { formatDate } = useAccount();
  const { publicAccessUuid } = useParams();
  const { stables } = useStables();
  const navigate = useNavigate();

  // A list of types we can filter by. Like Gender and DateOfBirth.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!horses) {
      return undefined;
    }
    let inactiveCount = 0;

    const birthYears = new Map<number, number>();
    const ageCount = new Map<number, number>();
    let hasNotSetDate = 0; // True if we have a date that is undefined in the list.
    horses.forEach(h => {
      if (h.date_of_birth) {
        const date = new Date(Date.parse(h.date_of_birth));
        birthYears.set(date.getFullYear(), birthYears.get(date.getFullYear()) ?? 1);
        const horseAge = age(h);
        if (horseAge !== undefined && horseAge !== null) {
          // We already known the horseAge is not undefined (but TS wants this if-condition)
          ageCount.set(horseAge, ageCount.get(horseAge) ?? 1);
        }
      } else {
        hasNotSetDate++;
      }

      if (h.hidden) {
        inactiveCount++;
      }
    });

    const birthYearFilterOptions: FilterOption[] = [];

    if (hasNotSetDate > 0) {
      birthYearFilterOptions.push({ id: 'unknown', name: t('unknown', 'Unknown'), count: hasNotSetDate });
    }
    const sortedBirthYears = Array.from(birthYears);
    sortedBirthYears.sort();
    sortedBirthYears.forEach(value => birthYearFilterOptions.push({ id: `${value[0]}`, name: `${value[0]}`, count: value[1] }));

    const birthYearFilter: ListFilterType = {
      id: 'birthyear',
      name: t('birthyear', 'Year of birth'),
      options: birthYearFilterOptions,
    };

    const ageFilterOptions: FilterOption[] = [];
    if (hasNotSetDate > 0) {
      ageFilterOptions.push({ id: 'unknown', name: t('unknown', 'Unknown'), count: hasNotSetDate });
    }

    const sortedAges = Array.from(ageCount);
    sortedAges.sort();
    sortedAges.forEach(value => {
      ageFilterOptions.push({ id: `${value[0]}`, name: `${value[0]}`, count: value[1] });
    });

    const ageFilter: ListFilterType = {
      id: 'age',
      name: t('age', 'Age'),
      options: ageFilterOptions,
    };

    const activeFilter: ListFilterType = {
      id: 'inactive',
      name: t('show-inactive', 'Show inactive'),
      options: [{ id: 'yes', name: t('yes', 'Yes'), count: inactiveCount }],
    };

    return [ageFilter, birthYearFilter, activeFilter];
  }, [t, horses]);

  const { filters } = useListFilter(filterTypes ?? []);

  // We don't show the full list directly. Make use of the fetch more strategy of PullToRefresh.
  const visibleHorses = useMemo((): { horses: Horse[]; canFetchMore: boolean } => {
    if (!horses) {
      return { horses: [], canFetchMore: false };
    }
    let all = textFilter(listFilter(horses ?? [], filters), searchText);
    all = all.filter(horse => horse.sex === SexEnum._2);
    return { horses: all.slice(0, itemsVisible), canFetchMore: itemsVisible < all.length };
  }, [horses, itemsVisible, filters, searchText]);

  const loadHorses = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedHorseList> => {
      setItemsVisible(defaultApiPageSize);
      const promise = HorsesService.horsesList({
        organisationUid: selectedOrganization?.uid ?? '',
        onUnknownLocation: true,
      });
      promise
        .then(() => setApiError(undefined))
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<PaginatedHorseList>(e), horses === undefined);
          }
        });
      cachedPaginatedApiData<Horse>(getCacheId('horses'), promise, setHorses, disableCache);
      return promise;
    },
    [selectedOrganization, horses, getCacheId, setApiError],
  );

  /**
   * Event that is invoked when we create a new horse
   */
  const onCreated = async () => {
    // as we are unsure how the sorting is on the API
    // the best way to include the new horse is to call loadHorses().
    // Because this is async, the createDialog will be closed after loadHorses() has been finished
    await loadHorses(false);
  };

  // Load the horses
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadHorses(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add-mare', 'Add mare'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => setCreateMareModalVisible(true),
      },
    ];
  }, [t]);

  return (
    <>
      <Page title={t('mares', 'Mares')} actions={pageActions} loading={!horses} showEmptyListPlaceholder={horses?.length === 0}>
        <PullScrollWrapper
          onRefresh={() => loadHorses(true)}
          canFetchMore={visibleHorses.canFetchMore}
          onFetchMore={() => {
            setItemsVisible(itemsVisible + defaultApiPageSize);
            return Promise.resolve();
          }}
        >
          <Tile noBoxOnMobile={true}>
            <FilterWrapper listFilterTypes={filterTypes}>
              <ListFilterButton listFilterTypes={filterTypes ?? []} />
              <input
                type='search'
                onChange={e => setSearchText(e.currentTarget.value)}
                size={10}
                placeholder={t('search-by-name-ueln-chipnr', 'Search by Name, UELN or Chipnr...')}
                className='placeholder:italic placeholder:text-sm px-2 max-w-md grow h-10 rounded-md border'
              />
            </FilterWrapper>
            <table className={table}>
              <thead className={tableThead}>
                <tr className={tableHiddenHeaderSm}>
                  <td className='w-10' />
                  <td className={tableTheadTd}>{t('name', 'Name')}</td>
                  <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('age', 'Age')}</td>
                  <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('DateOfArrival', 'Date of Arrival')}</td>
                  <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('color', 'Color')}</td>
                  <td className={classNames(tableTheadTd, tableHiddenColumnLg)}>{t('UELN', 'UELN')}</td>
                  <td className='w-10 md:hidden' />
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {visibleHorses.horses.map(horse => {
                  return (
                    <tr
                      className={tableTbodyTr}
                      key={horse.uid}
                      onClick={() => {
                        navigate(
                          generateWebshopPath(generatePath(WebshopRoutes.MareDetails.path, { uid: horse.uid }), publicAccessUuid ?? ''),
                        );
                      }}
                    >
                      <td className='text-center w-10'>
                        <HorseIcon size={22} weight='light' className='inline' />
                      </td>
                      <td>
                        <p>
                          {horse.name} {horse.hidden && <Badge color={AllColors.Rose}>{t('inactive', 'Inactive')}</Badge>}
                        </p>
                        {horse.dam && horse.sire && (
                          <p className='text-sm text-gray-600 italic'>
                            {horse.dam} × {horse.sire}
                          </p>
                        )}
                      </td>
                      <td className={tableHiddenColumnSm}>
                        {horse.date_of_birth && (
                          <>
                            {age(horse)}
                            <span className='ml-2 text-gray-400'>
                              <span>{'('}</span>
                              {new Date(Date.parse(horse.date_of_birth)).getFullYear()}
                              <span className='ml-0.5'>{')'}</span>
                            </span>
                          </>
                        )}
                        {!horse.date_of_birth && '-'}
                      </td>
                      <td className={tableHiddenColumnMd}>
                        {horse.current_location.arrival_date ? formatDate(new Date(Date.parse(horse.current_location.arrival_date))) : '-'}
                      </td>
                      <td className={tableHiddenColumnMd}>{horse.color ? <ColorWithName colorId={horse.color as ColorEnum} /> : '-'}</td>
                      <td className={tableHiddenColumnLg}>{horse.UELN || '-'}</td>
                      <td className={classNames('w-10 text-center md:hidden')}>
                        <CaretRight size={22} weight='light' className='inline' />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tile>
        </PullScrollWrapper>
      </Page>
      <CreateMareModal
        open={createMareModalVisible}
        onRequestCloseModal={() => setCreateMareModalVisible(false)}
        onCreated={onCreated}
        locationUid={stables?.length ? stables[0].location_uid : undefined}
      />
    </>
  );
}
