import ApiErrorParser from 'api/ApiErrorParser';
import { AppRoutes } from 'AppRoutes';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import {
  ApiError,
  CancelablePromise,
  CatalogueProduct,
  OrdersService,
  PricePoint,
  PublicService,
  PurchaserOrderDetail,
  TimeFrameEnum,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { EQUINEM_PUBLIC_ORG_UID } from 'ui/Const';
import { ErrorSection } from 'ui/Error';
import Skeleton from 'ui/Layout/Skeleton';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { SubscriptionState } from '../Helper';

interface Props {
  product: { product: CatalogueProduct; pricePoint: PricePoint };
  isVisible: boolean;
  onRequestCloseModal: () => void;
}

function CheckoutRvoCreditsModal({ isVisible, onRequestCloseModal, product: { pricePoint, product } }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<PurchaserOrderDetail> | undefined>();
  const [purchaserOrderDetail, setPurchaserOrderDetail] = useState<PurchaserOrderDetail>();

  const { selectedOrganizationUid, refresh, selectedOrganizationPrimaryStableLocation } = useOrganization();
  const { t } = useTranslation();
  const { parseAndFormatMoney } = useAccount();

  /**
   * Sum the total
   */
  const totals = useMemo(() => {
    if (!purchaserOrderDetail)
      return {
        price: Number(pricePoint.price),
        vat: 0,
      };

    return purchaserOrderDetail.order_items.reduce(
      (total, item) => {
        return {
          price: total.price + Number(item.unit_price),
          vat: total.vat + Number(item.total_vat.amount),
        };
      },
      {
        price: 0,
        vat: 0,
      },
    );
  }, [pricePoint.price, purchaserOrderDetail]);

  /**
   * get the currency
   */
  const currency = useMemo(() => {
    if (!purchaserOrderDetail || purchaserOrderDetail.order_items.length === 0) return 'eur';

    return purchaserOrderDetail.order_items[0].unit_price_currency;
  }, [purchaserOrderDetail]);

  /**
   * Generate the submit label
   */
  const submitLabel = useMemo(() => {
    if (totals.price === 0) {
      return t('checkout', 'Checkout');
    }

    return t('pay-n', 'Pay {{amount}}', { amount: parseAndFormatMoney(String(totals.price + totals.vat), currency) });
  }, [currency, parseAndFormatMoney, t, totals.price, totals.vat]);

  /**
   * Order products via the order purchased endpoint
   * We do this only for the modules as the user account is already included in the service_contract
   */
  const orderProducts = useCallback(
    (dryRun: boolean) => {
      if (!selectedOrganizationUid || !selectedOrganizationPrimaryStableLocation) return;

      // clear previous errors
      setApiError(undefined);

      const promise = OrdersService.ordersPurchasedCreate({
        purchaserUid: selectedOrganizationUid,
        requestBody: {
          customer_uid: selectedOrganizationPrimaryStableLocation.uid,
          supplier: EQUINEM_PUBLIC_ORG_UID,
          dry_run: dryRun,
          order_items: [
            {
              product_uid: product.uid,
              invoice_period: TimeFrameEnum.MONTH,
              price_point_unit_count: pricePoint.max_units,
            },
          ],
          terms_accepted: true,
        } as PurchaserOrderDetail,
      });

      return promise;
    },
    [pricePoint.max_units, product.uid, selectedOrganizationPrimaryStableLocation, selectedOrganizationUid],
  );

  /**
   * Run a dry run of the order
   */
  const dryRun = useCallback((): CancelablePromise<PurchaserOrderDetail> | undefined => {
    const promise = orderProducts(true);

    if (!promise) return;

    setLoading(true);

    promise
      .then(result => setPurchaserOrderDetail(result))
      .catch(error => {
        if (error instanceof ApiError) {
          setApiError(new ApiErrorParser<PurchaserOrderDetail>(error));
        } else {
          console.error(error);
        }
      })
      .finally(() => setLoading(false));

    return promise;
  }, [orderProducts, setApiError]);

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit = async () => {
    if (!selectedOrganizationUid) return;

    setSubmitting(true);

    try {
      const orderDetail = await orderProducts(false);
      if (orderDetail) {
        try {
          const url = new URL(`${AppRoutes.Subscription.path}#${SubscriptionState.RVO_CREDITS_ADDED}`, window.location.href);
          const paymentLink = await PublicService.apiV5OrdersMolliePaymentLinkCreate({
            publicAccessUuid: orderDetail.public_access_uuid ?? '',
            requestBody: {
              redirectUrl: url.toString(),
            },
          });
          window.location.href = paymentLink.redirect_uri;
        } catch (e) {
          console.error('Skipping payment due to an error', e);
        }
      }

      refresh();
      onRequestCloseModal();
    } catch (error) {
      setApiError(new ApiErrorParser<PurchaserOrderDetail>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * event that will be fired after the modal has been closed
   */
  const onClosed = () => {
    setApiError(undefined);
    setPurchaserOrderDetail(undefined);
  };

  /**
   * Event that will be fired when the user closes the modal
   */
  const onClose = () => {
    onRequestCloseModal();
    setApiError(undefined);
  };

  /**
   * Run a dry-run when we open the modal
   */
  useEffect(() => {
    if (isVisible) {
      const promise = dryRun();
      return () => promise?.cancel();
    }
  }, [dryRun, isVisible, orderProducts]);

  return (
    <PageModal open={isVisible} onClosed={onClosed}>
      <PageModalTitle title={t('buy-rvo-credits', 'Buy RvO credits')} onClose={onClose} />
      <PageModalContent>
        <ErrorSection errors={apiError} />
        <p>{t('review-and-buy-rvo-credits', 'You are about to buy RvO credits. Please review your order and checkout.')}</p>

        <ul className='space-y-2 mt-5'>
          <li className='flex gap-x-2 items-center'>
            {loading && (
              <>
                <Skeleton className='h-5 w-16' />
                <Skeleton className='h-5 w-10 ml-auto' />
              </>
            )}
            {!loading && (
              <>
                {t('n-rvo-credits', '{{number}} RvO credits', {
                  number: pricePoint.max_units,
                })}
                <span className='ml-auto'>{parseAndFormatMoney(String(totals.price), currency)}</span>
              </>
            )}
          </li>
          <li className='flex gap-x-2 items-center'>
            {loading && (
              <>
                <Skeleton className='h-5 w-16' />
                <Skeleton className='h-5 w-10 ml-auto' />
              </>
            )}

            {!loading && (
              <>
                {t('tax', 'VAT')}
                <span className='ml-auto'>{parseAndFormatMoney(String(totals.vat), currency)}</span>
              </>
            )}
          </li>
          <li className='flex gap-x-2 items-center font-bold border-t pt-2'>
            {loading && (
              <>
                <Skeleton className='h-5 w-16' />
                <Skeleton className='h-5 w-10 ml-auto' />
              </>
            )}

            {!loading && (
              <>
                {t('total', 'Total')}
                <span className='ml-auto'>{parseAndFormatMoney(String(totals.price + totals.vat), currency)}</span>
              </>
            )}
          </li>
        </ul>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: submitting,
            disabled: loading || apiError !== undefined,
            variant: ButtonVariant.Primary,
            text: submitLabel,
            type: 'submit',
            onClick: onSubmit,
          },
        ]}
      />
    </PageModal>
  );
}

export default CheckoutRvoCreditsModal;
