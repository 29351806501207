import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import { Float } from '@headlessui-float/react';
import { ActionModal } from 'ui/Modals';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import TitleBarButtons from 'ui/Modals/TitleBarButtons';
import { ActionButton, ActionProps } from 'ui/Modals/ActionModal';
import { ErrorSection } from 'ui/Error';
import ApiErrorParser from 'api/ApiErrorParser';
import { Feed, Feeding, FeedType, FeedTypeCategoryEnum, Horse, ModulePermissionsEnum } from 'openapi';
import { HorseUsageBadges } from 'components/Horses/HorseUsageBadges';
import Badge, { BadgeSize } from 'ui/Badge/Badge';
import { AllColors } from 'utilities/colors';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'context/AccountContext';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import { CaretDown, CirclesThree, FirstAidKit, Jar, Pencil, Plant, Trash, Warning } from '@phosphor-icons/react';
import classNames from 'classnames';
import usePermissions from 'hooks/UsePermissions';
import { Alert } from 'ui/Alert';
import { Severity } from 'utilities/severity';

export interface Props {
  children: ReactNode;
  visible?: boolean;
  horse: Horse;
  feeding: Feeding;
  feeds?: Feed[];
  feedTypes?: FeedType[];
  onRequestClose?: () => void;
  onRequestNewFeed?: () => void;
  onRequestEditFeed?: (feed: Feed) => void;
  onRequestRemoveFeed?: (feed: Feed) => void;
  className?: string;

  // Indicates that the feeding will change today or tomorrow.
  feedingChangesToday?: boolean;
  feedingChangesTomorrow?: boolean;
}

export interface ContentProps {
  horse: Horse;
  feeding: Feeding;
  feeds?: Feed[];
  feedTypes?: FeedType[];
  onRequestEditFeed?: (feed: Feed) => void;
  onRequestRemoveFeed?: (feed: Feed) => void;
  // Indicates that the feeding will change today or tomorrow.
  feedingChangesToday?: boolean;
  feedingChangesTomorrow?: boolean;
}

/**
 * An inline modal for showing an existing calendar item.
 */
function FeedingModalContent({
  horse,
  feeds,
  feedTypes,
  onRequestEditFeed,
  onRequestRemoveFeed,
  feedingChangesToday = false,
  feedingChangesTomorrow = false,
}: ContentProps): JSX.Element {
  const { t } = useTranslation();
  const { formatNumber, formatDate } = useAccount();
  const [selectedFeed, setSelectedFeed] = useState<string | undefined>();
  const { hasPermission } = usePermissions();

  return (
    <div className='space-y-3'>
      <div className='flex flex-col items-start overflow-hidden gap-1'>
        {horse.name}
        {!horse.hidden && <HorseUsageBadges size={BadgeSize.Small} iconOnly={true} horse={horse} />}
        {horse.hidden && <Badge color={AllColors.Rose}>{t('inactive', 'Inactive')}</Badge>}
      </div>
      {feedingChangesToday && (
        <Alert severity={Severity.Warning} message={t('feed-changes-today', 'Feeding for this horse is changed today.')} />
      )}
      {feedingChangesTomorrow && (
        <Alert severity={Severity.Warning} message={t('feed-changes-tomorrow', 'Feeding for this horse is will change tomorrow.')} />
      )}
      <ol className='space-y-2'>
        {feeds?.map(feed => {
          const feedType = feedTypes?.find(type => type.uid === feed.feed_type_uid);
          return (
            <li key={feed.uid} className='select-none space-y-4 border-b rounded py-2'>
              <div
                // className='flex flex-row items-center gap-1 cursor-pointer'
                onClick={() => (selectedFeed === feed.uid ? setSelectedFeed(undefined) : setSelectedFeed(feed.uid))}
              >
                <div className='flex flex-row items-center gap-1 cursor-pointer'>
                  {feedType?.category === FeedTypeCategoryEnum.ROUGHAGE && <Plant size={22} weight='light' className='inline shrink-0' />}
                  {feedType?.category === FeedTypeCategoryEnum.MEDICINE && (
                    <FirstAidKit size={22} weight='light' className='inline shrink-0' />
                  )}
                  {feedType?.category === FeedTypeCategoryEnum.SUPPLEMENT && <Jar size={22} weight='light' className='inline shrink-0' />}
                  {feedType?.category === FeedTypeCategoryEnum.CONCENTRATE && (
                    <CirclesThree size={22} weight='light' className='inline grow' />
                  )}
                  <p className='grow w-full'>
                    {feedType?.display_name} ({formatNumber(Number(feed.quantity))} {feedType?.quantity_measure})
                  </p>
                  <CaretDown className={classNames('shrink-0', { '-rotate-180': selectedFeed === feed.uid })} />
                </div>
                {feedType?.warning && (
                  <p className='text-xs bg-amber-200 line-clamp-5 mt-1 p-1 rounded'>
                    <Warning color={AllColors.Amber} className='inline' /> {feedType?.warning}
                  </p>
                )}
              </div>
              {selectedFeed === feed.uid && (
                <div>
                  <p className='text-xs'>
                    {t('feeding-started', 'Started')}: {feed?.start.date ? formatDate(new Date(feed?.start.date)) : '?'}
                  </p>
                  <p className='text-xs'>
                    {t('feeding-end', 'End')}: {feed.endTimeUnspecified || !feed.end?.date ? '-' : formatDate(new Date(feed.end?.date))}
                  </p>
                  {hasPermission(ModulePermissionsEnum.MANAGE_FEED) && (
                    <div className='flex justify-between gap-2 mt-1'>
                      <Button className='grow' size={ButtonSize.Small} icon={<Pencil />} onClick={() => onRequestEditFeed?.(feed)}>
                        {t('edit', 'Edit')}
                      </Button>
                      <Button size={ButtonSize.Small} icon={<Trash />} onClick={() => onRequestRemoveFeed?.(feed)} />
                    </div>
                  )}
                </div>
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
}

export default function FeedingModal({
  children,
  visible,
  horse,
  feeding,
  feeds,
  feedTypes,
  onRequestClose,
  onRequestNewFeed,
  onRequestEditFeed,
  onRequestRemoveFeed,
  className,
  feedingChangesToday,
  feedingChangesTomorrow,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { width } = useScreenSize();
  const [apiError, setApiError] = useState<ApiErrorParser<void>>();
  const { hasPermission } = usePermissions();

  const modalActions = useMemo((): ActionProps[] => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_FEED)) {
      return [
        {
          variant: ButtonVariant.Default,
          text: t('add-new-feed', 'Add new feed'),
          onClick: () => onRequestNewFeed?.(),
        },
      ];
    } else {
      return [];
    }
  }, [t, onRequestNewFeed, hasPermission]);

  if (width <= ScreenSize.md) {
    return (
      <div className={className}>
        {children}
        <ActionModal actions={modalActions} open={visible ?? false}>
          <TitleBarButtons title={feeding.name} actions={[]} onClose={onRequestClose} />
          <ErrorSection errors={apiError} />
          <FeedingModalContent
            onRequestEditFeed={onRequestEditFeed}
            onRequestRemoveFeed={onRequestRemoveFeed}
            horse={horse}
            feeding={feeding}
            feeds={feeds}
            feedTypes={feedTypes}
            feedingChangesToday={feedingChangesToday}
            feedingChangesTomorrow={feedingChangesTomorrow}
          />
        </ActionModal>
      </div>
    );
  }

  return (
    <Float
      offset={4}
      show={visible}
      placement='right-start'
      flip={true}
      composable
      portal={true}
      as={Fragment}
      enter='transition duration-200 ease-out'
      enter-from='opacity-0 -translate-y-1'
      enter-to='opacity-100 translate-y-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
      onHide={() => setApiError(undefined)}
    >
      <Float.Reference>
        <div className={className}>{children}</div>
      </Float.Reference>
      <Float.Content>
        <div
          className='w-64 rounded-lg bg-white pl-4 pb-4 pt-2 pr-2 shadow-[0_8px_30px_rgb(0,0,0,0.22)]'
          onClick={e => {
            // Don't hide when the modal is clicked
            e.stopPropagation();
          }}
        >
          <TitleBarButtons actions={[]} title={feeding.name} onClose={onRequestClose} />
          <ErrorSection errors={apiError} />
          <FeedingModalContent
            onRequestEditFeed={onRequestEditFeed}
            onRequestRemoveFeed={onRequestRemoveFeed}
            horse={horse}
            feeding={feeding}
            feeds={feeds}
            feedTypes={feedTypes}
            feedingChangesToday={feedingChangesToday}
            feedingChangesTomorrow={feedingChangesTomorrow}
          />
          <div className='pr-2 mt-5 sm:mt-6 gap-4 flex'>
            {modalActions.map((action, index) => (
              <ActionButton className='w-full' key={index + action.text} action={action} />
            ))}
          </div>
        </div>
      </Float.Content>
    </Float>
  );
}
