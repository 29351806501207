import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, HorseLocation, PaginatedHorseLocationList } from 'openapi';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, TileList, TileRow } from 'ui/Layout/Tile';
import ListItem from './ListItem';
import ApiErrorParser from 'api/ApiErrorParser';
import useHorseDetail from 'hooks/UseHorseDetail';
import useModal from 'ui/Modals/UseModal';
import UpdateLocationHistoryDateModal from 'components/Horses/LocationHistory/UpdateLocationHistoryDateModal';
import DeleteLocationHistoryModal from 'components/Horses/LocationHistory/DeleteLocationHistoryModal';
import Button, { ButtonSize } from 'ui/Button';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';
import { navBackToThisPage } from 'ui/Layout/Page';

const LIMIT_LOCATIONS = 5;

/**
 * Tile that show the location and history for a given horse
 */
function LocationHistoryTile(): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<HorseLocation>>();
  const [selectedLocation, setSelectedLocation] = useState<HorseLocation>();

  const { loadHorseLocations, horseLocations, horse, contacts } = useHorseDetail();
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { closeModal: closeEditModal, modalIsVisible: editModalIsVisible, showModal: showEditModal } = useModal();
  const navigate = useNavigate();
  const { closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible, showModal: showDeleteModal } = useModal();

  const loadLocations = useCallback((): CancelablePromise<PaginatedHorseLocationList> | undefined => {
    if (!selectedOrganization || !horse) return;
    const promise = loadHorseLocations(selectedOrganization.uid, horse.uid);
    promise
      .then(() => setApiError(undefined))
      .catch(error => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<HorseLocation>(error));
          console.error(error);
        }
      });
    return promise;
  }, [horse, loadHorseLocations, selectedOrganization]);

  /**
   * Limit the horse locations
   */
  const limitedhorseLocations = (horseLocations ?? []).slice(0, LIMIT_LOCATIONS);

  /**
   * Check if we have more locations so we can show a load more button
   */
  const hasMore = (horseLocations ?? []).length > LIMIT_LOCATIONS;

  /**
   * Load the horse locations
   */
  useEffect(() => {
    if (selectedOrganization && horse) {
      const promise = loadLocations();
      return () => promise && promise.cancel();
    }
  }, [selectedOrganization, horse]); //eslint-disable-line

  // If we don't have any locations we don't show the tile
  if (horseLocations?.length === 0) {
    return <></>;
  }

  return (
    <Tile title={t('location-history', 'Location history')} loading={horse === undefined} apiError={apiError}>
      {horse && (
        <TileList>
          {horseLocations && (
            <>
              {limitedhorseLocations.map((location, index) => (
                <TileRow key={location.uid}>
                  <ListItem
                    isCurrent={index === 0}
                    location={location}
                    contacts={contacts}
                    onEdit={() => {
                      setSelectedLocation(location);
                      showEditModal();
                    }}
                    onDelete={() => {
                      setSelectedLocation(location);
                      showDeleteModal();
                    }}
                  />
                </TileRow>
              ))}

              {hasMore && (
                <Button
                  onClick={() =>
                    navigate(`${generatePath(AppRoutes.HorsesLocationHistory.path)}?${navBackToThisPage()}&horse=${horse.uid}`)
                  }
                  className='mt-4 w-full'
                  size={ButtonSize.Small}
                >
                  {t('view-more', 'View more')}
                </Button>
              )}
              {horseLocations.length === 0 && (
                <p className='text-gray-500 italic'>{t('no-location-history', 'There is currently no location history.')}</p>
              )}

              {selectedLocation && (
                <>
                  <UpdateLocationHistoryDateModal
                    location={selectedLocation}
                    isVisible={editModalIsVisible}
                    onClosed={() => setSelectedLocation(undefined)}
                    onRequestCloseModal={closeEditModal}
                    onEdited={loadLocations}
                  />
                  <DeleteLocationHistoryModal
                    location={selectedLocation}
                    isVisible={deleteModalIsVisible}
                    onRequestCloseModal={closeDeleteModal}
                    onClosed={() => setSelectedLocation(undefined)}
                    onDeleted={loadLocations}
                  />
                </>
              )}
            </>
          )}
        </TileList>
      )}
    </Tile>
  );
}

export default LocationHistoryTile;
