import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { TFunction } from 'i18next';
import {
  CatalogueProduct,
  Category,
  Horse,
  Product,
  PurchaserOrderDetail,
  PurchaserOrderItem,
  SupplierOrderDetail,
  SupplierOrderItemDetail,
  VatCategoryEnum,
} from 'openapi';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  order: SupplierOrderDetail | PurchaserOrderDetail;
  products?: Product[];
  productCategories?: Category[];
  catalogue?: CatalogueProduct[];
  stallions?: Horse[];
}

interface OrderItemSummary {
  title: string;
  subTitle?: string;
  price: string;
  vatPercentage: string;
  currency: string;
  vatCategory: VatCategoryEnum;
}

interface ProductEssentials {
  name: string;
  category: {
    readonly uid: string;
    readonly name: string;
    readonly default: string | null;
  };
}

interface Totals {
  currency: string;
  subTotal: number;
  vatTotal: number;
  total: number;
}

// A helper function to get a uniform object (ProductEssentials) from either a
// Product or a CatalogueProduct.
const productEssentials = (
  t: TFunction,
  productUid: string,
  products?: Product[],
  productCategories?: Category[],
  catalogue?: CatalogueProduct[],
  stallions?: Horse[],
): ProductEssentials | undefined => {
  if (products) {
    const product = products.find(p => p.uid === productUid);
    const breedingProductCategory = productCategories?.find(cat => cat.default === 'BREEDING');
    const shippingProductCategory = productCategories?.find(cat => cat.default === 'SHIPPING');
    if (!product) {
      return undefined;
    }
    let name = t('product', 'Product');
    if (product.category_uid === breedingProductCategory?.uid) {
      const stallion = stallions?.find(horse => horse.uid === product.stallion_uid);
      name = stallion?.name ?? t('stallion', 'Stallion');
      return { name, category: breedingProductCategory };
    } else if (product.category_uid === shippingProductCategory?.uid) {
      name = t('shipping-with-provider-name', 'Shipping ({{providerName}})', { providerName: product.shipping_provider_name });
      return { name, category: shippingProductCategory };
    }
  } else if (catalogue) {
    const product = catalogue.find(p => p.uid === productUid);
    if (!product) {
      return undefined;
    }
    let name = t('product', 'Product');
    if (product.category.default === 'BREEDING') {
      name = product.stallion?.name ?? t('stallion', 'Stallion');
    } else if (product.category.default === 'SHIPPING') {
      name = t('shipping-with-provider-name', 'Shipping ({{providerName}})', { providerName: product.shipping_provider_name });
    }
    return { name, category: product.category };
  }
  return undefined;
};

/**
 * A table (with optional message) showing a summary of a semen order. It displays
 * mainly the financial part of it and the order items (product and shipping).
 */
export default function OrderSummaryTable({ order, products, productCategories, catalogue, stallions }: Props): JSX.Element {
  const { t } = useTranslation();
  const { formatMoney, parseAndFormatMoney } = useAccount();
  const { selectedOrganizationDetails } = useOrganization();

  const getProduct = useCallback(
    (orderItem: SupplierOrderItemDetail | PurchaserOrderItem): OrderItemSummary | undefined => {
      if (!orderItem.product_uid) {
        return;
      }
      const product = productEssentials(t, orderItem.product_uid, products, productCategories, catalogue, stallions);
      if (!product) {
        return undefined;
      }
      if (product.category.default === 'BREEDING') {
        const pregnancyPrice = Number(orderItem.unit_price) - Number(orderItem.upfront_payment);
        let productTitle = t('semen-from-stallion-name', 'Semen from {{stallionName}}', { stallionName: product.name });
        let productSubTitle = t('surcharge-of-in-case-of-pregnancy', 'Surcharge of {{price}} in case of pregnancy', {
          price: parseAndFormatMoney(!isNaN(pregnancyPrice) ? pregnancyPrice.toString() : '', orderItem.unit_price_currency),
        });

        const isPartial = orderItem.unit_price !== orderItem.upfront_payment;
        if (orderItem.parent_semen_order_item_uid) {
          // It's a repeat order.
          productTitle = t('repeat-order-semen-from', 'Repeat order for semen from {{stallionName}}', { stallionName: product.name });
          productSubTitle = '';
        } else if (isPartial) {
          productTitle = t('fixed-price-for-semen-from', 'Fixed price for semen from {{stallionName}}', { stallionName: product.name });
        } else {
          productSubTitle = t('repeat-order-free-of-charge', 'Repeat orders are free of charge');
        }

        return {
          title: productTitle,
          subTitle: productSubTitle,
          price: (orderItem.parent_semen_order_item_uid ? '0' : orderItem.upfront_payment) ?? '0',
          vatPercentage: orderItem.vat_percentage.percentage ?? '0',
          currency: orderItem.unit_price_currency,
          vatCategory: orderItem.vat_percentage.vat_category ?? VatCategoryEnum.S,
        };
      } else if (product.category.default === 'SHIPPING') {
        return {
          title: product.name,
          subTitle: undefined,
          price: orderItem.unit_price ?? '0',
          vatPercentage: orderItem.vat_percentage.percentage ?? '0',
          currency: orderItem.unit_price_currency,
          vatCategory: orderItem.vat_percentage.vat_category ?? VatCategoryEnum.S,
        };
      } else {
        return undefined;
      }
    },
    [products, catalogue, stallions, t, parseAndFormatMoney, productCategories],
  );

  const totals = useMemo((): Totals => {
    let subTotal = 0.0;
    let vatTotal = 0.0;
    let total = 0.0;
    let currency: string | undefined;

    order.order_items?.forEach(item => {
      const product = getProduct(item);
      const price = item.parent_semen_order_item_uid ? 0 : Number(product?.price);
      const vatPercentage = Number(product?.vatPercentage);
      const vat = price * (vatPercentage / 100.0);
      subTotal += price;
      vatTotal += vat;
      total += price + vat;
      if (currency) {
        if (currency !== item.unit_price_currency) {
          console.error('Multiple currencies in a order is not supported', order.uid);
        }
      } else {
        currency = item.unit_price_currency;
      }
    });
    return { subTotal, vatTotal, total, currency: currency ?? selectedOrganizationDetails?.currency ?? 'EUR' };
  }, [order.order_items, order.uid, selectedOrganizationDetails?.currency, getProduct]);

  // When we have a special vat percentage. Then create an info object for it.
  const vatToShortString = useCallback(
    (vatCategory: VatCategoryEnum): { short: string; full: string } | undefined => {
      switch (vatCategory) {
        case VatCategoryEnum.G:
          return { short: t('vat-category-export-short', 'E'), full: t('vat-category-export', 'Export') };
        case VatCategoryEnum.AE:
          return { short: t('vat-category-reverse-charge-short', 'RC'), full: t('vat-category-reverse-charge', 'Reverse-charge') };
        default:
          return undefined;
      }
    },
    [t],
  );

  return (
    <table className='w-full border-separate border-spacing-y-2'>
      <tbody>
        {order.order_items?.map(item => {
          const product = getProduct(item);
          if (!product) {
            return <></>;
          }
          // If we have a special vat percentage. Then add some info about it.
          const vatString = vatToShortString(product.vatCategory);
          return (
            <tr key={item.uid}>
              <td className='border-b border-gray-200 py-2'>
                <p>{product.title}</p>
                <p className='text-sm'>{product.subTitle}</p>
              </td>
              <td className='border-b border-gray-200 text-right'>{parseAndFormatMoney(product.price, product.currency)}</td>
              <td className='border-b border-gray-200 text-right text-sm space-x-2'>
                <span>
                  ({`${Number(product.vatPercentage)}%`} {t('vat', 'VAT')})
                </span>
                {vatString && <span title={vatString.full}>{`(${vatString.short})`}</span>}
              </td>
            </tr>
          );
        })}
        <tr>
          <td className='text-right'>{t('order-summary-subtotal', 'Subtotal')}</td>
          <td className='text-right'>{formatMoney(totals.subTotal, totals.currency)}</td>
          <td />
        </tr>
        <tr>
          <td className='text-right'>{t('vat', 'VAT')}</td>
          <td className='text-right'>{formatMoney(totals.vatTotal, totals.currency)}</td>
          <td />
        </tr>
        <tr>
          <td className='text-right font-medium'>{t('total-to-pay', 'Total to pay')}</td>
          <td className='text-right border-t-2 border-gray-400 font-medium'>{formatMoney(totals.total, totals.currency)}</td>
          <td />
        </tr>
      </tbody>
    </table>
  );
}
