import { TFunction } from 'i18next';

export interface InvoiceLanguage {
  id: string;
  name: string;
}

export const getInvoiceLanguages = (t: TFunction): InvoiceLanguage[] => {
  return (
    [
      {
        id: 'NL',
        name: t('netherlands', 'Netherlands'),
      },
      {
        id: 'DE',
        name: t('germany', 'Germany'),
      },
      {
        id: 'EN',
        name: t('english', 'English'),
      },
    ]
      // and sort by name
      .sort((a, b) => a.name.localeCompare(b.name))
  );
};
