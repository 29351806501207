import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { PublicOrganisation } from 'openapi';

interface Props {
  webshopOrganization?: PublicOrganisation;
  children: React.ReactNode;
}

type WebshopContextType = {
  webshopOrganization: Props['webshopOrganization'];
  setWebshopOrganization: Dispatch<SetStateAction<Props['webshopOrganization']>>;
};

const WebshopContext = createContext<WebshopContextType>({
  webshopOrganization: undefined,
  setWebshopOrganization: () => console.warn('no Webshop provider'),
});

export function useWebshopContext(): WebshopContextType {
  return useContext(WebshopContext);
}

export function WebshopProvider({ children }: Props): JSX.Element {
  const [webshopOrganization, setWebshopOrganization] = useState<Props['webshopOrganization']>();

  return <WebshopContext.Provider value={{ webshopOrganization, setWebshopOrganization }}>{children}</WebshopContext.Provider>;
}
