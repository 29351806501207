import { Dialog, Transition } from '@headlessui/react';
import { X } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ElementType, ForwardedRef, forwardRef, Fragment, ReactNode } from 'react';
import Button, { ButtonVariant } from '../Button';

export enum PageModalWidth {
  Lg = 'max-w-6xl',
  Base = 'max-w-4xl',
  Sm = 'max-w-2xl',
  Xs = 'max-w-xl',
}

export interface ActionProps {
  variant: ButtonVariant;
  text: string;
  formId?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

interface PageModalProps<T> {
  children: ReactNode;
  open: boolean;
  parentElement?: ElementType;
  parentProps?: T;
  onClose?: () => void;
  onClosed?: () => void;
  width?: PageModalWidth;
}

/**
 * PageModalContent Props
 * @param {ReactNode} children - The content to display inside the modal
 */
export const PageModalContent = forwardRef(function PageModalContent(
  { children }: { children: ReactNode },
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  return (
    <div className='bg-white relative transform overflow-hidden text-left transition-all w-full px-4 flex-1 overflow-y-auto py-2' ref={ref}>
      {children}
    </div>
  );
});

/**
 * PageModalActions Props
 * @param {ActionProps[]} actions - Array of actions to display as buttons
 *
 * ActionProps:
 * @prop {ButtonVariant} variant - Variant styling for the button
 * @prop {string} text - Text to display inside the button
 * @prop {string} [formId] - Form id for which this button acts
 * @prop {'button' | 'submit' | 'reset' | undefined} [type] - Button type
 * @prop {boolean} [loading] - Whether the button is in a loading state
 * @prop {boolean} [disabled] - Whether the button is disabled
 */
export function PageModalActions({ actions }: { actions: ActionProps[] }): JSX.Element {
  return (
    <div className='gap-4 flex justify-end bg-neutral-50 p-4'>
      {actions.map((action, index) => {
        return (
          <div key={index + action.text} className={'flex-1 md:flex-grow-0'}>
            <Button
              form={action.formId}
              onClick={e => {
                // implement the click event only when the formId is not given
                if (!action.formId) {
                  e.preventDefault();
                  action.onClick?.();
                } else if (action.onClick) {
                  console.warn(
                    '[PageModalActions] you implement both formId and onClick. FormId goes always before for the onClick if implemented both',
                  );
                }
              }}
              type={action.type || 'button'}
              disabled={action.disabled}
              className={'w-full md:w-auto'}
              loading={action.loading}
              variant={action.variant}
            >
              {action.text}
            </Button>
          </div>
        );
      })}
    </div>
  );
}

interface PageModalTitleProps {
  title: string;
  onClose?: () => void;
}

/**
 * PageModalTitle Props
 * @param {string} title - The title to display at the top of the modal
 * @param {() => void} onClose - Function to call when the close button is clicked
 */
export function PageModalTitle({ title, onClose }: PageModalTitleProps): JSX.Element {
  return (
    <div className={'flex rounded-t-lg justify-between bg-white text-gray-800 md:bg-white items-center gap-4'}>
      <div className='md:hidden w-10' />
      <Dialog.Title as='h3' className='text-base pl-0 md:pl-4 py-4 flex-1 text-center md:text-left font-semibold leading-6'>
        {title}
      </Dialog.Title>
      {onClose && (
        <div className={'group p-2 block cursor-pointer'} onClick={onClose}>
          <div className='group-hover:bg-neutral-100 p-1 rounded-full'>
            <X size={28} />
          </div>
        </div>
      )}
    </div>
  );
}

/**
 * PageModal Props
 * @template T - Type for parentProps
 * @param {boolean} open - Whether the modal is open or closed
 * @param {ReactNode} children - Content to display inside the modal
 * @param {ElementType} [parentElement] - Parent element type (e.g. 'div', custom React component)
 * @param {T} parentProps - Props to pass to the parent element
 * @param {() => void} onClose - Function to call when the modal should be closed
 */
export default function PageModal<T>({
  open,
  children,
  parentElement,
  onClose,
  onClosed,
  parentProps,
  width = PageModalWidth.Base,
}: PageModalProps<T>): JSX.Element {
  const props = parentProps ? { ...parentProps } : {};
  /**
   * headless-ui modal will move the modal to the end of the body element, which might break the form.
   * To avoid that, we are expecting parentElement, and parentProps.
   * If parentElement is not provided, we will use a div element.
   */
  const _Component = parentElement || 'div';
  const onCloseDummyFn = () => {
    // empty function
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog onClose={onClose ?? onCloseDummyFn} as='div' className='z-[99] fixed'>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={onClosed}
        >
          <div className='fixed h-full inset-0 z-[99] backdrop-blur-sm md:backdrop-blur-none md:bg-gray-800 md:bg-opacity-50 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-[99] md:overflow-y-auto h-full'>
          <div className='flex h-full md:h-auto min-h-[100%] items-end justify-center p-0 md:p-4 text-center sm:items-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <_Component
                {...props}
                className={classNames(
                  'pt-safe-offset-8 pb-safe md:pt-0 md:shadow-xl md:rounded-lg overflow-hidden w-screen flex flex-col h-full md:h-auto transition-all',
                  width,
                )}
              >
                {children}
              </_Component>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
