import { CirclesThree, DotsThreeVertical, FirstAidKit, Jar, Plant, Plus } from '@phosphor-icons/react';
import useApiPromises from 'api/hooks/useApiPromises';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import { FeedType, FeedTypeCategoryEnum, FeedtypesService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import {
  table,
  tableHiddenColumnMd,
  tableHiddenColumnSm,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTrNoClick,
  tableThead,
  tableTheadTd,
} from 'ui/Const';
import DropdownMenu from 'ui/DropdownMenu';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';
import SaveFeedTypeModal from './SaveFeedTypeModal';
import { useAccount } from 'context/AccountContext';
import { feedTypeCategoryString } from 'utilities/Feed';
import RemoveFeedTypeModal from './RemoveFeedTypeModal';

export default function FeedTypesTile(): JSX.Element {
  const [feedTypes, setFeedTypes] = useState<FeedType[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { t } = useTranslation();
  const { loading: loadingApiPromises } = useApiPromises({ apiPromises });
  const { selectedOrganization } = useOrganization();
  const { formatNumber } = useAccount();
  const { showModal: showSaveFeedTypeModal, closeModal: closeSaveFeedTypeModal, modalIsVisible: saveFeedTypeModalIsVisible } = useModal();
  const {
    showModal: showRemoveFeedTypeModal,
    closeModal: closeRemoveFeedTypeModal,
    modalIsVisible: removeFeedTypeModalIsVisible,
  } = useModal();
  const [edit, setEdit] = useState<FeedType>();
  const [removeFeedType, setRemoveFeedType] = useState<FeedType>();

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganization) {
      return promises;
    }
    promises.appendList<FeedType>(
      'feed-types',
      () =>
        FeedtypesService.feedtypesList({
          organisationUid: selectedOrganization.uid,
          o: 'name',
          hidden: false,
        }),
      setFeedTypes,
    );
    setApiPromises(promises);
    return promises;
  }, [selectedOrganization]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const tileActions = useMemo(() => {
    return [
      {
        onClick: () => {
          setEdit(undefined);
          showSaveFeedTypeModal();
        },
        buttonVariant: ButtonVariant.Default,
        icon: <Plus />,
        text: t('add-feed-type', 'Add feed type'),
      },
    ];
  }, [t, showSaveFeedTypeModal]);

  return (
    <Tile title={t('feed-types', 'Feed types')} loading={loadingApiPromises} actions={tileActions}>
      <table className={table}>
        <thead className={tableThead}>
          <tr className={tableHiddenHeaderSm}>
            <td className='w-10' />
            <td className={tableTheadTd}>{t('name', 'Name')}</td>
            <td className={tableTheadTd}>{t('type', 'Type')}</td>
            <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('brand', 'Brand')}</td>
            <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('default-amount', 'Default amount')}</td>
            <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('warning', 'Warning')}</td>
            <td className='w-10 md:hidden' />
          </tr>
        </thead>
        <tbody className={tableTbody}>
          {feedTypes?.length === 0 && (
            <tr>
              <td colSpan={100} className='text-center p-16 opacity-70'>
                {t('no-feeding-types', 'No feeding types created.')}
              </td>
            </tr>
          )}
          {(feedTypes ?? []).map(feed => (
            <tr className={tableTbodyTrNoClick} key={feed.uid}>
              <td className='text-center w-10'>
                {feed.category === FeedTypeCategoryEnum.ROUGHAGE && <Plant size={22} weight='light' className='inline' />}
                {feed.category === FeedTypeCategoryEnum.MEDICINE && <FirstAidKit size={22} weight='light' className='inline' />}
                {feed.category === FeedTypeCategoryEnum.SUPPLEMENT && <Jar size={22} weight='light' className='inline' />}
                {feed.category === FeedTypeCategoryEnum.CONCENTRATE && <CirclesThree size={22} weight='light' className='inline' />}
              </td>
              <td>
                <p>{feed.display_name}</p>
                <p className='text-sm text-gray-600 block sm:hidden'>{feed.brand}</p>
              </td>
              <td className={tableHiddenColumnSm}>{feedTypeCategoryString(t, feed.category)}</td>
              <td className={tableHiddenColumnSm}>{feed.brand}</td>
              <td className={tableHiddenColumnSm}>
                {formatNumber(Number(feed.default_quantity))} {feed.quantity_measure}
              </td>
              <td className={tableHiddenColumnSm}>{feed.warning}</td>

              <td className='w-14 pr-2'>
                <DropdownMenu
                  menuPlacement='bottom-end'
                  menuItems={[
                    [
                      {
                        element: t('edit', 'Edit'),
                        onClick: () => {
                          setEdit(feed);
                          showSaveFeedTypeModal();
                        },
                      },
                      {
                        element: t('remove', 'Remove'),
                        className: 'text-red-600',
                        onClick: () => {
                          setRemoveFeedType(feed);
                          showRemoveFeedTypeModal();
                        },
                      },
                    ],
                  ]}
                >
                  <div className='w-full text-right cursor-pointer'>
                    <DotsThreeVertical size={24} weight='bold' className='inline' />
                  </div>
                </DropdownMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <SaveFeedTypeModal
        onSaved={loadApiData}
        existingFeedType={edit}
        visible={saveFeedTypeModalIsVisible}
        closeModal={closeSaveFeedTypeModal}
        onClosed={() => {
          setEdit(undefined);
        }}
      />
      <RemoveFeedTypeModal
        open={removeFeedTypeModalIsVisible}
        feedType={removeFeedType}
        onRemoved={loadApiData}
        requestClose={closeRemoveFeedTypeModal}
      />
    </Tile>
  );
}
