import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

/**
 * This context is intended only saving the registrationToken of the user device
 */

interface Props {
  token?: string;
  isEnabled?: boolean;
  children: React.ReactNode;
}

type PushNotificationContextType = {
  token: Props['token'];
  setToken: Dispatch<SetStateAction<Props['token']>>;

  isEnabled: Props['isEnabled'];
  setIsEnabled: Dispatch<SetStateAction<Props['isEnabled']>>;
};

const PushNotificationContext = createContext<PushNotificationContextType>({
  token: undefined,
  setToken: () => console.warn('no PushNotification provider'),

  isEnabled: undefined,
  setIsEnabled: () => console.warn('no PushNotification provider'),
});

export function usePushNotificationContext(): PushNotificationContextType {
  return useContext(PushNotificationContext);
}

export function PushNotificationProvider({ children }: Props): JSX.Element {
  const [token, setToken] = useState<Props['token']>();
  const [isEnabled, setIsEnabled] = useState<Props['isEnabled']>();

  return (
    <PushNotificationContext.Provider value={{ token, setToken, isEnabled, setIsEnabled }}>{children}</PushNotificationContext.Provider>
  );
}
