import { PushNotifications, Token } from '@capacitor/push-notifications';
import ApiErrorParser from 'api/ApiErrorParser';
import { IS_ANDROID_APP, IS_IOS_APP, IS_MOBILE_APP } from 'const';
import { usePushNotificationContext } from 'context/PushNotificationContext';
import { ApiService, ApplicationIdEnum, CustomFCMDevice, CustomFCMDeviceTypeEnum } from 'openapi';
import { useCallback } from 'react';

interface ReturnType {
  pushNotificationToken: string | undefined;
  isPushNotificationEnabled: boolean;
  registerPushNotification: (accountUid: string) => void;
  setPushNotificationStatus: () => Promise<void>;
}

function usePushNotification(): ReturnType {
  const { token, setToken, isEnabled, setIsEnabled } = usePushNotificationContext();

  /**
   * Check if the push notification is enabled and set the state
   */
  const setPushNotificationStatus = async () => {
    if (!IS_MOBILE_APP) return;

    let enabled = false;
    try {
      const permissionStatus = await PushNotifications.checkPermissions();
      enabled = permissionStatus.receive === 'granted';
    } catch (e) {
      // ignore errors
    }

    setIsEnabled(enabled);
  };

  /**
   * Register a pushNotification
   */
  const registerPushNotification = useCallback(
    async (accountUid: string) => {
      // only mobile app is allowed
      if (!IS_MOBILE_APP) return;

      // do not allow a re-registration when we already have a token
      if (token) return;

      // remove any previous declared listners
      await PushNotifications.removeAllListeners();

      // listen when we registered a push notification and on success, set the token
      // this token will be saved in the API/BE (after the user object has been defined)
      await PushNotifications.addListener('registration', ({ value: token }: Token) => {
        // we also need the update the API so
        // if the device does not exists, we receive a 404, that is for us the signal to register the device in the API
        ApiService.apiV5DeviceFcmRetrieve({
          registrationId: token,
        })
          .then() // ignore the result as we only interested in the catch (404)
          .catch(e => {
            const error = new ApiErrorParser<CustomFCMDevice>(e);

            // if we did not found the device, we should create a new one
            if (error.statusCode === 404) {
              ApiService.apiV5DeviceFcmCreate({
                requestBody: {
                  application_id: ApplicationIdEnum.FCM_EQUINEM,
                  registration_id: token,
                  type: IS_IOS_APP
                    ? CustomFCMDeviceTypeEnum.IOS
                    : IS_ANDROID_APP
                      ? CustomFCMDeviceTypeEnum.ANDROID
                      : CustomFCMDeviceTypeEnum.WEB,
                  uid: accountUid,
                  date_created: Date().toString(),
                },
              }).catch(e => console.error(e));
            }
          })
          .finally(async () => {
            // and finally we should set the states
            await setPushNotificationStatus();
            setToken(token);
          });
      });

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      try {
        const result = await PushNotifications.requestPermissions();
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        }
      } catch (e) {
        // ignore errors
      }
    },
    [token], // eslint-disable-line
  );

  return {
    isPushNotificationEnabled: Boolean(isEnabled),
    registerPushNotification,
    pushNotificationToken: token,
    setPushNotificationStatus,
  };
}

export default usePushNotification;
