import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInvoiceLanguages, InvoiceLanguage } from './invoiceLanguage.data';

interface ReturnType {
  invoiceLanguageById: (id: string | undefined) => InvoiceLanguage | undefined;
  invoiceLanguageByName: (name: string | undefined) => InvoiceLanguage | undefined;
  invoiceLanguages: InvoiceLanguage[];
}

function useInvoiceLanguages(): ReturnType {
  const [invoiceLanguages, setInvoiceLanguages] = useState<InvoiceLanguage[]>([]);

  const { t } = useTranslation();

  /**
   * Load the translated countries
   */
  useEffect(() => {
    setInvoiceLanguages(getInvoiceLanguages(t));
  }, [t]);

  const invoiceLanguageById = useCallback(
    (id: string | undefined): InvoiceLanguage | undefined => {
      if (id === undefined) return undefined;
      return invoiceLanguages.find(lang => lang.id.toLowerCase() === id.toLowerCase());
    },
    [invoiceLanguages],
  );

  const invoiceLanguageByName = useCallback(
    (name: string | undefined): InvoiceLanguage | undefined => {
      if (name === undefined) return undefined;
      return invoiceLanguages.find(lang => lang.name.toLowerCase() === name.toLowerCase());
    },
    [invoiceLanguages],
  );

  return { invoiceLanguageById, invoiceLanguageByName, invoiceLanguages };
}

export default useInvoiceLanguages;
