import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import { useOrganization } from 'context/OrganizationContext';
import {
  CancelablePromise,
  Contact,
  Horse,
  SupplierOrderItem,
  OrdersService,
  Product,
  SemenTypeEnum,
  StudbookEnum,
  UsageTypeEnum,
  PaginatedHorseList,
  HorsesService,
  ShippingServiceTypeEnum,
  SupplierOrderDetail,
  PatchedSupplierOrderDetail,
  Category,
  CountryEnum,
} from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { DateInput, SelectInput, TextAreaInput, TextInput } from 'ui/Inputs';
import RadioButtonGroup from 'ui/Inputs/RadioGroupInput';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { contactName } from 'utilities/Contact';
import { transformEmptyToUndefined } from 'utilities/zod';
import { z } from 'zod';
import { useAccount } from 'context/AccountContext';
import HorseInputSelect from 'components/Horses/HorseInputSelect';
import { BreedingProductOptions, SemenTypeOptions, ShippingOptions, SemenUsageTypeOptions } from './Helpers';
import { Alert } from 'ui/Alert';
import OrderSummaryTable from './OrderSummaryTable';
import { Severity } from 'utilities/severity';
import { objectDiff } from 'utilities/compareObject';
import { SupplierOrderItemDetail } from 'openapi/models/SupplierOrderItemDetail';
import useCountries from 'hooks/UseCountries';
import { studBookMap } from './Studbook';
import StudbookInputSelect from 'components/Breeding/StudbookInputSelect';
import Fieldset from 'ui/Fieldset';
import { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import classNames from 'classnames';

interface Props {
  existingOrder?: SupplierOrderDetail; // For editing an order
  repeatOrder?: SupplierOrderDetail; // A new order can be a followup on a previous order. This happens when the mare doesn't get pregnant. The breeding costs of a repeat order are 0.
  horses: Horse[];
  products: Product[];
  categories: Category[];
  contacts: Contact[];
  open: boolean;
  onRequestClose: (reload: boolean) => void;
  onContactUpdate: () => void;
  onClosed?: () => void;
}

const SaveSemenOrderSchema = z.object({
  // Order fields
  customer: z.string(),

  // Shipping receiver address info
  shipping_name: z.string().max(255).optional(),
  shipping_address_line1: z.string().max(255).optional(),
  shipping_address_line2: z.string().max(255).optional(),
  shipping_address_line3: z.string().max(255).optional(),
  shipping_city: z.string().max(255).optional(),
  shipping_state: z.string().max(255).optional(),
  shipping_postcode: z.string().max(64).optional(),
  shipping_country: z.string().optional(),

  supplier_note: z.string(),
  shipping_date: z.string(),
  shipping_product: z.string(),

  // Semen order line fields.
  product: z.string(),
  product_price: z.string().optional(),
  semen_collection_station: z.string().optional(),
  mare: z.string(),
  semen_type: schemas.SemenTypeEnum,
  usage_type: schemas.UsageTypeEnum,
  studbook: z.string().optional(),
});

type SaveSemenOrder = z.infer<typeof SaveSemenOrderSchema>;

const transformFromOrderDetail = (
  existingOrder: SupplierOrderDetail,
  products: Product[],
  productCategories: Category[],
): Partial<SaveSemenOrder> => {
  const breedingProductCategory = productCategories.find(cat => cat.default === 'BREEDING');
  const shippingProductCategory = productCategories.find(cat => cat.default === 'SHIPPING');

  const breedingItems = (existingOrder.order_items ?? []).filter(
    item =>
      item.product_uid === products.find(prod => prod.uid === item.product_uid && prod.category_uid === breedingProductCategory?.uid)?.uid,
  );
  const shippingItems = (existingOrder.order_items ?? []).filter(
    item =>
      item.product_uid === products.find(prod => prod.uid === item.product_uid && prod.category_uid === shippingProductCategory?.uid)?.uid,
  );
  if (breedingItems.length > 1) {
    console.error('Editing a order with multiple breeding products is not supported. Taking the first from the list.');
  }

  if (breedingItems.length === 0) {
    console.error('Breeding product not found in order list');
    // Fill in as much as possible to still show the user something.
    return {
      customer: existingOrder.customer_uid ?? undefined,

      shipping_name: existingOrder.shipping_name,
      shipping_address_line1: existingOrder.shipping_address_line1,
      shipping_address_line2: existingOrder.shipping_address_line2,
      shipping_address_line3: existingOrder.shipping_address_line3,
      shipping_city: existingOrder.shipping_city,
      shipping_state: existingOrder.shipping_state,
      shipping_postcode: existingOrder.shipping_postcode,
      shipping_country: existingOrder.shipping_country,

      supplier_note: existingOrder.supplier_note,
      shipping_date: existingOrder.shipping_date ?? undefined,
    };
  }

  const breedingItem = breedingItems[0];
  const shippingItem = shippingItems.length > 0 ? shippingItems[0] : undefined;

  return {
    customer: existingOrder.customer_uid ?? undefined,

    shipping_name: existingOrder.shipping_name,
    shipping_address_line1: existingOrder.shipping_address_line1,
    shipping_address_line2: existingOrder.shipping_address_line2,
    shipping_address_line3: existingOrder.shipping_address_line3,
    shipping_city: existingOrder.shipping_city,
    shipping_state: existingOrder.shipping_state,
    shipping_postcode: existingOrder.shipping_postcode,
    shipping_country: existingOrder.shipping_country,

    supplier_note: existingOrder.supplier_note,
    shipping_date: existingOrder.shipping_date ?? undefined,
    product: breedingItem.product_uid ?? undefined,
    shipping_product: shippingItem?.product_uid,
    mare: breedingItem.mare_uid ?? undefined,
    product_price: breedingItem.unit_price,
    semen_type: breedingItem.semen_type,
    usage_type: breedingItem.usage_type,
    studbook: breedingItem.studbook,
  };
};

/**
 * We use the AddSemenOrder type for our form input. We do this because the
 * mismatch between de data type that we need to POST to the server and user
 * input is too big. The AddSemenOrder type is defined above and used for the
 * zod validator. The method transforms the AddSemenOrder to an OrderDetail
 * which is required by the server.
 */
const transformToOrderDetail = (
  data: SaveSemenOrder,
  dryRun: boolean,
  productCategories: Category[],
  products: Product[] | undefined,
  existingOrder: SupplierOrderDetail | undefined,
  repeatOrder: SupplierOrderDetail | undefined,
  defaultCurrency = 'EUR',
): Partial<SupplierOrderDetail> => {
  if (!products) {
    return {};
  }
  const breedingProductCategory = productCategories.find(cat => cat.default === 'BREEDING');
  const shippingProductCategory = productCategories.find(cat => cat.default === 'SHIPPING');

  const breedingItems = (existingOrder?.order_items ?? []).filter(
    item =>
      item.product_uid === products.find(prod => prod.uid === item.product_uid && prod.category_uid === breedingProductCategory?.uid)?.uid,
  );
  const shippingItems = (existingOrder?.order_items ?? []).filter(
    item =>
      item.product_uid === products.find(prod => prod.uid === item.product_uid && prod.category_uid === shippingProductCategory?.uid)?.uid,
  );

  if (breedingItems.length > 1) {
    console.error('Editing a order with multiple breeding products is not supported. Taking the first from the list.');
  }

  const foundBreedingItem = breedingItems.length === 0 ? undefined : breedingItems[0];
  const foundShippingItem = breedingItems.length === 0 ? undefined : shippingItems[0];

  // Has the incoming 'data' shipping or not.
  const hasShipping =
    products.find(prod => prod.uid === data.shipping_product && prod.category_uid === shippingProductCategory?.uid)
      ?.shipping_service_type !== ShippingServiceTypeEnum.PICK_UP;

  // If it's a repeat order, then get the original order item this order is based upon.
  let repeatOrderItemUid: string | null = null;
  if (repeatOrder) {
    const repeatOrderItem = repeatOrder.order_items?.find(item => item.mare_uid);
    if (!repeatOrderItem?.uid) {
      throw Error('Trying to create a repeat order but the original order item cannot be found.');
    }
    repeatOrderItemUid = repeatOrderItem.uid;
  } else if (foundBreedingItem && foundBreedingItem.parent_semen_order_item_uid) {
    repeatOrderItemUid = foundBreedingItem.parent_semen_order_item_uid;
  }

  let semenOrderLine: Partial<SupplierOrderItemDetail> = {
    product_uid: data.product,
    mare_uid: data.mare,
    semen_type: data.semen_type as SemenTypeEnum,
    usage_type: data.usage_type as UsageTypeEnum,
    quantity: 1, // Fixed amount of one breeding order.
    studbook: data.studbook as StudbookEnum,
    unit_price: data.product_price,
    unit_price_currency: foundBreedingItem?.unit_price_currency ?? defaultCurrency,
    // Provide the previous semen order line if this instance is a follow-up
    // for the same mare (whose impregnation failed).
    parent_semen_order_item_uid: repeatOrderItemUid,
  };

  let shippingOrderLine: Partial<SupplierOrderItem> = {
    product_uid: data.shipping_product,
    unit_price_currency: foundShippingItem?.unit_price_currency ?? defaultCurrency,
  };

  // On patch the differences if we're changing the order.
  if (foundBreedingItem) {
    semenOrderLine = { uid: foundBreedingItem?.uid, ...objectDiff(foundBreedingItem, semenOrderLine) };
  }

  // On patch the differences if we're changing the order.
  if (foundShippingItem) {
    shippingOrderLine = { uid: foundShippingItem?.uid, ...objectDiff(foundShippingItem, shippingOrderLine) };
  }

  let order: Partial<SupplierOrderDetail> = {
    customer_uid: data.customer,
    shipping_name: hasShipping ? data.shipping_name : undefined,
    shipping_address_line1: hasShipping ? data.shipping_address_line1 : undefined,
    shipping_address_line2: hasShipping ? data.shipping_address_line2 : undefined,
    shipping_address_line3: hasShipping ? data.shipping_address_line3 : undefined,
    shipping_city: hasShipping ? data.shipping_city : undefined,
    shipping_state: hasShipping ? data.shipping_state : undefined,
    shipping_postcode: hasShipping ? data.shipping_postcode : undefined,
    shipping_country: hasShipping ? (data.shipping_country as CountryEnum) : undefined,
    supplier_note: data.supplier_note,
    shipping_date: data.shipping_date,
  };

  if (existingOrder) {
    order = objectDiff(existingOrder, order);
  }

  order.order_items = [semenOrderLine as SupplierOrderItemDetail, shippingOrderLine as SupplierOrderItemDetail];
  order.dry_run = dryRun;
  return order;
};

// Return date of today in YYYY-MM-DD format.
const today = (): string => {
  return new Date().toISOString().substr(0, 10);
};

function SaveSemenOrder({
  existingOrder,
  repeatOrder,
  horses,
  products,
  categories,
  contacts,
  open,
  onRequestClose,
  onContactUpdate,
  onClosed,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { countries } = useCountries();
  const { selectedOrganizationUid, selectedOrganizationDetails, selectedOrganizationPrimaryStableLocation } = useOrganization();
  const [customerHorses, setCustomerHorses] = useState<Horse[] | undefined>();
  const [showDryRun, setShowDryRun] = useState<boolean>(false);
  const { accountDetails } = useAccount();
  const { countryById } = useCountries();

  // We use dry-run to post an order to the api but not store it. The result of
  // the order has all vat and breeding values correctly set. This way we can
  // show a summary of the prices before submitting.
  const [dryRun, setDryRun] = useState<SupplierOrderDetail | undefined>();

  const defaultValues = useMemo((): Partial<SaveSemenOrder> => {
    if (existingOrder) {
      return transformFromOrderDetail(existingOrder, products, categories);
    }
    if (repeatOrder) {
      const repeat = transformFromOrderDetail(repeatOrder, products, categories);
      repeat.shipping_date = today();
      repeat.supplier_note = '';
      repeat.product_price = '0';
      return repeat;
    }
    return {
      shipping_date: today(),
      semen_type: 'FRESH',
      usage_type: 'KI',
    };
  }, [existingOrder, repeatOrder, products, categories]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
    reset,
    setValue,
    trigger,
  } = useForm<SaveSemenOrder>({
    resolver: zodResolver(SaveSemenOrderSchema),
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(SaveSemenOrderSchema, errors);

  // Returns true if this is a repeat order. It can be a new repeat order or it
  // could be that we edit a repeat order.
  const isRepeatOrder = useMemo(() => {
    if (existingOrder) {
      // For existing orders, check if we have an order item with a followup.
      return existingOrder.order_items?.find(item => item.parent_semen_order_item_uid) !== undefined;
    }
    // For a new order, check if the repeatOrder param is set.
    return repeatOrder !== undefined;
  }, [existingOrder, repeatOrder]);

  const pageActions = useMemo((): ActionProps[] => {
    const availableActions: ActionProps[] = [];
    if (showDryRun) {
      availableActions.push({
        variant: ButtonVariant.Default,
        text: t('back', 'Back'),
        type: 'button',
        onClick: () => setShowDryRun(false),
      });
      availableActions.push({
        variant: ButtonVariant.Primary,
        text: existingOrder ? t('save-order', 'Save order') : t('place-order', 'Place order'),
        type: 'submit',
        formId: 'AddSemenOrder',
        loading: isSubmitting,
      });
    } else {
      availableActions.push({
        variant: ButtonVariant.Primary,
        text: t('finalize', 'Finalize'),
        type: 'button',
        loading: isSubmitting,
        onClick: async () => {
          // Don't continue when we have a form field error.
          if (!(await trigger())) {
            return;
          }

          handleSubmit(onSubmit)()
            .then(() => setShowDryRun(true))
            .catch(e => {
              console.error('Failed to go to final step in order process', e);
            });
        },
      });
    }
    return availableActions;
  }, [showDryRun, t, isSubmitting, handleSubmit]); //eslint-disable-line

  const product = watch('product');
  const selectedCustomerUid = watch('customer');
  const selectedShippingCountry = watch('shipping_country');
  const shippingProduct = watch('shipping_product');
  const selectedMareUid = watch('mare');
  const selectedStallionUid = watch('product');
  const selectedStallion = products.find(prod => prod.uid === selectedStallionUid);

  const onSubmit = async (data: SaveSemenOrder) => {
    if (!selectedOrganizationUid) {
      console.error('Cannot load invoice without a selected organization');
      return;
    }
    if (!products) {
      console.error('Products not loaded when trying to save order');
      return;
    }

    const dryRun = !showDryRun;

    setApiError(undefined);
    if (dryRun) {
      // Only unset the dry run when we're generating a new one. Otherwise the
      // the gui flickers
      setDryRun(undefined);
    }

    try {
      let promise: CancelablePromise<SupplierOrderDetail>;
      if (existingOrder) {
        const updatedFields = transformToOrderDetail(
          data,
          dryRun,
          categories,
          products,
          existingOrder,
          undefined,
          selectedOrganizationDetails?.currency ?? 'EUR',
        ) as PatchedSupplierOrderDetail;

        promise = OrdersService.ordersSuppliedPartialUpdate({
          uid: existingOrder.uid,
          supplierUid: selectedOrganizationUid,
          requestBody: updatedFields,
        });
      } else {
        const supplierOrder = transformToOrderDetail(
          data,
          dryRun,
          categories,
          products,
          undefined,
          repeatOrder,
          selectedOrganizationDetails?.currency ?? 'EUR',
        ) as SupplierOrderDetail;

        promise = OrdersService.ordersSuppliedCreate({
          supplierUid: selectedOrganizationUid,
          requestBody: supplierOrder,
        });
      }
      const result = await promise;

      if (dryRun) {
        setDryRun(result);
      } else {
        onRequestClose(true);
      }
    } catch (e) {
      setApiError(new ApiErrorParser<SupplierOrderDetail>(e));
      throw e;
    }
  };

  const selectedCustomerName = useMemo(() => {
    if (!selectedCustomerUid) {
      return t('not-selected', 'Not selected');
    }
    const contact = contacts.find(contact => contact.uid === selectedCustomerUid);
    if (contact) {
      return contactName(contact);
    }
    return selectedCustomerUid;
  }, [selectedCustomerUid, t, contacts]);

  const selectedMareName = useMemo(() => {
    if (!selectedMareUid) {
      return t('not-selected', 'Not selected');
    }
    let horse = horses.find(horse => horse.uid === selectedMareUid);
    if (!horse) {
      horse = customerHorses?.find(horse => horse.uid === selectedMareUid);
    }
    if (horse) {
      return horse.UELN ? `${horse.name} (${horse.UELN})` : horse.name;
    }
    return selectedMareUid;
  }, [selectedMareUid, t, horses, customerHorses]);

  const semenTypeOptions = useMemo(() => {
    return SemenTypeOptions(t, true);
  }, [t]);

  // Build a list of usage type options to show in the dropdown.
  const usageTypeOptions = useMemo(() => {
    return SemenUsageTypeOptions(t);
  }, [t]);

  // Get the shipping product type so we can determine if we need to show the
  // shipping address div.
  const shippingServiceType = useMemo((): ShippingServiceTypeEnum | undefined => {
    return products.find(prod => prod.uid === shippingProduct)?.shipping_service_type;
  }, [products, shippingProduct]);

  // Build a list of breeding products to show in the dropdown.
  const productOptions = useMemo((): OptionItemInterface[] => {
    return BreedingProductOptions(
      t,
      horses ?? [],
      products,
      categories ?? [],
      selectedOrganizationPrimaryStableLocation?.country,
      selectedOrganizationDetails?.currency,
    );
  }, [products, t, horses, selectedOrganizationDetails, selectedOrganizationPrimaryStableLocation, categories]);

  const shippingOptions = useMemo((): OptionItemInterface[] => {
    return ShippingOptions(
      products,
      categories ?? [],
      selectedShippingCountry,
      selectedOrganizationPrimaryStableLocation?.country,
      selectedOrganizationDetails?.currency,
    );
  }, [products, selectedOrganizationDetails, selectedOrganizationPrimaryStableLocation, selectedShippingCountry, categories]);

  // List only the mares from the customer
  const customerMares = useMemo(() => {
    return (customerHorses ?? []).filter(horse => horse.sex === SexEnum._2 && !horse.hidden);
  }, [customerHorses]);

  const noShippingOptionsMessage = useMemo((): string | undefined => {
    if (shippingOptions.length > 0 || !selectedShippingCountry) {
      return undefined;
    }

    const shippingCountryName = countryById(selectedShippingCountry)?.name ?? selectedShippingCountry;
    return t('no-shipping-options-purchaser-order', 'No shipping options available for {{shippingCountryName}}.', {
      shippingCountryName,
    });
  }, [shippingOptions.length, selectedShippingCountry, countryById, t]);

  // Load the mares from api for the selected customer
  const loadCustomerHorses = useCallback(
    (customerId: string): CancelablePromise<PaginatedHorseList> => {
      const promise = HorsesService.horsesList({
        organisationUid: selectedOrganizationUid ?? '',
        ownerUid: customerId,
      });
      promise
        .then(res => setCustomerHorses(res.results))
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<PaginatedHorseList>(e));
          }
        });
      return promise;
    },
    [selectedOrganizationUid, setCustomerHorses, setApiError],
  );

  const semenCollectionStationGroupedContacts = useMemo((): WrappedComboboxProps<Contact>[] => {
    return [
      {
        items: contacts.filter(contact => contact.is_semen_collection_station),
        heading: t('semen-collection-station', 'Semen collection station'),
      },
      {
        items: contacts.filter(contact => !contact.is_semen_collection_station),
        heading: t('contacts', 'Contacts'),
      },
    ];
  }, [contacts, t]);

  // Reset the price to the default stallion price when the selected stallion
  // changes. This only applies when we're not in a repeat order (because
  // the price is default 0) and not in an existing order.
  useEffect(() => {
    if (existingOrder || repeatOrder) {
      // Don't alter the price if we have an existing or repeat order.
      return;
    }

    const productItem = products?.find(item => item.uid === product);
    const stallion = horses.find(horse => horse.uid === productItem?.stallion_uid);
    let defaultSemenCollectionStation: Contact | undefined;
    if (stallion?.default_semen_collection_station) {
      defaultSemenCollectionStation = contacts.find(contact => contact.uid === stallion.default_semen_collection_station);
    }
    const price = productItem?.current_price;

    if (price) {
      setValue('product_price', price);
    } else {
      setValue('product_price', '');
    }
    if (defaultSemenCollectionStation) {
      setValue('semen_collection_station', defaultSemenCollectionStation.uid);
    } else {
      setValue('semen_collection_station', '');
    }
  }, [product, existingOrder, repeatOrder, setValue, products, contacts, horses]);

  /**
   * event that will be fired after the modal has been closed
   */
  const resetForm = () => {
    setApiError(undefined);
    setShowDryRun(false);
    setCustomerHorses(undefined);
    reset(defaultValues);
  };

  /**
   * Reset the form when a new 'existing order' or 'repeat order' is set.
   */
  useEffect(() => {
    setApiError(undefined);
    setShowDryRun(false);
    reset(defaultValues);
  }, [reset, setApiError, defaultValues]);

  // Load the horses from the customer when a customer is selected.
  useEffect(() => {
    if (selectedCustomerUid) {
      loadCustomerHorses(selectedCustomerUid);
    }
    if (!existingOrder && !repeatOrder && !showDryRun) {
      const customer = contacts.find(contact => contact.uid === selectedCustomerUid);
      if (!customer) {
        return;
      }
      // We update the receiver to match the customer.
      setValue('shipping_name', contactName(customer));
      setValue('shipping_address_line1', customer.address_line1);
      setValue('shipping_address_line2', customer.address_line2);
      setValue('shipping_address_line3', customer.address_line3);
      setValue('shipping_city', customer.city);
      setValue('shipping_state', customer.state);
      setValue('shipping_postcode', customer.postcode);
      setValue('shipping_country', customer.country);
    }
  }, [selectedCustomerUid]); //eslint-disable-line

  /**
   * Reset the mare when we switch from customer
   *
   * We use a watch subscription, so we are only checking the values when the input is changed after the initial setup of register() has been done.
   * If we are using the watch() result and use it with a useEffect, it will always trigger when it's value is changed from undefined.
   * The watch subscription does not trigger when the value is changed from undefined to an actual value.
   */
  useEffect(() => {
    const subscription = watch(({ customer: newCustomer }, { name }) => {
      if (name === 'customer' && selectedCustomerUid !== newCustomer) {
        setValue('mare', '');
      }
    });
    return () => subscription.unsubscribe();
  }, [selectedCustomerUid, setValue, watch]);

  return (
    <PageModal
      open={open}
      onClosed={() => {
        resetForm();
        onClosed?.();
      }}
      parentElement='form'
      parentProps={{ id: 'AddSemenOrder', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle
        title={existingOrder ? t('edit-semen-order', 'Edit semen order') : t('add-semen-order', 'New semen order')}
        onClose={() => onRequestClose(false)}
      />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />

        {!showDryRun && (
          <>
            <Fieldset legend={t('mare', 'Mare')}>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                {isRepeatOrder ? (
                  <p>
                    {existingOrder ? (
                      <>
                        {t(
                          'edit-repeat-order-intro',
                          'You are editing a repeat order for "{{selectedMareName}}" of "{{selectedCustomerName}}".',
                          {
                            selectedMareName,
                            selectedCustomerName,
                          },
                        )}
                      </>
                    ) : (
                      <>
                        {t(
                          'create-repeat-order-intro',
                          'You are creating a repeat order for "{{selectedMareName}}" of "{{selectedCustomerName}}".',
                          {
                            selectedMareName,
                            selectedCustomerName,
                          },
                        )}
                      </>
                    )}
                    <ErrorSection errors={[fieldError('customer'), fieldError('mare')].filter(e => e !== undefined) as string[]} />
                  </p>
                ) : (
                  <>
                    <ContactInputSelect
                      name='customer'
                      control={control}
                      contacts={contacts ?? []}
                      onCreated={contact => {
                        onContactUpdate(); // Update the list of contacts.
                        setValue('customer', contact.uid);
                      }}
                      required={true}
                      label={t('customer', 'Customer')}
                      error={fieldError('customer')}
                    />
                    <HorseInputSelect
                      disabled={!selectedCustomerUid}
                      name='mare'
                      label={t('mare', 'Mare')}
                      required={true}
                      control={control}
                      horses={customerMares}
                      error={fieldError('mare')}
                      hint={
                        selectedCustomerUid && customerMares?.length === 0
                          ? t('customer-has-no-mares', 'Customer has no mares in ownership. Please add a mare.')
                          : undefined
                      }
                      // Disabled for now, as the created mare requires to have some ownership
                      // See https://gitlab.qubis.nl/equinem/equiapp/-/issues/84
                      onCreated={newHorse => {
                        loadCustomerHorses(selectedCustomerUid);
                        setValue('mare', newHorse.uid);
                      }}
                      createMareOnly={true}
                      ownerUid={selectedCustomerUid}
                    />
                  </>
                )}
              </div>
            </Fieldset>
            <Fieldset legend={t('sperm', 'Sperm')}>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <SelectInput
                  label={t('stallion', 'Stallion')}
                  nullable={true}
                  required={true}
                  options={productOptions}
                  error={fieldError('product')}
                  nullableValue=''
                  {...register('product', { setValueAs: transformEmptyToUndefined() })}
                />
                <ContactInputSelect
                  name='semen_collection_station'
                  control={control}
                  contacts={semenCollectionStationGroupedContacts}
                  label={t('semen-collection-station', 'Semen collection station')}
                  error={fieldError('semen_collection_station')}
                />
                <TextInput
                  required={true}
                  type='number'
                  lang={accountDetails?.language}
                  step='0.01'
                  label={t('price', 'Price')}
                  {...register('product_price', { setValueAs: transformEmptyToUndefined() })}
                  postText={selectedStallion?.current_price_currency ?? selectedOrganizationDetails?.currency ?? 'EUR'}
                  error={fieldError('product_price')}
                />
                <RadioButtonGroup<SaveSemenOrder>
                  name='semen_type'
                  required={true}
                  control={control}
                  options={semenTypeOptions}
                  error={fieldError('semen_type')}
                  label={t('semen-type', 'Semen type')}
                />
                <SelectInput
                  required={true}
                  nullable={true}
                  options={usageTypeOptions}
                  error={fieldError('usage_type')}
                  label={t('semen-usage-type', 'Usage type')}
                  nullableValue=''
                  {...register('usage_type', { setValueAs: transformEmptyToUndefined() })}
                />
                <StudbookInputSelect
                  name='studbook'
                  label={t('studbook', 'Studbook')}
                  required={false}
                  control={control}
                  studbookOptions={studBookMap}
                  error={fieldError('studbook')}
                  setValueAs={transformEmptyToUndefined()}
                />
              </div>
            </Fieldset>
            <Fieldset legend={t('shipping-or-pickup', 'Shipping / pick up')}>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <SelectInput
                  error={fieldError('shipping_country')}
                  required={false}
                  options={countries}
                  nullable={true}
                  nullableValue=''
                  label={t('country', 'Country')}
                  {...register('shipping_country', { setValueAs: transformEmptyToUndefined() })}
                />
                <SelectInput
                  required={true}
                  options={shippingOptions}
                  nullable={true}
                  error={fieldError('shipping_product')}
                  label={t('shipping-method', 'Shipping method')}
                  nullableValue=''
                  {...register('shipping_product', { setValueAs: transformEmptyToUndefined() })}
                />
                {noShippingOptionsMessage && <Alert message={noShippingOptionsMessage} severity={Severity.Danger} />}
                <DateInput
                  control={control}
                  required={true}
                  label={
                    !shippingServiceType || shippingServiceType !== ShippingServiceTypeEnum.PICK_UP
                      ? t('shipping-date', 'Shipping date')
                      : t('pickup-date', 'Pick-up date')
                  }
                  name='shipping_date'
                  error={fieldError('shipping_date')}
                />
              </div>
            </Fieldset>
            <div className={classNames('', { hidden: !shippingServiceType || shippingServiceType === ShippingServiceTypeEnum.PICK_UP })}>
              <Fieldset legend={t('shipping-address', 'Shipping address')}>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <TextInput
                    error={fieldError('shipping_name')}
                    required={false}
                    label={t('name', 'Name')}
                    {...register('shipping_name', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <div className='flex flex-col md:flex-row gap-3'>
                    <TextInput
                      className='grow'
                      error={fieldError('shipping_address_line1')}
                      required={false}
                      label={t('address-line1', 'Street')}
                      {...register('shipping_address_line1', { setValueAs: transformEmptyToUndefined() })}
                    />
                    <TextInput
                      className='md:w-1/3'
                      error={fieldError('shipping_address_line3')}
                      required={false}
                      label={t('house-number', 'House number')}
                      {...register('shipping_address_line3', { setValueAs: transformEmptyToUndefined() })}
                    />
                  </div>

                  <TextInput
                    error={fieldError('shipping_address_line2')}
                    required={false}
                    label={t('address-line2', 'Address line 2')}
                    {...register('shipping_address_line2', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('shipping_postcode')}
                    required={false}
                    label={t('postcode', 'Postcode')}
                    {...register('shipping_postcode', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('shipping_city')}
                    required={false}
                    label={t('city', 'City')}
                    {...register('shipping_city', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('shipping_state')}
                    required={false}
                    label={t('stateOrCounty', 'State or province')}
                    {...register('shipping_state', { setValueAs: transformEmptyToUndefined() })}
                  />
                </div>
              </Fieldset>
            </div>
            <TextAreaInput
              className='mt-4 mx-1'
              label={t('internal-note', 'Internal note')}
              {...register('supplier_note')}
              error={fieldError('supplier_note')}
            />
          </>
        )}
        {showDryRun && (
          <div className='my-4 space-y-4'>
            {dryRun && <OrderSummaryTable productCategories={categories} order={dryRun} products={products} stallions={horses} />}
          </div>
        )}
      </PageModalContent>
      <PageModalActions actions={pageActions} />
    </PageModal>
  );
}
export default SaveSemenOrder;
