import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { HorseOwner, VatpercentagesService } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onImported: () => void;
}

/**
 * A modal that imports the default VAT percentages for EU countries.
 */
function ImportEuVatModal({ isVisible, onRequestClose, onImported }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<HorseOwner> | undefined>();
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const importVatPercentages = async () => {
    if (!selectedOrganization) return;
    setSubmitting(true);

    try {
      await VatpercentagesService.vatpercentagesImportDefaultEuVatPercentagesCreate({
        organisationUid: selectedOrganization.uid,
      });

      // fire the onDeleted callback
      onImported();

      // close the modal
      onRequestClose();
    } catch (error) {
      setApiError(new ApiErrorParser(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('import', 'Import'),
          loading: submitting,
          variant: ButtonVariant.Primary,
          onClick: importVatPercentages,
        },
      ]}
      title={t('import-eu-vat-percentages', 'Import default EU VAT percentages')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>
        {t(
          'import-eu-vat-percentages-desc',
          'Are you sure you want to import the default VAT percentages for all EU countries? Existing VAT percentages will be overwritten.',
        )}
      </p>
    </ActionModal>
  );
}

export default ImportEuVatModal;
