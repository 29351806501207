import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { AvailableWeekdaysEnum, FacilitiesService, Facility, FacilityTypeEnum, Stable } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextInput } from 'ui/Inputs';
import MultiSelectWeekDayInput from 'ui/Inputs/MultiSelectWeekDayInput';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { contactName } from 'utilities/Contact';
import { facilityTypeToString } from 'utilities/Planning';
import { transformEmptyToUndefined } from 'utilities/zod';

interface Props {
  stables: Stable[];
  facility?: Facility;
  isVisible: boolean;
  onRequestClose: () => void;
  onSaved: (facility: Facility) => void;
}

// Form validation
const schema = schemas.Facility.omit({
  uid: true,
  created_on: true,
  last_modified_on: true,
  created_by: true,
  last_modified_by: true,
  hidden: true,
  available: true,
  description: true,
}).required({
  name: true,
});

export default function SaveFacilityModal({ isVisible, onRequestClose, facility, stables, onSaved }: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { accountDetails } = useAccount();

  const defaultValues = useMemo((): Partial<Facility> => {
    return (
      facility ?? {
        stable_uid: accountDetails?.preferred_stable,
        available_weekdays: [
          AvailableWeekdaysEnum.MONDAY,
          AvailableWeekdaysEnum.TUESDAY,
          AvailableWeekdaysEnum.WEDNESDAY,
          AvailableWeekdaysEnum.THURSDAY,
          AvailableWeekdaysEnum.FRIDAY,
          AvailableWeekdaysEnum.SATURDAY,
          AvailableWeekdaysEnum.SUNDAY,
        ],
      }
    );
  }, [facility, accountDetails]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    clearErrors,
    control,
  } = useForm<Facility>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
    reset();
  };

  const pageTitle = useMemo(() => {
    return facility ? t('edit-facility', 'Edit facility') : t('add-facility', 'Add facility');
  }, [facility, t]);

  const facilityTypeOptions = useMemo(() => {
    return Object.values(FacilityTypeEnum).reduce<OptionItemInterface[]>((prevVal, currentVal) => {
      prevVal.push({ id: currentVal, name: facilityTypeToString(t, currentVal) });
      return prevVal;
    }, []);
  }, [t]);

  const stableOptions = useMemo(() => {
    return (
      stables?.map(stable => {
        return { id: stable.uid, name: contactName(stable.location) ?? '' };
      }) ?? []
    );
  }, [stables]);

  const minNumberOfHoursOptions = useMemo((): OptionItemInterface[] => {
    return [
      { id: '0.25', name: `15 ${t('minutes', 'minutes')}` },
      { id: '0.5', name: `30 ${t('minutes', 'minutes')}` },
      { id: '1', name: `1 ${t('hour', 'hour')}` },
    ];
  }, [t]);

  /**
   * Submit event handler, update the data via the API
   */
  const onSubmit: SubmitHandler<Facility> = async (data: Partial<Facility>) => {
    if (!selectedOrganization) return;

    try {
      let updatedFacility: Facility;

      // update if we deal with an existing horse group, otherwise create a new one
      if (facility) {
        const promise = FacilitiesService.facilitiesPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: facility.uid,
          requestBody: data,
        });
        updatedFacility = await promise;
      } else {
        const promise = FacilitiesService.facilitiesCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: data as Facility,
        });
        updatedFacility = await promise;
      }

      // fire the onSaved event
      onSaved(updatedFacility);

      // close the modal
      onRequestClose();
    } catch (error) {
      setApiError(new ApiErrorParser<Facility>(error));
    }
  };

  /**
   * Reset the form when the default values change
   */
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, isVisible, reset]);

  return (
    <PageModal
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'saveFacility', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
      width={PageModalWidth.Sm}
    >
      <PageModalTitle title={pageTitle} onClose={onRequestClose} />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />
        <div className='py-3 space-y-4'>
          <SelectInput
            label={t('stable', 'Stable')}
            required={true}
            options={stableOptions}
            error={fieldError('stable_uid')}
            {...register('stable_uid', { setValueAs: transformEmptyToUndefined() })}
          />
          <SelectInput
            nullable={true}
            required={true}
            label={t('facility-type', 'Facility type')}
            options={facilityTypeOptions}
            error={fieldError('facility_type')}
            {...register('facility_type', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
          />
          <TextInput
            required={true}
            label={t('name', 'Name')}
            error={fieldError('name')}
            {...register('name', { setValueAs: transformEmptyToUndefined() })}
          />
          <TextInput
            label={t('planning-window-opens-days-ahead', 'Planning window opens days ahead')}
            {...register('planning_window_opens_days_ahead', {
              setValueAs: val => (val === null || val === undefined ? val : val === '' ? null : Number(val)),
            })}
            postText={t('days', 'days')}
            error={fieldError('planning_window_opens_days_ahead')}
            hint={t('planning-window-opens-days-ahead-hint', 'Indicate how many days ahead this facility may be planned.')}
          />
          <TextInput
            label={t('planning-window-closes-hours-before', 'Planning window closes hours before')}
            {...register('planning_window_closes_hours_before', { setValueAs: transformEmptyToUndefined() })}
            postText={t('hours-lower', 'hours')}
            error={fieldError('planning_window_closes_hours_before')}
            hint={t(
              'planning-window-closes-hours-before-hint',
              'Indicate the minimum amount of hours this facility requires an reservation to be planned ahead, e.g. at least 0,5 hours.',
            )}
          />
          <TextInput
            label={t('max-number-of-horses', 'Maximum number of horses')}
            {...register('slot_capacity', {
              setValueAs: val => (val === null || val === undefined ? val : val === '' ? null : Number(val)),
            })}
            postText={t('horses', 'Horses').toLocaleLowerCase()}
            required={false}
            error={fieldError('slot_capacity')}
            hint={t(
              'max-number-of-horses-hint',
              'Maximum number of horses this facility can host for an reservation. Leave empty to skip validation.',
            )}
          />
          <SelectInput
            label={t('min-time-per-reservation', 'Minimum time per reservation')}
            {...register('min_hours_per_event', { setValueAs: transformEmptyToUndefined() })}
            options={minNumberOfHoursOptions}
            required={true}
            error={fieldError('min_hours_per_event')}
            hint={t('min-hours-per-reservation-hint', 'Minimum reservation duration when using this facility.')}
          />
          <TextInput
            label={t('max-hours-per-event', 'Maximum hours per reservation')}
            {...register('max_hours_per_event', { setValueAs: transformEmptyToUndefined() })}
            postText={t('hours', 'Hours').toLocaleLowerCase()}
            required={false}
            error={fieldError('max_hours_per_event')}
            hint={t('max-hours-per-event-hint', 'Maximum reservation duration when using this facility. Leave blank for no limit.')}
          />
          <div className='grid grid-cols-2 gap-4 w-full'>
            <TextInput
              className='grow'
              label={t('available-from', 'Available from')}
              type='time'
              {...register('available_from', { setValueAs: transformEmptyToUndefined() })}
              error={fieldError('available_from')}
              hint={t('available-from-hint', 'Facility can be booked/reserved starting from this time')}
            />
            <TextInput
              className='grow'
              label={t('available-to', 'Available to')}
              type='time'
              {...register('available_to', { setValueAs: transformEmptyToUndefined() })}
              error={fieldError('available_to')}
              hint={t('available-to-hint', 'Facility can be booked/reserved until this time')}
            />
          </div>
          <MultiSelectWeekDayInput
            name='available_weekdays'
            error={fieldError('available_weekdays')}
            required={true}
            control={control}
            label={t('days-available', 'Days available')}
            hint={t('facility-weekdays-hint', 'Select the days of the week this facility is available.')}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            disabled: isSubmitting,
            onClick: onRequestClose,
            variant: ButtonVariant.Default,
            type: 'button',
            text: t('cancel', 'Cancel'),
          },
          {
            formId: 'saveFacility',
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            type: 'submit',
            text: t('save', 'Save'),
          },
        ]}
      />
    </PageModal>
  );
}
