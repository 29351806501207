import BillingTile from 'components/Subscription/BillingTile';
import CancelSubscriptionTile from 'components/Subscription/CancelSubscriptionTile';
import CurrentSubscriptionTile from 'components/Subscription/CurrentSubscriptionTile';
import { EXCLUDE_FLATFEE_MODULES, getEndDateSubscription, SubscriptionState } from 'components/Subscription/Helper';
import RvoCreditsTile from 'components/Subscription/RvoCreditsTile';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import useTrialExpired from 'hooks/UseTrialExpired';
import { ApiService, CatalogueProduct, ModuleEnum, Plan, PricingModelEnum, PublicService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Alert } from 'ui/Alert';
import { EQUINEM_PUBLIC_ORG_UID } from 'ui/Const';
import Page, { PageMaxWidth } from 'ui/Layout/Page';
import { ApiPromises } from 'utilities/ApiPromises';
import { Severity } from 'utilities/severity';

function SubscriptionPage(): JSX.Element {
  const [addAdditionalModule, setAddAdditionalModule] = useState<CatalogueProduct>();
  // const [contacts, setContacts] = useState<Contact[]>();
  const [products, setProducts] = useState<CatalogueProduct[]>();
  const [plans, setPlans] = useState<Plan[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [hash, setHash] = useState<string>();

  const { formatDate } = useAccount();
  const { t } = useTranslation();
  const location = useLocation();
  const { selectedOrganizationUid, selectedOrganizationServiceContracts } = useOrganization();
  const { isInTrial } = useTrialExpired();

  // get the fixed plans
  // but exclude the modules we are not using
  const modulePricePlans = plans?.filter(
    plan => plan.pricing_model === PricingModelEnum.FLAT_FEE && !EXCLUDE_FLATFEE_MODULES.includes(plan.module),
  );
  // get the credits plans
  const rvoCreditsPricePlan = plans?.find(
    plan => plan.pricing_model === PricingModelEnum.CREDITS && plan.module === ModuleEnum.RVO_REPORTS,
  );

  /**
   * List the service contracts for the horse services full module
   */
  const currentHorseFullServiceContract = selectedOrganizationServiceContracts?.find(
    contract => contract.module === ModuleEnum.HORSE_SERVICES_FULL,
  );

  /**
   * List the service contracts for the horse services care module
   */
  const currentHorseCareServiceContract = selectedOrganizationServiceContracts?.find(
    contract => contract.module === ModuleEnum.HORSE_SERVICES_CARE,
  );

  /**
   * List the RVO service contracts
   */
  const currentRvoServiceContract = selectedOrganizationServiceContracts?.find(contract => contract.module === ModuleEnum.RVO_REPORTS);

  /**
   * List current running service contract for the user accounts module
   */
  const currentUserServiceContract = selectedOrganizationServiceContracts?.find(contract => contract.module === ModuleEnum.USER_ACCOUNTS);

  /**
   * List the current service contacts for the additional modules
   */
  const currentModuleServiceContracts = useMemo(() => {
    // we can get the types from the fixedPricePlans as that are the additional modules
    const moduleTypes = modulePricePlans?.map(plan => plan.module) ?? [];
    return selectedOrganizationServiceContracts?.filter(contract => moduleTypes.includes(contract.module));
  }, [modulePricePlans, selectedOrganizationServiceContracts]);

  /**
   * List the available flat fee plans
   */
  const availableModulePlans = useMemo(() => {
    // we can get the types from the fixedPricePlans as that are the additional modules
    const moduleTypes = currentModuleServiceContracts?.map(plan => plan.module) ?? [];
    return modulePricePlans?.filter(contract => !moduleTypes.includes(contract.module));
  }, [currentModuleServiceContracts, modulePricePlans]);

  /**
   * Load the data from the api
   */
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }

    promises.appendList<CatalogueProduct>(
      'products',
      () =>
        PublicService.apiV5PublicOrganisationsProductCatalogueList({
          organisationPublicAccessUuid: EQUINEM_PUBLIC_ORG_UID,
        }),
      setProducts,
    );

    promises.appendList<Plan>(
      'plans',
      () =>
        ApiService.apiV5PlansList({
          isGeneric: false,
        }),
      setPlans,
    );

    // promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganizationUid, generateCacheKey));

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid]);

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  /**
   * Make sure we remove the hash from the url, but without reloading the page
   */
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      setHash(hash);
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [location.hash]);

  return (
    <Page title={t('your-equinem-subscription', 'Your Equinem subscription')} maxWidth={PageMaxWidth.Default} loading={apiPromises}>
      {hash === SubscriptionState.RVO_CREDITS_ADDED && (
        <Alert
          className='mb-4'
          severity={Severity.Success}
          message={t('subscription-rvo-credits-ordered', 'RvO credits are successfully added to your account')}
        />
      )}

      {hash === SubscriptionState.SUBSCRIPTION_CREATED_PAID && (
        <Alert
          className='mb-4'
          severity={Severity.Success}
          message={t('subscription-created-paid', 'Your paid subscription has been created')}
        />
      )}

      {hash === SubscriptionState.SUBSCRIPTION_CANCELLED && (
        <Alert
          className='mb-4'
          severity={Severity.Success}
          message={t(
            'subscription-cancelled-and-converted-to-free',
            'Your subscription has been cancelled and will converted to a free plan on {{date}}.',
            {
              date: formatDate(
                getEndDateSubscription(
                  currentHorseCareServiceContract,
                  currentHorseFullServiceContract,
                  currentModuleServiceContracts,
                  currentUserServiceContract,
                  isInTrial,
                ) ?? '',
              ),
            },
          )}
        />
      )}

      {hash === SubscriptionState.SUBSCRIPTION_CREATED_FREE && (
        <Alert
          className='mb-4'
          severity={Severity.Success}
          message={t('subscription-updated-free', 'Your free subscription has been created')}
        />
      )}

      {hash === SubscriptionState.SUBSCRIPTION_UPDATED && (
        <Alert className='mb-4' severity={Severity.Success} message={t('subscription-updated', 'Your subscription has been updated')} />
      )}

      <div className='grid grid-col-1 xl:grid-cols-3 gap-5 mb-10'>
        <CurrentSubscriptionTile
          className='xl:col-span-2'
          currentUserServiceContract={currentUserServiceContract}
          currentModuleServiceContracts={currentModuleServiceContracts}
          currentHorseFullServiceContract={currentHorseFullServiceContract}
          currentHorseCareServiceContract={currentHorseCareServiceContract}
          availableModulePlans={availableModulePlans}
          plans={plans}
          products={products}
          addAdditionalModule={addAdditionalModule}
          onCloseSaveModal={() => setAddAdditionalModule(undefined)}
          onSuccess={hash => setHash(hash)}
        />

        <BillingTile className='order-1 lg:order-2' />
      </div>

      <div className='grid grid-col-1 xl:grid-cols-2 gap-5 mb-10'>
        <RvoCreditsTile
          rvoCreditsPricePlan={rvoCreditsPricePlan}
          products={products}
          currentRvoServiceContract={currentRvoServiceContract}
        />
        <CancelSubscriptionTile
          currentUserServiceContract={currentUserServiceContract}
          currentModuleServiceContracts={currentModuleServiceContracts}
          currentHorseFullServiceContract={currentHorseFullServiceContract}
          currentHorseCareServiceContract={currentHorseCareServiceContract}
          products={products}
          onCancelledSubscription={hash => setHash(hash)}
        />
      </div>
    </Page>
  );
}

export default SubscriptionPage;
