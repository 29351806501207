import { PaperPlaneTilt, TrashSimple } from '@phosphor-icons/react';
import { cachedPaginatedApiData } from 'api/ApiCache';
import ApiErrorParser from 'api/ApiErrorParser';
import EditInvoiceItems from 'components/Financial/EditInvoiceItems';
import InvoiceSenderLogoBlock from 'components/Financial/InvoiceSenderLogoBlock';
import MarkAsPaidModal from 'components/Financial/MarkAsPaidModal';
import {
  CancelablePromise,
  CategoriesService,
  Category,
  Contact,
  ContactsService,
  Horse,
  HorsesService,
  InvoiceDetail,
  InvoicesService,
  PaginatedContactList,
  PaginatedHorseList,
  VATPercentage,
  VatpercentagesService,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';
import useModal from 'ui/Modals/UseModal';
import { generatePath } from 'utilities/ReactRouter';
import { AppRoutes } from '../../AppRoutes';
import EditableInvoiceItem from '../../components/Financial/EditableInvoiceItem';
import EditInvoice from '../../components/Financial/EditInvoice';
import FinalizeInvoice from '../../components/Financial/FinalizeInvoice';
import InvoiceFooterBlock from '../../components/Financial/InvoiceFooterBlock';
import InvoiceInfoBlock from '../../components/Financial/InvoiceInfoBlock';
import InvoiceItemsBlock from '../../components/Financial/InvoiceItemsBlock';
import InvoiceMeta from '../../components/Financial/InvoiceMeta';
import InvoiceReceiverBlock from '../../components/Financial/InvoiceReceiverBlock';
import InvoiceSenderBlock from '../../components/Financial/InvoiceSenderBlock';
import { useOrganization } from '../../context/OrganizationContext';
import { PageAction, usePage } from '../../context/PageContext';
import { Alert } from '../../ui/Alert';
import { ButtonVariant } from '../../ui/Button';
import Page from '../../ui/Layout/Page';
import { Severity } from '../../utilities/severity';
import { contactName, InvoiceSender } from 'utilities/Contact';

export default function InvoicePage(): JSX.Element {
  const { invoiceId } = useParams();
  const [id, setId] = useState<string | undefined>(invoiceId === 'new' ? undefined : invoiceId);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [finalizeInvoiceModalOpen, setFinalizeInvoiceModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const breadCrumbs = useMemo(() => [AppRoutes.InvoicesList], []);
  const {
    selectedOrganization,
    selectedOrganizationDetails,
    selectedOrganizationPrimaryStableLocation,
    generateCacheKey: getCacheId,
  } = useOrganization();
  const { setApiError } = usePage();
  const [invoice, setInvoice] = useState<InvoiceDetail>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [horses, setHorses] = useState<Horse[]>();
  const [removeInvoiceDialogError, setRemoveInvoiceDialogError] = useState<ApiErrorParser<void> | undefined>();
  const { closeModal: closeRemoveInvoiceDialog, modalIsVisible: removeInvoiceDialog, showModal: showRemoveInvoiceDialog } = useModal();
  const { closeModal: closeItemsDialog, modalIsVisible: itemsDialogVisible, showModal: showItemsDialog } = useModal();
  const { closeModal: closeMarkAsPaidDialog, modalIsVisible: markAsPaidDialogIsVisible, showModal: showMarkAsPaidDialog } = useModal();
  const [vatPercentages, setVatPercentages] = useState<VATPercentage[]>();
  const [financialCategories, setFinancialCategories] = useState<Category[]>();
  const {
    showModal: showResendToBookkeepingModal,
    closeModal: closeResendToBookkeepingModal,
    modalIsVisible: resendToBookkeepingModalIsVisible,
  } = useModal();
  const [resendToBookkeepingDialogError, setResendToBookkeepingDialogError] = useState<ApiErrorParser<void> | undefined>();

  const { t } = useTranslation();

  const pageTitle = useMemo(() => {
    const title = t('invoice', 'Invoice');
    if (!id) {
      return title + ` (${t('New', 'New')})`;
    }
    if (!invoice) {
      return title;
    }
    if (invoice.draft === true) {
      return title + ` (${t('Draft', 'Draft')})`;
    } else {
      if (!invoice.invoice_no) {
        return title;
      }
      return title + ` (${invoice.invoice_no})`;
    }
  }, [invoice, t, id]);

  const pageActions = useMemo((): PageAction[] => {
    if (!invoice || invoice.finalized_on) {
      return [];
    }

    return [
      {
        text: t('remove-invoice', 'Remove invoice'),
        icon: <TrashSimple />,
        buttonVariant: ButtonVariant.Danger,
        onClick: showRemoveInvoiceDialog,
      },
      {
        text: t('finalize-invoice', 'Finalize invoice'),
        icon: <PaperPlaneTilt />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => setFinalizeInvoiceModalOpen(true),
      },
    ];
  }, [t, invoice, showRemoveInvoiceDialog]);

  const customer = useMemo(() => {
    if (!invoice || !contacts) return undefined;
    return contacts.find(contact => invoice.customer_uid === contact.uid);
  }, [contacts, invoice]);

  const removeInvoice = async (): Promise<void> => {
    const promise = InvoicesService.invoicesDestroy({
      organisationUid: selectedOrganization?.uid ?? '',
      uid: id ?? '',
    });
    try {
      await promise;
      closeRemoveInvoiceDialog();
      navigate(AppRoutes.InvoicesList.path);
    } catch (e) {
      setRemoveInvoiceDialogError(new ApiErrorParser(e));
    }
  };

  // Load the invoice from api
  const loadInvoice = useCallback((): CancelablePromise<InvoiceDetail> => {
    const promise = InvoicesService.invoicesRetrieve({
      organisationUid: selectedOrganization?.uid ?? '',
      uid: id ?? '',
    });
    promise
      .then(result => {
        setApiError(undefined);
        setInvoice(result);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<InvoiceDetail>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError, id]);

  // Load all the percentages from the api
  const loadVatPercentages = useCallback((): CancelablePromise<VATPercentage[]> => {
    const promise = VatpercentagesService.vatpercentagesList({
      organisationUid: selectedOrganization?.uid ?? '',
    });
    promise
      .then(res => setVatPercentages(res))
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<VATPercentage[]>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError]);

  // Load the contacts from api and/or cache
  const loadContacts = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedContactList> => {
      const promise = ContactsService.contactsList({
        organisationUid: selectedOrganization?.uid ?? '',
      });
      promise.catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PaginatedContactList>(e), false);
        }
      });
      cachedPaginatedApiData<Contact>(getCacheId('contacts'), promise, setContacts, disableCache);
      return promise;
    },
    [selectedOrganization, getCacheId, setApiError],
  );

  // Load the financial categories to use for the invoice items.
  const loadFinancialCategories = useCallback((): CancelablePromise<Category[]> => {
    const promise = CategoriesService.categoriesList({ organisationUid: selectedOrganization?.uid ?? '', o: 'p_s_type,name' });

    promise
      .then(res => setFinancialCategories(res))
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<Category[]>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError]);

  // Load the horses from cache and/or api
  const loadHorses = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedHorseList> => {
      const promise: CancelablePromise<PaginatedHorseList> = HorsesService.horsesList({
        organisationUid: selectedOrganization?.uid ?? '',
        onUnknownLocation: false,
      });
      promise
        .then(() => setApiError(undefined))
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<PaginatedHorseList>(e), horses === undefined);
          }
        });
      cachedPaginatedApiData<Horse>(getCacheId('horses'), promise, setHorses, disableCache);
      return promise;
    },
    [selectedOrganization, horses, getCacheId, setApiError],
  );

  // Remove the link with the bookkeeping software and resend the invoice to the bookkeeping software.
  const resendToBookkeepingSoftware = useCallback(async () => {
    if (!selectedOrganization || !invoice) {
      return;
    }
    try {
      await InvoicesService.invoicesRemoveLinkWithAccountingSoftwareCreate({
        organisationUid: selectedOrganization.uid,
        uid: invoice.uid,
      });
      await InvoicesService.invoicesSendToAccountingSoftwareCreate({
        organisationUid: selectedOrganization.uid,
        uid: invoice.uid,
      });
      closeResendToBookkeepingModal();
      loadInvoice();
    } catch (e) {
      setResendToBookkeepingDialogError(new ApiErrorParser(e));
    }
  }, [invoice, selectedOrganization, loadInvoice, closeResendToBookkeepingModal]);

  // Load the horses
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadHorses(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the financial categories.
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadFinancialCategories();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the invoice (when it's not a new one)
  useEffect(() => {
    if (selectedOrganization && id !== undefined) {
      const promise = loadInvoice();
      return () => promise.cancel();
    }
  }, [selectedOrganization, id]); //eslint-disable-line

  // Load the contacts
  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadContacts(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the vat percentages
  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadVatPercentages();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const invoiceSender = useMemo((): InvoiceSender | undefined => {
    if (!selectedOrganizationPrimaryStableLocation) {
      return undefined;
    }
    return {
      name: contactName(selectedOrganizationPrimaryStableLocation) ?? '',
      ...selectedOrganizationPrimaryStableLocation,
    };
  }, [selectedOrganizationPrimaryStableLocation]);

  return (
    <Page title={pageTitle} actions={pageActions} breadCrumbs={breadCrumbs}>
      {selectedOrganizationPrimaryStableLocation &&
        !selectedOrganizationPrimaryStableLocation.account_number &&
        !selectedOrganizationPrimaryStableLocation.IBAN && (
          <div className='pb-6 px-2 md:px-0'>
            <Alert
              severity={Severity.Info}
              message={t(
                'invoice-info-no-account-number',
                'No bank account details have been set for your organization. Please add them via the settings.',
              )}
            />
          </div>
        )}
      <div className='flex flex-col xl:flex-row-reverse xl:justify-end gap-4 md:gap-6 xl:gap-8 mb-4 mx-2 md:mx-0'>
        {invoice && (
          <div className='grow md:pl-6'>
            <InvoiceMeta
              horses={horses}
              invoice={invoice}
              customer={customer}
              requestMarkAsPaid={showMarkAsPaidDialog}
              requestResendToBookkeeping={showResendToBookkeepingModal}
            />
          </div>
        )}
        <div className='xl:w-[21cm] xl:min-h-[29.7cm] p-2 md:p-4 xl:p-12 bg-white shadow-letter flex flex-col gap-4 text-xs md:text-base'>
          <InvoiceSenderLogoBlock logoUrl={selectedOrganizationDetails?.logo ?? undefined} />
          <InvoiceSenderBlock invoice={invoice} organization={invoiceSender} />
          <div className='pl-8 pb-8 flex'>
            <EditableInvoiceItem
              loading={false}
              onEdit={() => {
                setEditModalOpen(true);
              }}
              required={customer === undefined}
              step={{ step: 1, text: t('select-a-contact', 'Select a contact') }}
            >
              <InvoiceReceiverBlock receiver={customer} />
            </EditableInvoiceItem>
          </div>
          <EditableInvoiceItem
            loading={false}
            onEdit={() => {
              setEditModalOpen(true);
            }}
          >
            <InvoiceInfoBlock invoice={invoice} receiver={customer} />
          </EditableInvoiceItem>
          <EditableInvoiceItem
            loading={false}
            required={(invoice?.items ?? []).length === 0}
            step={{ step: 2, text: t('invoice-add-lines', 'Add invoice lines') }}
            onEdit={showItemsDialog}
            enabled={invoice !== undefined}
          >
            <InvoiceItemsBlock
              fallbackLanguage={selectedOrganizationPrimaryStableLocation?.country}
              fallbackCurrency={selectedOrganizationDetails?.currency}
              invoice={invoice}
            />
          </EditableInvoiceItem>
          <div className='grow flex items-end'>
            <InvoiceFooterBlock invoice={invoice} organization={selectedOrganizationDetails} />
          </div>
        </div>
      </div>
      {((id && invoice) || !id) && (
        <EditInvoice
          contacts={contacts}
          contact={customer}
          invoice={invoice}
          open={editModalOpen}
          onClose={(reload: boolean, invoice?: InvoiceDetail) => {
            if (!id && invoice) {
              navigate(generatePath(AppRoutes.Invoice.path, { invoiceId: invoice.uid }));
              setId(invoice.uid);
            } else if (reload) {
              loadInvoice();
            }
            setEditModalOpen(false);
          }}
        />
      )}
      {customer && invoice && (
        <FinalizeInvoice
          contact={customer}
          invoice={invoice}
          open={finalizeInvoiceModalOpen}
          onRequestClose={(reload: boolean) => {
            if (reload) {
              loadInvoice();
            }
            setFinalizeInvoiceModalOpen(false);
          }}
        />
      )}
      <ActionModal
        open={removeInvoiceDialog}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeRemoveInvoiceDialog,
          },
          {
            text: t('remove', 'Remove'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: removeInvoice,
          },
        ]}
        title={t('remove-invoice-confirm-title', 'Remove invoice')}
      >
        <ErrorSection errors={removeInvoiceDialogError} />
        <p>{t('remove-invoice-confirm-text', 'Are you sure you want to remove this invoice?')}</p>
      </ActionModal>
      <EditInvoiceItems
        invoice={invoice}
        financialCategories={financialCategories}
        vatPercentages={vatPercentages}
        open={itemsDialogVisible}
        onRequestClose={(reload: boolean) => {
          if (reload) {
            loadInvoice();
          }
          closeItemsDialog();
        }}
      />
      {invoice && (
        <MarkAsPaidModal
          open={markAsPaidDialogIsVisible}
          invoice={invoice}
          requestClose={(reload: boolean) => {
            if (reload) {
              loadInvoice();
            }
            closeMarkAsPaidDialog();
          }}
        />
      )}
      <ActionModal
        open={resendToBookkeepingModalIsVisible}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeResendToBookkeepingModal,
          },
          {
            text: t('resend', 'Resend'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: resendToBookkeepingSoftware,
          },
        ]}
        title={t('resend-to-bookkeeping', 'Resend to bookkeeping')}
      >
        <>
          <ErrorSection errors={resendToBookkeepingDialogError} />
          <p>
            {t(
              'resend-to-bookkeeping-confirm-text',
              'Are you sure you want to resend this invoice to your bookkeeping software? This removes the already existing link to this invoice and might result in a duplicate entry.',
            )}
          </p>
        </>
      </ActionModal>
    </Page>
  );
}
