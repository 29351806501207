import { CalendarCluster, equalGroupByApplied, GroupByApplied } from 'context/Calendar';
import { usePlanning } from 'hooks/UsePlanning';
import { AvailableWeekdaysEnum, Facility } from 'openapi';
import { useCallback, useMemo } from 'react';
import { parseTime } from 'utilities/date.utilities';
import { DayParts, GroupBy, TimeScale } from 'utilities/Planning';
import { ActivityHeight, ActivityHeightSpacious } from '../DayGrid';

interface DaySections {
  heights: number[];
  visibleRange?: { start: number; end: number };
  plannableRange?: { start?: number; end?: number; weekdays: Array<AvailableWeekdaysEnum> };
}

interface ReturnType {
  calendarRowDaySections: (row: CalendarCluster) => DaySections;
  groupedByTimeScale: (groupByTarget?: GroupByApplied) => TimeScale;
}

interface Props {
  spacious: boolean;
}

/** Calculate the height of each day part. This differs for each view type.
 * For time scale view we divide into one hour blocks.
 * For day parts view we divide into day parts.
 * For full day view we only have one.
 * For dayparts/fullday, each height is adapted to the amount of activities (and leave one extra block for the blueprint).
 */
const useCalendarRow = ({ spacious }: Props): ReturnType => {
  const { timeScale, dayParts, selectedGroupBy, workingHours, hideNonWorkingHours } = usePlanning();

  // Height of one activity based on the spacious flag.
  const activityHeight = useMemo(() => {
    return spacious ? ActivityHeightSpacious : ActivityHeight;
  }, [spacious]);

  const calendarRowDaySections = useCallback(
    (row: CalendarCluster): DaySections => {
      if (equalGroupByApplied(selectedGroupBy, row.groupBy) || timeScale === TimeScale.TimeScale) {
        let plannableRange: { start?: number; end?: number; weekdays: Array<AvailableWeekdaysEnum> } | undefined;
        if (row.groupBy.groupBy === GroupBy.Facility) {
          const facility = row.groupBy.subject as Facility;

          // if(facility.available_weekdays)
          if (facility.available_from || facility.available_to || facility.available_weekdays.length) {
            plannableRange = { weekdays: facility.available_weekdays };
            if (facility.available_from) {
              const time = parseTime(facility.available_from);
              plannableRange.start = time.hours + (time.minutes > 0 ? time.minutes / 60 : 0);
            }
            if (facility.available_to) {
              const time = parseTime(facility.available_to);
              plannableRange.end = time.hours + (time.minutes > 0 ? time.minutes / 60 : 0);
            }
          }
        }

        let visibleRange: { start: number; end: number } | undefined;
        if (workingHours && hideNonWorkingHours) {
          visibleRange = { start: workingHours.from.hours, end: workingHours.to.hours };
        }
        return {
          heights: Array(DayParts.Hour).fill(ActivityHeight),
          visibleRange,
          plannableRange,
        };
      }
      if (timeScale === TimeScale.DayParts) {
        const heights: number[] = Array(dayParts?.length).fill(activityHeight);
        heights.splice(0, row.maxActivitiesInADayPart.length, ...row.maxActivitiesInADayPart.map(count => (count + 1) * activityHeight));
        return { heights };
      }
      if (timeScale === TimeScale.FullDay) {
        return { heights: [row.maxActivitiesInADay * activityHeight + activityHeight] };
      }
      console.error('Cannot calculate row heights for unknown TimeScale');
      return { heights: [] };
    },
    [dayParts, selectedGroupBy, timeScale, activityHeight, workingHours, hideNonWorkingHours],
  );

  // Get the TimeScale based on the GroupByApplied element.
  const groupedByTimeScale = useCallback(
    (groupByTarget?: GroupByApplied): TimeScale => {
      if (groupByTarget && equalGroupByApplied(selectedGroupBy, groupByTarget)) {
        return TimeScale.TimeScale;
      } else {
        return timeScale;
      }
    },
    [timeScale, selectedGroupBy],
  );

  return { calendarRowDaySections, groupedByTimeScale };
};

export default useCalendarRow;
