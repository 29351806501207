import useApiPromises from 'api/hooks/useApiPromises';
import { Barn, CaretLeft, CaretRight, Horse as HorseIcon, PlusCircle, WarningCircle } from '@phosphor-icons/react';
import { FilterOption, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import { useOrganization } from 'context/OrganizationContext';
import {
  ActivitiesService,
  ActivityType,
  ActivityTypeCategoryEnum,
  Horse,
  RealActivities,
  VaccinationRule,
  VaccinationrulesService,
} from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultApiPageSize, table, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import { Tile } from 'ui/Layout/Tile';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { ApiPromises } from 'utilities/ApiPromises';
import ListFilterButton from '../../components/Common/ListFilter/ListFilterButton';
import useListFilter from '../../components/Common/ListFilter/useListFilter';
import Page from '../../ui/Layout/Page';
import { age, gender, listFilter, textFilter } from 'utilities/Horse';
import classNames from 'classnames';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import { HorseUsageBadges } from 'components/Horses/HorseUsageBadges';
import { BadgeSize } from 'ui/Badge/Badge';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import CareActivityModal from 'components/Activities/CareActivityModal';
import { useAccount } from 'context/AccountContext';
import { PlanningProvider } from 'context/PlanningContext';
import { usePlanning } from 'hooks/UsePlanning';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { activityIntervalOverdue, BluePrintState, realActivityToDate } from 'utilities/Planning';
import SaveActivityForm from 'components/Activities/SaveActivityForm';
import { ButtonVariant } from 'ui/Button';
import { isSameDay, isToday, startOfToday } from 'date-fns';
import { listFilterStable } from 'utilities/Contact';
import { stableListFilterTypes } from 'utilities/Stable';
import GestureWrapper from 'ui/GestureWrapper';

interface SelectedItem {
  horse?: Horse;
  activityType?: ActivityType;
}

function CarePlanningContent(): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationUid } = useOrganization();
  const [searchText, setSearchText] = useState<string>('');
  const {
    horses,
    contacts,
    unsortedActivityTypes: activityTypes,
    groups,
    loadApiData: loadPlanningApiData,
    bluePrint,
    unsetBluePrint,
    stables,
  } = usePlanning();
  const [itemsVisible, setItemsVisible] = useState<number>(0);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [vaccinationRules, setVaccinationRules] = useState<VaccinationRule[]>();
  const [activities, setActivities] = useState<RealActivities[]>([]);
  const [visibleCount, setVisibleCount] = useState<number>(3);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState<'left' | 'right' | undefined>();
  const timeOutRef = useRef<NodeJS.Timeout>(); // Timer to unset the animation
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { formatDate, accountDetails } = useAccount();

  const { width } = useScreenSize();

  const [selectedItem, setSelectedItem] = useState<SelectedItem | undefined>();

  // When we have set a filter then we would like to load all available horses.
  // At first, we only load non-inactive horse with a stable (aka active horses).
  // Out of performance reasons we don't load all horses at first because all
  // horses from the webshop and all inactive horses are also in that list.
  const [loadAllHorses, setLoadAllHorses] = useState<boolean>(false);

  // Indicate if we're loading data from the api
  const { loading: loadingApiPromises } = useApiPromises({ apiPromises });

  const stableFilterTypes = useMemo((): ListFilterType[] | undefined => {
    if ((stables?.length ?? 0) <= 1) {
      // No need to show the stable filter when we only have one stable
      return undefined;
    }
    return [stableListFilterTypes(t, stables ?? [], accountDetails?.preferred_stable ?? undefined)];
  }, [t, stables, accountDetails]);

  // A list of types we can filter by. Like Gender and DateOfBirth.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!horses) {
      return undefined;
    }
    let genderUnknownCount = 0;
    let genderStallionCount = 0;
    let genderMareCount = 0;
    let genderGeldingCount = 0;
    let inactiveCount = 0;
    let noStableCount = 0;

    // Counting the filters only makes sense if we have loaded all horses.
    const showCounts = loadAllHorses && !loadingApiPromises;

    const birthYears = new Map<number, number>();
    const ageCount = new Map<number, number>();
    let hasNotSetDate = 0; // True if we have a date that is undefined in the list.
    horses.forEach(h => {
      if (h.date_of_birth) {
        const date = new Date(Date.parse(h.date_of_birth));
        birthYears.set(date.getFullYear(), birthYears.get(date.getFullYear()) ?? 1);
        const horseAge = age(h);
        if (horseAge !== undefined && horseAge !== null) {
          // We already known the horseAge is not undefined (but TS wants this if-condition)
          ageCount.set(horseAge, ageCount.get(horseAge) ?? 1);
        }
      } else {
        hasNotSetDate++;
      }

      if (h.sex === 1) {
        genderStallionCount++;
      } else if (h.sex === 2) {
        genderMareCount++;
      } else if (h.sex === 3) {
        genderGeldingCount++;
      } else {
        genderUnknownCount++;
      }

      if (h.hidden) {
        inactiveCount++;
      }
      if (!h.stable_uid) {
        noStableCount++;
      }
    });

    const ageFilterOptions: FilterOption[] = [];
    if (hasNotSetDate > 0) {
      ageFilterOptions.push({ id: 'unknown', name: t('unknown', 'Unknown'), count: showCounts ? hasNotSetDate : undefined });
    }

    const sortedAges = Array.from(ageCount);
    sortedAges.sort();
    sortedAges.forEach(value => {
      ageFilterOptions.push({ id: `${value[0]}`, name: `${value[0]}`, count: showCounts ? value[1] : undefined });
    });

    const ageFilter: ListFilterType = {
      id: 'age',
      name: t('age', 'Age'),
      options: ageFilterOptions,
    };

    const genderFilter: ListFilterType = {
      id: 'gender',
      name: t('gender', 'Gender'),
      options: [
        { id: 'unknown', name: t('gender-unknown', 'Unknown'), count: showCounts ? genderUnknownCount : undefined },
        { id: 'stallion', name: gender(SexEnum._1, t), count: showCounts ? genderStallionCount : undefined },
        { id: 'mare', name: gender(SexEnum._2, t), count: showCounts ? genderMareCount : undefined },
        { id: 'gelding', name: gender(SexEnum._3, t), count: showCounts ? genderGeldingCount : undefined },
      ],
    };

    const passiveFilter: ListFilterType = {
      id: 'passive',
      name: t('passive', 'Passive'),
      options: [
        { id: 'inactive', name: t('inactive', 'Inactive'), count: showCounts ? inactiveCount : undefined },
        { id: 'no-stable', name: t('no-stable', 'No stable'), count: showCounts ? noStableCount : undefined },
      ],
    };

    const myLocationFilter: ListFilterType = {
      id: 'location',
      name: t('pastures-and-other-locations', 'Pastures and other locations'),
      options: (contacts ?? [])
        .filter(contact => contact.external_location)
        .map(contact => {
          return { id: contact.uid, name: contact.business_name ?? '-' };
        }),
    };

    const groupFilter: ListFilterType = {
      id: 'group',
      name: t('group', 'Group'),
      options: (groups ?? []).map(group => {
        return { id: group.uid, name: group.name ?? t('unnamed-group', 'Unnamed group') };
      }),
    };

    const categoryFilter: ListFilterType = {
      id: 'category',
      name: t('usage-category', 'Category'),
      options: [
        { id: ActivityTypeCategoryEnum.CARE, name: t('activity-type-category-care', 'Care') },
        { id: ActivityTypeCategoryEnum.SPORT, name: t('activity-type-category-sport', 'Sport') },
        { id: ActivityTypeCategoryEnum.BREEDING, name: t('activity-type-category-breeding', 'Breeding') },
      ],
    };

    const filters = [categoryFilter, myLocationFilter, groupFilter, genderFilter, ageFilter, passiveFilter];

    return filters;
  }, [horses, loadAllHorses, loadingApiPromises, t, contacts, groups]);

  const { filters } = useListFilter(filterTypes ?? []);
  const { filters: stableFilters } = useListFilter(stableFilterTypes ?? []);

  /**
   * The activity types we want to show as columns of the table
   */
  const careActivityTypes = useMemo((): ActivityType[] | undefined => {
    return activityTypes?.filter(activityType => activityType.category === ActivityTypeCategoryEnum.CARE && !activityType.hidden);
  }, [activityTypes]);

  // List of horses after filter
  const filteredHorses = useMemo((): Horse[] => {
    return textFilter(listFilter(horses ?? [], filters.concat(stableFilters)), searchText);
  }, [horses, filters, searchText, stableFilters]);

  // We don't show the full list directly. Make use of the fetch more strategy of PullToRefresh.
  const visibleHorses = useMemo((): { horses: Horse[]; canFetchMore: boolean } => {
    if (!horses) {
      return { horses: [], canFetchMore: false };
    }
    return { horses: filteredHorses.slice(0, itemsVisible), canFetchMore: itemsVisible < filteredHorses.length };
  }, [horses, itemsVisible, filteredHorses]);

  const loadMore = useCallback(async (): Promise<void> => {
    if (!careActivityTypes) {
      throw Error('Activity types are not loaded yet');
    }
    const horseUidsToFetch = filteredHorses.slice(itemsVisible, itemsVisible + defaultApiPageSize).map(horse => horse.uid);
    const realActivities = await ActivitiesService.activitiesList({
      organisationUid: selectedOrganizationUid ?? '',
      lastDoneAndLastAndFirstFuturePerHorseAndActivityType: true,
      // @FIXME: Our api expects comma separated arrays. But the api code gen does ?param=a&param=b . Therefor we need to add one array item with the values comma separated.
      activityTypeUidIn: [careActivityTypes?.map(activityType => activityType.uid).join(',')],
      horseUidIn: [horseUidsToFetch.join(',')],
    });

    setActivities([...activities, ...realActivities.results]);

    setItemsVisible(itemsVisible + defaultApiPageSize);
  }, [itemsVisible, selectedOrganizationUid, careActivityTypes, filteredHorses, activities]);

  const reload = useCallback(async (): Promise<void> => {
    if (!careActivityTypes) {
      throw Error('Activity types are not loaded yet');
    }
    const horseUidsToFetch = filteredHorses.map(horse => horse.uid);

    const filterStable = listFilterStable(stables ?? [], stableFilters);

    const realActivities = await ActivitiesService.activitiesList({
      organisationUid: selectedOrganizationUid ?? '',
      lastDoneAndLastAndFirstFuturePerHorseAndActivityType: true,
      // @FIXME: Our api expects comma separated arrays. But the api code gen does ?param=a&param=b . Therefor we need to add one array item with the values comma separated.
      activityTypeUidIn: [careActivityTypes?.map(activityType => activityType.uid).join(',')],
      horseUidIn: [horseUidsToFetch.join(',')],
      horseStableUidIn: filterStable ? [filterStable?.map(stable => stable.uid).join(',')] : undefined,
    });

    setActivities(realActivities.results);
  }, [selectedOrganizationUid, careActivityTypes, filteredHorses, stableFilters, stables]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = loadPlanningApiData(true);
    setItemsVisible(0);

    promises.appendList<VaccinationRule>(
      'vaccination-rules',
      () =>
        VaccinationrulesService.vaccinationrulesList({
          organisationUid: selectedOrganizationUid ?? '',
        }),
      setVaccinationRules,
    );

    setApiPromises(promises);
    return promises;
  }, [loadPlanningApiData, selectedOrganizationUid]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, loadAllHorses]); //eslint-disable-line

  // When filters change the make sure we load all our horses.
  useEffect(() => {
    if (filters.length > 0 || searchText) {
      setLoadAllHorses(true);
    }
  }, [filters, searchText]); //eslint-disable-line

  /**
   * Set the items to show based on the screen size
   */
  useEffect(() => {
    if (width > ScreenSize['3xl']) {
      setVisibleCount(6);
    } else if (width > ScreenSize['2xl']) {
      setVisibleCount(5);
    } else if (width > ScreenSize.xl) {
      setVisibleCount(4);
    } else if (width > ScreenSize.lg) {
      setVisibleCount(2);
    } else if (width > ScreenSize.md) {
      setVisibleCount(1);
    } else {
      setVisibleCount(1);
    }
  }, [width]);

  const horseColWidth = useMemo(() => {
    if (width <= ScreenSize.xs) {
      return '140px';
    }
    if (width <= ScreenSize.sm) {
      return '225px';
    }
    return '350px';
  }, [width]);

  const gridCols = useMemo(() => {
    switch (visibleCount) {
      case 1:
      default:
        return 'grid-cols-1';
      case 2:
        return 'grid-cols-2';
      case 4:
        return 'grid-cols-4';
      case 5:
        return 'grid-cols-5';
      case 6:
        return 'grid-cols-6';
    }
  }, [visibleCount]);

  // Unset the slide animation when the animation is done.
  // Doesn't give us trouble when resizing the window for instance.
  useEffect(() => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
    timeOutRef.current = setTimeout(() => {
      setSlideDirection(undefined);
    }, 400);
    return () => clearTimeout(timeOutRef.current);
  }, [slideDirection]);

  return (
    <>
      <Page title={t('care', 'Care')} loading={apiPromises} showEmptyListPlaceholder={horses?.length === 0}>
        <PullScrollWrapper
          onRefresh={() => loadApiData().watchAll()}
          canFetchMore={visibleHorses.canFetchMore}
          onFetchMore={() => loadMore()}
        >
          <Tile noBoxOnMobile={true} overflowContent={true}>
            <FilterWrapper listFilterTypes={filterTypes}>
              {(stables?.length ?? 0) > 1 && (
                <ListFilterButton
                  currentCountDisplay={() => `* ${t('your-preferred-stable', 'Your preferred stable')}`}
                  icon={<Barn />}
                  // filterAsButtonText={true}
                  listFilterTypes={stableFilterTypes ?? []}
                />
              )}
              <ListFilterButton
                listFilterTypes={filterTypes ?? []}
                currentCountDisplay={() => t('current-horse-count', 'currently showing {{count}} horses', { count: filteredHorses.length })}
              />
              <input
                type='search'
                onChange={e => setSearchText(e.currentTarget.value)}
                size={10}
                placeholder={t('search-by-name-ueln-chipnr', 'Search by Name, UELN or Chipnr...')}
                className='placeholder:italic placeholder:text-sm px-2 max-w-md grow h-10 rounded-md border'
              />
              {loadingApiPromises && loadAllHorses && <Spinner size={SpinnerSize.Small} />}
            </FilterWrapper>
            <GestureWrapper
              className={table}
              onSwipeRight={() => {
                if (currentIndex > 0) {
                  setCurrentIndex(prevState => prevState - 1);
                  setSlideDirection('right');
                }
              }}
              onSwipeLeft={() => {
                if (currentIndex + visibleCount < (careActivityTypes?.length ?? 0)) {
                  setCurrentIndex(prevState => prevState + 1);
                  setSlideDirection('left');
                }
              }}
            >
              <div className={tableThead}>
                <div className='flex'>
                  <div className='w-10' />
                  <div className={classNames(tableTheadTd, 'flex items-center relative')} style={{ width: horseColWidth }}>
                    {currentIndex > 0 && (
                      <button
                        className='absolute right-0 px-2 h-full'
                        onClick={() => {
                          setCurrentIndex(prevState => prevState - 1);
                          setSlideDirection('right');
                        }}
                      >
                        <CaretLeft size={24} />
                      </button>
                    )}
                  </div>
                  <div className={classNames('grow grid', gridCols)}>
                    {careActivityTypes?.map((activityType, index) => (
                      <>
                        <div
                          className={classNames('animate-duration-300 hidden relative items-center justify-center flex-col', tableTheadTd, {
                            '!flex': index >= currentIndex && index < currentIndex + visibleCount,
                            'animate-slideInRight': slideDirection === 'left',
                            'animate-slideInLeft': slideDirection === 'right',
                          })}
                          key={activityType.uid + currentIndex} // We add the current index to the key so it gets animated on any nav change.
                        >
                          <p className='text-xs font-normal -mb-1'>{t('last-done', 'Last done')}</p>
                          <p>{activityType.name}</p>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className={'min-w-8'}>
                    {currentIndex + visibleCount < (careActivityTypes?.length ?? 0) && (
                      <button
                        className='h-full px-1'
                        onClick={() => {
                          setCurrentIndex(prevState => prevState + 1);
                          setSlideDirection('left');
                        }}
                      >
                        <CaretRight size={24} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className={tableTbody}>
                {visibleHorses.horses.map(horse => {
                  const vaccinationRule = vaccinationRules?.find(rule => rule.uid === horse.vaccination_rules_uid);
                  return (
                    <div key={horse.uid} className={classNames(tableTbodyTrNoClick, 'flex items-center')}>
                      <div className='text-center w-10 hidden md:block'>
                        <HorseIcon size={22} weight='light' className='inline' />
                      </div>
                      <div>
                        <div>
                          <div
                            className='flex flex-col items-start truncate pl-2 md:pl-0'
                            style={{ width: horseColWidth, maxWidth: horseColWidth }}
                          >
                            <div className='flex items-center gap-1'>
                              {horse.name}
                              {!horse.hidden && <HorseUsageBadges size={BadgeSize.Small} iconOnly={true} horse={horse} />}
                              {horse.hidden && <Badge color={AllColors.Rose}>{t('inactive', 'Inactive')}</Badge>}
                            </div>
                            {vaccinationRule && (
                              <Badge color={AllColors.Purple} size={BadgeSize.Small}>
                                <span>{vaccinationRule?.name}</span>
                              </Badge>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classNames('grid grow', gridCols)}>
                        {careActivityTypes?.map((activityType, index) => {
                          const foundLastDone = activities.find(
                            activity =>
                              activity.activity_type_uid === activityType.uid &&
                              activity.horse_uid === horse.uid &&
                              activity.done_on &&
                              (realActivityToDate(activity) < startOfToday() || isSameDay(realActivityToDate(activity), startOfToday())),
                          );
                          let foundLastNotDone = activities.find(
                            activity =>
                              activity.activity_type_uid === activityType.uid &&
                              activity.horse_uid === horse.uid &&
                              !activity.done_on &&
                              (realActivityToDate(activity) < startOfToday() || isSameDay(realActivityToDate(activity), startOfToday())),
                          );
                          if (
                            foundLastNotDone &&
                            foundLastDone &&
                            realActivityToDate(foundLastNotDone) < realActivityToDate(foundLastDone)
                          ) {
                            foundLastNotDone = undefined;
                          }
                          const foundNextPlanned = activities.find(
                            activity =>
                              activity.activity_type_uid === activityType.uid &&
                              activity.horse_uid === horse.uid &&
                              !activity.done_on &&
                              (isSameDay(realActivityToDate(activity), new Date()) || realActivityToDate(activity) > new Date()),
                          );
                          const isOverdue = foundLastDone?.done_on
                            ? activityIntervalOverdue(activityType, horse, foundLastDone, new Date())
                            : false;
                          return (
                            <CareActivityModal
                              visible={
                                selectedItem?.horse?.uid === horse.uid && selectedItem.activityType?.uid === activityType.uid && !bluePrint
                              }
                              requestReload={reload}
                              key={activityType.uid + currentIndex} // We add the current index to the key so it gets animated on any nav change.
                              horse={horse}
                              activityType={activityType}
                              lastActivity={foundLastDone}
                              foundLastNotDone={foundLastNotDone}
                              nextPlannedActivity={foundNextPlanned}
                              vaccinationRule={vaccinationRule}
                              onRequestClose={() => setSelectedItem(undefined)}
                              className={classNames('animate-duration-300 hidden', {
                                '!block': index >= currentIndex && index < currentIndex + visibleCount,
                                'animate-slideInRight': slideDirection === 'left',
                                'animate-slideInLeft': slideDirection === 'right',
                              })}
                            >
                              <div
                                className={classNames(
                                  'relative items-center justify-center hover:border-2 hover:cursor-pointer rounded-lg w-full flex flex-col overflow-hidden',
                                  tableTheadTd,
                                  {
                                    'border-2 border-blue-500':
                                      selectedItem?.horse?.uid === horse.uid && selectedItem.activityType?.uid === activityType.uid,
                                  },
                                )}
                                onClick={() => setSelectedItem({ horse, activityType })}
                              >
                                <div className='flex flex-row items-center gap-1'>
                                  {foundLastDone && <p className='text-sm lg:text-base'>{formatDate(realActivityToDate(foundLastDone))}</p>}
                                  {!foundLastDone && !foundLastNotDone && !foundNextPlanned && (
                                    <PlusCircle weight='light' size={24} className='text-gray-200 block' />
                                  )}
                                  {foundLastNotDone && <WarningCircle size={20} className='text-red-600' />}
                                </div>

                                {foundNextPlanned && (
                                  <div className='text-xs px-0.5 mb-1 border rounded border-emerald-400 text-emerald-700'>
                                    {isToday(realActivityToDate(foundNextPlanned)) ? (
                                      <>{t('planned-for-today', 'Planned for today')}</>
                                    ) : (
                                      <>
                                        {t('next', 'Next')} {formatDate(realActivityToDate(foundNextPlanned))}
                                      </>
                                    )}
                                  </div>
                                )}
                                {isOverdue && !foundNextPlanned && (
                                  <div className='text-xs px-0.5 mb-1 border rounded border-rose-400 text-rose-700'>
                                    {t('overdue', 'Overdue')}
                                  </div>
                                )}
                              </div>
                            </CareActivityModal>
                          );
                        })}
                      </div>
                      <div className='min-w-1 md:min-w-8 h-full' />
                    </div>
                  );
                })}
              </div>
            </GestureWrapper>
          </Tile>
        </PullScrollWrapper>
        <PageModal width={PageModalWidth.Sm} open={bluePrint?.state === BluePrintState.EditFull}>
          <PageModalTitle
            title={t('plan-care-activity', 'Plan care activity')}
            onClose={() => {
              if (bluePrint) {
                unsetBluePrint();
              }
            }}
          />
          <PageModalContent>
            <SaveActivityForm compact={false} submitting={setIsSubmitting} onSaved={reload} />
          </PageModalContent>
          <PageModalActions
            actions={[
              {
                loading: isSubmitting,
                variant: ButtonVariant.Primary,
                text: t('save', 'Save'),
                type: 'submit',
                formId: 'SaveActivityForm',
              },
            ]}
          />
        </PageModal>
      </Page>
    </>
  );
}

/**
 * Planning page for horses
 */
export default function CarePlanning(): JSX.Element {
  return (
    <PlanningProvider>
      <CarePlanningContent />
    </PlanningProvider>
  );
}
