import React, { useEffect, useMemo, useState } from 'react';
import { PageAction } from 'context/PageContext';
import { useTranslation } from 'react-i18next';
import { Plus } from '@phosphor-icons/react';
import { ButtonVariant } from 'ui/Button';
import {
  BluePrintState,
  CalendarActivity,
  CalendarActivityType,
  CalendarView,
  DragDropType,
  GroupBy,
  listFilterActivityTypes,
  SelectedActivityState,
  TimeScale,
  ViewType,
} from 'utilities/Planning';
import { PlanningProvider } from 'context/PlanningContext';
import { usePlanning } from 'hooks/UsePlanning';
import Calendar, { GroupByApplied } from 'context/Calendar';
import SaveActivityModal from 'components/Activities/SaveActivityModal';
import { MultiDayBody, MultiDayHeaderDailyNotes } from 'components/Activities/MultiDayView';
import { Page } from 'ui/Layout';
import { useOrganization } from 'context/OrganizationContext';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import { Tile } from 'ui/Layout/Tile';
import { ActivityTypeCategoryEnum, Contact, StableNames } from 'openapi';
import { useAccount } from 'context/AccountContext';
import { PlanningToolbar } from 'components/Activities/PlanningToolbar';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { PageMaxWidth } from 'ui/Layout/Page';
import classNames from 'classnames';
import { addDays, areIntervalsOverlapping, endOfDay } from 'date-fns';
import ProgressBar from 'ui/ProgressBar';
import PlanningErrorModal from 'components/Activities/PlanningErrorModal';
import usePermissions from 'hooks/UsePermissions';
import { ListFilterType } from 'components/Common/ListFilter';
import useListFilter from 'components/Common/ListFilter/useListFilter';

interface StableCalendarView {
  stable: StableNames;
  calendarView: CalendarView;
}

interface Progress {
  uid: string;
  name: string;
  progressText: string;
  progressFraction: number;
  total: number;
  done: number;
}

/**
 * We need this separate component to be able to use the PlanningDataProvider.
 */
function MyPlanningContent(): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationUid, selectedOrganization } = useOrganization();
  const { accountDetails, formatDate } = useAccount();

  const [printFilter, setPrintFilter] = useState<StableNames | undefined>();

  const {
    viewType,
    loadApiData,
    loadActivityApiData,
    offset,
    previousOffset,
    horses,
    activities,
    apiPromises,
    timeScale,
    selectedActivity,
    activityTypes,
    setSelectedActivity,
    requestBluePrint,
    getDayPartForTime,
    unsetBluePrint,
  } = usePlanning();
  const { now } = useRefreshingNow();
  const { permissions } = usePermissions();

  // A list of types we can filter by.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    const activityTypeFilter: ListFilterType = {
      id: 'activity-type',
      name: t('activityType', 'Activity type'),
      options: (activityTypes ?? []).map(type => {
        return { id: type.uid, name: type.name ?? t('unnamed-type', 'Unnamed type') };
      }),
    };

    const categoryFilter: ListFilterType = {
      id: 'category',
      name: t('horse-usage-category', 'Horse category'),
      options: [
        { id: ActivityTypeCategoryEnum.CARE, name: t('activity-type-category-care', 'Care') },
        { id: ActivityTypeCategoryEnum.SPORT, name: t('activity-type-category-sport', 'Sport') },
        { id: ActivityTypeCategoryEnum.BREEDING, name: t('activity-type-category-breeding', 'Breeding') },
      ],
    };

    return [categoryFilter, activityTypeFilter];
  }, [t, activityTypes]);

  const { filters } = useListFilter(filterTypes ?? []);

  const filteredActivities = useMemo((): CalendarActivity[] | undefined => {
    return listFilterActivityTypes(activities ?? [], filters);
  }, [activities, filters]);

  // Build the GroupByApplied for my personal planning.
  const myAppliedGroupBy = useMemo((): GroupByApplied | undefined => {
    if (!selectedOrganization?.me) {
      return undefined;
    }
    return {
      groupBy: GroupBy.Staff,
      subject: {
        uid: selectedOrganization?.me.uid ?? '',
        first_name: accountDetails?.first_name,
        last_name: accountDetails?.last_name,
      } as Contact,
    };
  }, [selectedOrganization, accountDetails]);

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add-activity', 'Add activity'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => {
          if (selectedActivity) {
            setSelectedActivity(selectedActivity?.activity, SelectedActivityState.Selected);
          }
          requestBluePrint({
            state: BluePrintState.EditFull,
            type: CalendarActivityType.Activity,
            day: addDays(now, offset),
            appliedGroupBy: myAppliedGroupBy,
            dayPart: getDayPartForTime(now.getHours(), now.getMinutes()),
          });
        },
      },
    ];
  }, [t, requestBluePrint, setSelectedActivity, selectedActivity, myAppliedGroupBy, now, offset, getDayPartForTime]);

  // Calculate the column header width based on arbitrary values.
  const colHeaderWidth = useMemo(() => {
    return timeScale === TimeScale.FullDay ? 0 : 52;
  }, [timeScale]);

  // Initially load all from the api
  useEffect(() => {
    // Next to the org uid we also require the permissions to be set in order to correctly load the api data.
    if (selectedOrganizationUid && permissions) {
      const promise = loadApiData(true);
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, viewType, permissions]); //eslint-disable-line

  // Load activities from the api
  useEffect(() => {
    // Check if we're editing/finalizing an activity. If so, don't refresh. It might mess up the form state.
    const isEditing =
      selectedActivity?.selectedActivityState === SelectedActivityState.Edit ||
      selectedActivity?.selectedActivityState === SelectedActivityState.Finalize;

    if (selectedOrganizationUid && permissions && !isEditing) {
      const promise = loadActivityApiData();
      return () => promise.cancel();
    }
  }, [offset, now, permissions]); //eslint-disable-line

  const stableCalenderViews = useMemo(() => {
    if (!filteredActivities || !activities || !horses) {
      return undefined;
    }
    const myStables = selectedOrganization?.me.stables;
    const result: StableCalendarView[] = [];
    for (const stable of myStables ?? []) {
      const cluster: GroupByApplied = { groupBy: GroupBy.Stable, subject: stable };
      const calendar = new Calendar(now, ViewType.Day, offset, [cluster], filteredActivities);

      // Create this calendar to only for checking if the stable has activities.
      const allActivitiesCalendar = new Calendar(now, ViewType.Day, offset, [cluster], activities);

      // Only show stables that have activities for the date.
      if (allActivitiesCalendar.hasActivities) {
        result.push({
          stable: stable,
          calendarView: {
            offset: offset,
            previousOffset: previousOffset,
            current: calendar,
            left: new Calendar(now, ViewType.Day, offset - 1, [cluster], []),
            right: new Calendar(now, ViewType.Day, offset + 1, [cluster], []),
          },
        });
      }
    }
    return result;
  }, [activities, filteredActivities, horses, now, offset, previousOffset, selectedOrganization]);

  // The calendar for the activities of the logged in user.
  const calendarView = useMemo((): CalendarView | undefined => {
    if (!filteredActivities || !myAppliedGroupBy) {
      return undefined;
    }
    const clusters: GroupByApplied[] = [myAppliedGroupBy];

    return {
      offset: offset,
      previousOffset: previousOffset,
      current: new Calendar(now, ViewType.Day, offset, clusters, filteredActivities),
      left: new Calendar(now, ViewType.Day, offset - 1, clusters, []),
      right: new Calendar(now, ViewType.Day, offset + 1, clusters, []),
    };
  }, [filteredActivities, now, offset, previousOffset, myAppliedGroupBy]);

  const progress = useMemo((): Progress[] => {
    const result: Progress[] = [];
    if (!calendarView) {
      return result;
    }
    const myActivities = calendarView?.current.clusters[0].columns[0].activities;
    const myTotal = myActivities.length;
    const myDone = myActivities.filter(activity => activity.done).length;

    result.push({
      uid: calendarView?.current.clusters[0].id,
      name: t('assigned-to-me', 'Assigned to me'),
      progressText: t('progress-done', '{{done}}/{{total}} done', { done: myDone, total: myTotal }),
      total: myTotal,
      done: myDone,
      progressFraction: myTotal === 0 ? 1 : myDone / myTotal,
    });

    stableCalenderViews?.forEach(stableCalendarView => {
      const calendar = stableCalendarView.calendarView.current.clusters[0];
      let stableActivities = calendar.columns[0].activities;
      stableActivities = stableActivities.concat(
        calendarView.current.dailyNotes.filter(
          note =>
            note.executable === true &&
            note.stableUid === stableCalendarView.stable.uid &&
            areIntervalsOverlapping(
              { start: note.startTime, end: note.endTime },
              { start: calendarView.current.days[0], end: endOfDay(calendarView.current.days[0]) },
            ),
        ),
      );

      const total = stableActivities.length;
      const done = stableActivities.filter(activity => activity.done).length;

      result.push({
        uid: calendar.id,
        name: (calendar.groupBy.subject as StableNames).name,
        progressText: t('progress-done', '{{done}}/{{total}} done', { done, total }),
        total,
        done,
        progressFraction: total === 0 ? 1 : done / total,
      });
    });

    return result;
  }, [calendarView, t, stableCalenderViews]);

  const myProgress = progress.find(item => item.uid === selectedOrganization?.me.uid);

  return (
    <Page
      title={t('my-planning', 'My planning')}
      actions={pageActions}
      loading={apiPromises}
      maxWidth={PageMaxWidth.Tile}
      mobileHeaderComponent={<PlanningToolbar showViewTypeControls={false} calendarView={calendarView} showGroupByControls={false} />}
    >
      <PullScrollWrapper onRefresh={() => loadApiData().watchAll()}>
        <>
          <div
            className='pb-4 hidden md:block print:hidden'
            onClick={() => {
              // Clear the selection when we click outside.
              if (selectedActivity) {
                setSelectedActivity(selectedActivity?.activity, SelectedActivityState.Selected);
              }
              unsetBluePrint();
            }}
          >
            <PlanningToolbar
              showGroupByControls={false}
              showViewTypeControls={false}
              calendarView={calendarView}
              filterTypes={filterTypes}
            />
          </div>
          <div
            className='flex flex-col md:flex-row gap-4'
            onClick={() => {
              // Clear the selection when we click outside.
              if (selectedActivity) {
                setSelectedActivity(selectedActivity?.activity, SelectedActivityState.Selected);
              }
              unsetBluePrint();
            }}
          >
            {calendarView && (
              <div className='md:w-3/5 grow flex flex-col gap-4'>
                <Tile className={classNames({ 'print:hidden': printFilter !== undefined })} title={t('my-planning', 'My planning')}>
                  {calendarView.current.hasAnnouncements && (
                    <p className='mb-1 text-sm font-medium text-gray-600' style={{ marginLeft: colHeaderWidth }}>
                      {t('announcements', 'Announcements')}
                    </p>
                  )}
                  <MultiDayHeaderDailyNotes
                    enableBluePrint={false}
                    selectedStableUids={[]}
                    showGrid={false}
                    calendarView={calendarView}
                    columnHeaderWidth={colHeaderWidth}
                    spacious={true}
                  />

                  <p
                    className={classNames('flex text-sm text-gray-600 font-medium mb-1', {
                      'mt-3': calendarView.current.hasAnnouncements,
                    })}
                    style={{ marginLeft: colHeaderWidth }}
                  >
                    {t('activities-assigned-to-me', 'Activities assigned to me')}
                  </p>

                  <MultiDayBody
                    enableClickToExpand={false}
                    spacious={true}
                    showTopBorder={true}
                    calendarView={calendarView}
                    columnHeaderWidth={colHeaderWidth}
                    hideColumnHeaderLabel={true}
                    dragDropType={DragDropType.Disabled}
                  />

                  {myProgress && <p className='md:hidden ml-1 text-xs text-right mt-1 text-gray-500'>({myProgress?.progressText})</p>}
                </Tile>

                {stableCalenderViews?.map(stableCalView => (
                  <Tile
                    key={stableCalView.stable.uid}
                    title={stableCalView.stable.name}
                    className={classNames({ 'print:hidden': printFilter?.uid !== stableCalView.stable.uid })}
                    actions={[
                      {
                        text: t('print', 'Print'),
                        onClick: () => {
                          setPrintFilter(stableCalView.stable);
                          // Give react a bit time to the tailwind print styling
                          setTimeout(() => {
                            window.print();
                          }, 100);
                        },
                      },
                    ]}
                  >
                    <p className='hidden print:block mb-4'>{formatDate(stableCalView.calendarView.current.days[0])}</p>
                    {calendarView.current.hasDailyNotesForStable(stableCalView.stable.uid) && (
                      <p className='mb-1 text-sm font-medium text-gray-600' style={{ marginLeft: colHeaderWidth }}>
                        {t('stable-tasks-and-messages', 'Stable tasks and messages')}
                      </p>
                    )}
                    <MultiDayHeaderDailyNotes
                      showAnnouncements={false}
                      enableBluePrint={false}
                      showGrid={false}
                      selectedStableUids={[stableCalView.stable.uid]}
                      calendarView={stableCalView.calendarView}
                      columnHeaderWidth={colHeaderWidth}
                      spacious={true}
                    />

                    <p
                      className={classNames('flex text-sm text-gray-600 font-medium mb-1', {
                        'mt-3': calendarView.current.hasDailyNotesForStable(stableCalView.stable.uid),
                      })}
                      style={{ marginLeft: colHeaderWidth }}
                    >
                      {t('activities-for-stable', 'Stable activities')}
                    </p>

                    <MultiDayBody
                      enableClickToExpand={false}
                      spacious={true}
                      showTopBorder={true}
                      calendarView={stableCalView.calendarView}
                      columnHeaderWidth={colHeaderWidth}
                      hideColumnHeaderLabel={true}
                      dragDropType={DragDropType.Disabled}
                    />
                    <p className='md:hidden ml-1 text-xs text-right mt-1 text-gray-500'>
                      ({progress.find(item => item.uid === stableCalView.stable.uid)?.progressText})
                    </p>
                  </Tile>
                ))}
              </div>
            )}
            <div className='hidden md:flex print:hidden flex-col grow gap-4'>
              <Tile className='sticky top-4' title={t('progress', 'Progress')}>
                <div className='space-y-3'>
                  {progress.map(progress => (
                    <div key={progress.uid}>
                      <p className='text-sm'>{progress.name}</p>
                      <ProgressBar fractionDone={progress.progressFraction} />
                      <p className='text-xs text-gray-500'>{progress.progressText}</p>
                    </div>
                  ))}
                </div>
              </Tile>
            </div>
          </div>
        </>
      </PullScrollWrapper>
      <SaveActivityModal />
      <PlanningErrorModal />
    </Page>
  );
}

export default function MyPlanning(): JSX.Element {
  return (
    <PlanningProvider viewType={ViewType.Day} groupBy={GroupBy.Stable} timeScale={TimeScale.DayParts}>
      <MyPlanningContent />
    </PlanningProvider>
  );
}
