import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageModal } from 'ui/Modals';
import { PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import Report from './Report';
import { Horse, ReportedHorseRelocation } from 'openapi';

interface Props {
  horse: Horse;
  reportedHorseRelocations: ReportedHorseRelocation[] | undefined;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onClosed?: () => void;
}

function RvoInfoModal({ isVisible, onRequestCloseModal, horse, reportedHorseRelocations, onClosed }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <PageModal open={isVisible} onClosed={onClosed}>
        <PageModalTitle
          title={t('rvo-reports-horse', 'RVO Reports for {{horseName}}', { horseName: horse?.name })}
          onClose={onRequestCloseModal}
        />
        <PageModalContent>
          <div className='space-y-10 mb-4'>
            {reportedHorseRelocations
              ?.sort((a, b) => {
                return a.created_on > b.created_on ? -1 : 1;
              })
              .map(report => <Report key={report.uid} report={report} horse={horse} />)}
          </div>
        </PageModalContent>
      </PageModal>
    </>
  );
}

export default RvoInfoModal;
