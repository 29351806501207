import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { ActionModal } from 'ui/Modals';
import { useTranslation } from 'react-i18next';
import { ActionProps } from 'ui/Modals/ActionModal';
import { ButtonVariant } from 'ui/Button';
import { IS_MOBILE_APP } from 'const';
import useModal from 'ui/Modals/UseModal';
import { lt } from 'semver';
import { useConfig } from 'context/ConfigContext';
import { ApiVersion } from 'Types';
import useAppStateChange from './UseAppStateChange';

interface ReturnType {
  appUpdateModal: ReactNode;
}

/**
 * Hook that will check if there is an app update available on IOS and Android
 * If so we generate a modal that contain one action - update the app.
 * The user is blocked for any other action.
 *
 * @see https://github.com/capawesome-team/capacitor-plugins/tree/main/packages/app-update
 */
function useNativeAppUpdate(): ReturnType {
  const { modalIsVisible, showModal, closeModal } = useModal();
  const { t } = useTranslation();
  const { config } = useConfig();
  const { appStateChange } = useAppStateChange();

  /**
   * Get the min version from our local/vercel api
   *
   * This version is set in the /config.js and is available for the app via /api/version
   */
  const getMinVersion = useCallback(
    async (signal?: AbortSignal) => {
      const fallbackVersion = '0.0.0';

      try {
        const serverlessFunctionsUrl = `${config?.serverlessFunctionsUrl ?? ''}/api/version`;
        const response = await fetch(serverlessFunctionsUrl, {
          method: 'GET',
          signal,
        });
        if (!response.ok) {
          console.error('Failed to get min_version_number from vercel api');
          return fallbackVersion;
        }
        const result = (await response.json()) as ApiVersion;
        return result.minVersionNumber;
      } catch (e) {
        console.error('Failed to get min_version_number from vercel api');
      }

      return fallbackVersion;
    },
    [config],
  );

  /**
   * Function that open the appStore to the correct version
   */
  const openAppStore = useCallback(async () => {
    try {
      await AppUpdate.openAppStore();
    } catch (error) {
      alert(t('unable-to-open-app-store', 'We are unable to open the App Store. Please open the App Store manually to update the app'));
    }
  }, [t]);

  /**
   * Return if there is a new version available
   */
  const updateAvailable = useCallback(
    async (signal?: AbortSignal): Promise<boolean> => {
      try {
        // fetch the min version
        const minVersion = await getMinVersion(signal);

        // fetch the current App info
        const updateInfo = await AppUpdate.getAppUpdateInfo();

        // in case there is no update, bail out
        if (updateInfo.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) return false;

        // check the version numbers
        // if the current version is lower than the minVersion, it return true
        return lt(updateInfo.currentVersionName, minVersion);
      } catch (error) {
        return false;
      }
    },
    [getMinVersion],
  );

  const actions = useMemo((): ActionProps[] => {
    return [
      {
        text: t('update-app', 'Update App'),
        variant: ButtonVariant.Primary,
        onClick: openAppStore,
      },
    ];
  }, [openAppStore, t]);

  /**
   * Generate the modal but will return empty on web
   */
  const appUpdateModal = useMemo(() => {
    if (!IS_MOBILE_APP) return <></>;

    return (
      <ActionModal open={modalIsVisible} title={t('update-available', 'Update available')} actions={actions}>
        <p className='mt-2  w-full'>{t('update-app-desc', 'A new version of the app is available. Please update your App to continue.')}</p>
      </ActionModal>
    );
  }, [actions, modalIsVisible, t]);

  /**
   * check if there is an update available, if so show the modal that show the message to update
   */
  useEffect(() => {
    // bailout if we are not on a mobile app
    if (!IS_MOBILE_APP) return;

    const abortController = new AbortController();

    updateAvailable(abortController.signal).then(shouldUpdate => {
      if (shouldUpdate) {
        showModal();
      } else {
        closeModal();
      }
    });

    return () => abortController.abort();
  }, [updateAvailable]); // eslint-disable-line

  /**
   * We also need to check this when we open the app when it is still active in the background
   */
  useEffect(() => {
    // bailout if we are not on a mobile app
    if (!IS_MOBILE_APP) return;

    const abortController = new AbortController();
    appStateChange(active => {
      if (active) {
        updateAvailable().then(shouldUpdate => {
          if (shouldUpdate) {
            showModal();
          } else {
            closeModal();
          }
        });
      }
    }, abortController.signal);

    return () => abortController.abort();
  }, [appStateChange, updateAvailable]); // eslint-disable-line

  return {
    appUpdateModal,
  };
}

export default useNativeAppUpdate;
