import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Feeding, FeedingsService } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  open: boolean;
  feeding?: Feeding;
  requestClose: () => void;
  onRemoved: () => void;
}

export default function RemoveFeedingModal({ open, feeding, requestClose, onRemoved }: Props): JSX.Element {
  const [removeFeedingDialogError, setRemoveFeedingDialogError] = useState<ApiErrorParser<void> | undefined>();
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  return (
    <ActionModal
      open={open}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: () => {
            requestClose();
            setRemoveFeedingDialogError(undefined);
          },
        },
        {
          text: t('remove', 'Remove'),
          variant: ButtonVariant.PrimaryDanger,
          onClick: async () => {
            try {
              await FeedingsService.feedingsDestroy({
                organisationUid: selectedOrganization?.uid ?? '',
                uid: feeding?.uid ?? '',
              });
              requestClose();
              onRemoved();
            } catch (e) {
              setRemoveFeedingDialogError(new ApiErrorParser(e));
            }
          },
        },
      ]}
      title={t('remove-feeding-confirm-title', 'Remove feeding')}
    >
      <>
        <ErrorSection errors={removeFeedingDialogError} />
        <p>
          {t('remove-feeding-confirm-text', 'Are you sure you want to remove the "{{feeding_name}}" feeding?', {
            feeding_name: feeding?.name,
          })}
        </p>
      </>
    </ActionModal>
  );
}
