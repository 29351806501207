import OrderDetailsPage from 'pages/breeding/OrderDetails';
import OrderListPage from 'pages/breeding/OrderList';
import PicklistPage from 'pages/breeding/Picklist';
import BreedingSettingsPage from 'pages/breeding/Settings';
import ConfirmPasswordReset from 'pages/ConfirmPasswordReset';
import HorsePedigree from 'pages/horses/HorsePedigree';
import PublicIntegrationResult from 'pages/PublicIntegrationResult';
import WebshopMaresList from 'pages/webshop/MaresList';
import WebshopOrderListPage from 'pages/webshop/OrderList';
import React, { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import {
  ActivitiesSettings,
  ContactDetails,
  ContactList,
  Home,
  HorseList,
  HorseLocationHistory,
  HorseSettings,
  IntegrationsPage,
  Invoice,
  InvoicesList,
  MountListPage,
  UiShowcase,
  VatPercentagesList,
} from './pages';
import Benchmark from './pages/Benchmark';
import ForgotPassword from './pages/ForgotPassword';
import HorseDetailsPage from './pages/horses/Details';
import Login, { LoginType } from './pages/Login';
import ProfilePage from './pages/profile/Profile';
import PublicInvoice from './pages/PublicInvoice';
import PublicInvoicePrint from './pages/PublicInvoicePrint';
import Signup from './pages/Signup';
import { HorseDetailProvider } from 'context/HorseDetailContext';
import VerifyEmail from 'pages/VerifyEmail';
import WebshopOrderDetailsPage from 'pages/webshop/OrderDetails';
import MareDetails from 'pages/webshop/MareDetails';
import StallionMountDetailsPage from 'pages/breeding/StallionMountDetails';
import OrganizationSettings from 'pages/admin/Settings';
import FinancialSetting from 'pages/Financial/Settings';
import PaymentsListPage from 'pages/Financial/PaymentsList';
import LocationDetailPage from 'pages/admin/LocationDetail';
import Users from 'pages/users';
import ConfirmInvitation from 'pages/ConfirmInvitation';
import UserDetailsPage from 'pages/users/Details';
import MicrosoftOidcLoginResponse from 'pages/MicrosoftOidcLoginResponse';
import RvoList from 'pages/horses/RvoList';
import PermissionsPage from 'pages/admin/Permissions';
import HorsePlanning from 'pages/planning/HorsePlanning';
import StaffPlanning from 'pages/planning/StaffPlanning';
import MyPlanning from 'pages/planning/MyPlanning';
import StablePlanning from 'pages/planning/StablePlanning';
import StallionMountInventoryDetailsPage from 'pages/breeding/StallionMountInventoryDetails';
import StallionMountInventoryPage from 'pages/breeding/StallionMountInventory';
import CarePlanning from 'pages/planning/CarePlanning';
import ActivityHistoryPage from 'pages/planning/ActivityHistory';
import HorseActivityHistoryPage from 'pages/horses/ActivityHistory';
import PublicHorseShare from 'pages/horses/PublicShare';
import Reservations from 'pages/planning/Reservations';
import FacilityPlanning, { FacilityAvailability } from 'pages/planning/FacilityPlanning';
import ManageFacilities from 'pages/planning/ManageFacilities';
import MareCycleCheckPage from 'pages/breeding/MareCycleCheckList';
import MareCycleCheckDetailsPage from 'pages/breeding/MareCycleCheckDetails';
import ManurePage from 'pages/admin/Manure';
import ManureBudgetPage from 'pages/admin/ManureBudget';
import ManureBalancePage from 'pages/admin/ManureBalance';
import ManureHorseSummaryPage from 'pages/admin/ManureHorseSummary';
import ManureEffectiveNitrogenPage from 'pages/admin/ManureEffectiveNitrogen';
import FeedSettings from 'pages/feed/Settings';
import FeedPlanning from 'pages/feed/FeedPlanning';
import SubscriptionPage from 'pages/admin/SubscriptionPage';
import ModuleAccess from 'components/Common/ModuleAccess';
import { ModuleEnum } from 'openapi';
import SubscriptionExpiredNonAdminPage from 'pages/SubscriptionExpiredNonAdminPage';

interface RouteConfigInterface {
  path: string;
  name: string;
  component: ReactNode | undefined;
  public?: boolean;
}

export enum AppRouteKeys {
  Home = 'Home',
  Profile = 'Profile',
  Signup = 'Signup',
  Login = 'Login',
  MicrosoftOidcLoginResponse = 'MicrosoftOidcLoginResponse',
  ForgotPassword = 'ForgotPassword',
  Benchmark = 'Benchmark',
  HorsePlanning = 'HorsePlanning',
  StaffPlanning = 'StaffPlanning',
  MyPlanning = 'MyPlanning',
  StablePlanning = 'StablePlanning',
  CarePlanning = 'CarePlanning',
  FacilityPlanning = 'FacilityPlanning',
  ManageFacilities = 'ManageFacilities',
  ActivityHistory = 'ActivityHistory',
  ActivitiesSettings = 'ActivitiesSettings',
  Reservations = 'Reservations',
  FacilityAvailability = 'FacilityAvailability',
  HorsesList = 'HorsesList',
  HorsesDetails = 'HorsesDetails',
  HorsesPedigree = 'HorsesPedigree',
  HorseActivityHistory = 'HorseActivityHistory',
  HorsesLocationHistory = 'HorsesLocationHistory',
  HorsesRvoList = 'HorsesRvoList',
  HorsesSettings = 'HorsesSettings',
  FeedSettings = 'FeedSettings',
  FeedPlanning = 'FeedPlanning',
  UiShowcase = 'UiShowcase',
  ContactList = 'ContactList',
  ContactDetails = 'ContactDetails',
  InvoicesList = 'InvoicesList',
  Invoice = 'Invoice',
  PublicInvoice = 'PublicInvoice',
  PublicInvoicePrint = 'PublicInvoicePrint',
  MountList = 'MountList',
  MountDetails = 'MountDetails',
  BreedingInventoryList = 'BreedingInventoryList',
  BreedingInventoryDetails = 'BreedingInventoryDetails',
  BreedingMareCycleCheckList = 'BreedingMareCycleCheckList',
  BreedingMareCycleCheckDetails = 'BreedingMareCycleCheckDetails',
  SemenOrderList = 'SemenOrderList',
  SemenOrderDetails = 'SemenOrderDetails',
  SemenPicklist = 'SemenPicklist',
  BreedingSettings = 'BreedingSettings',
  ConfirmPasswordReset = 'ConfirmPasswordReset',
  VatPercentages = 'VatPercentages',
  VerifyEmail = 'VerifyEmail',
  Integrations = 'Integrations',
  Permissions = 'Permissions',
  OrganizationSettings = 'OrganizationSettings',
  AdminUsersList = 'AdminUsersList',
  AdminUserDetails = 'AdminUserDetails',
  PublicIntegrationSuccess = 'PublicIntegrationSuccess',
  PublicHorseShare = 'PublicHorseShare',
  FinancialSettings = 'FinancialSettings',
  PaymentsList = 'PaymentsList',
  ConfirmInvitation = 'ConfirmInvitation',
  LocationDetail = 'LocationDetail',
  Manure = 'Manure',
  ManureBudget = 'ManureBudget',
  ManureBalance = 'ManureBalance',
  ManureEffectiveNitrogen = 'ManureEffectiveNitrogen',
  ManureHorseSummary = 'ManureHorseSummary',
  Subscription = 'Subscription',
  SubscriptionExpiredNonAdmin = 'SubscriptionExpiredNonAdmin',
}

export enum WebshopRouteKeys {
  Signup = 'Signup',
  Login = 'Login',
  ForgotPassword = 'ForgotPassword',
  ConfirmPasswordReset = 'ConfirmPasswordReset',
  Profile = 'Profile',
  OrderList = 'OrderList',
  OrderDetails = 'OrderDetails',
  MaresList = 'MaresList',
  MareDetails = 'MareDetails',
  MarePedigree = 'MarePedigree',
}

type AppRoutesInterface = {
  [key in AppRouteKeys]: RouteConfigInterface;
};

type WebshopRoutesInterface = {
  [key in WebshopRouteKeys]: RouteConfigInterface;
};

export const AppRoutes: AppRoutesInterface = {
  Home: {
    path: '/',
    name: 'Home',
    component: <Home />,
    public: false,
  },
  Signup: {
    path: '/auth/signup',
    name: 'Signup',
    component: <Signup loginType={LoginType.Equinem} />,
    public: true,
  },
  Login: {
    path: '/auth/login',
    name: 'Login',
    component: <Login loginType={LoginType.Equinem} />,
    public: true,
  },
  MicrosoftOidcLoginResponse: {
    path: '/auth/microsoft/response-oidc',
    name: 'MicrosoftOidcLoginResponse',
    component: <MicrosoftOidcLoginResponse />,
    public: true,
  },
  ForgotPassword: {
    path: '/auth/forgot-password',
    name: 'Forgot Password',
    component: <ForgotPassword loginType={LoginType.Equinem} />,
    public: true,
  },
  ConfirmPasswordReset: {
    path: '/confirm-password-reset',
    name: 'Confirm password reset',
    component: <ConfirmPasswordReset />,
    public: true,
  },
  VerifyEmail: {
    path: '/verify-email',
    name: 'Verify email',
    component: <VerifyEmail />,
    public: true,
  },
  ConfirmInvitation: {
    path: '/confirm-invitation',
    name: 'confirm-invitation',
    component: <ConfirmInvitation />,
    public: true,
  },
  Profile: {
    path: '/profile',
    name: 'Profile',
    component: <ProfilePage />,
    public: false,
  },
  Benchmark: {
    path: '/benchmark',
    name: 'Benchmark',
    component: <Benchmark />,
    public: true,
  },
  HorsePlanning: {
    path: '/planning/horses',
    name: 'Horse planning',
    component: (
      <ModuleAccess moduleType={[ModuleEnum.HORSE_SERVICES_CARE, ModuleEnum.HORSE_SERVICES_FULL]}>
        <HorsePlanning />
      </ModuleAccess>
    ),
    public: false,
  },
  StaffPlanning: {
    path: '/planning/staff',
    name: 'Staff planning',
    component: (
      <ModuleAccess moduleType={[ModuleEnum.HORSE_SERVICES_CARE, ModuleEnum.HORSE_SERVICES_FULL]}>
        <StaffPlanning />
      </ModuleAccess>
    ),
    public: false,
  },
  MyPlanning: {
    path: '/planning/my',
    name: 'My planning',
    component: (
      <ModuleAccess moduleType={[ModuleEnum.HORSE_SERVICES_CARE, ModuleEnum.HORSE_SERVICES_FULL]}>
        <MyPlanning />
      </ModuleAccess>
    ),
    public: false,
  },
  StablePlanning: {
    path: '/planning/stable',
    name: 'Stable planning',
    component: (
      <ModuleAccess moduleType={[ModuleEnum.HORSE_SERVICES_CARE, ModuleEnum.HORSE_SERVICES_FULL]}>
        <StablePlanning />
      </ModuleAccess>
    ),
    public: false,
  },
  CarePlanning: {
    path: '/planning/care',
    name: 'Care planning',
    component: (
      <ModuleAccess moduleType={[ModuleEnum.HORSE_SERVICES_CARE, ModuleEnum.HORSE_SERVICES_FULL]}>
        <CarePlanning />
      </ModuleAccess>
    ),
    public: false,
  },
  ActivityHistory: {
    path: '/planning/history',
    name: 'Activity history',
    component: (
      <ModuleAccess moduleType={[ModuleEnum.HORSE_SERVICES_CARE, ModuleEnum.HORSE_SERVICES_FULL]}>
        <ActivityHistoryPage />
      </ModuleAccess>
    ),
    public: false,
  },
  ActivitiesSettings: {
    path: '/planning/settings',
    name: 'Settings',
    component: (
      <ModuleAccess moduleType={[ModuleEnum.HORSE_SERVICES_CARE, ModuleEnum.HORSE_SERVICES_FULL]}>
        <ActivitiesSettings />
      </ModuleAccess>
    ),
    public: false,
  },
  FacilityPlanning: {
    path: '/facilities/planning',
    name: 'Facility planning',
    component: (
      <ModuleAccess moduleType={ModuleEnum.FACILITY_PLANNING}>
        <FacilityPlanning />
      </ModuleAccess>
    ),
    public: false,
  },
  ManageFacilities: {
    path: '/facilities/manage',
    name: 'Manage facilities',
    component: (
      <ModuleAccess moduleType={ModuleEnum.FACILITY_PLANNING}>
        <ManageFacilities />
      </ModuleAccess>
    ),
    public: false,
  },
  Reservations: {
    path: '/reservations',
    name: 'Reservations',
    component: (
      <ModuleAccess moduleType={ModuleEnum.FACILITY_PLANNING}>
        <Reservations />
      </ModuleAccess>
    ),
    public: false,
  },
  FacilityAvailability: {
    path: '/reservations/availability',
    name: 'Facility availability',
    component: (
      <ModuleAccess moduleType={ModuleEnum.FACILITY_PLANNING}>
        <FacilityAvailability />
      </ModuleAccess>
    ),
    public: false,
  },
  HorsesList: {
    path: '/horses/list',
    name: 'Horse list',
    component: <HorseList />,
    public: false,
  },
  HorsesDetails: {
    path: '/horses/:uid',
    name: 'Horse details',
    component: (
      <HorseDetailProvider>
        <HorseDetailsPage />
      </HorseDetailProvider>
    ),
    public: false,
  },
  HorsesPedigree: {
    path: '/horses/:uid/pedigree',
    name: 'Horse pedigree',
    component: <HorsePedigree loginType={LoginType.Equinem} />,
    public: false,
  },
  HorseActivityHistory: {
    path: '/horses/:uid/activity-history',
    name: 'Horse activity history',
    component: <HorseActivityHistoryPage />,
    public: false,
  },
  HorsesLocationHistory: {
    path: '/horses/location-history',
    name: 'Horse location history',
    component: <HorseLocationHistory />,
    public: false,
  },
  HorsesRvoList: {
    path: '/horses/location-history/rvo',
    name: 'RVO report list',
    component: <RvoList />,
    public: false,
  },
  HorsesSettings: {
    path: '/horses/settings',
    name: 'Horse settings',
    component: <HorseSettings />,
    public: false,
  },
  FeedSettings: {
    path: '/feed/settings',
    name: 'Feed settings',
    component: <FeedSettings />,
    public: false,
  },
  FeedPlanning: {
    path: '/feed/schedule',
    name: 'Feed schedule',
    component: <FeedPlanning />,
    public: false,
  },
  UiShowcase: {
    path: '/ui/showcase',
    name: 'UI showcase',
    component: <UiShowcase />,
    public: true,
  },
  ContactList: {
    path: '/contacts/list',
    name: 'Contacts list',
    component: <ContactList />,
    public: false,
  },
  ContactDetails: {
    path: '/contacts/:uid',
    name: 'Contact details',
    component: <ContactDetails />,
    public: false,
  },
  InvoicesList: {
    path: '/financial-administration/invoices',
    name: 'Invoices',
    component: (
      <ModuleAccess moduleType={ModuleEnum.FINANCIAL_AND_PAYMENT}>
        <InvoicesList />
      </ModuleAccess>
    ),
    public: false,
  },
  PaymentsList: {
    path: '/financial-administration/payments',
    name: 'Payments',
    component: (
      <ModuleAccess moduleType={ModuleEnum.FINANCIAL_AND_PAYMENT}>
        <PaymentsListPage />
      </ModuleAccess>
    ),
    public: false,
  },
  Invoice: {
    path: '/financial-administration/invoices/:invoiceId',
    name: 'Invoice',
    component: (
      <ModuleAccess moduleType={ModuleEnum.FINANCIAL_AND_PAYMENT}>
        <Invoice />
      </ModuleAccess>
    ),
    public: false,
  },
  VatPercentages: {
    path: '/financial-administration/vat',
    name: 'Vat',
    component: (
      <ModuleAccess moduleType={ModuleEnum.FINANCIAL_AND_PAYMENT}>
        <VatPercentagesList />
      </ModuleAccess>
    ),
    public: false,
  },
  FinancialSettings: {
    path: '/financial-administration/settings',
    name: 'Settings',
    component: (
      <ModuleAccess moduleType={ModuleEnum.FINANCIAL_AND_PAYMENT}>
        <FinancialSetting />
      </ModuleAccess>
    ),
    public: false,
  },
  PublicInvoice: {
    path: '/p/invoice/:publicAccessUuid',
    name: 'Invoice',
    component: <PublicInvoice />,
    public: true,
  },
  PublicInvoicePrint: {
    path: '/p/invoice/:publicAccessUuid/print',
    name: 'Invoice',
    component: <PublicInvoicePrint />,
    public: true,
  },
  MountList: {
    path: '/breeding/stallion-mounts',
    name: 'Mounts',
    component: (
      <ModuleAccess moduleType={ModuleEnum.SHOP_AND_BREEDING}>
        <MountListPage />
      </ModuleAccess>
    ),
    public: false,
  },
  MountDetails: {
    path: '/breeding/stallion-mounts/:uid',
    name: 'Mount details',
    component: (
      <ModuleAccess moduleType={ModuleEnum.SHOP_AND_BREEDING}>
        <StallionMountDetailsPage />
      </ModuleAccess>
    ),
    public: false,
  },
  BreedingInventoryList: {
    path: '/breeding/inventory',
    name: 'Inventory',
    component: (
      <ModuleAccess moduleType={ModuleEnum.SHOP_AND_BREEDING}>
        <StallionMountInventoryPage />
      </ModuleAccess>
    ),
    public: false,
  },
  BreedingInventoryDetails: {
    path: '/breeding/inventory/:uid',
    name: 'Inventory details',
    component: (
      <ModuleAccess moduleType={ModuleEnum.SHOP_AND_BREEDING}>
        <StallionMountInventoryDetailsPage />
      </ModuleAccess>
    ),
    public: false,
  },
  BreedingMareCycleCheckList: {
    path: '/breeding/mare-cycle-checks',
    name: 'Mare cycle checks',
    component: (
      <ModuleAccess moduleType={ModuleEnum.SHOP_AND_BREEDING}>
        <MareCycleCheckPage />
      </ModuleAccess>
    ),
    public: false,
  },
  BreedingMareCycleCheckDetails: {
    path: '/breeding/mare-cycle-checks/:uid',
    name: 'Mare cycle check',
    component: (
      <ModuleAccess moduleType={ModuleEnum.SHOP_AND_BREEDING}>
        <MareCycleCheckDetailsPage />
      </ModuleAccess>
    ),
    public: false,
  },
  SemenOrderList: {
    path: '/shop/orders',
    name: 'Semen orders',
    component: (
      <ModuleAccess moduleType={ModuleEnum.SHOP_AND_BREEDING}>
        <OrderListPage />
      </ModuleAccess>
    ),
    public: false,
  },
  SemenOrderDetails: {
    path: '/shop/orders/:uid',
    name: 'Semen order details',
    component: (
      <ModuleAccess moduleType={ModuleEnum.SHOP_AND_BREEDING}>
        <OrderDetailsPage />
      </ModuleAccess>
    ),
    public: false,
  },
  SemenPicklist: {
    path: '/shop/picklist',
    name: 'Semen picklist',
    component: (
      <ModuleAccess moduleType={ModuleEnum.SHOP_AND_BREEDING}>
        <PicklistPage />
      </ModuleAccess>
    ),
    public: false,
  },
  BreedingSettings: {
    path: '/shop/settings',
    name: 'Settings',
    component: (
      <ModuleAccess moduleType={ModuleEnum.SHOP_AND_BREEDING}>
        <BreedingSettingsPage />
      </ModuleAccess>
    ),
    public: false,
  },
  OrganizationSettings: {
    path: '/admin/settings',
    name: 'Settings',
    component: <OrganizationSettings />,
    public: false,
  },
  Integrations: {
    path: '/admin/integrations',
    name: 'Integrations',
    component: <IntegrationsPage />,
    public: false,
  },
  Permissions: {
    path: '/admin/permissions',
    name: 'Permissions',
    component: <PermissionsPage />,
    public: false,
  },
  AdminUsersList: {
    path: '/admin/users',
    name: 'Users',
    component: <Users />,
    public: false,
  },
  AdminUserDetails: {
    path: '/admin/users/:uid',
    name: 'Users details',
    component: <UserDetailsPage />,
    public: false,
  },
  LocationDetail: {
    path: '/admin/locations/:contactUid',
    name: 'Stable detail',
    component: <LocationDetailPage />,
    public: false,
  },
  PublicIntegrationSuccess: {
    path: '/p/oauth2-callback-result',
    name: 'PublicIntegrationResult',
    component: <PublicIntegrationResult />,
    public: true,
  },
  PublicHorseShare: {
    path: '/p/horse/:publicAccessUuid',
    name: 'PublicHorseShare',
    component: <PublicHorseShare />,
    public: true,
  },
  Manure: {
    path: '/admin/manure',
    name: 'Manure',
    component: <ManurePage />,
    public: false,
  },
  ManureBudget: {
    path: '/admin/manure/:stableContactUid/budget/:year',
    name: 'ManureBudget',
    component: <ManureBudgetPage />,
    public: false,
  },
  ManureBalance: {
    path: '/admin/manure/:stableContactUid/balance/:year',
    name: 'ManureBalance',
    component: <ManureBalancePage />,
    public: false,
  },
  ManureEffectiveNitrogen: {
    path: '/admin/manure/:stableContactUid/effective-nitrogen/:year',
    name: 'ManureEffectiveNitrogen',
    component: <ManureEffectiveNitrogenPage />,
    public: false,
  },
  ManureHorseSummary: {
    path: '/admin/manure/horse-summary',
    name: 'ManureHorseSummary',
    component: <ManureHorseSummaryPage />,
    public: false,
  },
  Subscription: {
    path: '/admin/subscription',
    name: 'Subscription',
    component: <SubscriptionPage />,
    public: false,
  },
  SubscriptionExpiredNonAdmin: {
    path: '/subscription_expired',
    name: 'Subscription expired',
    component: <SubscriptionExpiredNonAdminPage />,
    public: false,
  },
};

// The top level route of all webshop sub routes.
export const WebshopRoute = '/shop/:publicAccessUuid';

// A helper class to generate a full path for a webshop url.
export const generateWebshopPath = (path: string, publicAccessUuid: string): string => {
  return generatePath(`${WebshopRoute}/${path}`, { publicAccessUuid });
};

export const WebshopRoutes: WebshopRoutesInterface = {
  Signup: {
    path: 'auth/signup',
    name: 'Signup',
    component: <Signup loginType={LoginType.Webshop} />,
    public: true,
  },
  Login: {
    path: 'auth/login',
    name: 'Login',
    component: <Login loginType={LoginType.Webshop} />,
    public: true,
  },
  ForgotPassword: {
    path: 'auth/forgot-password',
    name: 'Forgot Password',
    component: <ForgotPassword loginType={LoginType.Webshop} />,
    public: true,
  },
  ConfirmPasswordReset: {
    path: 'confirm-password-reset',
    name: 'Confirm password reset',
    component: <ConfirmPasswordReset />,
    public: true,
  },
  OrderList: {
    path: 'orders',
    name: 'Orders',
    component: <WebshopOrderListPage />,
    public: false,
  },
  OrderDetails: {
    path: 'orders/:uid',
    name: 'Order details',
    component: <WebshopOrderDetailsPage />,
    public: false,
  },
  MaresList: {
    path: 'mares',
    name: 'Mares',
    component: <WebshopMaresList />,
    public: false,
  },
  MareDetails: {
    path: 'mares/:uid',
    name: 'Mare Details',
    component: (
      <HorseDetailProvider>
        <MareDetails />
      </HorseDetailProvider>
    ),
    public: false,
  },
  MarePedigree: {
    path: 'mares/:uid/pedigree',
    name: 'Mare pedigree',
    component: <HorsePedigree loginType={LoginType.Webshop} />,
    public: false,
  },
  Profile: {
    path: 'profile',
    name: 'Profile',
    component: <ProfilePage />,
    public: false,
  },
};
