import { ClockCountdown, Lock } from '@phosphor-icons/react';
import { AppRoutes } from 'AppRoutes';
import usePermissions from 'hooks/UsePermissions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'ui/Layout';

function SubscriptionExpiredNonAdminPage(): JSX.Element {
  const { t } = useTranslation();
  const { isAdmin } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAdmin) {
      navigate(AppRoutes.Subscription.path);
    }
  }, [isAdmin, navigate]);

  return (
    <Page title={t('trial-expired', 'Trial expired')}>
      <div className='flex flex-col items-center'>
        <div className='relative rounded-full bg-zinc-100 border p-5 mb-10'>
          <ClockCountdown size={90} weight='light' />
          <Lock size={30} weight='fill' className='text-primary absolute bottom-1 left-1' />
        </div>

        <h2 className='font-bold text-lg my-2'>{t('trial-expired-title', 'You trial has been expired.')}</h2>

        <p className='max-w-lg text-center'>
          {t('trial-expired-desc', 'In order continue EquineM, you need to upgrade your subscription. Please contact your administrator.')}
        </p>
      </div>
    </Page>
  );
}

export default SubscriptionExpiredNonAdminPage;
