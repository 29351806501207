import { TFunction } from 'i18next';
import { FeedTypeCategoryEnum, QuantityMeasureEnum } from 'openapi';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';

// QuantityMeasureEnum to string method.
export const quantityMeasureString = (t: TFunction, measure: QuantityMeasureEnum): string => {
  switch (measure) {
    case QuantityMeasureEnum.ML:
      return t('milliliter', 'Milliliter');
    case QuantityMeasureEnum.L:
      return t('liter', 'Liter');
    case QuantityMeasureEnum.G:
      return t('gram', 'Gram');
    case QuantityMeasureEnum.KG:
      return t('kilogram', 'Kilogram');
    case QuantityMeasureEnum.LB:
      return t('pound', 'Pound');
    case QuantityMeasureEnum.OZ:
      return t('ounce-weight', 'Ounce (weight)');
    case QuantityMeasureEnum.IMPERIAL_OZ:
      return t('imperial-oz', 'Imperial Ounce (fluid)');
    case QuantityMeasureEnum.IMPERIAL_PINT:
      return t('imperial-pint', 'Imperial Pint');
    case QuantityMeasureEnum.IMPERIAL_QT:
      return t('imperial-quart', 'Imperial Quart');
    case QuantityMeasureEnum.IMPERIAL_G:
      return t('imperial-gallon', 'Imperial Gallon');
    case QuantityMeasureEnum.IMPERIAL_TSP:
      return t('imperial-teaspoon', 'Imperial Teaspoon');
    case QuantityMeasureEnum.US_OZ:
      return t('us-oz', 'US Ounce (fluid)');
    case QuantityMeasureEnum.US_PINT:
      return t('us-pint', 'US Pint');
    case QuantityMeasureEnum.US_QT:
      return t('us-quart', 'US Quart');
    case QuantityMeasureEnum.US_G:
      return t('us-gallon', 'US Gallon');
    case QuantityMeasureEnum.US_TSP:
      return t('us-teaspoon', 'US Teaspoon');
  }
};

export const quantityMeasureOptions = (t: TFunction): OptionItemInterface[] => {
  const options: OptionItemInterface[] = [];
  Object.values(QuantityMeasureEnum).forEach(key => {
    const name = quantityMeasureString(t, key);
    if (name) {
      options.push({ id: key, name });
    }
  });
  return options;
};

export const feedTypeCategoryString = (t: TFunction, category: FeedTypeCategoryEnum): string => {
  switch (category) {
    case FeedTypeCategoryEnum.CONCENTRATE:
      return t('feed-type-concentrate', 'Concentrate');
    case FeedTypeCategoryEnum.ROUGHAGE:
      return t('feed-type-roughage', 'Roughage');
    case FeedTypeCategoryEnum.MEDICINE:
      return t('feed-type-medicine', 'Medicine');
    case FeedTypeCategoryEnum.SUPPLEMENT:
      return t('feed-type-supplement', 'Supplement');
  }
};

export const feedTypeCategoryOptions = (t: TFunction): OptionItemInterface[] => {
  const options: OptionItemInterface[] = [];
  Object.values(FeedTypeCategoryEnum).forEach(key => {
    const name = feedTypeCategoryString(t, key);
    if (name) {
      options.push({ id: key, name });
    }
  });
  return options;
};
