/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeedType } from '../models/FeedType';
import type { PatchedFeedType } from '../models/PatchedFeedType';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FeedtypesService {
  /**
   * @returns FeedType
   * @throws ApiError
   */
  public static feedtypesList({
    organisationUid,
    displayNameIcontains,
    hidden,
    o,
  }: {
    organisationUid: string,
    displayNameIcontains?: string,
    hidden?: boolean,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
  }): CancelablePromise<Array<FeedType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/feedtypes',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'display_name__icontains': displayNameIcontains,
        'hidden': hidden,
        'o': o,
      },
    });
  }
  /**
   * @returns FeedType
   * @throws ApiError
   */
  public static feedtypesCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: FeedType,
  }): CancelablePromise<FeedType> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/feedtypes',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns FeedType
   * @throws ApiError
   */
  public static feedtypesRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<FeedType> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/feedtypes/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns FeedType
   * @throws ApiError
   */
  public static feedtypesUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: FeedType,
  }): CancelablePromise<FeedType> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/feedtypes/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns FeedType
   * @throws ApiError
   */
  public static feedtypesPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedFeedType,
  }): CancelablePromise<FeedType> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/feedtypes/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static feedtypesDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/feedtypes/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
}
