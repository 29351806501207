import { CacheKey } from 'api/ApiCache';
import {
  ModulePermissionsEnum,
  Organisation,
  OrganisationList,
  OrganisationService,
  PurchaserServiceContract,
  Stable,
  StableContact,
  StablesService,
} from 'openapi';
import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BrowserStorageKeys } from '../api/UserSession';
import { useAccount } from './AccountContext';
import { useConfig } from './ConfigContext';

interface Props {
  children: React.ReactNode;
}

type OrganizationContextType = {
  selectedOrganizationUid: string | undefined;
  selectedOrganization: OrganisationList | undefined;
  selectedOrganizationDetails: Organisation | undefined;
  // The stables of the selected organization.
  selectedOrganizationStables: Stable[] | undefined;
  // The primary stable location of the selected organization.
  // This can be seen as the company primary location.
  selectedOrganizationPrimaryStableLocation: StableContact | undefined;
  // holds all active service contracts for the selected organization
  selectedOrganizationServiceContracts: PurchaserServiceContract[] | undefined;
  setSelectedOrganization: (orgUid: string) => void;
  organizations: OrganisationList[];
  loading: boolean;
  error: string | undefined;
  refresh: () => void;
  refreshServiceContracts: () => void;
  generateCacheKey: (name: string) => CacheKey;
  rvoReportCount: number;
  setRvoReportCount: Dispatch<SetStateAction<number>>;
  permissions: Set<ModulePermissionsEnum> | undefined;
  setPermissions: Dispatch<SetStateAction<Set<ModulePermissionsEnum> | undefined>>;
};

const OrganizationContext = createContext<OrganizationContextType>({
  selectedOrganizationUid: undefined,
  selectedOrganization: undefined,
  selectedOrganizationDetails: undefined,
  selectedOrganizationStables: undefined,
  selectedOrganizationPrimaryStableLocation: undefined,
  setSelectedOrganization: () => console.warn('No Organization provider'),
  selectedOrganizationServiceContracts: undefined,
  organizations: [],
  loading: false,
  error: undefined,
  refresh: () => console.warn('No Organization provider'),
  refreshServiceContracts: () => console.warn('No Organization provider'),
  generateCacheKey: (): CacheKey => {
    throw Error('No Organization provider');
  },
  rvoReportCount: 0,
  setRvoReportCount: () => console.warn('No Organization provider'),
  permissions: undefined,
  setPermissions: () => console.warn('No Organization provider'),
});

export function useOrganization(): OrganizationContextType {
  return useContext(OrganizationContext);
}

export function OrganizationProvider({ children }: Props): JSX.Element {
  const [selectedOrganizationUid, setSelectedOrganizationUid] = useState<string | undefined>();
  const [organizations, setOrganizations] = useState<OrganisationList[]>();
  const [selectedOrganizationDetails, setSelectedOrganizationDetails] = useState<Organisation>();
  const [selectedOrganizationServiceContracts, setSelectedOrganizationServiceContracts] = useState<PurchaserServiceContract[]>();

  // The stables of the selected organization.
  const [selectedOrganizationStables, setSelectedOrganizationStables] = useState<Stable[]>();

  const [selectedOrganization, setSelectedOrganization] = useState<OrganisationList>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { config } = useConfig();
  const { session: user, accountDetails } = useAccount();
  const [rvoReportCount, setRvoReportCount] = useState<OrganizationContextType['rvoReportCount']>(0);
  const [permissions, setPermissions] = useState<OrganizationContextType['permissions']>();

  const selectedOrganizationPrimaryStableLocation = useMemo((): StableContact | undefined => {
    return selectedOrganizationStables?.find(stable => stable.is_primary)?.location;
  }, [selectedOrganizationStables]);

  /**
   * A convenience function for generating a CacheId which can be used as
   * storage key for caching api data.
   * @param name A unique name that is used as key. I.e. 'horses' or 'contacts'.
   */
  const generateCacheKey = useCallback(
    (name: string): CacheKey => {
      if (!selectedOrganizationUid) {
        throw Error('No organization selected when trying to build a CacheId');
      }
      if (!accountDetails) {
        throw Error('No logged in user when trying to build a CacheId');
      }
      return {
        userUid: accountDetails.uid,
        organizationUid: selectedOrganizationUid,
        name,
      };
    },
    [accountDetails, selectedOrganizationUid],
  );

  /**
   * Load the selected organization details
   */
  const loadSelectedOrganization = useCallback(
    async (organizationUid: string) => {
      if (!organizations) {
        console.error('Trying to get the selected organization but organizations are not yet loaded.');
        return;
      }

      try {
        const orgPromise = OrganisationService.rootRetrieve({ uid: organizationUid });
        setSelectedOrganizationDetails(await orgPromise);
        const stablePromise = StablesService.stablesList({ organisationUid: organizationUid });
        setSelectedOrganizationStables(await stablePromise);
      } catch (e) {
        setError('Unable to load selected organization');
      }
    },
    [organizations, setSelectedOrganizationDetails],
  );

  /**
   * Small helper that load the organizations
   */
  const loadOrganization = useCallback(async () => {
    const promise = OrganisationService.rootList({});

    const results: OrganisationList[] = await promise;
    const localStorageOrgUid: string | null = localStorage?.getItem(BrowserStorageKeys.SelectedOrganization);
    let selectedOrg: OrganisationList | undefined = undefined;
    if (localStorageOrgUid) {
      selectedOrg = results.find(org => org.uid === localStorageOrgUid);
    } else if (accountDetails?.preferred_organisation) {
      // set the preferred organization as selected
      selectedOrg = results.find(org => org.uid === accountDetails.preferred_organisation);
    }
    if (!selectedOrg && results.length > 0) {
      selectedOrg = results[0];
    }

    return {
      results,
      selectedOrg,
    };
  }, [accountDetails?.preferred_organisation]);

  /**
   * Full refresh of the organizations
   */
  const refresh = useCallback(async () => {
    if (!config) {
      setError('Config is not set');
      return;
    }
    if (!user) {
      setError('User is not logged in');
      return;
    }
    setLoading(true);

    try {
      const { results, selectedOrg } = await loadOrganization();

      // we set the selected organization in local storage if it is not set
      // we are doing this so it will stick to the current choosen one, even when the user
      // change the preferred organization
      // On logout, this will be cleared and on login it will be set again, with the preferred organization or the first one
      if (selectedOrg) {
        localStorage?.setItem(BrowserStorageKeys.SelectedOrganization, selectedOrg.uid);
      }

      setOrganizations(results);
      setSelectedOrganization(selectedOrg);
      setSelectedOrganizationUid(selectedOrg?.uid);
      setSelectedOrganizationServiceContracts(selectedOrg?.me.service_contracts ?? []);
    } catch (e) {
      setError('Unable to load organizations');
    } finally {
      setLoading(false);
    }
  }, [config, user]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Refresh the service contracts for the selected organization
   *
   * This is a separate function because it will otherwise trigger a lot of re-renders and causing a lot of API calls
   */
  const refreshServiceContracts = useCallback(async () => {
    if (!config) {
      setError('Config is not set');
      return;
    }
    if (!user) {
      setError('User is not logged in');
      return;
    }

    try {
      const { selectedOrg } = await loadOrganization();

      setSelectedOrganizationServiceContracts(selectedOrg?.me.service_contracts ?? []);
    } catch (e) {
      console.error('Unable to refresh the service contracts for this organization', e);
    }
  }, [config, loadOrganization, user]);

  const changeSelectedOrganization = useCallback(
    (orgUid: string) => {
      if (!organizations) {
        throw Error('Trying to select organization but organizations not yet loaded');
      }
      const found = organizations.find(org => org.uid === orgUid);
      if (!found) {
        throw Error('Trying to select organization but it is not found in the organization list');
      }
      localStorage?.setItem(BrowserStorageKeys.SelectedOrganization, orgUid);
      setSelectedOrganizationUid(orgUid);
      setSelectedOrganization(found);
    },
    [organizations],
  );

  useEffect(() => {
    if (user) {
      refresh();
    }
  }, [user, refresh]);

  /**
   * Load the selected organization details when the selectedOrganizationUid changes
   */
  useEffect(() => {
    if (selectedOrganizationUid) {
      loadSelectedOrganization(selectedOrganizationUid);
    }
  }, [loadSelectedOrganization, selectedOrganizationUid]);

  return (
    <OrganizationContext.Provider
      value={{
        organizations: organizations ?? [],
        selectedOrganizationUid,
        selectedOrganization,
        selectedOrganizationDetails,
        selectedOrganizationStables,
        selectedOrganizationPrimaryStableLocation,
        selectedOrganizationServiceContracts,
        error,
        loading,
        refresh,
        refreshServiceContracts,
        setSelectedOrganization: changeSelectedOrganization,
        generateCacheKey,
        rvoReportCount,
        setRvoReportCount,
        permissions,
        setPermissions,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}
