import React, { useEffect, useMemo } from 'react';
import { Tile } from 'ui/Layout/Tile';
import SaveSubscriptionModal from './SaveSubscriptionModal';
import { CatalogueProduct, Plan, PurchaserServiceContract } from 'openapi';
import { Trans, useTranslation } from 'react-i18next';
import useModal from 'ui/Modals/UseModal';
import { useAccount } from 'context/AccountContext';
import { BreedingBadge, CareBadge, SportBadge } from 'components/Horses/HorseUsageBadges';
import Badge, { BadgeSize } from 'ui/Badge/Badge';
import { Pencil, WarningCircle } from '@phosphor-icons/react';
import { AllColors } from 'utilities/colors';
import {
  calculateHorsePrices,
  getEndDateSubscription,
  getModuleName,
  horseServicesFormatExtendAutomaticallyAndEndDt,
  SubscriptionState,
  translatedMaxUnitCount,
  translateFreePrice,
} from '../Helper';
import { PageAction } from 'context/PageContext';
import Button, { ButtonVariant } from 'ui/Button';
import { Alert } from 'ui/Alert';
import { Severity } from 'utilities/severity';
import { differenceInDays } from 'date-fns';
import StartFreeSubscriptionModal from '../StartFreeSubscriptionModal';
import StartPaidSubscriptionModal from '../StartPaidSubscriptionModal';
import useTrialExpired from 'hooks/UseTrialExpired';
import useSubscriptionUserContractFollowUp from 'hooks/UseSubscriptionUserContractFollowUp';

interface Props {
  currentModuleServiceContracts: PurchaserServiceContract[] | undefined;
  currentUserServiceContract: PurchaserServiceContract | undefined;
  currentHorseFullServiceContract: PurchaserServiceContract | undefined;
  currentHorseCareServiceContract: PurchaserServiceContract | undefined;
  // this is a list of all available module plans that are not yet ordered
  availableModulePlans: Plan[] | undefined;
  products: CatalogueProduct[] | undefined;
  plans: Plan[] | undefined;
  // this is a module/product that is added buy the upp-selling
  addAdditionalModule: CatalogueProduct | undefined;
  onCloseSaveModal: () => void;
  // onSuccess is only called when there are not orders ordered, but only the service contracts are updated
  onSuccess: (hash: SubscriptionState) => void;
  className?: string;
}

function SubscriptionTile({
  currentModuleServiceContracts,
  currentUserServiceContract,
  currentHorseCareServiceContract,
  currentHorseFullServiceContract,
  availableModulePlans,
  products,
  plans,
  addAdditionalModule,
  onCloseSaveModal,
  onSuccess,
  className,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { closeModal: closeModalStartPaid, modalIsVisible: modalIsVisibleStartPaid, showModal: showModalStartPaid } = useModal();
  const { closeModal: closeModalStartFree, modalIsVisible: modalIsVisibleStartFree, showModal: showModalStartFree } = useModal();
  const { closeModal: closeModalSavePlan, modalIsVisible: modalIsVisibleSavePlan, showModal: showModalSavePlan } = useModal();
  const { parseAndFormatMoney, formatDate } = useAccount();
  const { isInTrial, trialIsExpired } = useTrialExpired();

  // in case the user contact is ending, there should be a follow up contract
  const { followUpUserServiceContract } = useSubscriptionUserContractFollowUp({ currentUserServiceContract });

  /**
   * Make sure we have added the product to the plan and filter out the ones that are not
   * This way know for sure this modules can be ordered
   */
  const filteredAvailableModulePlans = availableModulePlans
    ?.map(plan => {
      const product = products?.find(product => product.plan === plan.uid && Number(product.price) > 0);
      return {
        plan,
        product,
      };
    })
    .filter((planAndProduct): planAndProduct is { plan: Plan; product: CatalogueProduct } => planAndProduct.product !== undefined);

  /**
   * Determine the days left in the trial
   */
  const trialDaysLeft = useMemo(() => {
    if (!currentHorseFullServiceContract?.end_dt) return 0;
    const endDt = new Date(currentHorseFullServiceContract.end_dt);
    const now = new Date();
    return differenceInDays(endDt, now) + 1;
  }, [currentHorseFullServiceContract?.end_dt]);

  /**
   * Get the currency by fetching it from the first service contract
   * As we assume we are not mixing currencies in the same subscription
   */
  const currency = useMemo(() => {
    return currentUserServiceContract?.currency ?? 'eur';
  }, [currentUserServiceContract?.currency]);

  /**
   * sumup the horse counts
   */
  const horsesSummary = useMemo(() => {
    return calculateHorsePrices(currentHorseCareServiceContract, currentHorseFullServiceContract);
  }, [currentHorseCareServiceContract, currentHorseFullServiceContract]);

  /**
   * Return the horse services extend automatically and the end date
   */
  const horseServicesExtendAutomaticallyAndEndDt = useMemo(() => {
    return horseServicesFormatExtendAutomaticallyAndEndDt(currentHorseCareServiceContract, currentHorseFullServiceContract);
  }, [currentHorseCareServiceContract, currentHorseFullServiceContract]);

  /**
   * Calculate the total price for the current module service contracts
   */
  const currentModuleServiceContractsTotal = useMemo(() => {
    if (!currentModuleServiceContracts) {
      return {
        total: 0,
        vat: 0,
      };
    }

    return currentModuleServiceContracts.reduce(
      (total, current) => {
        return {
          total: total.total + Number(current.fixed_price),
          vat: total.vat + Number(current.total_vat),
        };
      },
      {
        total: 0,
        vat: 0,
      },
    );
  }, [currentModuleServiceContracts]);

  /**
   * Calculate the total price and tax of the current service contracts
   */
  const currentServiceContractsTotal = useMemo(() => {
    return {
      total:
        Number(currentUserServiceContract?.fixed_price ?? 0) +
        horsesSummary.full.price +
        horsesSummary.care.price +
        currentModuleServiceContractsTotal.total,
      vat:
        Number(currentUserServiceContract?.total_vat ?? 0) +
        horsesSummary.full.vat +
        horsesSummary.care.vat +
        currentModuleServiceContractsTotal.vat,
    };
  }, [currentModuleServiceContractsTotal.total, currentModuleServiceContractsTotal.vat, currentUserServiceContract, horsesSummary]);

  /**
   * Return the actions for the tile
   */
  const actions = useMemo((): PageAction[] => {
    if (isInTrial) return [];

    return [
      {
        icon: <Pencil />,
        text: t('update', 'Update'),
        onClick: showModalSavePlan,
      },
    ];
  }, [isInTrial, showModalSavePlan, t]);

  /**
   * Find the end date for the service contract
   */
  const endDateContract = useMemo(() => {
    return getEndDateSubscription(
      currentHorseCareServiceContract,
      currentHorseFullServiceContract,
      currentModuleServiceContracts,
      currentUserServiceContract,
      isInTrial,
    );
  }, [
    currentHorseCareServiceContract,
    currentHorseFullServiceContract,
    currentModuleServiceContracts,
    currentUserServiceContract,
    isInTrial,
  ]);

  /**
   * Return if we are on a free subscription
   */
  const isFreeSubscription =
    currentHorseCareServiceContract && !currentHorseFullServiceContract && currentServiceContractsTotal.total === 0;

  /**
   * Open the save plan modal when the addAdditionalModule is set
   */
  useEffect(() => {
    if (addAdditionalModule) {
      showModalSavePlan();
    }
  }, [addAdditionalModule, showModalSavePlan]);

  return (
    <Tile className={className} title={t('current-subscription', 'Current subscription')} actions={actions}>
      {isInTrial && !trialIsExpired && (
        <Alert
          className='mb-10'
          severity={Severity.Info}
          message={
            <Trans
              i18nKey='subscription-is-in-trial'
              defaults='Your subscription is currently in trial with all features. You have <strong>{{daysLeft}}</strong> days left to test all features.'
              values={{ daysLeft: trialDaysLeft }}
            />
          }
        />
      )}

      {isInTrial && trialIsExpired && (
        <>
          <Alert
            className='mb-2'
            severity={Severity.Warning}
            message={t(
              'trial-ended-alert',
              'You free trial has ended. Please choose whether you wish to continue with the free license or a paid subscription.',
            )}
          />
          <div className='mb-10 flex flex-col items-center'>
            <div className='flex gap-x-3 mt-2'>
              <Button compress={true} onClick={showModalStartFree}>
                {t('continue-for-free', 'Continue for free')}
              </Button>
              <Button compress={true} onClick={showModalStartPaid} variant={ButtonVariant.Primary}>
                {t('continue-paid', 'Continue paid')}
              </Button>
            </div>
          </div>
        </>
      )}

      <ul className='list-inside divide-y divide-gray-100'>
        {currentUserServiceContract && (
          <li className='py-2'>
            <div className='flex gap-x-2 items-center'>
              <p className='flex gap-x-1 items-baseline'>
                {t('users', 'Users')}
                <Badge color={AllColors.Blue}>{translatedMaxUnitCount(t, currentUserServiceContract.maximum_unit_count)}</Badge>
              </p>
              {!isInTrial && currentUserServiceContract.followup_contract_uid !== null && (
                <div className='flex items-center gap-x-1'>
                  <Badge size={BadgeSize.Small} color={AllColors.Orange} className='flex gap-x-1 items-center'>
                    <WarningCircle /> {'ends on '} {formatDate(new Date(String(currentUserServiceContract.end_dt)))}
                  </Badge>
                </div>
              )}
              <span className='ml-auto'>
                {isInTrial
                  ? t('in-trial', 'In trial')
                  : translateFreePrice(currentUserServiceContract.fixed_price ?? '0', currency, parseAndFormatMoney, t)}
                {!isInTrial && currentUserServiceContract && currentUserServiceContract.extend_automatically === false && (
                  <span>{'*'}</span>
                )}
              </span>
            </div>
            {!isInTrial && currentUserServiceContract.followup_contract_uid !== null && followUpUserServiceContract && (
              <p className='mt-1 text-xs italic'>
                {t(
                  'user-contract-follow-up',
                  'On {{date}} your new user contract will be active. This new contract contains {{numberUsers}} users.',
                  {
                    date: formatDate(new Date(String(followUpUserServiceContract.end_dt))),
                    numberUsers: translatedMaxUnitCount(t, followUpUserServiceContract.maximum_unit_count).toLowerCase(),
                  },
                )}
              </p>
            )}
          </li>
        )}

        <li className='py-2'>
          <div className='flex gap-x-2 items-center '>
            {t('horses', 'Horses')}
            {!isInTrial &&
              horseServicesExtendAutomaticallyAndEndDt &&
              horseServicesExtendAutomaticallyAndEndDt.extendAutomatically === false &&
              horseServicesExtendAutomaticallyAndEndDt.endDt && (
                <div className='flex items-center gap-x-1'>
                  <Badge size={BadgeSize.Small} color={AllColors.Orange} className='flex gap-x-1 items-center'>
                    <WarningCircle /> {'ends on '} {formatDate(new Date(horseServicesExtendAutomaticallyAndEndDt.endDt))}
                  </Badge>
                </div>
              )}
            <span className='ml-auto'>
              {isInTrial
                ? t('in-trial', 'In trial')
                : !currentHorseCareServiceContract || !currentHorseFullServiceContract
                  ? ''
                  : parseAndFormatMoney(String(horsesSummary.full.price + horsesSummary.care.price), currency)}
              {!isInTrial &&
                horseServicesExtendAutomaticallyAndEndDt &&
                horseServicesExtendAutomaticallyAndEndDt.extendAutomatically === false && <span>{'*'}</span>}
            </span>
          </div>

          <ul className='mt-2 list-inside space-y-1 text-sm'>
            {currentHorseCareServiceContract && (
              <li className='flex gap-x-2 items-center text-sm pl-2 py-1'>
                <div className='flex gap-x-2 items-center w-full'>
                  <span>
                    - {horsesSummary.care.count}
                    {'x'}
                  </span>
                  <CareBadge size={BadgeSize.Normal} />
                  <span className='ml-auto font-light'>
                    {isInTrial
                      ? t('in-trial', 'In trial')
                      : !currentHorseCareServiceContract
                        ? '-'
                        : parseAndFormatMoney(String(horsesSummary.care.price), currency)}
                  </span>
                </div>
              </li>
            )}

            {currentHorseFullServiceContract && (
              <li className='flex gap-x-2 items-center text-sm pl-2 py-1'>
                <div className='flex gap-x-2 items-start w-full'>
                  <span>
                    - {horsesSummary.full.count}
                    {'x'}
                  </span>
                  <div>
                    <div className='flex gap-x-1 items-center'>
                      <SportBadge size={BadgeSize.Normal} /> {t('and-or', 'and/or')} <BreedingBadge size={BadgeSize.Normal} />{' '}
                    </div>
                    <p className='mt-1 text-xs text-gray-500'>
                      {t(
                        'subscription-usage-care-sport-breeding-desc',
                        'Care planning is included when sport and/or breeding planning is used',
                      )}
                    </p>
                  </div>
                  <span className='ml-auto font-light'>
                    {isInTrial ? t('in-trial', 'In trial') : parseAndFormatMoney(String(horsesSummary.full.price), currency)}
                  </span>
                </div>
              </li>
            )}

            {(!currentHorseFullServiceContract || !currentHorseCareServiceContract) && (
              <li className='flex gap-x-2 items-center text-sm pl-2 py-1'>
                -
                <div className='flex gap-x-2 items-center w-full italic text-gray-500'>
                  <span>{t('activity-planning', 'Activity planning')}</span>
                  <p className='ml-auto font-light'>{t('not-chosen', 'Not chosen')}</p>
                </div>
              </li>
            )}
          </ul>
        </li>

        <li className='py-2'>
          <div className='flex gap-x-2 items-center '>
            {'Additional modules'}{' '}
            <span className='ml-auto'>
              {isInTrial
                ? t('in-trial', 'In trial')
                : currentModuleServiceContractsTotal.total === 0
                  ? ''
                  : parseAndFormatMoney(String(currentModuleServiceContractsTotal.total), currency)}
            </span>
          </div>

          <ul className='mt-2 list-inside space-y-1 text-sm'>
            {currentModuleServiceContracts &&
              currentModuleServiceContracts.map(contract => (
                <li key={contract.uid} className='flex gap-x-2 items-center text-sm pl-2 py-1'>
                  -
                  <div className='flex gap-x-2 items-center w-full'>
                    <div className='flex gap-x-1'>
                      {getModuleName(plans, contract.module, t)}

                      {!isInTrial && contract.end_dt !== null && contract.extend_automatically === false && (
                        <div className='flex items-center gap-x-1'>
                          <Badge size={BadgeSize.Small} color={AllColors.Orange} className='flex gap-x-1 items-center'>
                            <WarningCircle /> {'ends on '} {formatDate(new Date(contract.end_dt))}
                          </Badge>
                        </div>
                      )}
                    </div>
                    <p className='ml-auto font-light'>
                      {isInTrial
                        ? t('in-trial', 'In trial')
                        : translateFreePrice(contract.fixed_price ?? '0', currency, parseAndFormatMoney, t)}
                      {!isInTrial && contract.extend_automatically === false && <span>{'*'}</span>}
                    </p>
                  </div>
                </li>
              ))}

            {filteredAvailableModulePlans &&
              filteredAvailableModulePlans.length > 0 &&
              filteredAvailableModulePlans.map(({ plan }) => (
                <li key={plan.uid} className='flex gap-x-2 items-center text-sm pl-2 py-1'>
                  -
                  <div className='flex gap-x-2 items-center w-full italic text-gray-500'>
                    <div className='flex gap-x-1'>{getModuleName(plans, plan.module, t)}</div>
                    <p className='ml-auto font-light'>{t('not-chosen', 'Not chosen')}</p>
                  </div>
                </li>
              ))}
          </ul>
        </li>

        {!isInTrial && (
          <li className='pt-2'>
            <p className='flex gap-x-2 items-center font-bold'>
              {isFreeSubscription ? t('total-monthly', 'Total monthly') : t('total-monthly-excl-vat', 'Total monthly (excl. VAT)')}
              <span className='ml-auto'>
                {isFreeSubscription ? t('free', 'Free') : parseAndFormatMoney(String(currentServiceContractsTotal.total), currency)}
              </span>
            </p>
            {currentServiceContractsTotal.vat > 0 && (
              <p className='flex gap-x-2 items-center font-light text-sm'>
                {t('vat', 'VAT')}
                <span className='ml-auto'>{parseAndFormatMoney(String(currentServiceContractsTotal.vat), currency)}</span>
              </p>
            )}

            {!isInTrial && endDateContract && (
              <p className='flex gap-x-2 items-center font-light text-xs mt-5'>
                {'* '}
                {t('your-contract-pricing-changes-on-date', 'Your contract and pricing will change on {{date}}', {
                  date: formatDate(endDateContract),
                })}
              </p>
            )}
          </li>
        )}
      </ul>

      {isInTrial && !trialIsExpired && (
        <div className='mt-4 pt-4 flex flex-col items-center border-t'>
          <p>{t('license-continue-info', 'Have you already decided whether you want to continue with the paid version of EquineM?')}</p>
          <div className='flex gap-x-3 mt-2'>
            <Button compress={true} onClick={showModalStartFree}>
              {t('continue-for-free', 'Continue for free')}
            </Button>
            <Button compress={true} onClick={showModalStartPaid} variant={ButtonVariant.Primary}>
              {t('continue-paid', 'Continue paid')}
            </Button>
          </div>
        </div>
      )}

      <SaveSubscriptionModal
        isVisible={modalIsVisibleSavePlan}
        onRequestCloseModal={() => {
          closeModalSavePlan();
          onCloseSaveModal();
        }}
        onSuccess={onSuccess}
        products={products}
        plans={plans}
        currentUserServiceContract={currentUserServiceContract}
        currentModuleServiceContracts={currentModuleServiceContracts}
        currentHorseCareServiceContract={currentHorseCareServiceContract}
        currentHorseFullServiceContract={currentHorseFullServiceContract}
        addAdditionalModule={addAdditionalModule}
      />

      <StartPaidSubscriptionModal
        isVisible={modalIsVisibleStartPaid}
        onRequestCloseModal={closeModalStartPaid}
        onSuccess={onSuccess}
        currentHorseCareServiceContract={currentHorseCareServiceContract}
        currentHorseFullServiceContract={currentHorseFullServiceContract}
        plans={plans}
        products={products}
      />

      <StartFreeSubscriptionModal isVisible={modalIsVisibleStartFree} onRequestCloseModal={closeModalStartFree} onSuccess={onSuccess} />
    </Tile>
  );
}

export default SubscriptionTile;
