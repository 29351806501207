import { CalendarCluster, DragOver } from 'context/Calendar';
import React, { useEffect, useRef, useState } from 'react';
import useCalendarRow from './UseCalendarRow';
import classNames from 'classnames';
import { DragDropType, GroupBy, TimeScale } from 'utilities/Planning';
import { usePlanning } from 'hooks/UsePlanning';
import { DayGrid } from '../DayGrid';
import { ActivityContainerTimeScale } from '../ActivityContainerTimeScale';
import { DayNowIndicator } from '../DayNowIndicator';
import ActivityContainerDayParts from '../ActivityContainerDayParts';

interface Props {
  calendarRow: CalendarCluster;
  hideColumnHeaderLabel: boolean;
  spacious: boolean;
  showTopBorder: boolean;
  now: Date;

  // When dropping an item, will you be able to specify date/time or only the 'group by target'.
  dragDropType: DragDropType;

  clusterIndex: number;

  // On activity save from blue print modal
  onSaved?: () => Promise<void>;

  dragOver: DragOver | undefined;
  setDragOver: (dragOver: DragOver | undefined) => void;
}

export function Row({
  calendarRow,
  hideColumnHeaderLabel,
  spacious,
  showTopBorder,
  now,
  dragDropType,
  clusterIndex,
  dragOver,
  setDragOver,
  onSaved,
}: Props): JSX.Element {
  const { dragActivity, moveActivityToContact } = usePlanning();
  const { calendarRowDaySections, groupedByTimeScale } = useCalendarRow({ spacious });
  const sections = calendarRowDaySections(calendarRow);
  // All heights together.
  const visibleHeights = sections.heights?.slice(sections.visibleRange?.start ?? 0, sections.visibleRange?.end ?? sections.heights.length);
  const fullHeight = visibleHeights.reduce((partialSum, height) => partialSum + height, 0);

  const rowRef = useRef(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  // Register Intersection observer to paint (or not) the row only when it's inside the view.
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        setIsVisible(entries[0].isIntersecting);
      },
      { root: null, rootMargin: '0px', threshold: 0 },
    );
    let ref: Element | undefined;
    if (rowRef.current) {
      observer.observe(rowRef.current);
      ref = rowRef.current;
    }
    return () => {
      if (ref) {
        observer.unobserve(ref);
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div
      ref={rowRef}
      style={{
        height: fullHeight,
      }}
      className={classNames('w-full flex flex-row relative', {
        'border-t': showTopBorder,
        'mt-8 md:mt-0': !hideColumnHeaderLabel,
      })}
      onDragOver={event => {
        if (
          dragDropType === DragDropType.Cluster ||
          (dragDropType === DragDropType.ClusterPlus && dragActivity && dragActivity.originGroupByUid !== calendarRow.groupBy)
        ) {
          setDragOver({ index: clusterIndex });
          event.preventDefault();
        }
      }}
      onDragLeave={() => setDragOver(undefined)}
      onDrop={event => {
        event.preventDefault();
        setDragOver(undefined);
        const uid = dragActivity?.activity.uid;
        if (!uid) {
          console.error('Failed to get uid from drag event');
          return;
        }
        if (dragOver) {
          moveActivityToContact(
            uid,
            dragActivity.originGroupByUid?.subject?.uid,
            calendarRow.groupBy.subject?.uid,
            undefined,
            undefined,
            undefined,
          ).catch(e => {
            // TODO: Inform the user (we might need to do this via the usePlanning hook)
            console.error(e);
          });
        }
      }}
    >
      {!isVisible && (
        <>
          {calendarRow.columns.map(calendarDay => (
            <div className='grow h-full border-r' key={calendarDay.day.valueOf() + 'placeholder'} />
          ))}
        </>
      )}

      {isVisible && (
        <>
          {calendarRow.columns.map(calendarDay => (
            <DayGrid
              className={'grow h-full border-r'}
              key={calendarDay.day.valueOf()}
              heights={sections.heights}
              visibleRange={sections.visibleRange}
            >
              {groupedByTimeScale(calendarRow.groupBy) === TimeScale.TimeScale && (
                <>
                  <ActivityContainerTimeScale
                    dragDropType={dragDropType}
                    heights={sections.heights}
                    visibleRange={sections.visibleRange}
                    plannableRange={sections.plannableRange}
                    day={calendarDay.day}
                    className='absolute inset-0'
                    activities={calendarDay.activities}
                    appliedGroupBy={calendarRow.groupBy}
                    showContactAvatar={calendarRow.groupBy.groupBy === GroupBy.Horse || calendarRow.groupBy.groupBy === GroupBy.Stable}
                    showHorseName={
                      calendarRow.groupBy.groupBy === GroupBy.StaffCatchAll ||
                      calendarRow.groupBy.groupBy === GroupBy.Staff ||
                      calendarRow.groupBy.groupBy === GroupBy.Stable
                    }
                    blockList={calendarDay.blockList}
                    onSaved={onSaved}
                  />
                  <DayNowIndicator
                    heights={sections.heights}
                    visibleRange={sections.visibleRange}
                    day={calendarDay.day}
                    now={now}
                    className='absolute inset-0 pointer-events-none'
                  />
                </>
              )}
              {groupedByTimeScale(calendarRow.groupBy) !== TimeScale.TimeScale && (
                <ActivityContainerDayParts
                  spacious={spacious}
                  dragDropType={dragDropType}
                  day={calendarDay.day}
                  className='absolute inset-0'
                  heights={sections.heights}
                  activities={calendarDay.activities}
                  appliedGroupBy={calendarRow.groupBy}
                  showContactAvatar={calendarRow.groupBy.groupBy === GroupBy.Horse || calendarRow.groupBy.groupBy === GroupBy.Stable}
                  showHorseName={
                    calendarRow.groupBy.groupBy === GroupBy.StaffCatchAll ||
                    calendarRow.groupBy.groupBy === GroupBy.Staff ||
                    calendarRow.groupBy.groupBy === GroupBy.Stable
                  }
                  onSaved={onSaved}
                />
              )}
            </DayGrid>
          ))}
          {dragOver && dragOver.index === clusterIndex && (
            <div className='absolute inset-0 bg-blue-500 bg-opacity-10 rounded-lg border-blue-500 border-2 pointer-events-none' />
          )}
        </>
      )}
    </div>
  );
}
