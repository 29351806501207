import { useAccount } from 'context/AccountContext';
import { CatalogueProduct, Plan, PricingModelEnum } from 'openapi';
import React, { useEffect, useState } from 'react';
import LicenseTile from './Tile';
import { getModuleIcon } from './Helper';
import { BadgeProps } from 'ui/Badge/Badge';
import { useTranslation } from 'react-i18next';

interface Props {
  plans: Plan[];
  products: CatalogueProduct[];
  selectedProducts?: CatalogueProduct[];
  onClick: (product: CatalogueProduct[]) => void;
  saveStates?: boolean;
  badgeProps?: (isSelected: boolean, product: CatalogueProduct) => BadgeProps | undefined;
}

function PlanFlatFee({
  plans,
  selectedProducts: givenSelectedProducts,
  products,
  onClick,
  saveStates = true,
  badgeProps,
}: Props): JSX.Element {
  const [selectedProducts, setSelectedProducts] = useState<CatalogueProduct[]>();

  const { parseAndFormatMoney } = useAccount();
  const { t } = useTranslation();

  useEffect(() => {
    if (givenSelectedProducts) {
      setSelectedProducts(givenSelectedProducts);
    }
  }, [givenSelectedProducts]);

  /**
   * Make sure we have added the product to the plan and filter out the ones that are not
   */
  const filteredPlanWithProducts = plans
    // we only need the flat_fee products here as we list only flat_fee product with this component
    .filter(plan => plan.pricing_model === PricingModelEnum.FLAT_FEE)
    .map(plan => {
      const product = products.find(product => product.plan === plan.uid);
      return {
        plan,
        product,
      };
    })
    .filter((planAndProduct): planAndProduct is { plan: Plan; product: CatalogueProduct } => planAndProduct.product !== undefined);

  return (
    <div className='flex flex-wrap mt-5 gap-5'>
      {filteredPlanWithProducts.map(({ plan, product }) => {
        const isSelected = selectedProducts?.find(p => p.uid === product.uid) !== undefined;

        return (
          <LicenseTile
            onClick={() => {
              let newState = [...(selectedProducts || [])];

              const find = newState.find(p => p.uid === product.uid);
              if (find) {
                newState = newState.filter(p => p.uid !== product.uid);
              } else {
                newState.push(product);
              }

              if (saveStates) {
                setSelectedProducts(newState);
              }

              onClick(newState);
            }}
            key={plan.uid}
            icon={getModuleIcon(plan.module)}
            name={plan.name}
            isSelected={isSelected}
            price={Number(product.price) > 0 ? parseAndFormatMoney(product.price, product.price_currency) : t('free', 'Free')}
            showCheckbox={saveStates}
            badgeProps={() => badgeProps?.(isSelected, product)}
            type='checkbox'
          />
        );
      })}
    </div>
  );
}

export default PlanFlatFee;
