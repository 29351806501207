/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Feeding } from '../models/Feeding';
import type { PatchedFeeding } from '../models/PatchedFeeding';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FeedingsService {
  /**
   * @returns Feeding
   * @throws ApiError
   */
  public static feedingsList({
    organisationUid,
    o,
    uidIn,
  }: {
    organisationUid: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * Multiple values may be separated by commas.
     */
    uidIn?: Array<string>,
  }): CancelablePromise<Array<Feeding>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/feedings',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'o': o,
        'uid__in': uidIn,
      },
    });
  }
  /**
   * @returns Feeding
   * @throws ApiError
   */
  public static feedingsCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: Feeding,
  }): CancelablePromise<Feeding> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/feedings',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Feeding
   * @throws ApiError
   */
  public static feedingsRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<Feeding> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/feedings/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns Feeding
   * @throws ApiError
   */
  public static feedingsUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: Feeding,
  }): CancelablePromise<Feeding> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/feedings/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Feeding
   * @throws ApiError
   */
  public static feedingsPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedFeeding,
  }): CancelablePromise<Feeding> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/feedings/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static feedingsDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/feedings/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
}
