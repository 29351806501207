import React, { useMemo, useState } from 'react';
import { ActivityHeight, ActivityHeightSpacious } from '../DayGrid';
import SlidableCalendarView from '../SlidableCalendarView';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import { CalendarView, DragDropType } from 'utilities/Planning';
import Calendar from 'context/Calendar';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import { colHeaderWidthMobile } from '.';
import ColumnHeader from './ColumnHeader';
import { Row } from './Row';

export interface BodyProps {
  // The reserved width of the column header.
  columnHeaderWidth: number;

  // The calendar data.
  calendarView: CalendarView;

  // When dropping an item, will you be able to specify date/time or only the 'group by target'.
  dragDropType: DragDropType;

  // Set to true when we don't want to display the groupBy name. Like the horse name, contact name, etc.
  hideColumnHeaderLabel?: boolean;

  // Give the activity items a bit more height and spacing.
  spacious?: boolean;

  showTopBorder?: boolean;

  enableClickToExpand?: boolean;

  // On activity save from blue print modal
  onSaved?: () => Promise<void>;
}

/**
 * A multi day calendar view.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * The view uses a SlidableCalendarView for extra smoothness.
 *
 *        ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 *        │  12  │  13  │  14  │  15  │  16  │  17  │  18  │
 *        │      │      │      │      │      │      │      │
 * ┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * └──────└──────└──────└──────└──────└──────└──────└──────┘
 */
export function MultiDayBody({
  columnHeaderWidth: givenColumnHeaderWidth,
  calendarView,
  hideColumnHeaderLabel = false,
  dragDropType,
  spacious = false,
  showTopBorder = false,
  enableClickToExpand = true,
  onSaved,
}: BodyProps): JSX.Element {
  const { now } = useRefreshingNow();
  const { width } = useScreenSize();

  const columnHeaderWidth = width <= ScreenSize.md ? colHeaderWidthMobile : givenColumnHeaderWidth;

  // Height of one activity based on the spacious flag.
  const activityHeight = useMemo(() => {
    return spacious ? ActivityHeightSpacious : ActivityHeight;
  }, [spacious]);

  interface DragOver {
    index: number; // What cluster index are we dragging over.
  }

  const [dragOver, setDragOver] = useState<DragOver | undefined>();

  return (
    <div className='flex select-none'>
      <div style={{ width: columnHeaderWidth }} className='shrink-0 flex flex-col items-end select-none'>
        {calendarView.current.clusters.map(calendarRow => (
          <ColumnHeader
            key={calendarRow.id}
            activityHeight={activityHeight}
            calendarRow={calendarRow}
            columnHeaderWidth={columnHeaderWidth}
            enableClickToExpand={enableClickToExpand}
            hideColumnHeaderLabel={hideColumnHeaderLabel}
            isMobileView={width <= ScreenSize.md}
            now={now}
            showTopBorder={showTopBorder}
            spacious={spacious}
          />
        ))}
      </div>
      <SlidableCalendarView
        calendarView={calendarView}
        className='grow'
        render={(calendar: Calendar) => {
          return (
            <div className='w-full'>
              {calendar.clusters.map((calendarRow, clusterIndex) => {
                return (
                  <Row
                    key={calendarRow.id}
                    calendarRow={calendarRow}
                    clusterIndex={clusterIndex}
                    dragDropType={dragDropType}
                    dragOver={dragOver}
                    now={now}
                    hideColumnHeaderLabel={hideColumnHeaderLabel}
                    setDragOver={setDragOver}
                    showTopBorder={showTopBorder}
                    spacious={spacious}
                    onSaved={onSaved}
                  />
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
}
