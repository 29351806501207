import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { EquineMHorseSearchDetail, EquineMSearchLastPage } from 'openapi';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import Button, { ButtonVariant } from 'ui/Button';
import Skeleton from 'ui/Layout/Skeleton';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import PageModal, { PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { fillArray } from 'utilities/FillArray';
import { bloodlineString } from 'utilities/Horse';

interface Props {
  loading: boolean | undefined;
  isVisible: boolean;
  searchHorses: EquineMSearchLastPage | undefined;
  pageNr: number;
  onSelect: (horse: EquineMHorseSearchDetail) => void;
  onNextPage: () => void;
  onPrevPage: () => void;
  onRequestClose: () => void;
}

/**
 * Component that show the result of the searched horses from the external DB in an modal.
 */
export default function SearchResultSearchedHorsesModal({
  isVisible,
  searchHorses,
  pageNr,
  loading,
  onSelect,
  onNextPage,
  onPrevPage,
  onRequestClose,
}: Props): JSX.Element {
  const { t } = useTranslation();

  // modal Ref
  const ref = useRef<HTMLDivElement>(null);

  const horses = useMemo((): EquineMHorseSearchDetail[] => {
    return searchHorses?.items ?? [];
  }, [searchHorses]);

  /**
   * Check if the next button should be disabled
   */
  const nextDisabled = useMemo(() => {
    if (!searchHorses) return true;
    return searchHorses.is_last_page === true;
  }, [searchHorses]);

  /**
   * Select a horse and close the modal
   */
  const select = (horse: EquineMHorseSearchDetail) => {
    onSelect(horse);
    onRequestClose();
  };

  /**
   * This is a small fix for a modal in a modal. It seems that we are in a scroll-lock and therefor the scroll will be set to the bottom
   * This effect is fixing this, by using the ref of the modalContent
   * The better fix is updating headless UI to 2.x --> https://gitlab.qubis.nl/equinem/equiapp/-/issues/577
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref.current && horses) {
        ref.current.scrollTo(0, 0);
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, [horses, isVisible]);

  return (
    <PageModal open={isVisible} width={PageModalWidth.Sm}>
      <PageModalTitle title={t('searc-result-from-horsetelex-etc', 'Search result from HorseTelex etc')} onClose={onRequestClose} />
      <PageModalContent ref={ref}>
        {loading && (
          <div className='flex items-center pt-3 gap-x-2'>
            <Spinner size={SpinnerSize.XSmall} /> <p>{t('loading-data', 'Loading data')}...</p>
          </div>
        )}

        {loading && (
          <>
            <div className='space-y-2 mt-2 mb-4'>
              {fillArray(10).map(index => (
                <div key={index} className='border rounded-md'>
                  <Skeleton className='h-[2.35rem] w-full' />
                </div>
              ))}
            </div>
          </>
        )}
        {!loading && horses.length > 0 && (
          <>
            <div className='space-y-2 mb-5'>
              {horses.map(horse => (
                <div
                  key={`${horse.name}-${horse.UELN}-${horse.id_horsetelex}-${horse.source_db}`}
                  className='border px-3 py-1 rounded-md flex hover:bg-gray-100 cursor-pointer'
                  onClick={() => select(horse)}
                >
                  <div className='w-full flex items-center justify-between'>
                    <div>
                      <p>{horse.name}</p>
                      {horse.UELN && (
                        <p className='text-xs text-gray-500'>
                          {t('UELN', 'UELN')}
                          {': '}
                          {horse.UELN}
                        </p>
                      )}
                      {bloodlineString(horse) && <p className='text-xs text-gray-500'>{bloodlineString(horse)}</p>}
                    </div>
                    <Badge size={BadgeSize.Small}>{horse.source_db.toString()}</Badge>
                  </div>
                </div>
              ))}
            </div>

            <div className='space-y-4'>
              <div className='flex gap-x-2 justify-between items-center'>
                <Button
                  type='button'
                  variant={ButtonVariant.Default}
                  disabled={pageNr === 1 || loading}
                  onClick={onPrevPage}
                  icon={<CaretLeft />}
                >
                  {t('previous', 'Previous')}
                </Button>
                <p className='hidden sm:block text-sm text-gray-500'>
                  {t('pedigree-page-n', 'Page {{currentPage}}', {
                    currentPage: pageNr,
                  })}
                </p>
                <Button
                  type='button'
                  variant={ButtonVariant.Default}
                  disabled={nextDisabled || loading}
                  onClick={onNextPage}
                  icon={<CaretRight />}
                  iconReverse={true}
                >
                  {t('next', 'Next')}
                </Button>
              </div>
              <p className='sm:hidden text-sm text-gray-500'>
                {t('pedigree-page-n', 'Page {{currentPage}}', {
                  currentPage: pageNr,
                })}
              </p>
            </div>
          </>
        )}
      </PageModalContent>
    </PageModal>
  );
}
