import { TFunction } from 'i18next';
import { AvailableWeekdaysEnum } from 'openapi';
import { ByWeekday, Frequency, RRule } from 'rrule';

export const frequencyToStringSingle = (t: TFunction, frequency: Frequency): string => {
  switch (frequency) {
    case Frequency.DAILY:
      return t('rrule-freq-day', 'Day');
    case Frequency.WEEKLY:
      return t('rrule-freq-week', 'Week');
    case Frequency.MONTHLY:
      return t('rrule-freq-month', 'Month');
    case Frequency.YEARLY:
      return t('rrule-freq-year', 'Year');
    case Frequency.HOURLY:
      return t('rrule-freq-hourly', 'Hour');
    case Frequency.MINUTELY:
      return t('rrule-freq-minutely', 'Minute');
    case Frequency.SECONDLY:
      return t('rrule-freq-secondly', 'Second');
  }
};

export const frequencyToStringPlural = (t: TFunction, frequency: Frequency): string => {
  switch (frequency) {
    case Frequency.DAILY:
      return t('rrule-freq-day-plural', 'Days');
    case Frequency.WEEKLY:
      return t('rrule-freq-week-plural', 'Weeks');
    case Frequency.MONTHLY:
      return t('rrule-freq-month-plural', 'Months');
    case Frequency.YEARLY:
      return t('rrule-freq-year-plural', 'Years');
    case Frequency.HOURLY:
      return t('rrule-freq-hourly-plural', 'Hours');
    case Frequency.MINUTELY:
      return t('rrule-freq-minutely-plural', 'Minutes');
    case Frequency.SECONDLY:
      return t('rrule-freq-secondly-plural', 'Seconds');
  }
};

// Helper for weekdays. We have 0 as sunday because js Date also does it this way.
export enum WeekDay {
  Mo = 1,
  Tu = 2,
  We = 3,
  Th = 4,
  Fr = 5,
  Sa = 6,
  Su = 0,
}

export const weekdaysToByWeekDay = (weekDays: WeekDay[]): ByWeekday[] => {
  const byweekday: ByWeekday[] = [];
  if (weekDays.includes(WeekDay.Mo)) {
    byweekday.push(RRule.MO);
  }
  if (weekDays.includes(WeekDay.Tu)) {
    byweekday.push(RRule.TU);
  }
  if (weekDays.includes(WeekDay.We)) {
    byweekday.push(RRule.WE);
  }
  if (weekDays.includes(WeekDay.Th)) {
    byweekday.push(RRule.TH);
  }
  if (weekDays.includes(WeekDay.Fr)) {
    byweekday.push(RRule.FR);
  }
  if (weekDays.includes(WeekDay.Sa)) {
    byweekday.push(RRule.SA);
  }
  if (weekDays.includes(WeekDay.Su)) {
    byweekday.push(RRule.SU);
  }
  return byweekday;
};

export const weekDaysToAvailableWeekdaysEnum = (weekDays: WeekDay[]): AvailableWeekdaysEnum[] => {
  const result: AvailableWeekdaysEnum[] = [];
  if (weekDays.includes(WeekDay.Mo)) {
    result.push(AvailableWeekdaysEnum.MONDAY);
  }
  if (weekDays.includes(WeekDay.Tu)) {
    result.push(AvailableWeekdaysEnum.TUESDAY);
  }
  if (weekDays.includes(WeekDay.We)) {
    result.push(AvailableWeekdaysEnum.WEDNESDAY);
  }
  if (weekDays.includes(WeekDay.Th)) {
    result.push(AvailableWeekdaysEnum.THURSDAY);
  }
  if (weekDays.includes(WeekDay.Fr)) {
    result.push(AvailableWeekdaysEnum.FRIDAY);
  }
  if (weekDays.includes(WeekDay.Sa)) {
    result.push(AvailableWeekdaysEnum.SATURDAY);
  }
  if (weekDays.includes(WeekDay.Su)) {
    result.push(AvailableWeekdaysEnum.SUNDAY);
  }
  return result;
};

export const availableWeekdaysEnumToWeekDays = (availableWeekDaysEnum: AvailableWeekdaysEnum[]): WeekDay[] => {
  const result: WeekDay[] = [];
  if (availableWeekDaysEnum.includes(AvailableWeekdaysEnum.MONDAY)) {
    result.push(WeekDay.Mo);
  }
  if (availableWeekDaysEnum.includes(AvailableWeekdaysEnum.TUESDAY)) {
    result.push(WeekDay.Tu);
  }
  if (availableWeekDaysEnum.includes(AvailableWeekdaysEnum.WEDNESDAY)) {
    result.push(WeekDay.We);
  }
  if (availableWeekDaysEnum.includes(AvailableWeekdaysEnum.THURSDAY)) {
    result.push(WeekDay.Th);
  }
  if (availableWeekDaysEnum.includes(AvailableWeekdaysEnum.FRIDAY)) {
    result.push(WeekDay.Fr);
  }
  if (availableWeekDaysEnum.includes(AvailableWeekdaysEnum.SATURDAY)) {
    result.push(WeekDay.Sa);
  }
  if (availableWeekDaysEnum.includes(AvailableWeekdaysEnum.SUNDAY)) {
    result.push(WeekDay.Su);
  }
  return result;
};
