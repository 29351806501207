import React, { useMemo } from 'react';
import { useOrganization } from 'context/OrganizationContext';
import UseCountries from 'hooks/UseCountries';
import { Tile } from 'ui/Layout/Tile';
import { Trans, useTranslation } from 'react-i18next';
import DescriptionList from 'ui/Layout/DescriptionList';
import { DefinitionItem } from 'ui/Layout/DescriptionList/DescriptionListItem';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
}

/**
 * This component displays the billing details of the organization
 * It also let you update the billing details. This are basically the organization details
 */
function BillingTile({ className }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationDetails, selectedOrganizationPrimaryStableLocation } = useOrganization();
  const { countries } = UseCountries();

  const descriptionListItems = useMemo(() => {
    const list: DefinitionItem[] = [];

    list.push({ term: t('name', 'Name'), definition: selectedOrganizationDetails?.name ?? '-' });
    list.push({ term: t('phone-number', 'Phone number'), definition: selectedOrganizationPrimaryStableLocation?.phone_number ?? '-' });
    list.push({
      term: t('street', 'Street'),
      definition: (
        <span>
          {selectedOrganizationPrimaryStableLocation?.address_line1 || '-'} {selectedOrganizationPrimaryStableLocation?.address_line3}
        </span>
      ),
    });

    if (selectedOrganizationPrimaryStableLocation?.address_line2) {
      list.push({
        term: t('address-line2', 'Address line 2'),
        definition: selectedOrganizationPrimaryStableLocation.address_line2,
      });
    }

    list.push({ term: t('postcode', 'Postcode'), definition: selectedOrganizationPrimaryStableLocation?.postcode ?? '-' });
    list.push({ term: t('city', 'City'), definition: selectedOrganizationPrimaryStableLocation?.city ?? '-' });

    if (selectedOrganizationPrimaryStableLocation?.state) {
      list.push({ term: t('state', 'State'), definition: selectedOrganizationPrimaryStableLocation.state });
    }

    list.push({
      term: t('country', 'Country'),
      definition: selectedOrganizationPrimaryStableLocation?.city
        ? (countries.find(country => country.id === selectedOrganizationPrimaryStableLocation.country)?.name ??
          selectedOrganizationPrimaryStableLocation.country)
        : '-',
    });
    return list;
  }, [
    t,
    selectedOrganizationDetails?.name,
    selectedOrganizationPrimaryStableLocation?.phone_number,
    selectedOrganizationPrimaryStableLocation?.address_line1,
    selectedOrganizationPrimaryStableLocation?.address_line3,
    selectedOrganizationPrimaryStableLocation?.address_line2,
    selectedOrganizationPrimaryStableLocation?.postcode,
    selectedOrganizationPrimaryStableLocation?.city,
    selectedOrganizationPrimaryStableLocation?.state,
    selectedOrganizationPrimaryStableLocation?.country,
    countries,
  ]);

  return (
    <Tile className={className} title='Billing details'>
      <DescriptionList classNameListItem='!py-2' classNameDT='sm:w-1/4' list={descriptionListItems} />

      <p className='mt-5 text-sm text-gray-500'>
        <Trans
          i18nKey='edit-billing-details-desc'
          defaults='The billing details listed above are from the primary stable. You can edit these details <0>here</0>.'
          components={[
            <Link to='/admin/settings' key='link' className='text-blue-500 underline'>
              {t('here', 'here')}
            </Link>,
          ]}
        />
      </p>
    </Tile>
  );
}

export default BillingTile;
