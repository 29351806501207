import classNames from 'classnames';
import { CalendarCluster, GroupByApplied } from 'context/Calendar';
import { Contact, Facility, Horse, Role } from 'openapi';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { facilityTypeToString, GroupBy, groupByAppliedGetName } from 'utilities/Planning';
import React from 'react';
import Badge, { BadgeSize } from 'ui/Badge/Badge';
import { HorseUsageBadges } from 'components/Horses/HorseUsageBadges';

interface ColHeaderProps {
  orientation: 'col' | 'row';
  calendarRow: CalendarCluster;
  fullHeight: number;
  activityHeight: number;
  roles: Role[];
}

/**
 * The column header content for 'group by' view. So it displays like, the horse name or facility name, etc.
 */
export default function ColumnHeaderContent({ orientation, calendarRow, fullHeight, activityHeight, roles }: ColHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const rolesForGroupBy = useCallback(
    (groupByApplied: GroupByApplied) => {
      if (groupByApplied.groupBy !== GroupBy.Staff) {
        return [];
      }
      const contact = groupByApplied.subject as Contact;
      return roles?.filter(role => contact.roles?.includes(role.uid));
    },
    [roles],
  );
  return (
    <div
      className={classNames('text-sm grow h-full w-full break-word overflow-x-hidden no-scrollbar flex', {
        'flex-row px-2 items-center justify-between gap-1 min-w-screen bg-neutral-100': orientation === 'row',
        'flex-col pl-2 pt-1 items-start': orientation === 'col',
      })}
    >
      <p className={classNames('text-base pr-0.5', { grow: orientation === 'row', inline: fullHeight <= activityHeight })}>
        <span>{groupByAppliedGetName(t, calendarRow.groupBy)}</span>
      </p>
      {calendarRow.groupBy.groupBy === GroupBy.Horse && (
        <HorseUsageBadges size={BadgeSize.Small} iconOnly={true} horse={calendarRow.groupBy.subject as Horse} />
      )}
      {calendarRow.groupBy.groupBy === GroupBy.Facility && (
        <Badge size={BadgeSize.Small}>
          {facilityTypeToString(t, (calendarRow.groupBy.subject as Facility).facility_type)} {'('}
          {t('facility-max-short', 'max')} {(calendarRow.groupBy.subject as Facility).slot_capacity ?? '-'}
          {')'}
        </Badge>
      )}
      <div className={classNames('flex gap-1', { 'flex-wrap': orientation === 'col' })}>
        {rolesForGroupBy(calendarRow.groupBy)?.map(role => (
          <span className='ml-[2px] text-xs bg-neutral-200 rounded px-0.5 text-neutral-500 opacity-80' key={role.uid}>
            {role.name}
          </span>
        ))}
      </div>
    </div>
  );
}
