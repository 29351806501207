import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { FeedType, FeedtypesService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextAreaInput, TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { feedTypeCategoryOptions, quantityMeasureOptions, quantityMeasureString } from 'utilities/Feed';
import { transformEmptyToUndefined } from 'utilities/zod';

interface Props {
  visible: boolean;
  closeModal: () => void;
  onClosed: () => void;
  existingFeedType?: FeedType;
  onSaved?: (category: FeedType) => void;
}

export default function SaveFeedTypeModal({ visible, closeModal, onClosed, existingFeedType, onSaved }: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return schemas.FeedType.omit({
      uid: true,
      created_on: true,
      last_modified_on: true,
      hidden: true,
      harvest_date: true,
      wait_time: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm<FeedType>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const watchQuantityMeasure = watch('quantity_measure');

  const closed = () => {
    setApiError(undefined);
    onClosed();
  };

  const onSubmit = async (data: FeedType) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    try {
      let savedFeedType: FeedType | undefined = undefined;
      if (existingFeedType) {
        const promiseUpdate = FeedtypesService.feedtypesPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: existingFeedType.uid,
          requestBody: data,
        });
        savedFeedType = (await promiseUpdate) as FeedType;
      } else {
        const promiseCreate = FeedtypesService.feedtypesCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: data,
        });
        savedFeedType = (await promiseCreate) as FeedType;
      }
      closeModal();
      onSaved?.(savedFeedType);
    } catch (error) {
      setApiError(new ApiErrorParser<FeedType>(error));
    }
  };

  // Reset when the modal visibility is changed or when the existingFeedType is changed.
  useEffect(() => {
    if (existingFeedType) {
      reset(existingFeedType);
    } else {
      reset({});
    }
  }, [reset, existingFeedType, visible]);

  return (
    <PageModal open={visible} width={PageModalWidth.Xs} onClosed={closed}>
      <PageModalTitle
        title={existingFeedType ? t('edit-feed-type-title', 'Edit feed type') : t('new-feed-type-title', 'New feed type')}
        onClose={closeModal}
      />
      <PageModalContent>
        <ErrorSection errors={nonFieldErrors} className='mb-1' />
        <form noValidate={true} id='SaveFeedTypeForm' onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4 grow'>
            <TextInput
              required={true}
              label={t('name', 'Name')}
              {...register('display_name', {
                setValueAs: transformEmptyToUndefined(),
              })}
              error={fieldError('display_name')}
            />
            <TextInput
              required={true}
              label={t('brand', 'Brand')}
              {...register('brand', {
                setValueAs: transformEmptyToUndefined(),
              })}
              error={fieldError('brand')}
            />
            <SelectInput
              required={true}
              nullable={true}
              options={feedTypeCategoryOptions(t)}
              error={fieldError('category')}
              label={t('category', 'Category')}
              nullableValue=''
              {...register('category', { setValueAs: transformEmptyToUndefined() })}
            />
            <SelectInput
              label={t('quantity-measure', 'Quantity measure')}
              nullable={true}
              options={quantityMeasureOptions(t)}
              error={fieldError('quantity_measure')}
              required={true}
              nullableValue=''
              {...register('quantity_measure', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              required={true}
              label={t('default-quantity', 'Default quantity')}
              {...register('default_quantity', {
                setValueAs: transformEmptyToUndefined(),
              })}
              error={fieldError('default_quantity')}
              postText={watchQuantityMeasure ? quantityMeasureString(t, watchQuantityMeasure).toLowerCase() : undefined}
            />
            <TextAreaInput
              label={t('warning', 'Warning')}
              {...register('warning', {
                setValueAs: transformEmptyToUndefined(),
              })}
              error={fieldError('warning')}
            />
          </div>
        </form>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'SaveFeedTypeForm',
          },
        ]}
      />
    </PageModal>
  );
}
