import { CaretUp, Clock, MagnifyingGlass, Minus, Moon, Plus } from '@phosphor-icons/react';
import { BrowserStorageKeys } from 'api/UserSession';
import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { addMinutes, isSameSecond } from 'date-fns';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  menuOpened: boolean;
}

interface PlusMinusButtonsProps {
  text: string;
  value: string;
  icon?: ReactNode;
  onPlus: () => void;
  onMinus: () => void;
}

function PlusMinusButtons({ icon, text, value, onMinus, onPlus }: PlusMinusButtonsProps): JSX.Element {
  return (
    <div className='flex gap-2 items-center py-2 pr-2 pl-4 bg-white bg-opacity-20 rounded-full mx-2'>
      {icon}
      <div className='grow'>
        <p>{text}</p>
        <p className='text-xs opacity-70'>{value}</p>
      </div>
      <div className='border border-white text-white p-3 rounded-full' onClick={onMinus}>
        <Minus />
      </div>
      <div className='border border-white text-white p-3 rounded-full' onClick={onPlus}>
        <Plus />
      </div>
    </div>
  );
}

/**
 * Sidebar menu for equiboard settings. And a simple time clock.
 */
export default function EquiboardSection({ menuOpened }: Props): JSX.Element {
  const { formatTime } = useAccount();
  const { now } = useRefreshingNow({ interval: 10 * 1000 }); // Refresh every 10 seconds.
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const [zoom, setZoom] = useState<number>(1.0);
  const [sleepAfter, setSleepAfter] = useState<number>(10);
  const { selectedOrganizationDetails } = useOrganization();
  const [nextLockScreen, setNextLockScreen] = useState<Date>(addMinutes(new Date(), 10));
  const [showLockScreen, setShowLockScreen] = useState<boolean>(false);

  const sleepAfterText = useMemo(() => {
    if (sleepAfter === 0) {
      return t('never', 'Never');
    }
    return t('sleep-after-minutes', 'After {{minutes}} min', { minutes: sleepAfter });
  }, [sleepAfter, t]);

  const clearLockScreen = useCallback(() => {
    if (sleepAfter) {
      setNextLockScreen(addMinutes(new Date(), sleepAfter));
    }
    setShowLockScreen(false);
  }, [sleepAfter]);

  // Set the initial zoom level and sleep timer
  useEffect(() => {
    let zoomFromStorage = Number(localStorage?.getItem(BrowserStorageKeys.ZoomLevel) ?? 1) ?? 1;
    if (zoomFromStorage < 0.5 || zoomFromStorage > 3) {
      zoomFromStorage = 1;
    }
    setZoom(zoomFromStorage);

    let sleepAfterFromStorage = Number(localStorage?.getItem(BrowserStorageKeys.SleepAfter) ?? 10) ?? 10;
    if (sleepAfterFromStorage < 0) {
      sleepAfterFromStorage = 10;
    }
    setSleepAfter(sleepAfterFromStorage);
    setNextLockScreen(addMinutes(new Date(), sleepAfterFromStorage));
  }, []);

  // Update the document zoom based on the zoom property
  useEffect(() => {
    document.body.style.zoom = zoom.toString();
  }, [zoom]);

  // Listen to the 'now' var that refreshes every 10 sec and show the lock screen if we need to.
  useEffect(() => {
    if (sleepAfter && nextLockScreen < new Date()) {
      setShowLockScreen(true);
    }
  }, [nextLockScreen, sleepAfter, now]);

  // Wake up on touch, click or mousemove.
  useEffect(() => {
    const clearLockWhenLocked = () => {
      if (showLockScreen && !isSameSecond(nextLockScreen, new Date())) {
        clearLockScreen();
      }
    };

    window.addEventListener('touchstart', clearLockWhenLocked);
    window.addEventListener('click', clearLockWhenLocked);
    window.addEventListener('mousemove', clearLockWhenLocked);
    return () => {
      window.removeEventListener('touchstart', clearLockWhenLocked);
      window.removeEventListener('click', clearLockWhenLocked);
      window.removeEventListener('mousemove', clearLockWhenLocked);
    };
  }, [clearLockScreen, showLockScreen, nextLockScreen, sleepAfter]);

  return (
    <>
      {showLockScreen && (
        <div className='z-[9999] bg-black bg-opacity-90 fixed inset-0 flex flex-col items-center justify-center' onClick={clearLockScreen}>
          <p className='text-white text-[10rem] opacity-80'>{formatTime(now)}</p>
          {selectedOrganizationDetails?.logo && (
            <img src={selectedOrganizationDetails.logo} className='w-64 h-32 object-contain object-left-top border-none' />
          )}
        </div>
      )}
      <div className='bg-primary'>
        <div className='text-white text-lg flex items-center justify-between'>
          <div className='flex gap-1 items-center py-4 px-4 grow h-full'>
            {menuOpened && <Clock weight='bold' />}
            {formatTime(now)}
          </div>
          {menuOpened && (
            <>
              <div
                className='flex gap-1 items-center px-4 h-14 border-l border-opacity-10 border-white cursor-pointer'
                onClick={() => {
                  setShowLockScreen(true);
                  setNextLockScreen(new Date());
                }}
              >
                <Moon weight='bold' />
              </div>
              <div
                className='flex gap-1 items-center px-4 h-14 border-l border-opacity-10 border-white cursor-pointer'
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                <CaretUp weight='bold' className={classNames('transition-all duration-400', { 'rotate-180': expanded })} />
              </div>
            </>
          )}
        </div>
        {expanded && menuOpened && (
          <div className='text-white space-y-2 py-4'>
            <PlusMinusButtons
              text={t('zoom', 'Zoom')}
              icon={<MagnifyingGlass />}
              onMinus={() => {
                localStorage?.setItem(BrowserStorageKeys.ZoomLevel, (zoom - 0.1).toString());
                setZoom(zoom - 0.1);
              }}
              onPlus={() => {
                localStorage?.setItem(BrowserStorageKeys.ZoomLevel, (zoom + 0.1).toString());
                setZoom(zoom + 0.1);
              }}
              value={Math.round(zoom * 100).toString() + '%'}
            />
            <PlusMinusButtons
              text={t('sleep', 'Sleep')}
              icon={<Moon />}
              onMinus={() => {
                if (sleepAfter <= 15 && sleepAfter > 0) {
                  localStorage?.setItem(BrowserStorageKeys.SleepAfter, (sleepAfter - 1).toString());
                  setSleepAfter(sleepAfter - 1);
                }
                if (sleepAfter === 30) {
                  localStorage?.setItem(BrowserStorageKeys.SleepAfter, '15');
                  setSleepAfter(15);
                }
                if (sleepAfter === 60) {
                  localStorage?.setItem(BrowserStorageKeys.SleepAfter, '30');
                  setSleepAfter(30);
                }
                if (sleepAfter === 120) {
                  localStorage?.setItem(BrowserStorageKeys.SleepAfter, '60');
                  setSleepAfter(60);
                }
              }}
              onPlus={() => {
                if (sleepAfter < 15) {
                  setSleepAfter(sleepAfter + 1);
                  localStorage?.setItem(BrowserStorageKeys.SleepAfter, (sleepAfter + 1).toString());
                }
                if (sleepAfter === 15) {
                  localStorage?.setItem(BrowserStorageKeys.SleepAfter, '30');
                  setSleepAfter(30);
                }
                if (sleepAfter === 30) {
                  localStorage?.setItem(BrowserStorageKeys.SleepAfter, '60');
                  setSleepAfter(60);
                }
                if (sleepAfter === 60) {
                  localStorage?.setItem(BrowserStorageKeys.SleepAfter, '120');
                  setSleepAfter(120);
                }
              }}
              value={sleepAfterText}
            />
          </div>
        )}
      </div>
    </>
  );
}
