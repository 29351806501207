import React, { useMemo } from 'react';
import { Tile } from 'ui/Layout/Tile';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'ui/Button';
import useModal from 'ui/Modals/UseModal';
import { CatalogueProduct, PurchaserServiceContract } from 'openapi';
import { SubscriptionState } from '../Helper';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import useTrialExpired from 'hooks/UseTrialExpired';

interface Props {
  products: CatalogueProduct[] | undefined;
  currentModuleServiceContracts: PurchaserServiceContract[] | undefined;
  currentUserServiceContract: PurchaserServiceContract | undefined;
  currentHorseCareServiceContract: PurchaserServiceContract | undefined;
  currentHorseFullServiceContract: PurchaserServiceContract | undefined;
  onCancelledSubscription: (hash: SubscriptionState) => void;
}

/**
 * Cancel subscription tile
 */
function CancelSubscriptionTile({
  currentHorseCareServiceContract,
  currentHorseFullServiceContract,
  currentModuleServiceContracts,
  currentUserServiceContract,
  products,
  onCancelledSubscription,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { closeModal, modalIsVisible, showModal } = useModal();
  const { isInTrial } = useTrialExpired();

  /**
   * Determine if the user is paying for a subscription
   */
  const canCancel = useMemo(() => {
    // in trial this is not possible
    if (isInTrial) {
      return false;
    }

    // if the user has a user contract that is not free and has an end date
    if (
      currentUserServiceContract &&
      typeof currentUserServiceContract.fixed_price === 'string' &&
      currentUserServiceContract.extend_automatically === true &&
      Number(currentUserServiceContract.fixed_price) > 0
    ) {
      return true;
    }

    // if the user has a module contract that is not free and has some have not an end date
    if (
      currentModuleServiceContracts &&
      currentModuleServiceContracts?.length > 0 &&
      currentModuleServiceContracts.some(contract => contract.extend_automatically === true)
    ) {
      return true;
    }

    // if the horse has a full service contract exists and has not an end date
    if (currentHorseCareServiceContract && currentHorseCareServiceContract.extend_automatically === true) {
      return true;
    }

    // if the horse has a care service contract exists and has not an end date
    if (currentHorseFullServiceContract && currentHorseFullServiceContract.extend_automatically === true) {
      return true;
    }

    return false;
  }, [
    isInTrial,
    currentUserServiceContract,
    currentModuleServiceContracts,
    currentHorseCareServiceContract,
    currentHorseFullServiceContract,
  ]);

  // we only show this modal when the user is paying for a subscription
  if (!canCancel) {
    return <></>;
  }

  return (
    <Tile title={t('cancel-subscription', 'Cancel subscription')}>
      <p>
        {t(
          'cancel-subscription-tile-text',
          'You can cancel your subscription at any time. You will continue to have access to the paid subscription features until your current billing period ends.',
        )}
      </p>
      <Button className='mt-4' onClick={showModal} variant={ButtonVariant.Danger}>
        {t('cancel-subscription', 'Cancel subscription')}
      </Button>

      <CancelSubscriptionModal
        isVisible={modalIsVisible}
        onRequestCloseModal={closeModal}
        currentUserServiceContract={currentUserServiceContract}
        currentModuleServiceContracts={currentModuleServiceContracts}
        currentHorseFullServiceContract={currentHorseFullServiceContract}
        currentHorseCareServiceContract={currentHorseCareServiceContract}
        products={products}
        onCancelledSubscription={hash => {
          onCancelledSubscription(hash);
          closeModal();
        }}
      />
    </Tile>
  );
}

export default CancelSubscriptionTile;
