import classNames from 'classnames';
import React, { useState } from 'react';
import Tooltip from '../Tooltip';
import { Check, Copy as CopyIcon } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  autoSelect?: boolean;
  onCopied?: () => void;
  buttonClassName?: string;
  iconSize?: number;
  children?: (onCopy: () => void) => JSX.Element;
}

export default function Copy({ value, buttonClassName, onCopied, iconSize, children }: Props): JSX.Element {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const { t } = useTranslation();

  /**
   * The actual copy function
   */
  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  /**
   * onClick handler function for the copy button
   */
  const onCopy = () => {
    copyTextToClipboard(value)
      .then(() => {
        // If successful, update the showTooltip state value
        onCopied?.();
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 1500);
      })
      .catch(err => console.error(err));
  };

  return (
    <>
      <Tooltip
        visible={showTooltip}
        content={
          <div className='flex items-center'>
            <Check className='mr-1' /> {t('copied', 'Copied')}
          </div>
        }
        mode='dark'
        arrow={false}
        placement='right'
      >
        <div onClick={onCopy} className='inline-block'>
          {children === undefined && (
            <button onClick={onCopy} type='button' className={classNames('ml-1', buttonClassName)}>
              <CopyIcon size={iconSize} />
            </button>
          )}

          {children && children(onCopy)}
        </div>
      </Tooltip>
    </>
  );
}
