import { CheckCircle, X } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactsService, HorseGroup, HorsegroupsService } from 'openapi';
import { HorseDetail } from 'openapi/models/HorseDetail';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { contactName } from 'utilities/Contact';
import { Label } from '../../ui/Label';

interface Props {
  horse: HorseDetail;
  className?: string;
}

function HorseDetails({ horse, className }: Props): JSX.Element {
  const [contact, setContact] = useState<Contact>();
  const [group, setGroup] = useState<HorseGroup>();

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  /**
   * get the group based on horse.group_uid
   */
  useEffect(() => {
    if (!selectedOrganization || !horse.group_uid) return;

    const promise = HorsegroupsService.horsegroupsRetrieve({
      organisationUid: selectedOrganization.uid,
      uid: horse.group_uid,
    });

    promise.then(res => setGroup(res)).catch(error => console.error(error));

    return () => promise.cancel();
  }, [horse.group_uid, selectedOrganization]);

  /**
   * get the location based on horse.current_horselocation.location_uid
   */
  useEffect(() => {
    if (!selectedOrganization || !horse.current_horselocation) return;

    const promise = ContactsService.contactsRetrieve({
      organisationUid: selectedOrganization.uid,
      uid: horse.current_horselocation.location_uid,
    });

    promise.then(res => setContact(res)).catch(error => console.error(error));

    return () => promise.cancel();
  }, [horse.current_horselocation, selectedOrganization]);

  if (!horse) {
    return <div />;
  }

  return (
    <div className={classNames('grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4', className)}>
      <div>
        <Label>{t('horseDetails.name', 'Name')}</Label>
        <div>{horse.name}</div>
      </div>
      <div>
        <Label>{t('horseDetails.stable', 'Stable')}</Label>
        <div>{horse.stable_uid || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.sire', 'Sire')}</Label>
        <div>{horse.sire || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.dam', 'Dam')}</Label>
        <div>{horse.dam || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.damsire', 'Damsire')}</Label>
        <div>{horse.damsire || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.grandsire', 'Grandsire')}</Label>
        <div>{horse.siredam || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.breeder', 'Breeder')}</Label>
        <div>{horse.breeder || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.studbook', 'Studbook')}</Label>
        <div>{horse.studbook || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.studbooknumber', 'Studbook number')}</Label>
        <div>{'-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.purchasedate', 'Purchase date')}</Label>
        <div>{horse.purchase_date}</div>
      </div>
      <div>
        <Label>{t('horseDetails.withersheight', 'Withers height')}</Label>
        <div>{horse.withers_height}</div>
      </div>
      <div>
        <Label>{t('horseDetails.nickname', 'Nickname')}</Label>
        <div>{horse.nickname}</div>
      </div>
      <div>
        <Label>{t('horseDetails.dateofbirth', 'Date of birth')}</Label>
        <div>{horse.date_of_birth || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.active', 'Active')}</Label>
        <div>
          {horse.hidden ? (
            <CheckCircle weight='fill' className={'w-5 h-5 text-green-600'} />
          ) : (
            <X weight='fill' className={'w-5 h-5 text-red-600'} />
          )}
        </div>
      </div>
      <div>
        <Label>{t('horseDetails.defaultride', 'Default ride')}</Label>
        <div>{horse.default_rider_uid || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.defaultgroom', 'Default groom')}</Label>
        <div>{horse.default_groom_uid || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.defaulttrainer', 'Default trainer')}</Label>
        <div>{horse.default_trainer_uid || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.defaultfarrier', 'Default farrier')}</Label>
        <div>{horse.default_farrier_uid || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.defaultveterinarian', 'Default veterinarian')}</Label>
        <div>{horse.default_vet_uid || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.UELN', 'UELN')}</Label>
        <div>{horse.UELN || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.FEIpassportnumber', 'FEI passport number')}</Label>
        <div>{horse.FEI_pass_nr || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.chipnumber', 'Chipnumber')}</Label>
        <div>{horse.chip_nr || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.federationnumber', 'Federation number')}</Label>
        <div>{horse.local_federation_number || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.FEIpassportexpirydate', 'FEI passport expiry date')}</Label>
        <div>{horse.FEI_expiry_date || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.color', 'Color')}</Label>
        <div>{horse.color?.toString() || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.gender', 'Gender')}</Label>
        <div>{horse.sex?.toString() || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.vaccinationrules', 'Vaccination rules')}</Label>
        <div>{horse.vaccination_rules_uid || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.numberofweeksbetweeneachfarriervisit', 'Number of weeks between each farrier visit')}</Label>
        <div>{horse.farrier_weeks || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.group', 'Group')}</Label>
        <div>{group?.name ?? '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.numberofweeksbetweendentistvisits', 'Number of weeks between dentist visits')}</Label>
        <div>{horse.dentist_weeks || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.boxnumber', 'Box number')}</Label>
        <div>{horse.box_no || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.numberofweeksbetweendeworm', 'Number of weeks between deworm')}</Label>
        <div>{horse.deworm_weeks || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.note', 'Note')}</Label>
        <div>{horse.note || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.createdat', 'Created at')}</Label>
        <div>{'-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.lastmodifiedat', 'Last modified at')}</Label>
        <div>{'-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.temperature', 'Temperature')}</Label>
        <div>{horse.temperature || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.heartrate', 'Heart rate')}</Label>
        <div>{'-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.respiratoryrate', 'Respiratory rate')}</Label>
        <div>{'-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.weight', 'Weight')}</Label>
        <div>{horse.weight || '-'}</div>
      </div>
      <div>
        <Label>{t('horseDetails.currentlocation', 'Current location')}</Label>
        <div>{contact ? contactName(contact) : '-'}</div>
      </div>
    </div>
  );
}
export default HorseDetails;
