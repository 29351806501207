import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, PurchaserServiceContract, ServiceContractsService } from 'openapi';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  currentUserServiceContract: PurchaserServiceContract | undefined;
}

interface ReturnType {
  followUpUserServiceContract: PurchaserServiceContract | undefined;
}

/**
 * Small hook that will fetch the follow up contract for the current user
 */
function useSubscriptionUserContractFollowUp({ currentUserServiceContract }: Props): ReturnType {
  const [followUpUserServiceContract, setFollowUpUserServiceContract] = useState<PurchaserServiceContract>();

  const { selectedOrganizationUid } = useOrganization();

  /**
   * Load the roles from API
   */
  const loadFollowUp = useCallback((purchaserUid: string, uid: string): CancelablePromise<PurchaserServiceContract> => {
    const promise = ServiceContractsService.serviceContractsPurchasedRetrieve({
      purchaserUid,
      uid: uid,
    });
    promise
      .then(res => setFollowUpUserServiceContract(res))
      .catch(e => {
        console.error('Unable to load the follow-up contract', e);
      });
    return promise;
  }, []);

  /**
   * Check if the userContract has a follow up contract
   * If so, fetch that contract so we can show the user that the contract is ending
   */
  useEffect(() => {
    if (currentUserServiceContract && currentUserServiceContract.followup_contract_uid !== null && selectedOrganizationUid) {
      const promise = loadFollowUp(selectedOrganizationUid, currentUserServiceContract.followup_contract_uid);
      return () => promise.cancel();
    }
  }, [currentUserServiceContract, loadFollowUp, selectedOrganizationUid]);

  return {
    followUpUserServiceContract,
  };
}

export default useSubscriptionUserContractFollowUp;
