import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { IS_MOBILE_APP, IS_WEB_APP } from 'const';
import usePushNotification from 'hooks/UsePushNotification';
import { ApiService, NotificationSettings, PatchedNotificationSettings } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui/Alert';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import Fieldset from 'ui/Fieldset';
import { TextInput } from 'ui/Inputs';
import TogggleInput from 'ui/Inputs/ToggleInput';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { Severity } from 'utilities/severity';
import { transformEmptyToUndefined } from 'utilities/zod';

interface Props {
  isVisible: boolean;
  notifications: NotificationSettings | undefined;
  onRequestClose: () => void;
  onUpdated: () => void;
}

const schema = schemas.NotificationSettings.omit({
  push_silent_start: true,
  push_silent_end: true,
});

export default function UpdateNotificationModal({ isVisible, onRequestClose, notifications, onUpdated }: Props): JSX.Element {
  const { t } = useTranslation();
  const { isPushNotificationEnabled } = usePushNotification();

  const defaultValues = useMemo((): NotificationSettings => {
    return {
      enable_push_notifications: notifications?.enable_push_notifications,
      task_assigned_enabled: notifications?.task_assigned_enabled,
      feed_change_enabled: notifications?.feed_change_enabled,
      horse_media_enabled: notifications?.horse_media_enabled,
      daily_tomorrow_not_planned_enabled: notifications?.daily_tomorrow_not_planned_enabled,
      daily_unchecked_enabled: notifications?.daily_unchecked_enabled,
      activity_done_own_horse_enabled: notifications?.activity_done_own_horse_enabled,
      daily_horse_incomplete_enabled: notifications?.daily_horse_incomplete_enabled,
      enable_notify_before: notifications?.enable_notify_before,
      daily_timing: notifications?.daily_timing,
    };
  }, [notifications]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
    clearErrors,
  } = useForm<PatchedNotificationSettings>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const enableNotificationFormValue = watch('enable_push_notifications');

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close event for the modal
   */
  const onClosed = () => {
    reset(notifications);
    clearErrors();
    setApiError(undefined);
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  // const onSubmit: SubmitHandler<PatchedNotificationSettings> = async (data: PatchedNotificationSettings) => {
  const onSubmit: SubmitHandler<PatchedNotificationSettings> = async (data: PatchedNotificationSettings) => {
    try {
      await ApiService.apiV5DeviceFcmSettingsPartialUpdate({
        requestBody: data,
      });
      onRequestClose();
      onUpdated();
    } catch (e) {
      setApiError(new ApiErrorParser<PatchedNotificationSettings>(e));
    }
  };

  /**
   * Make sure we reset the form when the modal is opened
   */
  useEffect(() => {
    if (isVisible) {
      reset(defaultValues);
    }
  }, [defaultValues, isVisible, reset]);

  return (
    <PageModal
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'updateNotificationSettings', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      width={PageModalWidth.Xs}
      onClosed={onClosed}
    >
      <PageModalTitle title={t('update-notification-settings', 'Update notifications settings')} onClose={onRequestClose} />
      <PageModalContent>
        <ErrorSection errors={nonFieldErrors} />

        {IS_MOBILE_APP && !isPushNotificationEnabled && (
          <Alert
            severity={Severity.Warning}
            className='mb-5'
            message={t(
              'notification-device-disabled-desc-modal',
              "Your device will not receive any push notifications because they are disabled in your device settings. To start receiving notifications, go to 'Settings -> EquineM -> Notifications' and enable push notifications.",
            )}
          />
        )}

        {IS_WEB_APP && (
          <Alert
            severity={Severity.Info}
            className='mb-5'
            message={t(
              'notification-device-settings-desc',
              'Push notifications are currently only sent to mobile devices. Updating the settings will therefore only affect your connected mobile devices.',
            )}
          />
        )}

        <TogggleInput
          control={control}
          error={fieldError('enable_push_notifications')}
          label={t('enable-notification', 'Enable notifications')}
          name='enable_push_notifications'
          hint={t('enable-notification-desc', 'Enable or disable all notifications that we send to all of your device(s)')}
          asRow={true}
        />

        <Fieldset legend={t('instant-notifications', 'Instant notifications')}>
          <div className='space-y-4'>
            <TogggleInput
              disabled={!enableNotificationFormValue}
              control={control}
              error={fieldError('task_assigned_enabled')}
              label={t('notification-task-assigned-enabled', 'Task assigned')}
              name='task_assigned_enabled'
              hint={t('notification-task-assigned-desc', 'Notification when an activity is assigned to me')}
              asRow={true}
            />
            <TogggleInput
              disabled={!enableNotificationFormValue}
              control={control}
              error={fieldError('feed_change_enabled')}
              label={t('notification-feed-change-enabled', 'Feed changed')}
              name='feed_change_enabled'
              hint={t('notification-feed-change-desc', 'Notification when "feed planning" has been updated')}
              asRow={true}
            />
            <TogggleInput
              disabled={!enableNotificationFormValue}
              control={control}
              error={fieldError('horse_media_enabled')}
              label={t('notification-horse-media-enabled', 'Task assigned')}
              name='horse_media_enabled'
              hint={t('notification-horse-media-desc', 'Notification when horse media is updated')}
              asRow={true}
            />
          </div>
        </Fieldset>

        <Fieldset legend={t('plannend-notifications', 'Plannend notifications')}>
          <div className='space-y-4'>
            <TogggleInput
              disabled={!enableNotificationFormValue}
              control={control}
              error={fieldError('daily_tomorrow_not_planned_enabled')}
              label={t('notification-daily-tomorow-not-planned-enabled', 'Daily planning not yet created')}
              name='daily_tomorrow_not_planned_enabled'
              hint={t('notification-daily-tomorow-not-planned-desc', 'Notification when a daily planning for the next day is not created')}
              asRow={true}
            />
            <TogggleInput
              disabled={!enableNotificationFormValue}
              control={control}
              error={fieldError('daily_unchecked_enabled')}
              label={t('notification-daily-unchecked-enabled', 'Unchecked activity')}
              name='daily_unchecked_enabled'
              hint={t('notification-daily-unchecked-desc', 'Notification when there are unfinished activities for today')}
              asRow={true}
            />
            <TogggleInput
              disabled={!enableNotificationFormValue}
              control={control}
              error={fieldError('activity_done_own_horse_enabled')}
              label={t('notification-activity-done-own-horse-enabled', 'Activity done with own horse')}
              name='activity_done_own_horse_enabled'
              hint={t(
                'notification-activity-done-own-horse-desc',
                'Notification when there are activities finished with a horse that you own',
              )}
              asRow={true}
            />
            <TogggleInput
              disabled={!enableNotificationFormValue}
              control={control}
              error={fieldError('daily_horse_incomplete_enabled')}
              label={t('notification-horse-details-incomplete-enabled', 'Horse details incomplete')}
              name='daily_horse_incomplete_enabled'
              hint={t(
                'notification-horse-details-incomplete--desc',
                'Notification when a there is a horse that has not all details complete',
              )}
              asRow={true}
            />
            <TogggleInput
              disabled={!enableNotificationFormValue}
              control={control}
              error={fieldError('enable_notify_before')}
              label={t('notification-reminder-activity-enabled', 'Reminder for activity')}
              name='enable_notify_before'
              hint={t('notification-reminder-activity-desc', 'Notification for activities that are planned')}
              asRow={true}
            />
            <TextInput
              type='time'
              label={t('notification-deliver-at', 'Deliver the notifications at')}
              error={fieldError('daily_timing')}
              hint={t('notification-deliver-at-desc', 'At this time we sent the plannend notifications')}
              {...register('daily_timing', { setValueAs: transformEmptyToUndefined() })}
            />
          </div>
        </Fieldset>
      </PageModalContent>

      <PageModalActions
        actions={[
          {
            onClick: onRequestClose,
            variant: ButtonVariant.Default,
            type: 'button',
            text: t('cancel', 'Cancel'),
          },
          {
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            type: 'submit',
            formId: 'updateNotificationSettings',
            text: t('save', 'Save'),
          },
        ]}
      />
    </PageModal>
  );
}
