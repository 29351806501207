import React from 'react';
import useModuleAccess from 'hooks/UseModuleAccess';
import { ModuleEnum } from 'openapi';

interface Props {
  moduleType: ModuleEnum | ModuleEnum[];
  children: JSX.Element;
}
export default function ModuleAccess({ moduleType, children }: Props): JSX.Element {
  const { hasModuleAccess, noAccessElement, selectedOrganizationServiceContracts } = useModuleAccess();

  // if we receive undefined on the selectedOrganizationServiceContracts, we are still loading the data
  // we return in such case an empty element
  if (selectedOrganizationServiceContracts === undefined) {
    return <></>;
  }

  // check if the user has access to the module
  if (!hasModuleAccess(moduleType)) {
    return noAccessElement(moduleType);
  }

  return children;
}
