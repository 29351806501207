import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../ui/Layout';
import { PageMaxWidth } from 'ui/Layout/Page';
import SettingsFacilitiesTile from 'components/Activities/SettingsFacilitiesTile';

export default function ManageFacilities(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Page title={t('manage-facilities', 'Manage facilities')} maxWidth={PageMaxWidth.Tile}>
      <div className='space-y-4'>
        <SettingsFacilitiesTile />
      </div>
    </Page>
  );
}
