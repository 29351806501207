import { Bug } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';

interface Props {
  error: unknown;
  componentStack: string;
  eventId: string;
}

// Sentry error page. A catch all when something goes wrong.
export default function ErrorBoundary({ error, componentStack, eventId }: Props): JSX.Element {
  const { t } = useTranslation();
  const [detailsVisible, setDetailsVisible] = useState<boolean>(false);

  // get the error message if we deal with an actual error
  const errorMessage = error instanceof Error ? error.message : undefined;

  return (
    <div className='w-screen h-screen flex flex-col justify-center items-center gap-4'>
      <Bug size={50} /> <h1 className='text-xl font-medium'>{t('oops', 'Oops!')}</h1>
      <p>{t('something-went-wrong', 'Something went wrong.')}</p>
      <p className='max-w-96 text-center'>
        {t('we-have-been-informed', 'Our team is informed by this problem and is trying to fix it as soon as possible.')}
      </p>
      <div className='flex gap-2 items-center'>
        <Button size={ButtonSize.XSmall} onClick={() => setDetailsVisible(!detailsVisible)}>
          {t('error-event-id', 'Event ID')} <pre>{eventId}</pre>
        </Button>
      </div>
      {detailsVisible && (
        <pre className='min-w-96 w-1/2 border min-h-10'>
          {errorMessage && <p className='mb-2'>{errorMessage}</p>}
          {componentStack}
        </pre>
      )}
      <Button
        className='mt-4'
        variant={ButtonVariant.Primary}
        onClick={() => {
          window.location.href = '/';
        }}
      >
        {t('back', 'Back')}
      </Button>
    </div>
  );
}
