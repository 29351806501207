import useRefreshingNow from 'hooks/UseRefreshingNow';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import { colHeaderWidthMobile } from '.';
import React from 'react';
import SlidableCalendarView from '../SlidableCalendarView';
import Calendar from 'context/Calendar';
import { DayGridHeader } from '../DayGridHeader';
import classNames from 'classnames';
import { isSameDay } from 'date-fns';
import { CalendarView } from 'utilities/Planning';

export interface Props {
  columnHeaderWidth: number;
  calendarView: CalendarView;
}

/**
 * The header for the multi day view. It show the dates as header columns.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * The view uses a SlidableCalendarView for extra smoothness.
 *
 * ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * ┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * └──────└──────└──────└──────└──────└──────└──────┘
 */
export function MultiDayHeader({ columnHeaderWidth: givenColumnHeaderWidth, calendarView }: Props): JSX.Element {
  const { now } = useRefreshingNow();
  const { width } = useScreenSize();
  const columnHeaderWidth = width <= ScreenSize.md ? colHeaderWidthMobile : givenColumnHeaderWidth;

  return (
    <div className='flex w-full'>
      <div className='md:border-r shrink-0 border-white md:border-inherit border-opacity-10' style={{ width: columnHeaderWidth }} />
      <SlidableCalendarView
        calendarView={calendarView}
        className='grow'
        render={(calendar: Calendar, availableWidth) => (
          <div className='w-full flex'>
            {calendar.days.map((day, index) => (
              <div className='grow' style={{ width: availableWidth ? availableWidth / calendar.days.length : 0 }} key={day.toDateString()}>
                <DayGridHeader className='w-full md:border-r' highlight={isSameDay(day, now)} day={day} />
                <div
                  className={classNames('w-full h-1', {
                    'md:border-r border-white md:border-inherit border-opacity-20': index !== calendar.days.length - 1,
                  })}
                />
              </div>
            ))}
          </div>
        )}
      />
    </div>
  );
}
