import { useAccount } from 'context/AccountContext';
import { CatalogueProduct, Plan, PricePoint } from 'openapi';
import React, { Fragment } from 'react';
import LicenseTile from './Tile';
import { getModuleIcon } from './Helper';
import { useTranslation } from 'react-i18next';

interface Props {
  plans: Plan[];
  products: CatalogueProduct[];
  onClick: (product: CatalogueProduct, pricePoint: PricePoint) => void;
}

function PlanCredits({ plans, products, onClick }: Props): JSX.Element {
  const { t } = useTranslation();
  const { parseAndFormatMoney } = useAccount();

  /**
   * Make sure we have added the product to the plan and filter out the ones that are not
   */
  const filteredPlanWithProducts = plans
    .map(plan => {
      const product = products.find(product => product.plan === plan.uid);
      return {
        plan,
        product,
      };
    })
    .filter((planAndProduct): planAndProduct is { plan: Plan; product: CatalogueProduct } => planAndProduct.product !== undefined);

  return (
    <div className='flex flex-wrap mt-5 gap-5'>
      {filteredPlanWithProducts.map(({ plan, product }) => (
        <Fragment key={product.uid}>
          {product.price_points
            .filter(price_point => typeof price_point.price === 'string')
            .sort((a, b) => parseFloat(a.price as string) - parseFloat(b.price as string))
            .map(pricePoint => {
              const name = `${pricePoint.max_units} ${t('credits', 'credits')}`;

              return (
                <LicenseTile
                  key={`${product.uid}-${pricePoint.price}`}
                  icon={getModuleIcon(plan.module)}
                  name={name}
                  price={
                    Number(pricePoint.price) > 0
                      ? parseAndFormatMoney(pricePoint.price as string, pricePoint.price_currency as string)
                      : 'free'
                  }
                  onClick={() => onClick(product, pricePoint)}
                  showCheckbox={false}
                />
              );
            })}
        </Fragment>
      ))}
    </div>
  );
}

export default PlanCredits;
