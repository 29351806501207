import { zodResolver } from '@hookform/resolvers/zod';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { EquineMSearchLastPage, externalDbEnum, HorseDetail, HorsesService, PatchedHorseDetail } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui/Alert';
import Button, { ButtonVariant } from 'ui/Button';
import RadioButtonGroup, { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import Skeleton from 'ui/Layout/Skeleton';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import PageModal, { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { fillArray } from 'utilities/FillArray';
import { bloodlineString } from 'utilities/Horse';
import { Severity } from 'utilities/severity';
import { z } from 'zod';

interface Props {
  isVisible: boolean;
  horse: HorseDetail | undefined;
  onImported: (horseDetail: HorseDetail) => void;
  onRequestClose: () => void;
}

interface FormModel {
  horseTelexId: string;
}

const schema = z.object({
  horseTelexId: z.string().min(1),
});

export default function ImportFromHorseTelexModal({ horse, isVisible, onImported, onRequestClose }: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);
  const [hasSubmittingError, setHasSubmittingError] = useState<boolean>(false);
  const [horseSearch, setHorseSearch] = useState<EquineMSearchLastPage>();
  const [horseTelexCurrentPageNr, setHorseTelexCurrentPageNr] = useState<number>(1);

  const { t } = useTranslation();
  const { selectedOrganizationUid } = useOrganization();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<FormModel>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError } = useFormError(schema, errors);

  const importFromHorseTelex = useCallback(
    async (pageNr: number) => {
      if (!selectedOrganizationUid) return;

      setLoading(true);
      const promise = HorsesService.horsesSearchHorseCreate({
        organisationUid: selectedOrganizationUid,
        requestBody: {
          name: horse?.name,
          chip_nr: horse?.chip_nr || undefined,
          UELN: horse?.UELN || undefined,
          FEI_pass_nr: horse?.FEI_pass_nr || undefined,
          page: pageNr,
          pageSize: 10,
        },
      });

      // const promise = ExternalIntegrationsService.apiV5SearchHorsetelexCreate({
      //   requestBody: {
      //     name: horse?.name,
      //     chip_nr: horse?.chip_nr || undefined,
      //     UELN: horse?.UELN || undefined,
      //     FEI: horse?.FEI_pass_nr || undefined,
      //     page: pageNr,
      //   },
      // });

      try {
        const result = await promise;
        setHorseSearch(result);
        setHasLoadingError(false);
      } catch (error) {
        console.error(error);
        setHasLoadingError(true);
      } finally {
        setLoading(false);
      }
    },
    [horse?.FEI_pass_nr, horse?.UELN, horse?.chip_nr, horse?.name, selectedOrganizationUid],
  );

  const horseTelexOptions = useMemo((): RadioButtonGroupOption[] => {
    return (horseSearch?.items ?? [])
      .filter(item => item.source_db === externalDbEnum.HORSE_TELEX && item.dam !== null && item.sire !== null)
      .map<RadioButtonGroupOption>(item => ({ id: item.id_horsetelex, name: bloodlineString(item) ?? '' }));
  }, [horseSearch]);

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<FormModel> = async (data: FormModel) => {
    if (!horse || !selectedOrganizationUid) return;

    const selectedHorseTelex = horseSearch?.items.find(item => item.id_horsetelex === data.horseTelexId);
    const updatedPedigree: PatchedHorseDetail = {
      sire: selectedHorseTelex?.sire ?? '',
      dam: selectedHorseTelex?.dam ?? '',
      damsire: selectedHorseTelex?.damsire ?? '',
      id_horsetelex: data.horseTelexId,
    };

    try {
      const updateHorsePromise = HorsesService.horsesPartialUpdate({
        organisationUid: selectedOrganizationUid,
        uid: horse.uid,
        requestBody: updatedPedigree,
      });
      const updatedHorse = await updateHorsePromise;
      onImported(updatedHorse);
      onRequestClose();
      setHasSubmittingError(false);
    } catch (error) {
      console.error(error);
      setHasSubmittingError(true);
    }
  };

  /**
   * reset the modal
   */
  const onClosed = useCallback(() => {
    reset();
    setHorseSearch(undefined);
    setHorseTelexCurrentPageNr(1);
    setHasLoadingError(false);
    setHasSubmittingError(false);
  }, [reset]);

  /**
   * When we open the modal we do a query to the Horse Telex API
   */
  useEffect(() => {
    if (isVisible && horse) {
      importFromHorseTelex(horseTelexCurrentPageNr);
    }
  }, [horse, horseTelexCurrentPageNr, importFromHorseTelex, isVisible]);

  const nextDisabled = useMemo(() => {
    if (!horseSearch) return true;
    return horseSearch.is_last_page === true;
  }, [horseSearch]);

  return (
    <PageModal
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'importFromHorseTelex', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
    >
      <PageModalTitle title={t('import-from-horsetelex', 'Import from Horsetelex')} onClose={onRequestClose} />
      <PageModalContent>
        {(hasLoadingError || hasSubmittingError) && (
          <div className='mb-2'>
            {hasLoadingError && (
              <Alert
                severity={Severity.Warning}
                message='We cannot load the data from horseTelex. Please try again later or contact support'
              />
            )}
            {hasSubmittingError && (
              <Alert severity={Severity.Warning} message='We cannot save the data. Please try again later or contact support' />
            )}
          </div>
        )}

        {loading && (
          <div className='flex items-center pt-3 gap-x-2'>
            <Spinner size={SpinnerSize.XSmall} /> <p>{t('loading-data-from-horsetelex', 'Loading data from Horsetelex')}...</p>
          </div>
        )}

        {!hasLoadingError && !loading && horseTelexOptions.length === 0 && (
          <>
            <div className='flex items-center gap-x-2'>
              <p>{t('no-match-from-horsetelex', 'We did not found a match in the HorseTelex database')}</p>
            </div>
          </>
        )}

        {loading && (
          <>
            <div className='space-y-2 mt-2 mb-4'>
              {fillArray(10).map(index => (
                <div key={index} className='border rounded-md'>
                  <Skeleton className='h-[2.35rem] w-full' />
                </div>
              ))}
            </div>
          </>
        )}
        {!loading && horseTelexOptions.length > 0 && (
          <>
            <RadioButtonGroup
              flexType='col'
              className='my-4'
              name='horseTelexId'
              required={false}
              control={control}
              error={fieldError('horseTelexId')}
              options={horseTelexOptions}
              label={t('pedigree', 'Pedigree')}
              hint={t('horsetelex-import-hint', 'Select the pedigree you want to import')}
            />

            <div className='space-y-4'>
              <div className='flex gap-x-2 justify-between items-center'>
                <Button
                  type='button'
                  variant={ButtonVariant.Default}
                  disabled={horseTelexCurrentPageNr === 1 || loading}
                  onClick={() => setHorseTelexCurrentPageNr(prevState => prevState - 1)}
                  icon={<CaretLeft />}
                >
                  {t('previous', 'Previous')}
                </Button>
                <p className='hidden sm:block text-sm text-gray-500'>
                  {t('pedigree-page-n', 'Page {{currentPage}}', {
                    currentPage: horseTelexCurrentPageNr,
                  })}
                </p>
                <Button
                  type='button'
                  variant={ButtonVariant.Default}
                  disabled={nextDisabled || loading}
                  onClick={() => setHorseTelexCurrentPageNr(prevState => prevState + 1)}
                  icon={<CaretRight />}
                  iconReverse={true}
                >
                  {t('next', 'Next')}
                </Button>
              </div>
              <p className='sm:hidden text-sm text-gray-500'>
                {t('pedigree-page-n', 'Page {{currentPage}}', {
                  currentPage: horseTelexCurrentPageNr,
                })}
              </p>
            </div>
          </>
        )}
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            disabled: horseSearch === undefined || horseSearch.items?.length === 0,
            variant: ButtonVariant.Primary,
            type: 'submit',
            loading: isSubmitting,
            text: t('import', 'Import'),
            formId: 'importFromHorseTelex',
          },
        ]}
      />
    </PageModal>
  );
}
