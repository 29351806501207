import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { BreadCrumb, PageAction, usePage } from 'context/PageContext';
import React, { memo, ReactNode, useEffect, useMemo } from 'react';
import { NavLink, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import Button from 'ui/Button';
import { generatePath } from 'utilities/ReactRouter';
import { PageErrorWrapper } from './PageError';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import EmptyListPlaceholder from './EmptyListPlaceholder';
import classNames from 'classnames';
import { Theme } from './Theme';
import { ApiPromises } from 'utilities/ApiPromises';
import { useTranslation } from 'react-i18next';
import useApiPromises from 'api/hooks/useApiPromises';
import ButtonDropdown from 'ui/Button/ButtonDropdown';

export enum PageMaxWidth {
  Default = 'max-w-[1400px]',
  Tile = 'max-w-4xl',
  NoLimit = '',
}

// A helper method that generates a navback url query string. When the string
// is added to a path then the breadcrumb will be overwritten and a simple back
// button is shown.
// I.e.: http://localhost:3000/shop/orders/ORD1p8ta?navback=/financial-administration/invoices/INVaC05U
export const navBackToThisPage = (): URLSearchParams => {
  const params = new URLSearchParams();
  params.set('navback', window.location.pathname);
  return params;
};

interface Props {
  children: ReactNode;
  title: string;
  titleSuffix?: ReactNode;
  margins?: boolean; // Apply natural looking margins (on large display only)
  maxWidth?: PageMaxWidth; // Apply a natural looking max width.
  showTitleSection?: boolean; // Show a page title on large displays
  breadCrumbs?: BreadCrumb[];
  loading?: boolean | ApiPromises;
  actions?: PageAction[];
  showEmptyListPlaceholder?: boolean; // Shows a graphic of an empty list with the primary add action below it.
  mobileHeaderComponent?: JSX.Element; // A custom element to show as sticky header for mobile.
}

// A page should be used as top level component in every Equinem page. It helps
// with consistent margins, and interaction with the nav bar for mobile.
function Page({
  children,
  title,
  titleSuffix,
  margins = true,
  maxWidth = PageMaxWidth.Default,
  showTitleSection = true,
  breadCrumbs: givenBreadCrumbs,
  actions,
  loading = false,
  showEmptyListPlaceholder,
  mobileHeaderComponent = (
    <div className='px-16 w-full flex items-center justify-center h-14'>
      <h1 className='text-lg font-medium text-white line-clamp-1'>{title}</h1>
    </div>
  ),
}: Props): JSX.Element {
  const { theme } = useOutletContext<{ theme: Theme }>();
  const { setTitle, setTitleSuffix, setBreadCrumbs, setActions, setApiError } = usePage();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const isMobileDevice = useIsMobileDevice();
  const addAction = (actions ?? []).find(a => a.isMobileAddAction);
  const { t } = useTranslation();
  const { loading: loadingApiPromises } = useApiPromises({ apiPromises: typeof loading === 'boolean' ? undefined : loading });

  /**
   * Is set when we have a back navigation action set in our url.
   */
  const navBackFromUrl = useMemo((): string | undefined => {
    const urlPath = searchParams.get('navback');
    if (!urlPath) {
      return undefined;
    }
    return urlPath;
  }, [searchParams]);

  const breadCrumbs = useMemo((): BreadCrumb[] | undefined => {
    if (navBackFromUrl) {
      return [{ name: t('nav-back', 'Back'), path: navBackFromUrl }];
    }
    return givenBreadCrumbs;
  }, [givenBreadCrumbs, navBackFromUrl, t]);

  /**
   * When showEmptyListPlaceholder is false it checks the hasEmptyListResult of
   * the given apiPromise. If hasEmptyListResult is set to true then show the
   * empty list placeholder.
   */
  const overrideEmptyListPlaceholder = useMemo(() => {
    if (showEmptyListPlaceholder === true) {
      return true;
    }
    if (typeof loading !== 'boolean' && !loadingApiPromises) {
      return loading.hasEmptyListResult;
    }
    return false;
  }, [loadingApiPromises, loading, showEmptyListPlaceholder]);

  /**
   * On construct we should set the title, breadcrumb and actions
   */
  useEffect(() => {
    setTitle(title);
    setTitleSuffix(titleSuffix);
    setBreadCrumbs(breadCrumbs || []);
    setActions(actions || []);
    setApiError(undefined);

    // clear the title when the component get destroyed
    return () => {
      setTitle(undefined);
      setTitleSuffix(undefined);
    };
  }, [title, actions, breadCrumbs]); // eslint-disable-line

  return (
    <>
      <div className={'pb-safe-offset-16 svh md:pt-0 min-h-lvh'}>
        <div
          className={classNames('block print:hidden sticky z-10 inset-x-0 top-0 select-none text-white pt-safe md:hidden insert-x-0', {
            'bg-primary': theme === Theme.Equinem,
            'bg-webshop': theme === Theme.Webshop,
          })}
        >
          {mobileHeaderComponent}
        </div>

        {navBackFromUrl !== undefined && (
          <div className='hidden md:flex p-2 gap-1 text-sm items-center text-neutral-700'>
            <p />
            <NavLink className='hover:underline flex items-center' to={navBackFromUrl}>
              <CaretLeft className='mt-0.5' /> {t('back', 'Back')}
            </NavLink>
          </div>
        )}

        {breadCrumbs && breadCrumbs.length > 0 && !navBackFromUrl && (
          <div className='hidden md:flex p-2 gap-1 text-sm items-center text-neutral-700'>
            {breadCrumbs.map(bc => {
              // we should deal with params (:uid) in the url and add the matching value from useParams()
              const path = generatePath(bc.path, params);
              return (
                <NavLink className='hover:underline flex items-center' key={bc.path} to={path}>
                  {bc.name} <CaretRight className='mt-1' />
                </NavLink>
              );
            })}
            <span className='font-medium'>{title}</span>
          </div>
        )}
        {
          <div
            className={classNames(
              'm-auto',
              // set a padding on the sides so it will have a padding on both sides to avoid
              // it will stick to the sides of the screen.
              'md:px-4',
              {
                'my-4': margins,
              },
              maxWidth,
              'print:max-w-none', // Let the printer decide the width of the page.
            )}
          >
            {title !== undefined && showTitleSection === true && !isMobileDevice && (
              <div className='flex space-x-2 items-center mb-2 relative'>
                <h1 className='print:hidden inline-block text-2xl font-medium my-6 w-full'>
                  {title}
                  {titleSuffix}
                </h1>
                {!overrideEmptyListPlaceholder && (
                  <div className='pl-3 actions items-center md:flex gap-2'>
                    {actions &&
                      actions.map((action, index) => {
                        if (action.options && action.options.length > 0) {
                          return (
                            <ButtonDropdown
                              showCaretDown={true}
                              icon={action.icon}
                              options={action.options}
                              selectedOption={action.selectedOption}
                              key={index}
                            >
                              {action.text}
                            </ButtonDropdown>
                          );
                        }

                        return (
                          <Button
                            onClick={action.onClick}
                            disabled={action.disabled}
                            icon={action.icon}
                            key={index}
                            variant={action.buttonVariant}
                            badge={action.badge}
                          >
                            {action.text}
                          </Button>
                        );
                      })}
                  </div>
                )}
              </div>
            )}
            <PageErrorWrapper loading={loading}>
              {overrideEmptyListPlaceholder ? <EmptyListPlaceholder addAction={addAction} title={title} /> : <>{children}</>}
            </PageErrorWrapper>
          </div>
        }
      </div>
    </>
  );
}

export default memo(Page);
