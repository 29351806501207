import { useAccount } from 'context/AccountContext';
import { CatalogueProduct, Plan, PurchaserOrderDetail, PurchaserOrderItem, PurchaserServiceContract } from 'openapi';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Skeleton from 'ui/Layout/Skeleton';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import { AllColors } from 'utilities/colors';
import {
  calculateHorsePrices,
  getModuleName,
  horseServicesFormatExtendAutomaticallyAndEndDt,
  translatedMaxUnitCount,
  translateFreePrice,
} from 'components/Subscription/Helper';
import { StairStepProduct } from 'components/Subscription/PlanStairStep';
import { BreedingBadge, CareBadge, SportBadge } from 'components/Horses/HorseUsageBadges';
import classNames from 'classnames';
import useTrialExpired from 'hooks/UseTrialExpired';

interface Props {
  plans: Plan[] | undefined;
  purchaserOrderDetail: PurchaserOrderDetail | undefined;
  selectedUserProduct: StairStepProduct | undefined;
  selectedModuleProducts: CatalogueProduct[] | undefined;
  loading: boolean;
  currentModuleServiceContracts: PurchaserServiceContract[] | undefined;
  currentUserServiceContract: PurchaserServiceContract | undefined;
  followUpUserServiceContract?: PurchaserServiceContract;
  currentHorseCareServiceContract: PurchaserServiceContract | undefined;
  currentHorseFullServiceContract: PurchaserServiceContract | undefined;
  // it could that the serviceContact is not available for the horse services
  // this flag will show a row with 0 prices in case the user select it
  showEmptyHorseServiceRow?: boolean;
}

function SubscriptionTable({
  plans,
  purchaserOrderDetail,
  selectedModuleProducts,
  selectedUserProduct,
  loading,
  currentModuleServiceContracts,
  currentUserServiceContract,
  followUpUserServiceContract,
  currentHorseCareServiceContract,
  currentHorseFullServiceContract,
  showEmptyHorseServiceRow,
}: Props): JSX.Element {
  const { parseAndFormatMoney, formatDate } = useAccount();
  const { t } = useTranslation();
  const { isInTrial } = useTrialExpired();

  /**
   * Get the module products from the order (dry)
   */
  const purchaserOrderDetailModuleProducts = purchaserOrderDetail?.order_items.filter(item =>
    selectedModuleProducts?.some(product => product.uid === item.product_uid),
  );

  /**
   * List the cancelled module products
   */
  const cancelledModuleProducts = useMemo(() => {
    return currentModuleServiceContracts?.filter(
      serviceContract => !selectedModuleProducts?.some(selectedProduct => selectedProduct.uid === serviceContract.product_uid),
    );
  }, [currentModuleServiceContracts, selectedModuleProducts]);

  /**
   * List the active module products
   */
  const activeModuleServiceContracts = useMemo(() => {
    return currentModuleServiceContracts?.filter(product =>
      selectedModuleProducts?.some(selectedProduct => selectedProduct.uid === product.product_uid),
    );
  }, [currentModuleServiceContracts, selectedModuleProducts]);

  /**
   * Sum the prices of the selected module products
   */
  const moduleTotalSum = useMemo(() => {
    // sum the prices of the selected module products that are from the purchaser order
    const totalPurchaseOrder = (purchaserOrderDetailModuleProducts ?? []).reduce(
      (total, item) => {
        return {
          price: total.price + Number(item.unit_price),
          vat: total.vat + Number(item.total_vat.amount),
        };
      },
      {
        price: 0,
        vat: 0,
      },
    );

    // add the prices of the active module products
    return (activeModuleServiceContracts ?? []).reduce((total, item) => {
      return {
        price: total.price + Number(item.fixed_price),
        vat: total.vat + Number(item.total_vat),
      };
    }, totalPurchaseOrder);
  }, [activeModuleServiceContracts, purchaserOrderDetailModuleProducts]);

  /**
   * Return the horse services extend automatically and the end date
   */
  const horseServicesExtendAutomaticallyAndEndDt = useMemo(() => {
    return horseServicesFormatExtendAutomaticallyAndEndDt(currentHorseCareServiceContract, currentHorseFullServiceContract);
  }, [currentHorseCareServiceContract, currentHorseFullServiceContract]);

  /**
   * Get the currency by fetching it from the first service contract
   * As we assume we are not mixing currencies in the same subscription
   */
  const currency = useMemo(() => {
    return currentUserServiceContract?.currency ?? 'eur';
  }, [currentUserServiceContract?.currency]);

  /**
   * Determine the user account product price.
   */
  const currentUserServiceContactOrProductPrice = useMemo(() => {
    let price = 0;
    let vat = 0;
    if (selectedUserProduct) {
      price = Number(selectedUserProduct.pricePoint.price);

      // we can get the vat if there is a current user service contract
      if (currentUserServiceContract) {
        // recalculate the vat based on the price and the vat percentage
        vat = (price * Number(currentUserServiceContract.vat_percentage.percentage)) / 100;
      } else {
        // we could also check the order items if we have the user account there to fetch the vat
        const userProductFromOrderDetail = purchaserOrderDetail?.order_items.find(
          item => item.product_uid === selectedUserProduct.product.uid,
        );

        if (userProductFromOrderDetail) {
          vat = Number(userProductFromOrderDetail.total_vat.amount);
          price = Number(userProductFromOrderDetail.unit_price); // this price could change if we get this from the orderDetail
        }
      }
    } else if (followUpUserServiceContract) {
      // price = Number(currentUserServiceContract.fixed_price);
      // vat = Number(currentUserServiceContract.total_vat);
    } else if (currentUserServiceContract) {
      price = Number(currentUserServiceContract.fixed_price);
      vat = Number(currentUserServiceContract.total_vat);
    }

    return {
      price,
      vat,
    };
  }, [currentUserServiceContract, followUpUserServiceContract, purchaserOrderDetail?.order_items, selectedUserProduct]);

  /**
   * Sum up the horse counts
   */
  const horsesSummary = useMemo(() => {
    return calculateHorsePrices(currentHorseCareServiceContract, currentHorseFullServiceContract);
  }, [currentHorseCareServiceContract, currentHorseFullServiceContract]);

  /**
   * Determine if the horse service is cancelled
   */
  const horseCancelled = useMemo(() => {
    if (isInTrial) return false;
    return (
      !showEmptyHorseServiceRow &&
      horseServicesExtendAutomaticallyAndEndDt &&
      horseServicesExtendAutomaticallyAndEndDt.extendAutomatically === false
    );
  }, [horseServicesExtendAutomaticallyAndEndDt, isInTrial, showEmptyHorseServiceRow]);

  /**
   * Calculate the total price of the subscription
   */
  const priceTotal = useMemo(() => {
    let horseTotal = horsesSummary.care.price + horsesSummary.full.price;
    let horseTotalVat = horsesSummary.care.vat + horsesSummary.full.vat;

    // we reset the horse prices if the service is cancelled
    if (horseCancelled) {
      horseTotal = 0;
      horseTotalVat = 0;
    }

    return {
      price: moduleTotalSum.price + currentUserServiceContactOrProductPrice.price + horseTotal,
      vat: moduleTotalSum.vat + currentUserServiceContactOrProductPrice.vat + horseTotalVat,
    };
  }, [
    currentUserServiceContactOrProductPrice.price,
    currentUserServiceContactOrProductPrice.vat,
    horseCancelled,
    horsesSummary.care.price,
    horsesSummary.care.vat,
    horsesSummary.full.price,
    horsesSummary.full.vat,
    moduleTotalSum.price,
    moduleTotalSum.vat,
  ]);

  return (
    <>
      <div className='mt-10 p-5 border border-gray-200 rounded-md bg-gray-50'>
        <h3 className='font-semibold text-lg'>{t('your-subscription', 'Your subscription')}</h3>
        <ul className='mt-5 list-inside space-y-1'>
          <li key='user-accounts'>
            <div className='flex gap-x-2 items-center'>
              {t('user-account', 'User account')}
              <span className='ml-auto'>
                {loading ? (
                  <Skeleton className='h-4 w-14' />
                ) : (
                  translateFreePrice(String(currentUserServiceContactOrProductPrice.price), currency, parseAndFormatMoney, t)
                )}
              </span>
            </div>
            {followUpUserServiceContract && (
              <p className='mt-1 text-xs italic'>
                {t(
                  'user-contract-follow-up',
                  'On {{date}} your new user contract will be active. This new contract contains {{numberUsers}} users.',
                  {
                    date: formatDate(new Date(String(followUpUserServiceContract.end_dt))),
                    numberUsers: translatedMaxUnitCount(t, followUpUserServiceContract.maximum_unit_count).toLowerCase(),
                  },
                )}
              </p>
            )}
          </li>

          {!currentHorseCareServiceContract && !currentHorseFullServiceContract && showEmptyHorseServiceRow && (
            <li key='horses' className='flex gap-x-2 items-center'>
              <span>
                {t('horses', 'Horses')} <span className='text-xs'>{'*'}</span>
              </span>

              <span className='ml-auto '>{loading ? <Skeleton className='h-4 w-14' /> : t('pay-per-horse', 'Pay per horse')}</span>
            </li>
          )}

          {currentHorseCareServiceContract && currentHorseFullServiceContract && (
            <>
              <li key='horses' className='flex gap-x-2 items-center'>
                <span
                  className={classNames({
                    'line-through': horseCancelled,
                  })}
                >
                  {t('horses', 'Horses')} <span className='text-xs'>{'*'}</span>
                </span>
                {horseCancelled && (
                  <Badge size={BadgeSize.Small} color={AllColors.Orange}>
                    {t('ends-on', 'ends on {{date}}', {
                      date: formatDate(new Date(String(horseServicesExtendAutomaticallyAndEndDt?.endDt))),
                    })}
                  </Badge>
                )}

                <span
                  className={classNames('ml-auto', {
                    'line-through': horseCancelled,
                  })}
                >
                  {loading ? (
                    <Skeleton className='h-4 w-14' />
                  ) : (
                    parseAndFormatMoney(String(horsesSummary.full.price + horsesSummary.care.price), currency)
                  )}
                </span>
              </li>

              <li className='flex gap-x-2 items-center text-sm pl-2'>
                <div className='flex items-center gap-x-1'>
                  <span>
                    - {horsesSummary.care.count}
                    {'x'}
                  </span>
                  <CareBadge size={BadgeSize.Normal} />
                </div>
                <span
                  className={classNames('ml-auto font-light', {
                    'line-through': horseCancelled,
                  })}
                >
                  {loading ? <Skeleton className='h-4 w-14' /> : parseAndFormatMoney(String(horsesSummary.care.price), currency)}
                </span>
              </li>

              <li className='flex gap-x-2 items-start text-sm pl-2'>
                <div className='flex items-start gap-x-1'>
                  <span>
                    - {horsesSummary.full.count}
                    {'x'}
                  </span>
                  <div>
                    <div className='flex gap-x-1 items-center'>
                      <SportBadge size={BadgeSize.Normal} /> {t('and-or', 'and/or')} <BreedingBadge size={BadgeSize.Normal} />{' '}
                    </div>
                    <p className='mt-1 text-xs text-gray-500'>
                      {t(
                        'subscription-usage-care-sport-breeding-desc',
                        'Care planning is included when sport and/or breeding planning is used',
                      )}
                    </p>
                  </div>
                </div>
                <span
                  className={classNames('ml-auto font-light', {
                    'line-through': horseCancelled,
                  })}
                >
                  {loading ? <Skeleton className='h-4 w-14' /> : parseAndFormatMoney(String(horsesSummary.full.price), currency)}
                </span>
              </li>
            </>
          )}

          <li key='additional-modules' className='flex gap-x-2 items-center'>
            {t('additional-modules', 'Additional modules')}
            <span className='ml-auto'>
              {loading ? <Skeleton className='h-4 w-14' /> : parseAndFormatMoney(String(moduleTotalSum.price), currency)}
            </span>
          </li>
          {selectedModuleProducts
            // first filter the products that are in the order
            ?.filter(product => purchaserOrderDetail?.order_items.find(item => item.product_uid === product.uid) !== undefined)
            .map(product => {
              // we know that the product is in the order, so we can safely cast it
              const purchaserProduct = purchaserOrderDetail?.order_items.find(
                item => item.product_uid === product.uid,
              ) as PurchaserOrderItem;

              return (
                <li key={product.uid} className='flex gap-x-2 items-center text-sm pl-2'>
                  -<span>{product.name}</span>
                  <span className='ml-auto font-light'>
                    {loading ? (
                      <Skeleton className='h-4 w-14' />
                    ) : (
                      translateFreePrice(purchaserProduct.unit_price, currency, parseAndFormatMoney, t)
                    )}
                  </span>
                </li>
              );
            })}

          {activeModuleServiceContracts?.map(contract => (
            <li key={contract.uid} className='flex gap-x-2 items-center text-sm pl-2 '>
              -<span>{getModuleName(plans, contract.module, t)}</span>
              {contract.extend_automatically === false && (
                <>
                  <Badge size={BadgeSize.Small} color={AllColors.Blue}>
                    {t('reactivated', 'Reactivated')}
                  </Badge>
                </>
              )}
              <span className='ml-auto font-light'>
                {loading ? (
                  <Skeleton className='h-4 w-14' />
                ) : (
                  translateFreePrice(contract.fixed_price ?? '0', currency, parseAndFormatMoney, t)
                )}
              </span>
            </li>
          ))}

          {cancelledModuleProducts?.map(contract => (
            <li key={`cancelled-${contract.uid}`} className='flex gap-x-2 items-center text-sm pl-2'>
              -<span className='line-through'>{getModuleName(plans, contract.module, t)}</span>
              {contract.extend_automatically === false && (
                <Badge size={BadgeSize.Small} color={AllColors.Orange}>
                  {t('ends-on', 'ends on {{date}}', {
                    date: formatDate(new Date(String(contract.end_dt))),
                  })}
                </Badge>
              )}
              {contract.extend_automatically === true && (
                <Badge size={BadgeSize.Small} color={AllColors.Red}>
                  {t('will-be-stopped', 'will be stopped')}
                </Badge>
              )}
              <span className='ml-auto font-light line-through'>
                {loading ? (
                  <Skeleton className='h-4 w-14' />
                ) : (
                  translateFreePrice(contract.fixed_price ?? '0', currency, parseAndFormatMoney, t)
                )}
              </span>
            </li>
          ))}
        </ul>

        <div className='flex justify-between gap-x-2 items-center border-t mt-4 pt-4'>
          <p className='font-semibold'>{t('total-monthly-excl-vat', 'Total monthly (excl. VAT)')}</p>
          {loading ? (
            <Skeleton className='h-4 w-14' />
          ) : (
            <span className='font-semibold'>{parseAndFormatMoney(String(priceTotal.price), currency)}</span>
          )}
        </div>

        <div className='flex justify-between gap-x-2 items-center font-light text-sm'>
          <p>{t('total-monthly-incl-vat', 'Total monthly (incl. VAT)')}</p>
          {loading ? (
            <Skeleton className='h-4 w-14' />
          ) : (
            <span>{parseAndFormatMoney(String(priceTotal.price + priceTotal.vat), currency)}</span>
          )}
        </div>
      </div>

      {((currentHorseCareServiceContract && currentHorseFullServiceContract) || showEmptyHorseServiceRow) && (
        <div>
          <p className='text-sm text-gray-400'>
            <Trans
              i18nKey='activity-planning-info-text-subscription-footer'
              defaults='* You may create as many horses as you like, free of charge. However, if you enable care, sport or breeding usage (activity planning) for a horse additional fees apply. The price per horse used in activity planning can be found on <0>equinem.com/pricing</0>'
              components={[
                <a
                  key='link'
                  href='https://equinem.com/pricing/'
                  target='_blank'
                  rel='noreferrer noopener'
                  className='text-blue-500 underline'
                >
                  equinem.com/pricing
                </a>,
              ]}
            />
          </p>
        </div>
      )}
    </>
  );
}

export default SubscriptionTable;
