/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ios` - ios
 * * `android` - android
 * * `web` - web
 */
export enum CustomFCMDeviceTypeEnum {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}
