import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../ui/Layout';
import SettingsTile from 'components/Activities/SettingsTile';
import { PageMaxWidth } from 'ui/Layout/Page';
import SettingsCalendarTile from 'components/Activities/SettingsCalendarTile';
import SettingsActivityTypesTile from 'components/Activities/SettingsActivityTypesTile';

export default function ActivitiesSettings(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Page title={t('settings', 'Settings')} maxWidth={PageMaxWidth.Tile}>
      <div className='space-y-4'>
        <SettingsTile />
        <SettingsCalendarTile />
        <SettingsActivityTypesTile />
      </div>
    </Page>
  );
}
