import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { AvailableWeekdaysEnum } from 'openapi';
import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Hint } from 'ui/Hint';
import { InputError } from 'ui/InputError';
import { Label } from 'ui/Label';

export type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  error?: string;
  required?: boolean;
  hint?: string;
  className?: string;
};

// A horizontal multi select for the days of the week.
export default function MultiSelectWeekDayInput<T extends FieldValues>({
  name,
  control,
  label,
  error,
  required,
  hint,
  className,
}: Props<T>): JSX.Element {
  const { formatDateIntl } = useAccount();

  return (
    <div className={classNames('group relative', className)}>
      {label && (
        <Label className='mb-2'>
          {label} {required && '*'}
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className='space-x-1'>
            {Object.values(AvailableWeekdaysEnum).map((element, index) => (
              <div key={index} className='inline-block relative w-7 h-7'>
                <input
                  type='checkbox'
                  key={index}
                  name={`weekday.${index}`}
                  className='peer w-full h-full appearance-none border cursor-pointer rounded-full bg-gray-200 checked:bg-blue-500 border-none'
                  // Check if the weekday is part of weekDays by bit flag check.
                  checked={((field.value ?? []) as Array<AvailableWeekdaysEnum>).includes(element)}
                  onChange={() => {
                    const values = (field.value ?? []) as Array<AvailableWeekdaysEnum>;
                    if (values.includes(element)) {
                      field.onChange(values.filter(weekDays => weekDays !== element));
                    } else {
                      field.onChange([...values, element]);
                    }
                  }}
                />
                <span className='absolute inset-0 text-center pointer-events-none text-xs flex items-center justify-center text-gray-500 peer-checked:text-white'>
                  {formatDateIntl(new Date(Date.UTC(2024, 9, 7 + index)), { weekday: 'narrow' })}
                </span>
              </div>
            ))}
          </div>
        )}
      />
      {hint && <Hint>{hint}</Hint>}
      {error && <InputError>{error}</InputError>}
    </div>
  );
}
