import { CheckCircle, Info, Warning, WarningOctagon } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { mapSeverityToColor } from 'utilities/colors';
import { Severity } from 'utilities/severity';

export interface AlertProps {
  message: string | React.ReactNode;
  title?: string;
  // This will help us get more flexibility while using alert. We can use any color from tailwindcss & Severity also for logical cases
  severity: Severity;
  className?: string;
}

function Alert({ message, severity, title, className }: AlertProps): JSX.Element {
  const color = useMemo(() => {
    return mapSeverityToColor(severity);
  }, [severity]);

  const icon = useMemo(() => {
    // Using a switch statement to determine which icon to display based on the severity.
    switch (severity) {
      // If the severity is "Danger", display the WarningOctagon icon.
      case Severity.Danger:
        return <WarningOctagon className={'h-5 w-5'} />;
      // If the severity is "Success", display the CheckCircle icon.

      case Severity.Success:
        return <CheckCircle className={'h-5 w-5'} />;
      // If the severity is "Warning", display the Warning icon.

      case Severity.Warning:
        return <Warning className={'h-5 w-5'} />;
      // If the severity is "Info", display the Info icon.

      case Severity.Info:
        return <Info className={'h-5 w-5'} />;
    }
    return <></>;
  }, [severity]);

  const borderColor = color[300];
  const bgColor = color[100];
  const textColor = color[800];

  return (
    <>
      <div
        className={classNames('flex items-center p-3 border-l-4 gap-2', className)}
        style={{
          color: textColor,
          borderColor: borderColor,
          backgroundColor: bgColor,
        }}
        role='alert'
      >
        <div className='w-5 hidden md:block'>{icon}</div>
        <div className='grow'>
          {title && <span className='font-medium'>{title} </span>}
          {message}
        </div>
      </div>
    </>
  );
}

export default Alert;
