import * as Sentry from '@sentry/react';
import { decode, Jwt, JwtPayload } from 'jsonwebtoken';
import { AccountService, OpenAPI, TokenObtainPair, TokenRefresh } from '../openapi';

export interface LoginResult {
  refresh: string;
  access: string;
}

export interface LoginError {
  detail: LoginErrorDetails;
}

export interface LoginErrorDetails {
  message: string;
  code: string;
}

// The lookup keys for accessing local/session storage.
export enum BrowserStorageKeys {
  RefreshToken = 'refresh-token',
  SelectedOrganization = 'selected-organization',
  SidebarOpen = 'sidebar-open', // Is the sidebar shown (desktop flag only)
  ZoomLevel = 'zoom-level', // Zoom level of the EquiApp. For better readability (on Equiboards).
  SleepAfter = 'sleep-after', // Sleep after x minutes.
  PlanningGroupPreference = 'planning-group-preference', // Which view does the user prefer: horse, staff, stable
}

// A UserSession represents a logged in user.
export class UserSession {
  static async login(username: string, password: string, remember = false): Promise<UserSession> {
    const body: Partial<TokenObtainPair> = { username, password };
    const result: LoginResult = await AccountService.apiV5JwtObtainCreate({ requestBody: body as TokenObtainPair });
    return new UserSession(result.access, result.refresh, remember);
  }

  // Returns true if a jwt is expired.
  private static jwtIsExpired(jwtStr: string): boolean {
    const jwt: Jwt | null = decode(jwtStr, { complete: true });
    if (!jwt) {
      throw Error('Failed to decode jwt');
    }

    const payload = jwt.payload as JwtPayload;
    if (!payload.exp) {
      // No expiration date on the jwt.
      return false;
    }

    const expDate = new Date(0);
    expDate.setUTCSeconds(payload.exp);

    const now = new Date();

    return now > expDate;
  }

  // Removed expired jwt from local and session storage.
  public static cleanExpiredLogin(): void {
    try {
      const jwtInSessionStorage = sessionStorage?.getItem(BrowserStorageKeys.RefreshToken);
      const jwtInLocalStorage = localStorage?.getItem(BrowserStorageKeys.RefreshToken);
      if (jwtInSessionStorage && this.jwtIsExpired(jwtInSessionStorage)) {
        sessionStorage?.clear();
        console.info('Cleared expired session');
      }
      if (jwtInLocalStorage && this.jwtIsExpired(jwtInLocalStorage)) {
        localStorage?.removeItem(BrowserStorageKeys.RefreshToken);
        console.info('Cleared expired session from local storage');
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error('Failed to cleanup expired sessions due to an exception. Cleaning all sessions', e);
      sessionStorage?.clear();
      localStorage?.removeItem(BrowserStorageKeys.RefreshToken);
    }
  }

  // Does a simple check to see if we're logged in based on the browsers storage.
  public static hasSessionInBrowserStorage(): boolean {
    return (
      sessionStorage?.getItem(BrowserStorageKeys.RefreshToken) !== null || localStorage?.getItem(BrowserStorageKeys.RefreshToken) !== null
    );
  }

  // Restore a logged in user session from the session storage. This is typically helpfull for i.e. a hard page refresh.
  static async restoreFromBrowserStorage(abortSignal?: AbortSignal): Promise<UserSession> {
    let refreshJwtStr = sessionStorage?.getItem(BrowserStorageKeys.RefreshToken);
    const remember: boolean = localStorage?.getItem(BrowserStorageKeys.RefreshToken) !== null;

    if (!refreshJwtStr) {
      // Check if we have it in the local storage.
      refreshJwtStr = localStorage?.getItem(BrowserStorageKeys.RefreshToken);
    }

    if (!refreshJwtStr) {
      throw Error('No logged in user session');
    }

    const refreshJwt: Jwt | null = decode(refreshJwtStr, { complete: true });
    if (!refreshJwt) {
      throw Error('Failed to decode jwt');
    }

    const payload: JwtPayload = refreshJwt.payload as JwtPayload;
    const jwtExp: number | undefined = payload.exp;
    if (!jwtExp) {
      throw Error('Refresh jwt has not expiration date');
    }
    if (Math.floor(Date.now().valueOf() / 1000) > jwtExp) {
      throw Error('Session expired');
    }

    const body: Partial<TokenRefresh> = { refresh: refreshJwtStr };
    const promise = AccountService.apiV5JwtRefreshCreate({ requestBody: body as TokenRefresh });
    if (abortSignal) {
      abortSignal.onabort = () => promise.cancel();
    }
    const result: TokenRefresh = await promise;
    return new UserSession(result.access, refreshJwtStr, remember);
  }

  // Timer id of the jwt refresher.
  private refreshTimerId: number | undefined = undefined;

  private userId: string;

  constructor(
    private jwtStr: string,
    private jwtRefreshStr: string,
    private remember: boolean,
  ) {
    // Set the refresh token in the session storage. This will represent the logged in session.
    sessionStorage?.setItem(BrowserStorageKeys.RefreshToken, jwtRefreshStr);

    // Store the refresh token into the permanent local storage. This allows us
    // to fetch it later so that we stay logged in.
    if (this.remember) {
      localStorage?.setItem(BrowserStorageKeys.RefreshToken, jwtRefreshStr);
    } else {
      localStorage?.removeItem(BrowserStorageKeys.RefreshToken);
    }

    const jwt: Jwt | null = decode(jwtStr, { complete: true });
    if (!jwt) {
      throw Error('Failed to decode jwt');
    }

    // Just for type safety, first cast it to a JwtPayload object. Then get the
    // optional user_id field.
    const payload = jwt.payload as JwtPayload;
    this.userId = payload['user_id'] as string;

    this.refreshTimerId = window.setInterval(
      () => {
        if (!sessionStorage?.getItem(BrowserStorageKeys.RefreshToken)) {
          // We're logged out.
          return;
        }
        this.refreshJwt().catch(e => console.log('Failed to refresh jwt', e));
      },
      1000 * 60 * 60,
    ); // Refresh the jwt once every hour.

    // A methods that returns the latest auth token. This is used by the open api
    // auto generated code to do fetch calls to the backend.
    const getToken = async () => {
      return this.authToken;
    };
    OpenAPI.TOKEN = getToken;

    console.log('User session created with for user id ' + this.userId);
  }

  public logout(): void {
    if (this.refreshTimerId) {
      window.clearTimeout(this.refreshTimerId);
    }
    sessionStorage?.removeItem(BrowserStorageKeys.RefreshToken);
    localStorage?.removeItem(BrowserStorageKeys.RefreshToken);
    this.jwtRefreshStr = '';
    this.jwtStr = '';
    Sentry.setUser(null);
    OpenAPI.TOKEN = undefined;
  }

  // Use this token for Bearer token authentication for doing api calls to the backend.
  get authToken(): string {
    return this.jwtStr;
  }

  private async refreshJwt(): Promise<void> {
    const body: Partial<TokenRefresh> = { refresh: this.jwtRefreshStr };
    const promise = AccountService.apiV5JwtRefreshCreate({ requestBody: body as TokenRefresh });
    this.refreshTimerId = window.setTimeout(
      () => {
        if (!sessionStorage?.getItem(BrowserStorageKeys.RefreshToken)) {
          // We're logged out.
          return;
        }
        this.refreshJwt().catch(e => {
          console.log('Failed to refresh jwt', e);
        });
      },
      1000 * 60 * 60,
    ); // Refresh the jwt once every hour.
    const result: TokenRefresh = await promise;
    this.jwtStr = result.access;
  }
}
