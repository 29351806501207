import { PencilSimple, Plus } from '@phosphor-icons/react';
import SaveVatPercentageModal from 'components/Financial/SaveVatPercentageModal';
import { useOrganization } from 'context/OrganizationContext';
import {
  OAuth2Token,
  OauthService,
  ProviderEnum,
  VATPercentage,
  VATPercentageExternalProviderId,
  VatpercentageexternalprovideridsService,
  VatpercentagesService,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultApiPageSize, table, tableHiddenColumnMd, tableHiddenHeaderSm, tableTbody, tableThead, tableTheadTd } from 'ui/Const';
import Page, { PageMaxWidth } from 'ui/Layout/Page';
import classNames from 'classnames';
import { AppliedListFilter, ListFilterType } from 'components/Common/ListFilter';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import ListFilterButton from 'components/Common/ListFilter/ListFilterButton';
import { ButtonVariant } from 'ui/Button';
import useModal from 'ui/Modals/UseModal';
import SaveSpecialVatPercentageModal from 'components/Financial/SaveSpecialVatPercentageModal';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import { integrationName } from 'utilities/Integrations';
import VatPercentagesListItem from './Row';
import useCountries from 'hooks/UseCountries';
import ImportEuVatModal from 'components/Financial/ImportEuVatModal';

interface GroupedVatPercentages {
  id: string;
  name: string;
  vatPercentages: VATPercentage[];
}

export default function VatPercentagesPage(): JSX.Element {
  const { t } = useTranslation();
  const [vatPercentageModalOpen, setVatPercentageModalOpen] = useState<boolean>(false);
  const [vatPercentages, setVatPercentages] = useState<VATPercentage[]>();
  const [integrations, setIntegrations] = useState<OAuth2Token[]>();
  const [providerIds, setProviderIds] = useState<VATPercentageExternalProviderId[]>();
  const [edit, setEdit] = useState<VATPercentage>();
  const { selectedOrganization } = useOrganization();
  const [itemsVisible, setItemsVisible] = useState<number>(defaultApiPageSize);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const {
    modalIsVisible: specialVatPercentageModalVisible,
    closeModal: closeSpecialVatPercentageModalOpen,
    showModal: showSpecialVatPercentageModalOpen,
  } = useModal();
  const { modalIsVisible: importEuVatModalVisible, closeModal: closeImportEuVatModal, showModal: showImportEuVatModal } = useModal();
  const { countryById } = useCountries();

  const bookkeepingProviders = useMemo((): ProviderEnum[] => {
    return Object.values(ProviderEnum).filter(key => integrations?.find(integration => integration.name === key));
  }, [integrations]);

  // A list of types we can filter by. Like Type and Product.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!vatPercentages) {
      return undefined;
    }

    let inactiveCount = 0;

    vatPercentages.forEach(vatPercentage => {
      if (vatPercentage.hidden) {
        inactiveCount++;
        return;
      } else if (vatPercentage.start_date && !vatPercentage.is_active) {
        const start = new Date(Date.parse(vatPercentage.start_date));
        const today = new Date();
        if (start < today) {
          inactiveCount++;
          return;
        }
      }
    });

    const activeFilter: ListFilterType = {
      id: 'inactive',
      name: t('show-inactive', 'Show inactive'),
      options: [{ id: 'yes', name: t('yes', 'Yes'), count: inactiveCount }],
    };

    return [activeFilter];
  }, [t, vatPercentages]);

  const { filters } = useListFilter(filterTypes ?? []);

  // Filter based in the search criteria.
  const listFilter = (vatPercentages: VATPercentage[], appliedListFilter: AppliedListFilter[]): VATPercentage[] => {
    const showInactive = appliedListFilter.find(alf => alf.type.id === 'inactive' && alf.options.find(o => o.id === 'yes')) !== undefined;

    return vatPercentages.filter(vatPercentage => {
      let isInactive = false;
      if (vatPercentage.hidden === true) {
        isInactive = true;
      } else if (vatPercentage.start_date && !vatPercentage.is_active) {
        const start = new Date(Date.parse(vatPercentage.start_date));
        const today = new Date();
        if (start < today) {
          isInactive = true;
        }
      }

      if (!showInactive && isInactive) {
        return false;
      }
      return true;
    });
  };

  // The vat percentages to show (pull to refresh included).
  const visibleVatPercentages = useMemo((): { vatPercentages: VATPercentage[]; canFetchMore: boolean } => {
    if (!vatPercentages) {
      return { vatPercentages: [], canFetchMore: false };
    }
    const all = listFilter(vatPercentages ?? [], filters);
    return { vatPercentages: all.slice(0, itemsVisible), canFetchMore: itemsVisible < all.length };
  }, [vatPercentages, itemsVisible, filters]);

  const externalProviderIdsForEdit = useMemo((): VATPercentageExternalProviderId[] => {
    if (edit) {
      if (!providerIds) {
        return [];
      }
      return providerIds.filter(
        providerId => providerId.vat_percentage_uid === edit.uid && bookkeepingProviders.includes(providerId.provider),
      );
    }
    return [];
  }, [bookkeepingProviders, edit, providerIds]);

  // Group vat percentages
  const groupedVatPercentages = useMemo((): GroupedVatPercentages[] => {
    const map = new Map<string, VATPercentage[]>();
    visibleVatPercentages.vatPercentages.forEach(vatPercentage => {
      const countryCode = vatPercentage.country ?? '';
      if (map.has(countryCode)) {
        map.get(countryCode)?.push(vatPercentage);
      } else {
        map.set(countryCode, [vatPercentage]);
      }
    });
    const res: GroupedVatPercentages[] = [];
    for (const [country, array] of map) {
      let name = country;
      if (name === '') {
        name = t('edit-special-vat', 'Export and Reverse-charging');
      } else {
        name = countryById(name)?.name ?? '';
      }
      res.push({ id: country, name: name, vatPercentages: array });
    }
    return res;
  }, [visibleVatPercentages.vatPercentages, t, countryById]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    promises.appendList<VATPercentage>(
      'vat-percentages',
      () => {
        return VatpercentagesService.vatpercentagesList({
          organisationUid: selectedOrganization?.uid ?? '',
          o: 'country,-percentage',
        });
      },
      setVatPercentages,
      undefined,
      true,
    );
    promises.appendList<OAuth2Token>(
      'integrations',
      () => {
        return OauthService.oauthTokensList({ organisationUid: selectedOrganization?.uid ?? '' });
      },
      setIntegrations,
    );
    promises.appendList<VATPercentageExternalProviderId>(
      'providerIds',
      () => {
        return VatpercentageexternalprovideridsService.vatpercentageexternalprovideridsList({
          vatPercentageOrganisationUid: selectedOrganization?.uid ?? '',
        });
      },
      setProviderIds,
    );
    setApiPromises(promises);
    return promises;
  }, [selectedOrganization]);

  const onEdit = useCallback((vatPercentage: VATPercentage) => {
    setEdit(vatPercentage);
    setVatPercentageModalOpen(true);
  }, []);

  const onDelete = useCallback(
    async (vatPercentage: VATPercentage) => {
      await VatpercentagesService.vatpercentagesDestroy({
        organisationUid: selectedOrganization?.uid ?? '',
        uid: vatPercentage.uid,
      });
      setVatPercentages((vatPercentages ?? []).filter(vatPer => vatPer.uid !== vatPercentage.uid));
    },
    [vatPercentages, selectedOrganization],
  );

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const pageActions = useMemo(() => {
    return [
      {
        buttonVariant: ButtonVariant.Default,
        onClick: showImportEuVatModal,
        text: t('import-eu-vat', 'Import for EU'),
      },
      {
        onClick: showSpecialVatPercentageModalOpen,
        buttonVariant: ButtonVariant.Default,
        icon: <PencilSimple />,
        text: t('edit-special-vat', 'Export and Reverse-charging'),
      },
      {
        onClick: () => {
          setEdit(undefined);
          setVatPercentageModalOpen(true);
        },
        isMobileAddAction: true,
        buttonVariant: ButtonVariant.Primary,
        icon: <Plus />,
        text: t('add', 'Add'),
      },
    ];
  }, [t, showSpecialVatPercentageModalOpen, showImportEuVatModal]);

  return (
    <>
      <Page title={t('vat-percentages', 'VAT percentages')} maxWidth={PageMaxWidth.Tile} actions={pageActions} loading={apiPromises}>
        <PullScrollWrapper
          onRefresh={
            apiPromises
              ? () => {
                  apiPromises.refresh();
                  return apiPromises.watchAll();
                }
              : undefined
          }
          canFetchMore={visibleVatPercentages.canFetchMore}
          onFetchMore={() => {
            setItemsVisible(itemsVisible + defaultApiPageSize);
            return Promise.resolve();
          }}
        >
          <Tile noBoxOnMobile={true}>
            <FilterWrapper listFilterTypes={filterTypes}>
              <ListFilterButton listFilterTypes={filterTypes ?? []} />
            </FilterWrapper>

            {groupedVatPercentages.map(item => {
              return (
                <table className={table} key={item.id + 'body'}>
                  <thead className={tableThead}>
                    <tr>
                      <td colSpan={8}>
                        <h2 className='text-lg md:text-xl font-medium my-2 md:my-6 w-full ml-2 md:ml-0 md:pl-3'>{item.name}</h2>
                      </td>
                    </tr>
                    <tr className={classNames(tableHiddenHeaderSm)}>
                      <td className={classNames(tableTheadTd, 'pl-3')}>{t('percentage', 'Percentage')}</td>
                      <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('vat-level-short', 'Level')}</td>
                      {bookkeepingProviders.map(provider => (
                        <td key={provider} className={classNames(tableTheadTd, tableHiddenColumnMd)}>
                          <span>{integrationName(t, provider)}</span> {t('code', 'code')}
                        </td>
                      ))}
                      <td className='w-10 md:hidden' />
                    </tr>
                  </thead>
                  <tbody className={tableTbody}>
                    {item.vatPercentages.map(vatPercentage => (
                      <VatPercentagesListItem
                        key={vatPercentage.uid}
                        vatPercentage={vatPercentage}
                        bookkeepingProviders={bookkeepingProviders}
                        bookkeepingProvidersIds={providerIds ?? []}
                        onEdit={onEdit}
                        onDelete={onDelete}
                      />
                    ))}
                  </tbody>
                </table>
              );
            })}
          </Tile>
        </PullScrollWrapper>
      </Page>
      <SaveVatPercentageModal
        vatPercentageExternalProviderIds={externalProviderIdsForEdit}
        enabledBookkeepingIntegrations={bookkeepingProviders}
        onSaved={loadApiData}
        existingVatPercentage={edit}
        visible={vatPercentageModalOpen}
        closeModal={() => setVatPercentageModalOpen(false)}
        onClosed={() => {
          setEdit(undefined);
        }}
      />
      {vatPercentages && (
        <SaveSpecialVatPercentageModal
          vatPercentages={vatPercentages}
          visible={specialVatPercentageModalVisible}
          onRequestClose={reload => {
            closeSpecialVatPercentageModalOpen();
            if (reload) {
              loadApiData();
            }
          }}
        />
      )}
      <ImportEuVatModal isVisible={importEuVatModalVisible} onImported={loadApiData} onRequestClose={closeImportEuVatModal} />
    </>
  );
}
