import { ContactDetail, ModuleEnum, ModulePermissionsEnum } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utilities/date.utilities';
import { Tile } from 'ui/Layout/Tile';
import DescriptionList from 'ui/Layout/DescriptionList';
import { Pencil } from '@phosphor-icons/react';
import { stringArrayToString } from 'utilities/string.utility';
import { DefinitionItem } from 'ui/Layout/DescriptionList/DescriptionListItem';
import OwnedHorseListTile from './Tiles/OwnedHorseListTile';
import OrdersTile from './Tiles/OrdersTile';
import useCountries from 'hooks/UseCountries';
import { PageAction } from 'context/PageContext';
import usePermissions from 'hooks/UsePermissions';
import { ContactType, getContactType } from 'utilities/Contact';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import VatVerifiedBadge from './VatVerifiedBadge';
import useInvoiceLanguages from 'hooks/UseInvoiceLanguages';
import useModuleAccess from 'hooks/UseModuleAccess';
import { studBookMap } from 'components/Breeding/Studbook';

interface Props {
  contact: ContactDetail;
  onRequestUpdate: () => void;
}

function ContactDetails({ contact, onRequestUpdate }: Props): JSX.Element {
  const { t } = useTranslation();
  const { countryById } = useCountries();
  const { hasPermission } = usePermissions();
  const { invoiceLanguageById } = useInvoiceLanguages();
  const { hasModuleAccess } = useModuleAccess();

  // detect if the contact is a personal contact
  const isBusiness = contact.business_name !== '';

  /**
   * Get the name of the country
   */
  const country = useMemo((): string => {
    const countryResult = countryById(contact.country);
    if (countryResult) {
      return countryResult.name;
    }

    return '-';
  }, [contact.country, countryById]);

  const addressDescriptionList = useMemo(() => {
    const list: DefinitionItem[] = [];
    list.push({
      term: t('street', 'Street'),
      definition: stringArrayToString([contact.address_line1, contact.address_line3], ''),
    });
    if (contact.address_line2) {
      list.push({
        term: t('address-line2', 'Address line 2'),
        definition: contact.address_line2,
      });
    }
    list.push({
      term: t('postcode', 'Postcode'),
      definition: contact.postcode || '-',
    });
    list.push({
      term: t('city', 'City'),
      definition: contact.city || '-',
    });
    if (contact.state) {
      list.push({
        term: t('state', 'State'),
        definition: contact.state,
      });
    }
    list.push({
      term: t('country', 'Country'),
      definition: country || '-',
    });
    return list;
  }, [contact, t, country]);

  // generate the general description list
  // also include the business name if it's a business contact
  const generalDescriptionList = useMemo(() => {
    let list: DefinitionItem[] = [
      {
        term: t('customer-no', 'Customer No'),
        definition: contact.customer_id || '-',
      },
      {
        term: t('title', 'Title'),
        definition: contact.title || '-',
      },
      {
        term: t('first-name', 'First name'),
        definition: contact.first_name || '-',
      },
      {
        term: t('last-name', 'Last name'),
        definition: contact.last_name || '-',
      },
      {
        term: t('email', 'Email'),
        definition: contact.email || '-',
      },
      {
        term: t('phone-number', 'Phone number'),
        definition: contact.phone_number || '-',
      },
    ];

    if (contact.second_phone_number) {
      list.push({
        term: t('second-phone-number', 'Second phone number'),
        definition: contact.second_phone_number,
      });
    }

    // add the address fields
    list = [...list, ...addressDescriptionList];

    list.push({
      term: t('invoice-language', 'Invoice language'),
      definition: invoiceLanguageById(contact.invoice_language)?.name || '-',
    });

    if (contact.date_of_birth) {
      list.push({
        term: t('date-of-birth', 'Date of birth'),
        definition: formatDate(new Date(contact.date_of_birth)),
      });
    }

    if (contact.UBN) {
      list.push({
        term: t('UBN', 'UBN'),
        definition: contact.UBN,
      });
    }

    if (contact.note) {
      list.push({
        term: t('note', 'Note'),
        definition: contact.note,
      });
    }

    // Add breeding fields
    if (hasModuleAccess([ModuleEnum.SHOP_AND_BREEDING])) {
      const studbookName = studBookMap.find(studbook => studbook.code === (contact.studbook_preference as string))?.name;
      list.push({
        term: t('studbook-preference', 'Studbook preference'),
        definition: studbookName ?? '-',
      });
      if (contact.is_semen_collection_station) {
        list.push({
          term: t('is-semen-collection-station', 'Is semen collection station'),
          definition: t('yes', 'Yes'),
        });
      }
    }

    return list;
  }, [
    addressDescriptionList,
    contact.customer_id,
    contact.date_of_birth,
    contact.email,
    contact.first_name,
    contact.invoice_language,
    contact.last_name,
    contact.note,
    contact.phone_number,
    contact.second_phone_number,
    contact.title,
    invoiceLanguageById,
    hasModuleAccess,
    contact.is_semen_collection_station,
    contact.studbook_preference,
    contact.UBN,
    t,
  ]);

  const businessDescriptionList = useMemo(() => {
    let list: DefinitionItem[] = [
      {
        term: t('business-name', 'Business name'),
        definition: contact.business_name || '-',
      },
      {
        term: t('customer-no', 'Customer No'),
        definition: contact.customer_id || '-',
      },
    ];

    if (contact.first_name || contact.last_name) {
      list.push({
        term: t('first-name', 'First name'),
        definition: contact.first_name || '-',
      });
      list.push({
        term: t('last-name', 'Last name'),
        definition: contact.last_name || '-',
      });
    }

    list = [
      ...list,
      {
        term: t('email', 'Email'),
        definition: contact.email || '-',
      },
      {
        term: t('phone-number', 'Phone number'),
        definition: contact.phone_number || '-',
      },
    ];

    if (contact.second_phone_number) {
      list.push({
        term: t('second-phone-number', 'Second phone number'),
        definition: contact.second_phone_number || '-',
      });
    }

    list = [
      ...list,
      {
        term: t('company-registration-number', 'Company registration number'),
        definition: contact.company_registration_number || '-',
      },
      {
        term: t('vat-number', 'VAT number'),
        definition: contact.vat_number ? (
          <>
            {contact.vat_number} <VatVerifiedBadge vat_number_checked={contact.vat_number_checked} />
          </>
        ) : (
          '-'
        ),
      },
      {
        term: t('IBAN', 'IBAN'),
        definition: contact.IBAN || '-',
      },
    ];

    // add the address fields
    list = [...list, ...addressDescriptionList];

    if (contact.note) {
      list.push({
        term: t('note', 'Note'),
        definition: contact.note,
      });
    }

    // Add breeding fields
    if (hasModuleAccess([ModuleEnum.SHOP_AND_BREEDING])) {
      const studbookName = studBookMap.find(studbook => studbook.code === String(contact.studbook_preference))?.name;
      list.push({
        term: t('studbook-preference', 'Studbook preference'),
        definition: studbookName ?? '-',
      });
      if (contact.is_semen_collection_station) {
        list.push({
          term: t('is-semen-collection-station', 'Is semen collection station'),
          definition: t('yes', 'Yes'),
        });
      }
    }

    return list;
  }, [
    addressDescriptionList,
    contact.IBAN,
    contact.business_name,
    contact.company_registration_number,
    contact.customer_id,
    contact.email,
    contact.first_name,
    contact.last_name,
    contact.note,
    contact.phone_number,
    contact.second_phone_number,
    contact.vat_number,
    contact.vat_number_checked,
    contact.studbook_preference,
    contact.is_semen_collection_station,
    hasModuleAccess,
    t,
  ]);

  /**
   * Actions for the general tile
   */
  const generalTileAction = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_CONTACTS)) {
      return [
        {
          icon: <Pencil />,
          text: t('edit', 'Edit'),
          onClick: onRequestUpdate,
        },
      ];
    }
  }, [hasPermission, onRequestUpdate, t]);

  return (
    <>
      <Tile
        title={
          <>
            {t('general', 'General')}
            {getContactType(contact) === ContactType.Business && (
              <Badge className='ml-2' color={AllColors.Amber}>
                {t('business', 'Business')}
              </Badge>
            )}
            {getContactType(contact) === ContactType.Contact && (
              <Badge className='ml-2' color={AllColors.Blue}>
                {t('personal', 'Personal')}
              </Badge>
            )}
          </>
        }
        className='mb-4'
        actions={generalTileAction}
      >
        {isBusiness && <DescriptionList list={businessDescriptionList} />}
        {!isBusiness && <DescriptionList list={generalDescriptionList} />}
      </Tile>

      <OwnedHorseListTile contact={contact} />
      <OrdersTile contact={contact} />
    </>
  );
}

export default ContactDetails;
