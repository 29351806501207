/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `FLAT_FEE` - flat fee
 * * `STAIR_STEP` - stair step
 * * `TIERED` - tiered
 * * `CREDITS` - credits
 */
export enum PricingModelEnum {
  FLAT_FEE = 'FLAT_FEE',
  STAIR_STEP = 'STAIR_STEP',
  TIERED = 'TIERED',
  CREDITS = 'CREDITS',
}
