import classNames from 'classnames';
import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

function Label({ children, className }: Props): JSX.Element {
  return <label className={classNames('block text-sm font-medium leading-4 text-gray-600', className)}>{children}</label>;
}

export default Label;
