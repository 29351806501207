import classNames from 'classnames';
import { Contact, InvoiceDetail, PurchaserNameAndAddress } from 'openapi';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceNumber from './InvoiceNumber';
import { addDays } from 'utilities/date.utilities';
import { useOrganization } from 'context/OrganizationContext';

interface Props {
  invoice: InvoiceDetail | undefined;
  receiver: PurchaserNameAndAddress | Contact | undefined;
}

function InvoiceInfoBlock({ invoice, receiver }: Props): JSX.Element {
  // Translate to the language of the invoice.
  const { t: translate, i18n } = useTranslation();
  const t = invoice ? i18n.getFixedT(invoice.language) : translate;
  const { selectedOrganizationDetails } = useOrganization();

  const formatDate = useCallback(
    (date?: Date) => {
      return new Intl.DateTimeFormat(invoice?.language).format(date ?? new Date());
    },
    [invoice?.language],
  );

  const invoiceDate = useMemo((): Date => {
    return invoice && invoice.date ? new Date(invoice.date) : new Date();
  }, [invoice]);

  const expDate = useMemo((): Date => {
    if (invoice?.expiration_date) {
      return new Date(invoice?.expiration_date);
    } else {
      return addDays(invoiceDate, invoice?.first_due_interval ?? selectedOrganizationDetails?.invoice_first_due_interval ?? 0);
    }
  }, [invoiceDate, invoice, selectedOrganizationDetails]);

  return (
    <div className='flex justify-between py-2'>
      <div>
        <p className='text-xl md:text-3xl'>{t('invoice', 'Invoice')}</p>
        <p className='font-medium'>
          <span className='mr-1'>{t('invoice-no', 'Invoice No')}:</span>
          <InvoiceNumber invoice={invoice} />
        </p>
        {receiver?.customer_id && (
          <p className='font-medium'>
            <span className='mr-1'>{t('customer-no', 'Customer No')}:</span>
            {receiver?.customer_id}
          </p>
        )}
      </div>
      <div className='grid grid-cols-2'>
        <p className='font-medium'>{t('invoice-date', 'Invoice date')}:</p>
        <p className={classNames('text-right font-medium', { 'opacity-50': invoice === undefined })}>{formatDate(invoiceDate)}</p>
        <p className='font-medium'>{t('due-date', 'Due date')}:</p>
        <p className={classNames('text-right font-medium', { 'opacity-50': invoice === undefined })}>{formatDate(expDate)}</p>
      </div>
    </div>
  );
}
export default InvoiceInfoBlock;
