/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `DAY` - day
 * * `MONTH` - month
 * * `YEAR` - year
 */
export enum TimeFrameEnum {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
