import classNames from 'classnames';
import React, { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes, useState } from 'react';
import { Hint } from 'ui/Hint';
import { InputError } from 'ui/InputError';
import { Label } from 'ui/Label';

export enum InputSize {
  XSmall,
  Small,
  Normal,
  Large,
}

export interface DateProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  hint?: string;
  postText?: string;
  error?: string;
  preText?: string;
  invalid?: boolean;
  size?: InputSize;
}

const DateInputNative: ForwardRefRenderFunction<HTMLInputElement, DateProps> = (
  { label, error, preText, invalid = false, size = InputSize.Normal, onChange, onBlur, onFocus, hint, className, ...props },
  ref,
) => {
  const [focused, setFocused] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };
  const rootClassName = classNames(className, 'group relative');
  const inputRootClassName = classNames(
    'group w-full border flex items-center space-x-1.5 relative rounded-md border transition-all duration-200',
    {
      'border-primary': focused,
      'bg-gray-100 border-gray-200 cursor-not-allowed': props.disabled,
      'border-rose-400': error || invalid,
      'bg-white': !props.disabled,
      'py-1 px-1.5 ': size === InputSize.XSmall,
      'py-1 px-2 ': size === InputSize.Small,
      'py-1.5 px-2': size === InputSize.Normal,
      'py-2 px-2.5': size === InputSize.Large,
    },
  );

  // @note: We can't set bg-transparent because iPhones won't render the element anymore. Therefor we use bg-white.
  const inputClassName = classNames(
    'block flex-1 bg-white rounded-md border-0 focus:ring-0 focus:outline-none text-gray-900 ring-0 placeholder:text-gray-400',
    {
      'text-xs leading-5': size === InputSize.XSmall,
      'placeholder:text-rose-300': error || invalid,
      'cursor-not-allowed': props.disabled,
      'text-sm leading-6': size === InputSize.Small,
      'sm:text-sm sm:leading-6': size === InputSize.Normal,
      'text-large sm:text-base leading-7 sm:leading-6': size === InputSize.Large,
    },
  );
  return (
    <div className={rootClassName}>
      {label && (
        <Label className='mb-2'>
          {label} {props.required && '*'}
        </Label>
      )}
      <div className={inputRootClassName}>
        {preText && (
          <div className='pointer-events-none inset-y-0 flex items-center'>
            <span className='text-gray-500 sm:text-sm'>{preText}</span>
          </div>
        )}
        <input
          ref={ref}
          {...props}
          type={'date'}
          onFocus={handleFocus}
          autoComplete={props.autoComplete ?? 'off'}
          onBlur={handleBlur}
          onChange={handleChange}
          className={inputClassName}
        />
      </div>
      {hint && <Hint>{hint}</Hint>}
      {error && <InputError>{error}</InputError>}
    </div>
  );
};
export default forwardRef<HTMLInputElement, DateProps>(DateInputNative);
