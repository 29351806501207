// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This CSS file is need for the placement of the arrow */

.tooltip[data-placement^='top'] > .tooltip-arrow {
  bottom: -4px;
}

.tooltip[data-placement^='top'] > .tooltip-arrow::before {
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.tooltip[data-placement^='bottom'] > .tooltip-arrow {
  top: -4px;
}

.tooltip[data-placement^='bottom'] > .tooltip-arrow::before {
  border-left-width: 1px;
  border-top-width: 1px;
}

.tooltip[data-placement^='left'] > .tooltip-arrow {
  right: -4px;
}

.tooltip[data-placement^='left'] > .tooltip-arrow::before {
  border-right-width: 1px;
  border-top-width: 1px;
}

.tooltip[data-placement^='right'] > .tooltip-arrow {
  left: -4px;
}

.tooltip[data-placement^='right'] > .tooltip-arrow::before {
  border-left-width: 1px;
  border-bottom-width: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/Tooltip/tooltip.css"],"names":[],"mappings":"AAAA,yDAAyD;;AAEzD;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,wBAAwB;AAC1B","sourcesContent":["/* This CSS file is need for the placement of the arrow */\n\n.tooltip[data-placement^='top'] > .tooltip-arrow {\n  bottom: -4px;\n}\n\n.tooltip[data-placement^='top'] > .tooltip-arrow::before {\n  border-right-width: 1px;\n  border-bottom-width: 1px;\n}\n\n.tooltip[data-placement^='bottom'] > .tooltip-arrow {\n  top: -4px;\n}\n\n.tooltip[data-placement^='bottom'] > .tooltip-arrow::before {\n  border-left-width: 1px;\n  border-top-width: 1px;\n}\n\n.tooltip[data-placement^='left'] > .tooltip-arrow {\n  right: -4px;\n}\n\n.tooltip[data-placement^='left'] > .tooltip-arrow::before {\n  border-right-width: 1px;\n  border-top-width: 1px;\n}\n\n.tooltip[data-placement^='right'] > .tooltip-arrow {\n  left: -4px;\n}\n\n.tooltip[data-placement^='right'] > .tooltip-arrow::before {\n  border-left-width: 1px;\n  border-bottom-width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
