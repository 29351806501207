import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, Contact, ContactsService, InvitationsService, Role, RolesService, SentInvitation } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { ButtonVariant } from '../../../ui/Button';
import { TextInput } from 'ui/Inputs';
import { schemas } from 'openapi/zod-schemas';
import useFormError from 'api/hooks/useFormError';
import { zodInputIsRequired } from 'utilities/zod';
import MultiSelectInput from 'ui/Inputs/MultiSelectInput';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import useStables from 'api/hooks/useStables';
import classNames from 'classnames';
import { z } from 'zod';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onInvited?: () => void;
  contact: Contact;
  modalTitle: string;
}

function InviteAccountForExistingContactModal({ isVisible, onRequestCloseModal, onInvited, contact, modalTitle }: Props): JSX.Element {
  const [loadingRoles, setLoadingRoles] = useState<boolean>(false);
  const [roles, setRoles] = useState<Role[] | undefined>();
  const [apiErrorRole, setApiErrorRole] = useState<ApiErrorParser<Role[]> | undefined>();
  const [success, setSuccess] = useState<boolean>();

  const { selectedOrganization } = useOrganization();
  const { stables, error: apiErrorStable } = useStables();
  const { t } = useTranslation();

  // Form validation
  const schema = useMemo(() => {
    return schemas.SentInvitation.pick({
      invitee_email: true,
      stables: true,
      roles: true,
    }).extend({
      invitee_email: z.string().min(1).max(254).email(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    clearErrors,
    setValue,
    reset,
  } = useForm<SentInvitation>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Build a list with options based on the roles
   */
  const roleOptions = (roles ?? []).map<OptionItemInterface>(role => ({
    id: role.uid,
    name: role.name ?? '',
  }));

  /**
   * build the option list for the stables
   */
  const stableOptions: OptionItemInterface[] = (stables ?? []).map(stable => ({
    id: stable.uid,
    name: stable.location.business_name,
  }));

  /**
   * Close modal action
   */
  const close = useCallback(() => {
    onRequestCloseModal();
  }, [onRequestCloseModal]);

  /**
   * Event that is invoked when the modal is closed
   */
  const onClosed = useCallback(() => {
    setApiError(undefined);
    clearErrors();
    reset();
    setSuccess(false);
  }, [clearErrors, reset, setApiError]);

  /**
   * Submit handler
   */
  const onSubmit = async (data: SentInvitation) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    if (!data.invitee_email) return console.error('Email not defined');

    try {
      // first check if the contact has no email address
      // if so, patch the user first with this email address
      if (contact.email === null || contact.email === '') {
        await ContactsService.contactsPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: contact.uid,
          requestBody: {
            email: data.invitee_email,
          },
        });
      }

      // create the invitation
      await InvitationsService.invitationsCreate({
        inviterOrganisationUid: selectedOrganization.uid,
        requestBody: {
          ...data,
          contact_uid: contact.uid,
        },
      });

      clearErrors();
      setSuccess(true);
      setApiError(undefined);
      onInvited?.();
    } catch (error) {
      setApiError(new ApiErrorParser<SentInvitation>(error));
    }
  };

  /**
   * Load the roles from api
   */
  const loadRoles = useCallback(
    (organisationUid: string): CancelablePromise<Role[]> => {
      setLoadingRoles(true);
      const promise = RolesService.rolesList({
        organisationUid,
      });

      promise
        .then(res => setRoles(res))
        .catch(e => setApiErrorRole(new ApiErrorParser<Role>(e)))
        .finally(() => setLoadingRoles(false));
      return promise;
    },
    [setApiErrorRole],
  );

  /**
   * Render for both success and the default state the actions
   */
  const actions = useMemo((): ActionProps[] => {
    if (!success) {
      return [
        {
          loading: isSubmitting,
          disabled: loadingRoles,
          variant: ButtonVariant.Primary,
          text: t('invite', 'Invite'),
          type: 'submit',
          formId: 'inviteAccountForm',
        },
      ];
    } else {
      return [
        {
          variant: ButtonVariant.Primary,
          text: t('close', 'Close'),
          onClick: close,
        },
      ];
    }
  }, [close, isSubmitting, loadingRoles, success, t]);

  /**
   * Load the Roles
   */
  useEffect(() => {
    if (!isVisible) return;
    if (!selectedOrganization) return;
    const promise = loadRoles(selectedOrganization.uid);
    return () => promise.cancel();
  }, [isVisible, loadRoles, selectedOrganization]);

  /**
   * In case we just have one value for Stables,
   * we can use that first value and hide the form field but on only when we don't have a contact
   */
  useEffect(() => {
    if (!isVisible) return;
    if (stables && stables.length === 1) {
      setValue('stables', [stables[0].uid]);
    }
  }, [isVisible, setValue, stables]);

  /**
   * Set the roles in case we have a contact
   */
  useEffect(() => {
    if (!isVisible) return;
    const filteredRoles = (contact.roles ?? []).filter((value: string | null): value is string => typeof value === 'string');
    setValue('roles', filteredRoles);
  }, [isVisible, setValue, contact]);

  /**
   * In case we have a contact, we can set the email address
   */
  useEffect(() => {
    if (!isVisible) return;
    setValue('invitee_email', contact?.email ?? '');
  }, [contact, isVisible, setValue]);

  return (
    <PageModal
      width={success ? PageModalWidth.Sm : PageModalWidth.Base}
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'inviteAccountForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
    >
      <PageModalTitle title={modalTitle} onClose={close} />
      <PageModalContent>
        {success && (
          <p>
            {t(
              'invite-user-success',
              'User has been successfully invited to create an account in your organization. The user will receive an email with further instructions.',
            )}
          </p>
        )}
        {!success && (
          <>
            <ErrorSection errors={[apiErrorRole, apiErrorStable, ...nonFieldErrors]} className='mb-2' />
            <p className='mb-2 text-sm'>
              {t(
                'invite-user-desc',
                'Create a new account for this contact. The user will receive an email with either a link to create an account or to log in and accept the invitation.',
              )}
            </p>
            <div className='py-3 space-y-3'>
              <TextInput
                error={fieldError('invitee_email')}
                required={zodInputIsRequired<SentInvitation>(schema, 'invitee_email')}
                label={t('email-address', 'Email address')}
                {...register('invitee_email')}
              />

              <MultiSelectInput<SentInvitation>
                className={classNames({
                  hidden: stables && stables.length === 1,
                })}
                name='stables'
                required={zodInputIsRequired<SentInvitation>(schema, 'stables')}
                control={control}
                error={fieldError('stables')}
                label={t('stables', 'Stables')}
                options={stableOptions}
                showToolbar={false}
              />

              <MultiSelectInput<SentInvitation>
                loading={loadingRoles}
                name='roles'
                required={zodInputIsRequired<SentInvitation>(schema, 'roles')}
                control={control}
                error={fieldError('roles')}
                label={t('roles', 'Roles')}
                options={roleOptions}
                showToolbar={false}
              />
            </div>
          </>
        )}
      </PageModalContent>
      <PageModalActions actions={actions} />
    </PageModal>
  );
}

export default InviteAccountForExistingContactModal;
