import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Country, getCountries } from './countries.data';

interface ReturnType {
  countryById: (id: string | undefined) => Country | undefined;
  countryByName: (name: string | undefined) => Country | undefined;
  countries: Country[];
}

function useCountries(): ReturnType {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<Country[]>([]);

  /**
   * Load the translated countries
   */
  useEffect(() => {
    setCountries(getCountries(t));
  }, [t]);

  const countryById = useCallback(
    (id: string | undefined): Country | undefined => {
      if (id === undefined) return undefined;
      return countries.find(country => country.id.toLowerCase() === id.toLowerCase());
    },
    [countries],
  );

  const countryByName = useCallback(
    (name: string | undefined): Country | undefined => {
      if (name === undefined) return undefined;
      return countries.find(country => country.name.toLowerCase() === name.toLowerCase());
    },
    [countries],
  );

  return { countryById, countryByName, countries };
}

export default useCountries;
