import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import {
  PatchedVATPercentageUpdate,
  VatCategoryEnum,
  VATPercentage,
  VatpercentagesService,
  VATPercentageExternalProviderId,
  VatpercentageexternalprovideridsService,
  ProviderEnum,
} from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo } from 'react';
import { useFieldArray, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { objectDiff } from 'utilities/compareObject';
import { integrationName } from 'utilities/Integrations';
import { transformEmpty, transformEmptyToUndefined } from 'utilities/zod';
import { ButtonVariant } from '../../ui/Button';
import { DateInput, SelectInput, TextInput } from '../../ui/Inputs';
import useCountries from 'hooks/UseCountries';

type VATPercentageWithProviders = VATPercentage & { externalProviders: VATPercentageExternalProviderId[] };

interface Props {
  visible: boolean;
  enabledBookkeepingIntegrations: ProviderEnum[];
  vatPercentageExternalProviderIds: VATPercentageExternalProviderId[];
  closeModal: () => void;
  onClosed: () => void;
  existingVatPercentage?: VATPercentage;
  onSaved?: (vatPercentage: VATPercentage) => void;
}

// Return date of today in YYYY-MM-DD format.
const today = (): string => {
  return new Date().toISOString().substr(0, 10);
};

export default function SaveVatPercentageModal({
  visible,
  enabledBookkeepingIntegrations,
  vatPercentageExternalProviderIds,
  closeModal,
  onClosed,
  existingVatPercentage,
  onSaved,
}: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { countryById, countries } = useCountries();

  const schema = useMemo(() => {
    if (existingVatPercentage) {
      return schemas.VATPercentage.omit({
        uid: true,
        created_on: true,
        hidden: true,
        percentage: true,
        country: true,
        is_active: true,
        vat_category: true,
        vat_level: true,
      });
    } else {
      return schemas.VATPercentage.omit({
        uid: true,
        created_on: true,
        hidden: true,
        is_active: true,
        vat_category: true,
        vat_level: true,
        start_date: true,
      }).required({
        country: true,
      });
    }
  }, [existingVatPercentage]);

  const defaultValues = useMemo((): Partial<VATPercentageWithProviders> => {
    const externalProviders = enabledBookkeepingIntegrations.map(provider => {
      const found = vatPercentageExternalProviderIds.find(existingProvider => existingProvider.provider === provider);
      if (found) {
        return found;
      }
      return {
        uid: '',
        vat_percentage_uid: '',
        provider,
        external_provider_id: '',
      };
    });

    if (existingVatPercentage) {
      return { ...existingVatPercentage, externalProviders };
    } else {
      return { start_date: today(), vat_category: VatCategoryEnum.S, externalProviders };
    }
  }, [existingVatPercentage, vatPercentageExternalProviderIds, enabledBookkeepingIntegrations]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<VATPercentageWithProviders>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  // Use a fieldArray for the book keeping external providers.
  const { fields } = useFieldArray({
    name: 'externalProviders',
    control,
  });

  const closed = () => {
    setApiError(undefined);
    reset(defaultValues);
    onClosed();
  };

  /**
   * Submit handler
   */
  const onSubmit = async (data: VATPercentageWithProviders) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    try {
      let savedVatPercentage: VATPercentage | undefined;
      if (existingVatPercentage) {
        if (data.percentage !== existingVatPercentage.percentage) {
          const newPercentage = existingVatPercentage;
          newPercentage.percentage = data.percentage;
          newPercentage.start_date = data.start_date;
          const promiseCreate = VatpercentagesService.vatpercentagesCreate({
            organisationUid: selectedOrganization.uid,
            requestBody: data,
          });
          savedVatPercentage = (await promiseCreate) as VATPercentage;
        } else {
          const update = objectDiff(existingVatPercentage, data) as PatchedVATPercentageUpdate;
          const promiseUpdate = VatpercentagesService.vatpercentagesPartialUpdate({
            organisationUid: selectedOrganization.uid,
            uid: existingVatPercentage.uid,
            requestBody: update,
          });
          savedVatPercentage = (await promiseUpdate) as VATPercentage;
        }
      } else {
        const promiseCreate = VatpercentagesService.vatpercentagesCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: data,
        });
        savedVatPercentage = (await promiseCreate) as VATPercentage;
      }

      // CRUD the bookkeeping reference ids for the integrations.
      for (const prov of data.externalProviders) {
        prov.vat_percentage_uid = existingVatPercentage?.uid ?? savedVatPercentage.uid;
        if (prov.uid) {
          // Already exists
          if (prov.external_provider_purchases_id || prov.external_provider_sales_id) {
            // Transform undefined values to empty strings when patching.
            if (!prov.external_provider_purchases_id) {
              prov.external_provider_purchases_id = '';
            }
            if (!prov.external_provider_sales_id) {
              prov.external_provider_sales_id = '';
            }
            // Update
            await VatpercentageexternalprovideridsService.vatpercentageexternalprovideridsPartialUpdate({
              uid: prov.uid,
              vatPercentageOrganisationUid: selectedOrganization.uid,
              requestBody: prov,
            });
          } else {
            // Remove
            await VatpercentageexternalprovideridsService.vatpercentageexternalprovideridsDestroy({
              uid: prov.uid,
              vatPercentageOrganisationUid: selectedOrganization.uid,
            });
          }
        } else {
          // Create
          if (prov.external_provider_purchases_id || prov.external_provider_sales_id) {
            await VatpercentageexternalprovideridsService.vatpercentageexternalprovideridsCreate({
              vatPercentageOrganisationUid: selectedOrganization.uid,
              requestBody: prov,
            });
          }
        }
      }
      closeModal();
      onSaved?.(savedVatPercentage);
    } catch (error) {
      setApiError(new ApiErrorParser<VATPercentage>(error));
    }
  };

  /**
   * When a vat percentage is given, we should update this
   */
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // Returns the name of the country from the existingVatPercentage
  const countryName = useMemo(() => {
    if (!existingVatPercentage || !existingVatPercentage.country) {
      return t('universal', 'Universal');
    }
    const found = countryById(existingVatPercentage?.country?.toString());
    if (!found) {
      return existingVatPercentage.country.toString();
    }
    return found.name;
  }, [countryById, existingVatPercentage, t]);

  return (
    <>
      <PageModal open={visible} width={PageModalWidth.Xs} onClosed={closed}>
        <PageModalTitle
          title={existingVatPercentage ? t('edit-vat-percentage', 'Edit VAT percentage') : t('new-vat-percentage', 'New VAT percentage')}
          onClose={closeModal}
        />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />
          <form noValidate={true} id='SaveVatPercentageForm' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 grow'>
              {existingVatPercentage !== undefined && (
                <p>
                  {`${t('vat-percentage-edit-desc', 'You are editing the VAT percentage for')} ${
                    existingVatPercentage.percentage
                  }% (${countryName}).`}
                </p>
              )}
              {existingVatPercentage === undefined && (
                <SelectInput
                  label={t('country', 'Country')}
                  nullable={true}
                  options={countries}
                  error={fieldError('country')}
                  required={true}
                  nullableValue=''
                  {...register('country', { setValueAs: transformEmptyToUndefined() })}
                />
              )}
              <TextInput
                required={true}
                label={t('vat-percentage', 'VAT percentage')}
                {...register('percentage', {
                  valueAsNumber: false,
                  setValueAs: transformEmpty(undefined),
                })}
                error={fieldError('percentage')}
                postText='%'
                hint={t('vat-percentage-hint', 'The percentage of VAT on your products and services in the selected country.')}
              />
              {existingVatPercentage !== undefined && (
                <DateInput
                  control={control}
                  required={true}
                  label={t('start-date', 'Start date')}
                  name='start_date'
                  error={fieldError('start_date')}
                  hint={t('start-date-hint', 'When will this VAT percentage become active.')}
                />
              )}
              {fields.map((field, index) => (
                <>
                  <TextInput
                    key={`${field.id}_purchase`}
                    label={`${integrationName(t, field.provider)} ${t('identifier-vat-purchases', 'purchases VAT code')}`}
                    {...register(`externalProviders.${index}.external_provider_purchases_id`, {
                      setValueAs: transformEmptyToUndefined(),
                    })}
                    error={get(errors, `externalProviders.[${index}].external_provider_purchases_id`)?.message}
                    hint={t(
                      'vat-external-provider-hint-purchases',
                      'The code/id known at {{provider}} for this purchases VAT percentage.',
                      {
                        provider: field.provider,
                      },
                    )}
                  />
                  <TextInput
                    key={`${field.id}_sales`}
                    label={`${integrationName(t, field.provider)} ${t('identifier-vat-sales', 'sales VAT code')}`}
                    {...register(`externalProviders.${index}.external_provider_sales_id`, {
                      setValueAs: transformEmptyToUndefined(),
                    })}
                    error={get(errors, `externalProviders.[${index}].external_provider_sales_id`)?.message}
                    hint={t('vat-external-provider-hint-sales', 'The code/id known at {{provider}} for this sales VAT percentage.', {
                      provider: field.provider,
                    })}
                  />
                  <input type='hidden' value={field.uid} {...register(`externalProviders.${index}.uid`)} />
                  <input type='hidden' value={field.provider} {...register(`externalProviders.${index}.provider`)} />
                </>
              ))}
            </div>
          </form>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'SaveVatPercentageForm',
            },
          ]}
        />
      </PageModal>
    </>
  );
}
