import useApiPromises from 'api/hooks/useApiPromises';
import {
  CaretLeft,
  CaretRight,
  CirclesThree,
  FirstAidKit,
  Horse as HorseIcon,
  Jar,
  Plant,
  PlusCircle,
  Warning,
} from '@phosphor-icons/react';
import { FilterOption, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import { useOrganization } from 'context/OrganizationContext';
import {
  ActivityTypeCategoryEnum,
  Contact,
  Feed,
  Feeding,
  FeedingsService,
  FeedType,
  FeedTypeCategoryEnum,
  FeedtypesService,
  Horse,
  HorsefeedsService,
  HorseGroup,
  HorsegroupsService,
  ModulePermissionsEnum,
  Stable,
  StablesService,
} from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultApiPageSize, table, tableTbody, tableThead, tableTheadTd } from 'ui/Const';
import { Tile } from 'ui/Layout/Tile';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { ApiPromises } from 'utilities/ApiPromises';
import ListFilterButton, { FilterButtonTypeEnum } from '../../components/Common/ListFilter/ListFilterButton';
import useListFilter from '../../components/Common/ListFilter/useListFilter';
import Page from '../../ui/Layout/Page';
import { age, gender, listFilter, textFilter } from 'utilities/Horse';
import classNames from 'classnames';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import { HorseUsageBadges } from 'components/Horses/HorseUsageBadges';
import { BadgeSize } from 'ui/Badge/Badge';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import { useAccount } from 'context/AccountContext';
import { stableListFilterTypes } from 'utilities/Stable';
import { activeContacts, activeOrganizationHorses } from 'utilities/ApiRequests';
import FeedingModal from 'components/Feed/FeedingModal';
import usePermissions from 'hooks/UsePermissions';
import SaveFeedModal from 'components/Feed/SaveFeedModal';
import useModal from 'ui/Modals/UseModal';
import { ButtonVariant } from 'ui/Button';
import ApiErrorParser from 'api/ApiErrorParser';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';
import { isToday, isTomorrow } from 'date-fns';
import GestureWrapper from 'ui/GestureWrapper';

interface SelectedItem {
  horse?: Horse;
  feeding?: Feeding;
}

export default function FeedPlanning(): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const [searchText, setSearchText] = useState<string>('');
  const [stables, setStables] = useState<Stable[]>();
  const [horses, setHorses] = useState<Horse[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [groups, setGroups] = useState<HorseGroup[]>();
  const [feedings, setFeedings] = useState<Feeding[]>();
  const [feeds, setFeeds] = useState<Feed[]>();
  const [feedTypes, setFeedTypes] = useState<FeedType[]>();

  const [itemsVisible, setItemsVisible] = useState<number>(0);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [visibleCount, setVisibleCount] = useState<number>(3);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState<'left' | 'right' | undefined>();
  const timeOutRef = useRef<NodeJS.Timeout>(); // Timer to unset the animation
  const { accountDetails, formatNumber, formatTime } = useAccount();
  const { hasPermission } = usePermissions();

  const { showModal: showSaveFeedModal, closeModal: closeSaveFeedModal, modalIsVisible: saveFeedModalIsVisible } = useModal();
  const { showModal: showRemoveFeedModal, closeModal: closeRemoveFeedModal, modalIsVisible: removeFeedModalIsVisible } = useModal();
  const [removeFeedDialogError, setRemoveFeedDialogError] = useState<ApiErrorParser<void> | undefined>();

  const [edit, setEdit] = useState<Feed | undefined>();
  const [removeFeed, setRemoveFeed] = useState<Feed>();

  const { width } = useScreenSize();

  const [selectedItem, setSelectedItem] = useState<SelectedItem | undefined>();

  // When we have set a filter then we would like to load all available horses.
  // At first, we only load non-inactive horse with a stable (aka active horses).
  // Out of performance reasons we don't load all horses at first because all
  // horses from the webshop and all inactive horses are also in that list.
  const [loadAllHorses, setLoadAllHorses] = useState<boolean>(false);

  // Indicate if we're loading data from the api
  const { loading: loadingApiPromises } = useApiPromises({ apiPromises });

  const stableFilterTypes = useMemo((): ListFilterType[] | undefined => {
    if ((stables?.length ?? 0) <= 1) {
      // No need to show the stable filter when we only have one stable
      return undefined;
    }
    return [stableListFilterTypes(t, stables ?? [], accountDetails?.preferred_stable ?? undefined)];
  }, [t, stables, accountDetails]);

  // A list of types we can filter by. Like Gender and DateOfBirth.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!horses) {
      return undefined;
    }
    let genderUnknownCount = 0;
    let genderStallionCount = 0;
    let genderMareCount = 0;
    let genderGeldingCount = 0;
    let inactiveCount = 0;
    let noStableCount = 0;

    // Counting the filters only makes sense if we have loaded all horses.
    const showCounts = loadAllHorses && !loadingApiPromises;

    const birthYears = new Map<number, number>();
    const ageCount = new Map<number, number>();
    let hasNotSetDate = 0; // True if we have a date that is undefined in the list.
    horses.forEach(h => {
      if (h.date_of_birth) {
        const date = new Date(Date.parse(h.date_of_birth));
        birthYears.set(date.getFullYear(), birthYears.get(date.getFullYear()) ?? 1);
        const horseAge = age(h);
        if (horseAge !== undefined && horseAge !== null) {
          // We already known the horseAge is not undefined (but TS wants this if-condition)
          ageCount.set(horseAge, ageCount.get(horseAge) ?? 1);
        }
      } else {
        hasNotSetDate++;
      }

      if (h.sex === 1) {
        genderStallionCount++;
      } else if (h.sex === 2) {
        genderMareCount++;
      } else if (h.sex === 3) {
        genderGeldingCount++;
      } else {
        genderUnknownCount++;
      }

      if (h.hidden) {
        inactiveCount++;
      }
      if (!h.stable_uid) {
        noStableCount++;
      }
    });

    const ageFilterOptions: FilterOption[] = [];
    if (hasNotSetDate > 0) {
      ageFilterOptions.push({ id: 'unknown', name: t('unknown', 'Unknown'), count: showCounts ? hasNotSetDate : undefined });
    }

    const sortedAges = Array.from(ageCount);
    sortedAges.sort();
    sortedAges.forEach(value => {
      ageFilterOptions.push({ id: `${value[0]}`, name: `${value[0]}`, count: showCounts ? value[1] : undefined });
    });

    const ageFilter: ListFilterType = {
      id: 'age',
      name: t('age', 'Age'),
      options: ageFilterOptions,
    };

    const genderFilter: ListFilterType = {
      id: 'gender',
      name: t('gender', 'Gender'),
      options: [
        { id: 'unknown', name: t('gender-unknown', 'Unknown'), count: showCounts ? genderUnknownCount : undefined },
        { id: 'stallion', name: gender(SexEnum._1, t), count: showCounts ? genderStallionCount : undefined },
        { id: 'mare', name: gender(SexEnum._2, t), count: showCounts ? genderMareCount : undefined },
        { id: 'gelding', name: gender(SexEnum._3, t), count: showCounts ? genderGeldingCount : undefined },
      ],
    };

    const passiveFilter: ListFilterType = {
      id: 'passive',
      name: t('passive', 'Passive'),
      options: [
        { id: 'inactive', name: t('inactive', 'Inactive'), count: showCounts ? inactiveCount : undefined },
        { id: 'no-stable', name: t('no-stable', 'No stable'), count: showCounts ? noStableCount : undefined },
      ],
    };

    const myLocationFilter: ListFilterType = {
      id: 'location',
      name: t('pastures-and-other-locations', 'Pastures and other locations'),
      options: (contacts ?? [])
        .filter(contact => contact.external_location)
        .map(contact => {
          return { id: contact.uid, name: contact.business_name ?? '-' };
        }),
    };

    const groupFilter: ListFilterType = {
      id: 'group',
      name: t('group', 'Group'),
      options: (groups ?? []).map(group => {
        return { id: group.uid, name: group.name ?? t('unnamed-group', 'Unnamed group') };
      }),
    };

    const categoryFilter: ListFilterType = {
      id: 'category',
      name: t('usage-category', 'Category'),
      options: [
        { id: ActivityTypeCategoryEnum.CARE, name: t('activity-type-category-care', 'Care') },
        { id: ActivityTypeCategoryEnum.SPORT, name: t('activity-type-category-sport', 'Sport') },
        { id: ActivityTypeCategoryEnum.BREEDING, name: t('activity-type-category-breeding', 'Breeding') },
      ],
    };

    const filters = [categoryFilter, myLocationFilter, groupFilter, genderFilter, ageFilter, passiveFilter];

    return filters;
  }, [horses, loadAllHorses, loadingApiPromises, t, contacts, groups]);

  const { filters } = useListFilter(filterTypes ?? []);
  const { filters: stableFilters } = useListFilter(stableFilterTypes ?? []);

  // List of horses after filter
  const filteredHorses = useMemo((): Horse[] => {
    return textFilter(listFilter(horses ?? [], filters.concat(stableFilters)), searchText);
  }, [horses, filters, searchText, stableFilters]);

  // We don't show the full list directly. Make use of the fetch more strategy of PullToRefresh.
  const visibleHorses = useMemo((): { horses: Horse[]; canFetchMore: boolean } => {
    if (!horses) {
      return { horses: [], canFetchMore: false };
    }
    return { horses: filteredHorses.slice(0, itemsVisible), canFetchMore: itemsVisible < filteredHorses.length };
  }, [horses, itemsVisible, filteredHorses]);

  const loadMore = useCallback(async (): Promise<void> => {
    if (!feedings) {
      throw Error('Activity types are not loaded yet');
    }
    const horseUidsToFetch = filteredHorses.slice(itemsVisible, itemsVisible + defaultApiPageSize).map(horse => horse.uid);
    const feedsResult = await HorsefeedsService.horsefeedsList({
      organisationUid: selectedOrganizationUid ?? '',
      horseUidIn: [horseUidsToFetch.join(',')],
    });

    setFeeds([...(feeds ?? []), ...feedsResult.results]);

    setItemsVisible(itemsVisible + defaultApiPageSize);
  }, [itemsVisible, selectedOrganizationUid, feeds, filteredHorses, feedings]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    setFeeds(undefined);
    const promises = new ApiPromises();
    setItemsVisible(0);

    if (!selectedOrganizationUid) {
      return promises;
    }

    promises.appendListObj<Horse>('horses', setHorses, activeOrganizationHorses(selectedOrganizationUid, generateCacheKey));

    promises.appendList<HorseGroup>(
      'horse-groups',
      () =>
        HorsegroupsService.horsegroupsList({
          organisationUid: selectedOrganizationUid,
        }),
      setGroups,
      generateCacheKey('horse-groups'),
    );
    promises.appendList<Stable>(
      'stables',
      () =>
        StablesService.stablesList({
          organisationUid: selectedOrganizationUid,
        }),
      setStables,
      generateCacheKey('stables'),
    );
    promises.appendList<FeedType>(
      'feed-types',
      () =>
        FeedtypesService.feedtypesList({
          organisationUid: selectedOrganizationUid,
          o: 'name',
        }),
      setFeedTypes,
    );
    promises.appendList<Feeding>(
      'feedings',
      () =>
        FeedingsService.feedingsList({
          organisationUid: selectedOrganizationUid,
        }),
      setFeedings,
    );

    if (hasPermission(ModulePermissionsEnum.VIEW_CONTACTS)) {
      promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganizationUid, generateCacheKey));
    }

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, generateCacheKey, hasPermission]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, loadApiData]);

  // When filters change the make sure we load all our horses.
  useEffect(() => {
    if (filters.length > 0 || searchText) {
      setLoadAllHorses(true);
    }
  }, [filters, searchText]); //eslint-disable-line

  /**
   * Set the items to show based on the screen size
   */
  useEffect(() => {
    const feedingCount = feedings?.length ?? 0;
    if (width > ScreenSize['3xl']) {
      setVisibleCount(Math.min(feedingCount, 6));
    } else if (width > ScreenSize['2xl']) {
      setVisibleCount(Math.min(feedingCount, 5));
    } else if (width > ScreenSize.xl) {
      setVisibleCount(Math.min(feedingCount, 4));
    } else if (width > ScreenSize.lg) {
      setVisibleCount(Math.min(feedingCount, 2));
    } else if (width > ScreenSize.md) {
      setVisibleCount(Math.min(feedingCount, 1));
    } else {
      setVisibleCount(Math.min(feedingCount, 1));
    }
  }, [width, feedings]);

  const horseColWidth = useMemo(() => {
    if (width <= ScreenSize.xs) {
      return '140px';
    }
    if (width <= ScreenSize.sm) {
      return '225px';
    }
    return '350px';
  }, [width]);

  const gridCols = useMemo(() => {
    switch (visibleCount) {
      case 1:
      default:
        return 'grid-cols-1';
      case 2:
        return 'grid-cols-2';
      case 3:
        return 'grid-cols-3';
      case 4:
        return 'grid-cols-4';
      case 5:
        return 'grid-cols-5';
      case 6:
        return 'grid-cols-6';
    }
  }, [visibleCount]);

  // Unset the slide animation when the animation is done.
  // Doesn't give us trouble when resizing the window for instance.
  useEffect(() => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
    timeOutRef.current = setTimeout(() => {
      setSlideDirection(undefined);
    }, 400);
    return () => clearTimeout(timeOutRef.current);
  }, [slideDirection]);

  return (
    <>
      <Page title={t('feeding-schedule', 'Feeding schedule')} loading={apiPromises}>
        <PullScrollWrapper
          onRefresh={() => loadApiData().watchAll()}
          canFetchMore={visibleHorses.canFetchMore}
          onFetchMore={() => loadMore()}
        >
          <Tile noBoxOnMobile={true} overflowContent={true}>
            <FilterWrapper listFilterTypes={filterTypes}>
              {(stables?.length ?? 0) > 1 && (
                <ListFilterButton
                  currentCountDisplay={() => `* ${t('your-preferred-stable', 'Your preferred stable')}`}
                  type={FilterButtonTypeEnum.Stable}
                  listFilterTypes={stableFilterTypes ?? []}
                />
              )}
              <ListFilterButton
                listFilterTypes={filterTypes ?? []}
                currentCountDisplay={() => t('current-horse-count', 'currently showing {{count}} horses', { count: filteredHorses.length })}
              />
              <input
                type='search'
                onChange={e => setSearchText(e.currentTarget.value)}
                size={10}
                placeholder={t('search-by-name-ueln-chipnr', 'Search by Name, UELN or Chipnr...')}
                className='placeholder:italic placeholder:text-sm px-2 max-w-md grow h-10 rounded-md border'
              />
              {loadingApiPromises && loadAllHorses && <Spinner size={SpinnerSize.Small} />}
            </FilterWrapper>
            <GestureWrapper
              className={table}
              onSwipeRight={() => {
                if (currentIndex > 0) {
                  setCurrentIndex(prevState => prevState - 1);
                  setSlideDirection('right');
                }
              }}
              onSwipeLeft={() => {
                if (currentIndex + visibleCount < (feedings?.length ?? 0)) {
                  setCurrentIndex(prevState => prevState + 1);
                  setSlideDirection('left');
                }
              }}
            >
              <div className={tableThead}>
                <div className='flex'>
                  <div className='w-10' />
                  <div className={classNames(tableTheadTd, 'flex items-center relative')} style={{ width: horseColWidth }}>
                    {currentIndex > 0 && (
                      <button
                        className='absolute right-0 px-2 h-full'
                        onClick={() => {
                          setCurrentIndex(prevState => prevState - 1);
                          setSlideDirection('right');
                        }}
                      >
                        <CaretLeft size={24} />
                      </button>
                    )}
                  </div>
                  <div className={classNames('grow grid', gridCols)}>
                    {feedings?.map((feedings, index) => {
                      const timeParts = feedings.time.split(':');
                      const date = new Date();
                      date.setHours(Number(timeParts[0]));
                      date.setMinutes(Number(timeParts[1]));
                      return (
                        <div
                          className={classNames('animate-duration-300 hidden relative items-center justify-center flex-col', {
                            '!flex': index >= currentIndex && index < currentIndex + visibleCount,
                            'animate-slideInRight': slideDirection === 'left',
                            'animate-slideInLeft': slideDirection === 'right',
                          })}
                          key={feedings.uid + currentIndex} // We add the current index to the key so it gets animated on any nav change.
                        >
                          <p>{feedings.name}</p>
                          <p className='text-sm'>{formatTime(date)}</p>
                        </div>
                      );
                    })}
                  </div>
                  <div className={'min-w-8'}>
                    {currentIndex + visibleCount < (feedings?.length ?? 0) && (
                      <button
                        className='h-full px-1'
                        onClick={() => {
                          setCurrentIndex(prevState => prevState + 1);
                          setSlideDirection('left');
                        }}
                      >
                        <CaretRight size={24} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {visibleHorses.horses.length === 0 && (
                <p className='py-32 flex items-center justify-center italic'>
                  {t('no-horses-in-feeding-schedule', 'No horses in stable or filter')}
                </p>
              )}
              <div className={tableTbody}>
                {visibleHorses.horses.map(horse => {
                  return (
                    <div key={horse.uid} className={classNames('border-b border-neutral-100 last:border-b-0', 'flex items-center')}>
                      <div className='text-center min-w-10 w-10 hidden md:block'>
                        <HorseIcon size={22} weight='light' className='inline' />
                      </div>
                      <div>
                        <div>
                          <div
                            className='flex flex-col items-start truncate pl-2 md:pl-0'
                            style={{ width: horseColWidth, maxWidth: horseColWidth }}
                          >
                            <div className='flex items-center gap-1'>
                              {horse.name}
                              {!horse.hidden && <HorseUsageBadges size={BadgeSize.Small} iconOnly={true} horse={horse} />}
                              {horse.hidden && <Badge color={AllColors.Rose}>{t('inactive', 'Inactive')}</Badge>}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classNames('grid grow', gridCols)}>
                        {feedings?.map((feeding, index) => {
                          const foundFeeds = feeds?.filter(feed => feed.feeding_uid === feeding.uid && feed.horse_uid === horse.uid);
                          const changesToday = foundFeeds?.find(feed => feed.end?.date && isToday(new Date(feed.end?.date)));
                          const changesTomorrow = foundFeeds?.find(feed => feed.end?.date && isTomorrow(new Date(feed.end?.date)));
                          return (
                            <FeedingModal
                              feedingChangesToday={changesToday !== undefined}
                              feedingChangesTomorrow={changesTomorrow !== undefined}
                              onRequestNewFeed={() => {
                                setEdit(undefined);
                                showSaveFeedModal();
                              }}
                              onRequestEditFeed={feed => {
                                setEdit(feed);
                                showSaveFeedModal();
                              }}
                              onRequestRemoveFeed={feed => {
                                setRemoveFeed(feed);
                                showRemoveFeedModal();
                              }}
                              feeds={foundFeeds}
                              feedTypes={feedTypes}
                              visible={
                                selectedItem?.horse?.uid === horse.uid &&
                                selectedItem.feeding?.uid === feeding.uid &&
                                !saveFeedModalIsVisible &&
                                !removeFeedModalIsVisible &&
                                (foundFeeds?.length ?? 0) > 0
                              }
                              key={feeding.uid + horse.uid} // We add the current index to the key so it gets animated on any nav change.
                              horse={horse}
                              feeding={feeding}
                              onRequestClose={() => setSelectedItem(undefined)}
                              className={classNames('animate-duration-300 hidden', {
                                '!block': index >= currentIndex && index < currentIndex + visibleCount,
                                'animate-slideInRight': slideDirection === 'left',
                                'animate-slideInLeft': slideDirection === 'right',
                              })}
                            >
                              <div
                                className={classNames(
                                  'relative items-center min-h-12 justify-center border-2 hover:cursor-pointer rounded-lg w-full flex flex-col overflow-hidden',
                                  {
                                    'border-blue-500':
                                      selectedItem?.horse?.uid === horse.uid &&
                                      selectedItem.feeding?.uid === feeding.uid &&
                                      ((foundFeeds?.length ?? 0) > 0 || saveFeedModalIsVisible),
                                    'border-transparent hover:border-inherit': !(
                                      selectedItem?.horse?.uid === horse.uid && selectedItem.feeding?.uid === feeding.uid
                                    ),
                                    'border bg-amber-100 rounded': changesToday || changesTomorrow,
                                  },
                                )}
                                onClick={() => {
                                  if (foundFeeds?.length === 0) {
                                    setEdit(undefined);
                                    if (hasPermission(ModulePermissionsEnum.MANAGE_FEED)) {
                                      showSaveFeedModal();
                                      setSelectedItem({ horse, feeding });
                                    }
                                  } else {
                                    setSelectedItem({ horse, feeding });
                                  }
                                }}
                              >
                                {(foundFeeds?.length ?? 0) > 0 && (
                                  <div className='grow max-w-full flex items-center flex-col justify-center'>
                                    {foundFeeds?.map(feed => {
                                      const feedingType = feedTypes?.find(type => type.uid === feed?.feed_type_uid);
                                      return (
                                        <div key={feed.uid} className='max-w-full flex gap-1 items-center select-none pr-0.5'>
                                          {feedingType?.category === FeedTypeCategoryEnum.ROUGHAGE && (
                                            <Plant size={22} weight='light' className='inline shrink-0' />
                                          )}
                                          {feedingType?.category === FeedTypeCategoryEnum.MEDICINE && (
                                            <FirstAidKit size={22} weight='light' className='inline shrink-0' />
                                          )}
                                          {feedingType?.category === FeedTypeCategoryEnum.SUPPLEMENT && (
                                            <Jar size={22} weight='light' className='inline shrink-0' />
                                          )}
                                          {feedingType?.category === FeedTypeCategoryEnum.CONCENTRATE && (
                                            <CirclesThree size={22} weight='light' className='inline shrink-0' />
                                          )}
                                          <span className='shrink truncate'>{feedingType?.display_name}</span>
                                          {feedingType?.warning && <Warning color={AllColors.Amber} className='inline text-sm' />}
                                          <span className='bg-neutral-300 rounded px-1 -py-0.5 text-sm text-nowrap'>
                                            {formatNumber(Number(feed.quantity))} {feedingType?.quantity_measure}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                {(changesToday || changesTomorrow) && (
                                  <p className='text-xs bg-amber-500 px-1 text-white text-center truncate w-full'>
                                    {changesToday && t('changes-today', 'Changes today')}
                                    {changesTomorrow && t('changes-tomorrow', 'Changes tomorrow')}
                                  </p>
                                )}
                                {hasPermission(ModulePermissionsEnum.MANAGE_FEED) && (
                                  <div className='flex flex-row items-center gap-1'>
                                    {foundFeeds?.length === 0 && <PlusCircle weight='light' size={24} className='text-gray-200 block' />}
                                  </div>
                                )}
                              </div>
                            </FeedingModal>
                          );
                        })}
                      </div>
                      <div className='md:min-w-8 h-full' />
                    </div>
                  );
                })}
              </div>
            </GestureWrapper>
          </Tile>
        </PullScrollWrapper>
        <SaveFeedModal
          selectedHorse={selectedItem?.horse}
          selectedFeeding={selectedItem?.feeding}
          onSaved={loadApiData}
          existingFeed={edit}
          visible={saveFeedModalIsVisible}
          feedTypes={feedTypes}
          closeModal={() => {
            // Unselect the item when there are no feeds. This just looks more intuitive for the user.
            const foundFeeds = feeds?.filter(
              feed => feed.feeding_uid === selectedItem?.feeding?.uid && feed.horse_uid === selectedItem?.horse?.uid,
            );
            if (foundFeeds?.length === 0) {
              setSelectedItem(undefined);
            }
            closeSaveFeedModal();
          }}
          onClosed={() => {
            setEdit(undefined);
          }}
        />
        <ActionModal
          open={removeFeedModalIsVisible}
          actions={[
            {
              text: t('cancel', 'Cancel'),
              variant: ButtonVariant.Default,
              onClick: () => {
                closeRemoveFeedModal();
                setRemoveFeedDialogError(undefined);
              },
            },
            {
              text: t('remove', 'Remove'),
              variant: ButtonVariant.PrimaryDanger,
              onClick: async () => {
                try {
                  await HorsefeedsService.horsefeedsDeletePartialUpdate({
                    organisationUid: selectedOrganizationUid ?? '',
                    uid: removeFeed?.uid ?? '',
                  });
                  closeRemoveFeedModal();
                  loadApiData();
                } catch (e) {
                  setRemoveFeedDialogError(new ApiErrorParser(e));
                }
              },
            },
          ]}
          title={t('remove-feed-confirm-title', 'Remove feed')}
        >
          <>
            <ErrorSection errors={removeFeedDialogError} />
            <p>{t('remove-feed-confirm-text', 'Are you sure you want to remove the selected feed?')}</p>
          </>
        </ActionModal>
      </Page>
    </>
  );
}
