import { IS_MOBILE_APP } from 'const';
import { App as CapacitorApp } from '@capacitor/app';
import { useCallback } from 'react';

export default function useAppStateChange(): {
  appStateChange: (callback: (active: boolean) => void, signal?: AbortSignal) => Promise<void>;
} {
  const appStateChange = useCallback(async (callback: (active: boolean) => void, signal?: AbortSignal) => {
    if (IS_MOBILE_APP) {
      try {
        const listener = await CapacitorApp.addListener('appStateChange', ({ isActive }) => callback(isActive));

        if (signal) {
          signal.onabort = () => {
            listener.remove();
          };
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  return {
    appStateChange,
  };
}
