import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';
import { ButtonVariant } from 'ui/Button';
import { useOrganization } from 'context/OrganizationContext';
import ApiErrorParser from 'api/ApiErrorParser';
import { ApiError, CatalogueProduct, PurchaserServiceContract, ServiceContractsService } from 'openapi';
import { SubscriptionState } from '../Helper';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onCancelledSubscription: (hash: SubscriptionState) => void;
  products: CatalogueProduct[] | undefined;
  currentModuleServiceContracts: PurchaserServiceContract[] | undefined;
  currentUserServiceContract: PurchaserServiceContract | undefined;
  currentHorseCareServiceContract: PurchaserServiceContract | undefined;
  currentHorseFullServiceContract: PurchaserServiceContract | undefined;
}

function CancelSubscriptionModal({
  isVisible,
  onRequestCloseModal,
  products,
  onCancelledSubscription,
  currentHorseCareServiceContract,
  currentHorseFullServiceContract,
  currentModuleServiceContracts,
  currentUserServiceContract,
}: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<unknown> | string | undefined>();

  const { t } = useTranslation();
  const { refresh, selectedOrganizationUid } = useOrganization();

  const onClosed = () => {
    setApiError(undefined);
    setIsSubmitting(false);
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const cancelSubscription = async () => {
    if (!selectedOrganizationUid) return;

    setIsSubmitting(true);

    try {
      // switch back the userAccount to the free plan
      // but only when we paid for the plan
      if (currentUserServiceContract && Number(currentUserServiceContract.fixed_price) > 0) {
        // Find the plan that is free
        // so we can move to that plan in stead
        const currentUserProduct = products?.find(product => product.uid === currentUserServiceContract.product_uid);
        const currentUserProductFreePricePoint = currentUserProduct?.price_points?.find(pricePoint => Number(pricePoint.price) === 0);
        if (!currentUserProductFreePricePoint) {
          console.error('No free price point found');
          setApiError('Unable to cancel your subscription at this time, please try again later or contact support');
          return;
        }

        // update the service contract to the free plan for the users
        await ServiceContractsService.serviceContractsPurchasedChangePartialUpdate({
          purchaserUid: selectedOrganizationUid,
          uid: currentUserServiceContract.uid,
          requestBody: {
            max_amount: currentUserProductFreePricePoint.max_units,
          },
        });
      }

      // cancel all modules
      for (const serviceContract of currentModuleServiceContracts ?? []) {
        await ServiceContractsService.serviceContractsPurchasedCancelPartialUpdate({
          purchaserUid: selectedOrganizationUid,
          uid: serviceContract.uid,
        });
      }

      // cancel the horse care service contract
      if (currentHorseCareServiceContract) {
        await ServiceContractsService.serviceContractsPurchasedCancelPartialUpdate({
          purchaserUid: selectedOrganizationUid,
          uid: currentHorseCareServiceContract.uid,
        });
      }

      // cancel the horse full service contract
      if (currentHorseFullServiceContract) {
        await ServiceContractsService.serviceContractsPurchasedCancelPartialUpdate({
          purchaserUid: selectedOrganizationUid,
          uid: currentHorseFullServiceContract.uid,
        });
      }

      // refresh the organization
      refresh();

      // and close the modal if all went well
      onRequestCloseModal();

      // // fire the success handler
      onCancelledSubscription(SubscriptionState.SUBSCRIPTION_CANCELLED);
    } catch (error) {
      if (error instanceof ApiError) {
        setApiError(new ApiErrorParser<unknown>(error));
      } else {
        console.error(error);
      }
      // throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ActionModal
      onClosed={onClosed}
      open={isVisible}
      onClose={onRequestCloseModal}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestCloseModal,
        },
        {
          text: t('cancel-subscription', 'Cancel subscription'),
          loading: isSubmitting,
          variant: ButtonVariant.PrimaryDanger,
          onClick: cancelSubscription,
        },
      ]}
      title={t('cancel-your-subscription', 'Cancel your subscription')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2 w-full'>
        {t(
          'cancel-subscription-modal-desc',
          'You are about to cancel your paid subscription. Your subscription will be downgraded to the free plan. The free plan includes the horse module with the horse profiles. All other functionality is disabled. However, if you want to use those features again, you can upgrade your license at any time.',
        )}
      </p>

      <p className='mt-4'>{t('cancel-subscription-modal-are-you-suer', 'Are you sure you want to cancel your subscription?')}</p>
    </ActionModal>
  );
}

export default CancelSubscriptionModal;
