import React, { useEffect, useMemo } from 'react';
import {
  AddressBook,
  Calendar,
  CalendarCheck,
  Dna,
  Horse,
  ShoppingCart,
  SquaresFour,
  Carrot,
  TrayArrowDown,
  User,
  Wallet,
  Wrench,
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { MenuItemConfig } from 'ui/Layout/Layout';
import usePermissions from './UsePermissions';
import { ModuleEnum, ModulePermissionsEnum } from 'openapi';
import { useAccount } from 'context/AccountContext';
import preferredStableQuery from 'utilities/PreferredStableQuery';
import useRvoReportCount from './UseRvoReportCount';
import useModuleAccess from './UseModuleAccess';
import { GroupBy } from 'utilities/Planning';

interface ReturnType {
  EquinemSideBarTopMenu: MenuItemConfig[];
  EquinemSideBarBottomMenu: MenuItemConfig[];
  EquinemFooterMenu: MenuItemConfig[];
  WebshopSideBarTopMenu: MenuItemConfig[];
  WebshopFooterMenu: MenuItemConfig[];
}

const useMenu = (webshopUrlPrefix = '/shop/:publicAccessUuid/'): ReturnType => {
  const { loadPermissions, isAdmin, hasPermission } = usePermissions();
  const { accountDetails, preferredPlanningGroupBy } = useAccount();
  const { t } = useTranslation();
  const { reportCount } = useRvoReportCount();
  const { hasModuleAccess } = useModuleAccess();

  /**
   * Load the permissions
   */
  useEffect(() => {
    const promise = loadPermissions();
    return () => promise?.cancel();
  }, [loadPermissions]);

  /**
   * Get the preferred stable url suffix so we can add this to the url if needed
   */
  const preferredStableUrlSuffix = useMemo(() => {
    return preferredStableQuery(accountDetails?.preferred_stable);
  }, [accountDetails]);

  const EquinemSideBarTopMenu = useMemo((): MenuItemConfig[] => {
    const menuItems: MenuItemConfig[] = [];

    let planningPath = '';
    switch (preferredPlanningGroupBy) {
      default:
      case GroupBy.Horse:
        planningPath = '/planning/horses';
        break;
      case GroupBy.Staff:
        planningPath = '/planning/staff';
        break;
      case GroupBy.Stable:
        planningPath = '/planning/stable';
        break;
    }

    // activities
    menuItems.push({
      name: t('menu-activities', 'Activities'),
      path: '/planning',
      icon: <Calendar />,
      isEnabled:
        hasPermission([ModulePermissionsEnum.VIEW_SCHEDULE, ModulePermissionsEnum.MANAGE_SCHEDULE]) &&
        hasModuleAccess([ModuleEnum.HORSE_SERVICES_CARE, ModuleEnum.HORSE_SERVICES_FULL]),
      subMenu: [
        {
          name: t('menu-planning', 'Planning'),
          path: planningPath + preferredStableUrlSuffix,
          isEnabled: hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE),
        },
        {
          name: t('menu-my-planning', 'My day'),
          path: '/planning/my',
          isEnabled: hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE),
        },
        {
          name: t('menu-care-planning', 'Care'),
          path: '/planning/care' + preferredStableUrlSuffix,
          isEnabled: hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE),
        },
        {
          name: t('menu-settings', 'Settings'),
          path: '/planning/settings',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_SCHEDULE),
        },
      ],
    });

    // horses
    menuItems.push({
      name: t('menu-horses', 'Horses'),
      path: '/horses',
      icon: <Horse />,
      isEnabled: hasPermission([
        ModulePermissionsEnum.MANAGE_HORSES,
        ModulePermissionsEnum.VIEW_HORSES,
        ModulePermissionsEnum.VIEW_OWN_HORSES,
      ]),
      subMenu: [
        {
          name: t('menu-horse-list', 'Horse list'),
          path: '/horses/list' + preferredStableUrlSuffix,
          isEnabled: hasPermission([
            ModulePermissionsEnum.MANAGE_HORSES,
            ModulePermissionsEnum.VIEW_HORSES,
            ModulePermissionsEnum.VIEW_OWN_HORSES,
          ]),
        },
        {
          name: t('menu-location-history', 'Location history'),
          path: '/horses/location-history' + preferredStableUrlSuffix,
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_HORSES),
          badge: reportCount,
        },
        {
          name: t('menu-settings', 'Settings'),
          path: '/horses/settings',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_HORSES),
        },
      ],
    });

    // facilities
    menuItems.push({
      name: t('menu-facilities', 'Facilities'),
      path: '/facilities',
      icon: <SquaresFour />,
      isEnabled: hasPermission([ModulePermissionsEnum.MANAGE_FACILITIES]) && hasModuleAccess(ModuleEnum.FACILITY_PLANNING),
      subMenu: [
        {
          name: t('menu-facility-planning', 'Reservations'),
          path: '/facilities/planning' + preferredStableUrlSuffix,
          isEnabled: hasPermission([ModulePermissionsEnum.MANAGE_FACILITIES]),
        },
        {
          name: t('menu-manage-facilities', 'Manage facilities'),
          path: '/facilities/manage',
          isEnabled: hasPermission([ModulePermissionsEnum.MANAGE_FACILITIES]),
        },
      ],
    });

    // food and medicine
    menuItems.push({
      name: t('menu-feeding', 'Feeding'),
      path: '/feed',
      icon: <Carrot />,
      isEnabled:
        hasPermission([ModulePermissionsEnum.VIEW_FEED, ModulePermissionsEnum.MANAGE_FEED]) &&
        hasModuleAccess(ModuleEnum.FEED_AND_MEDICINE),
      subMenu: [
        {
          name: t('menu-feed-schedule', 'Schedule'),
          path: '/feed/schedule' + preferredStableUrlSuffix,
          isEnabled: hasPermission([ModulePermissionsEnum.VIEW_FEED, ModulePermissionsEnum.MANAGE_FEED]),
        },
        {
          name: t('menu-settings', 'Settings'),
          path: '/feed/settings',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FEED),
        },
      ],
    });

    // availablitity
    // menuItems.push({
    //   name: t('menu-availability', 'Availability'),
    //   path: '/availability',
    //   icon: <CalendarCheck />,
    //   isEnabled: isAdmin,
    //   subMenu: [
    //     {
    //       name: t('menu-overview', 'Overview'),
    //       path: '/availability/overview',
    //       isEnabled: isAdmin,
    //     },
    //     {
    //       name: t('menu-settings', 'Settings'),
    //       path: '/availability/settings',
    //       isEnabled: isAdmin,
    //     },
    //   ],
    // });

    // breeding
    menuItems.push({
      name: t('menu-breeding', 'Breeding'),
      path: '/breeding',
      icon: <Dna />,
      isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_SEMEN_JOURNAL) && hasModuleAccess(ModuleEnum.SHOP_AND_BREEDING),
      subMenu: [
        {
          name: t('menu-mare-cycle-checks', 'Mare cycle checks'),
          path: '/breeding/mare-cycle-checks',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_SEMEN_JOURNAL),
        },
        {
          name: t('menu-semen-journal', 'Semen journal'),
          path: '/breeding/stallion-mounts',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_SEMEN_JOURNAL),
        },
        {
          name: t('menu-semen-inventory', 'Semen inventory'),
          path: '/breeding/inventory',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_SEMEN_JOURNAL),
        },
      ],
    });

    // orders
    menuItems.push({
      name: t('menu-orders', 'Orders'),
      path: '/shop',
      icon: <TrayArrowDown />,
      isEnabled:
        hasPermission([ModulePermissionsEnum.PICK_ORDERS, ModulePermissionsEnum.MANAGE_ORDERS]) &&
        hasModuleAccess(ModuleEnum.SHOP_AND_BREEDING),
      subMenu: [
        {
          name: t('menu-breeding-orders', 'Breeding orders'),
          path: '/shop/orders',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_ORDERS),
        },
        {
          name: t('menu-breeding-picklist', 'Breeding picklist'),
          path: '/shop/picklist',
          isEnabled: hasPermission(ModulePermissionsEnum.PICK_ORDERS),
        },
        {
          name: t('menu-settings', 'Settings'),
          path: '/shop/settings',
          isEnabled: isAdmin,
        },
      ],
    });

    // menuItems.push({
    //   name: t('menu-reservations', 'Reservations'),
    //   path: '/reservations',
    //   icon: <StackSimple />,
    //   isEnabled: isAdmin,
    // });

    // menuItems.push({
    //   name: t('menu-reports-and-communication', 'Reports and communication'),
    //   path: '/reports-and-communication',
    //   icon: <ChartLine />,
    //   isEnabled: isAdmin,
    //   subMenu: [
    //     {
    //       name: t('menu-overview', 'Overview'),
    //       path: '/reports-and-communication/overview',
    //       isEnabled: isAdmin,
    //     },
    //     {
    //       name: t('menu-settings', 'Settings'),
    //       path: '/reports-and-communication/settings',
    //       isEnabled: isAdmin,
    //     },
    //   ],
    // });

    menuItems.push({
      name: t('menu-financial-administration', 'Financial administration'),
      path: '/financial-administration',
      icon: <Wallet />,
      isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FINANCIAL) && hasModuleAccess(ModuleEnum.FINANCIAL_AND_PAYMENT),
      subMenu: [
        {
          name: t('menu-invoices', 'Invoices'),
          path: '/financial-administration/invoices',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FINANCIAL),
        },
        // {
        //   name: t('menu-services', 'Services'),
        //   path: '/financial-administration/services',
        //   isEnabled: isAdmin,
        // },
        // {
        //   name: t('menu-purchasing', 'Purchasing'),
        //   path: '/financial-administration/purchasing',
        //   isEnabled: isAdmin,
        // },
        {
          name: t('menu-payments', 'Payments'),
          path: '/financial-administration/payments',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FINANCIAL),
        },
        {
          name: t('menu-vat', 'VAT percentages'),
          path: '/financial-administration/vat',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FINANCIAL),
        },
        {
          name: t('settings', 'Settings'),
          path: '/financial-administration/settings',
          isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FINANCIAL),
        },
      ],
    });

    return menuItems;
  }, [t, hasPermission, hasModuleAccess, preferredStableUrlSuffix, reportCount, isAdmin, preferredPlanningGroupBy]);

  const EquinemSideBarBottomMenu = useMemo((): MenuItemConfig[] => {
    return [
      {
        name: t('menu-my-reservations', 'My reservations'),
        path: '/reservations',
        icon: <CalendarCheck />,
        isEnabled:
          hasPermission([ModulePermissionsEnum.PLAN_OWN_FACILITIES_EVENTS, ModulePermissionsEnum.MANAGE_FACILITIES]) &&
          hasModuleAccess(ModuleEnum.FACILITY_PLANNING),
      },
      {
        name: t('contacts', 'Contacts'),
        path: '/contacts/list',
        icon: <AddressBook />,
        isEnabled: hasPermission([ModulePermissionsEnum.MANAGE_CONTACTS, ModulePermissionsEnum.VIEW_CONTACTS]),
      },
      {
        name: t('menu-organization-admin', 'Organization admin'),
        path: '/admin',
        icon: <Wrench />,
        isEnabled: isAdmin,
        subMenu: [
          {
            name: t('menu-user', 'Users'),
            path: '/admin/users',
            isEnabled: isAdmin,
          },
          {
            name: t('menu-integrations', 'Integrations'),
            path: '/admin/integrations',
            isEnabled: isAdmin,
          },
          {
            name: t('manure', 'Manure'),
            path: '/admin/manure',
            isEnabled: isAdmin,
          },
          {
            name: t('menu-permissions', 'Permissions'),
            path: '/admin/permissions',
            isEnabled: isAdmin,
          },
          {
            name: t('manage-subscription', 'Subscription'),
            path: '/admin/subscription',
            isEnabled: isAdmin,
          },
          {
            name: t('settings', 'Settings'),
            path: '/admin/settings',
            isEnabled: isAdmin,
          },
        ],
      },
    ];
  }, [hasModuleAccess, hasPermission, isAdmin, t]);

  // Menu items for mobile navigation in the Equinem app
  const EquinemFooterMenu = useMemo((): MenuItemConfig[] => {
    return [
      {
        name: t('horses', 'Horses'),
        path: '/horses/list' + preferredStableUrlSuffix,
        icon: <Horse />,
        isEnabled: hasPermission([
          ModulePermissionsEnum.MANAGE_HORSES,
          ModulePermissionsEnum.VIEW_HORSES,
          ModulePermissionsEnum.VIEW_OWN_HORSES,
        ]),
      },
      {
        name: t('menu-my-planning', 'My day'),
        path: '/planning/my',
        icon: <Calendar />,
        isEnabled:
          hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE) &&
          hasModuleAccess([ModuleEnum.HORSE_SERVICES_CARE, ModuleEnum.HORSE_SERVICES_FULL]),
      },
      {
        name: t('contacts', 'Contacts'),
        path: '/contacts/list',
        icon: <AddressBook />,
        isEnabled: hasPermission([ModulePermissionsEnum.MANAGE_CONTACTS, ModulePermissionsEnum.VIEW_CONTACTS]),
      },
      {
        name: t('menu-reservations', 'Reservations'),
        path: '/reservations',
        icon: <CalendarCheck />,
        isEnabled:
          hasPermission([ModulePermissionsEnum.PLAN_OWN_FACILITIES_EVENTS, ModulePermissionsEnum.MANAGE_FACILITIES]) &&
          hasModuleAccess(ModuleEnum.FACILITY_PLANNING),
      },
    ];
  }, [t, preferredStableUrlSuffix, hasPermission, hasModuleAccess]);

  const WebshopSideBarTopMenu = useMemo((): MenuItemConfig[] => {
    return [
      {
        name: t('menu-mares', 'Mares'),
        path: webshopUrlPrefix + 'mares',
        icon: <Horse />,
        isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_ORDERS),
      },
      {
        name: t('menu-orders', 'Orders'),
        path: webshopUrlPrefix + 'orders',
        icon: <ShoppingCart />,
        isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_ORDERS),
      },
    ];
  }, [hasPermission, t, webshopUrlPrefix]);

  // Menu items for mobile navigation in the webshop
  const WebshopFooterMenu = useMemo((): MenuItemConfig[] => {
    return [
      {
        name: t('menu-mares', 'Mares'),
        path: webshopUrlPrefix + 'mares',
        icon: <Horse />,
        isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_ORDERS),
      },
      {
        name: t('menu-orders', 'Orders'),
        path: webshopUrlPrefix + 'orders',
        icon: <ShoppingCart />,
        isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_ORDERS),
      },
      {
        name: t('account', 'Account'),
        path: webshopUrlPrefix + 'profile',
        icon: <User />,
        isEnabled: true,
      },
    ];
  }, [hasPermission, t, webshopUrlPrefix]);

  return {
    EquinemSideBarTopMenu,
    EquinemSideBarBottomMenu,
    EquinemFooterMenu,
    WebshopSideBarTopMenu,
    WebshopFooterMenu,
  };
};

export default useMenu;
