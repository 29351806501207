import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';
import { ButtonVariant } from 'ui/Button';
import { useOrganization } from 'context/OrganizationContext';
import ApiErrorParser from 'api/ApiErrorParser';
import { ServiceContractsService } from 'openapi';
import { SubscriptionState } from './Helper';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSuccess: (hash: SubscriptionState) => void;
}

function StartFreeSubscriptionModal({ isVisible, onRequestCloseModal, onSuccess }: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<unknown> | undefined>();

  const { t } = useTranslation();
  const { refresh, selectedOrganizationUid } = useOrganization();

  const cancelSubscription = useCallback(async () => {
    if (!selectedOrganizationUid) return console.error('No organization found');

    setIsSubmitting(true);

    try {
      await ServiceContractsService.serviceContractsPurchasedContinueForFreeCreate({
        purchaserUid: selectedOrganizationUid,
      });

      // refresh the organization
      refresh();

      // fire the events
      onRequestCloseModal();
      onSuccess(SubscriptionState.SUBSCRIPTION_CREATED_FREE);
    } catch (e) {
      setApiError(new ApiErrorParser(e));
    } finally {
      setIsSubmitting(false);
    }
  }, [onRequestCloseModal, onSuccess, refresh, selectedOrganizationUid]);

  /**
   * Event that is fired when the modal is closed
   */
  const onClosed = () => {
    setApiError(undefined);
    setIsSubmitting(false);
  };

  return (
    <ActionModal
      onClosed={onClosed}
      open={isVisible}
      onClose={onRequestCloseModal}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestCloseModal,
        },
        {
          text: t('start-free-subscription', 'Start your free subscription'),
          loading: isSubmitting,
          variant: ButtonVariant.Primary,
          onClick: cancelSubscription,
        },
      ]}
      title={t('continue-with-free-subscription', 'Continue with free subscription')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2 w-full'>
        {t(
          'continue-free-subscription-desc',
          'You are about to continue with a free subscription. The free license includes the horse module with the horse profiles. All other functionality is disabled. However, if you want to use those features, you can upgrade your license at any time.',
        )}
      </p>

      <p className='mt-4'>{t('are-you-sure-you-want-to-continue', 'Are you sure you want to continue?')}</p>
    </ActionModal>
  );
}

export default StartFreeSubscriptionModal;
