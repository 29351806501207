import { useAccount } from 'context/AccountContext';
import { useConfig } from 'context/ConfigContext';
import { useOrganization } from 'context/OrganizationContext';
import { LoginType } from 'pages/Login';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

/**
 * Hook that redirects to the home page when the user is logged in.
 *
 * @param path
 */
function useRedirectToHomeWhenLoggedIn(loginType: LoginType): void {
  const { session } = useAccount();
  const navigate = useNavigate();
  const { selectedOrganizationDetails } = useOrganization();
  const { config } = useConfig();
  const [searchParams] = useSearchParams();

  /**
   * If we have a valid session, we can ensure the user is logged in.
   * Redirect to the home page for either the default App or the webshop.
   */
  useEffect(() => {
    if (loginType === LoginType.Webshop && selectedOrganizationDetails && config) {
      const webshopUrl = config.getWebshopRoute(selectedOrganizationDetails.public_access_uuid);

      if (session) {
        navigate(webshopUrl);
      }
    } else if (loginType === LoginType.Equinem) {
      if (session) {
        // detect if we have a redirect in the url
        const redirect = searchParams.has('redirect') ? `?redirect=${String(searchParams.get('redirect'))}` : '';
        navigate(`/${redirect}`);
      }
    }
  }, [config, loginType, navigate, session, selectedOrganizationDetails, searchParams]);
}

export default useRedirectToHomeWhenLoggedIn;
