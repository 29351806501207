/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ACCOUNTING` - Accounting
 * * `SHOP_AND_BREEDING` - Shop and breeding
 * * `FEED_AND_MEDICINE` - Feed and medicine
 * * `FACILITY_PLANNING` - Facility planning
 * * `FINANCIAL_AND_PAYMENT` - Financial and payment
 * * `USER_ACCOUNTS` - User accounts
 * * `HORSE_SERVICES_CARE` - Horse services, care
 * * `HORSE_SERVICES_FULL` - Horse services, full
 * * `RVO_REPORTS` - RvO reports
 */
export enum ModuleEnum {
  ACCOUNTING = 'ACCOUNTING',
  SHOP_AND_BREEDING = 'SHOP_AND_BREEDING',
  FEED_AND_MEDICINE = 'FEED_AND_MEDICINE',
  FACILITY_PLANNING = 'FACILITY_PLANNING',
  FINANCIAL_AND_PAYMENT = 'FINANCIAL_AND_PAYMENT',
  USER_ACCOUNTS = 'USER_ACCOUNTS',
  HORSE_SERVICES_CARE = 'HORSE_SERVICES_CARE',
  HORSE_SERVICES_FULL = 'HORSE_SERVICES_FULL',
  RVO_REPORTS = 'RVO_REPORTS',
}
