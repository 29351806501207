/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Feed } from '../models/Feed';
import type { FeedUpdate } from '../models/FeedUpdate';
import type { PaginatedFeedList } from '../models/PaginatedFeedList';
import type { PatchedEventDestroy } from '../models/PatchedEventDestroy';
import type { PatchedFeedUpdate } from '../models/PatchedFeedUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HorsefeedsService {
  /**
   * @returns PaginatedFeedList
   * @throws ApiError
   */
  public static horsefeedsList({
    organisationUid,
    dtendGt,
    dtstartLt,
    feedingUidIn,
    horseUidIn,
    o,
    page,
    pageSize,
    statusIn,
  }: {
    organisationUid: string,
    dtendGt?: string,
    dtstartLt?: string,
    /**
     * Multiple values may be separated by commas.
     */
    feedingUidIn?: Array<string>,
    /**
     * Multiple values may be separated by commas.
     */
    horseUidIn?: Array<string>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * Multiple values may be separated by commas.
     */
    statusIn?: Array<string>,
  }): CancelablePromise<PaginatedFeedList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/horsefeeds',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'dtend__gt': dtendGt,
        'dtstart__lt': dtstartLt,
        'feeding__uid__in': feedingUidIn,
        'horse__uid__in': horseUidIn,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'status__in': statusIn,
      },
    });
  }
  /**
   * @returns Feed
   * @throws ApiError
   */
  public static horsefeedsCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: Feed,
  }): CancelablePromise<Feed> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/horsefeeds',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Feed
   * @throws ApiError
   */
  public static horsefeedsRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<Feed> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/horsefeeds/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns FeedUpdate
   * @throws ApiError
   */
  public static horsefeedsUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: FeedUpdate,
  }): CancelablePromise<FeedUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/horsefeeds/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns FeedUpdate
   * @throws ApiError
   */
  public static horsefeedsPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedFeedUpdate,
  }): CancelablePromise<FeedUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/horsefeeds/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any No response body
   * @throws ApiError
   */
  public static horsefeedsDeletePartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedEventDestroy,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/horsefeeds/{uid}/delete',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
