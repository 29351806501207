import React, { ComponentProps, forwardRef, ForwardRefRenderFunction, ReactNode, useRef } from 'react';

interface Props extends ComponentProps<'div'> {
  children: ReactNode;
  enabled?: boolean;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

interface TouchTracking {
  start: number;
  x: number;
  xDiff: number;
  y: number;
  yDiff: number;
}

// A wrapper that detects touch gestures from phones.
const GestureWrapper: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { enabled = true, children, onSwipeLeft, onSwipeRight, ...props },
  ref,
) => {
  const instanceRef = useRef<TouchTracking | undefined>(undefined);
  return (
    <div
      ref={ref}
      onTouchStart={e => {
        if (!enabled) {
          return;
        }
        instanceRef.current = {
          start: Date.now(),
          x: e.touches[0].clientX,
          xDiff: 0,
          y: e.touches[0].clientY,
          yDiff: 0,
        };
      }}
      onTouchMove={e => {
        if (!enabled || !instanceRef.current) {
          return;
        }
        instanceRef.current.xDiff = instanceRef.current.x - e.touches[0].clientX;
        instanceRef.current.yDiff = instanceRef.current.y - e.touches[0].clientY;
      }}
      onTouchEnd={e => {
        if (!enabled || !instanceRef.current) {
          return;
        }
        const minDistance = 20;
        const timeout = 500;
        const maxDistance = Infinity;

        const timeDiff = Date.now() - instanceRef.current.start;
        const xDiffAbs = Math.abs(instanceRef.current.xDiff);
        const yDiffAbs = Math.abs(instanceRef.current.yDiff);

        if (xDiffAbs > yDiffAbs) {
          if (xDiffAbs >= minDistance && xDiffAbs <= maxDistance && timeDiff <= timeout) {
            // Prevent other swipeables
            e.stopPropagation();
            if (instanceRef.current.xDiff > 0) {
              onSwipeLeft?.();
            } else {
              onSwipeRight?.();
            }
          }
          // Vertical swipe
        }
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default forwardRef<HTMLDivElement, Props>(GestureWrapper);
