import React, { useCallback } from 'react';
import { ModuleEnum, PurchaserServiceContract } from 'openapi';
import { useOrganization } from 'context/OrganizationContext';
import { IconContext, Lock } from '@phosphor-icons/react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import usePermissions from './UsePermissions';
import { Page } from 'ui/Layout';
import { getModuleIcon, getModuleNameFromModuleEnum } from 'components/Subscription/Helper';

interface ReturnType {
  hasModuleAccess: (moduleType: ModuleEnum | ModuleEnum[]) => boolean;
  noAccessElement: (moduleType: ModuleEnum | ModuleEnum[]) => JSX.Element;
  selectedOrganizationServiceContracts: PurchaserServiceContract[] | undefined;
}

/**
 * Hook to check if the user has access to a specific module.
 */
const useModuleAccess = (): ReturnType => {
  const { selectedOrganizationServiceContracts, selectedOrganizationDetails } = useOrganization();
  const { t } = useTranslation();
  const { isAdmin } = usePermissions();

  /**
   * Check if the user has access to a specific module.
   */
  const hasModuleAccess = useCallback(
    (moduleType: ModuleEnum | ModuleEnum[]) => {
      let hasAccess = false;

      // check if the user has access to the module from a list of modules
      if (Array.isArray(moduleType)) {
        hasAccess = moduleType.some(mod => selectedOrganizationServiceContracts?.find(contract => contract.module === mod) !== undefined);
      } else {
        // single module check
        hasAccess = selectedOrganizationServiceContracts?.find(contract => contract.module === moduleType) !== undefined;
      }

      // for some users there may not be any service contracts as we are still in a migration phase from the old style to the new style
      // so these users has the flag is_paid even when they don't have any service contracts
      // so by checking is_paid flag we can determine if the user has access to the module
      // however, there is one exception to the rule, for "breeding and shop" the user needs to have a service contract
      if (selectedOrganizationDetails?.is_paid && !hasAccess && moduleType !== ModuleEnum.SHOP_AND_BREEDING) {
        hasAccess = true;
      }

      // regular check
      return hasAccess;
    },
    [selectedOrganizationDetails?.is_paid, selectedOrganizationServiceContracts],
  );

  /**
   * Element to show when the user doesn't have access to a specific module.
   */
  const noAccessElement = useCallback(
    (moduleType: ModuleEnum | ModuleEnum[]) => {
      const icon = getModuleIcon(Array.isArray(moduleType) ? moduleType[0] : moduleType);
      const moduleName = getModuleNameFromModuleEnum(Array.isArray(moduleType) ? moduleType[0] : moduleType, t);

      if (!hasModuleAccess(moduleType)) {
        return (
          <Page title={moduleName}>
            <div className='flex flex-col items-center'>
              <div className='relative rounded-full bg-zinc-100 border p-5 mb-10'>
                <IconContext.Provider
                  value={{
                    size: 90,
                    weight: 'light',
                  }}
                >
                  {icon}
                </IconContext.Provider>
                <Lock size={30} weight='fill' className='text-primary absolute bottom-1 left-1' />
              </div>

              <h2 className='font-bold text-lg my-2'>
                {t('no-access-to-module-name', 'No access to the {{moduleName}} module', {
                  moduleName,
                })}
              </h2>

              <p className='max-w-lg text-center'>
                {t(
                  'no-access-to-module-desc',
                  'You don`t have access to this module. In order to access this module you need to upgrade your subscription.',
                )}
              </p>

              {isAdmin && (
                <p className='max-w-lg text-center mt-4'>
                  <Trans
                    i18nKey='no-access-to-module-upgrade-desc-admin'
                    defaults='Navigate to the <0>subscription page</0> to upgrade your subscription to include the <strong>{{moduleName}}</strong> module.'
                    values={{
                      moduleName,
                    }}
                    components={[
                      <Link to='/admin/subscription' key='link' className='text-blue-500 underline'>
                        subscription page
                      </Link>,
                    ]}
                  />
                </p>
              )}

              {!isAdmin && (
                <p className='max-w-lg text-center mt-4'>
                  {t(
                    'no-access-to-module-upgrade-desc-user',
                    'Please ask you administrator to upgrade your subscription to include the {{moduleName}} module.',
                    {
                      moduleName,
                    },
                  )}
                </p>
              )}
            </div>
          </Page>
        );
      }

      return <></>;
    },
    [hasModuleAccess, isAdmin, t],
  );

  return {
    noAccessElement,
    hasModuleAccess,
    selectedOrganizationServiceContracts,
  };
};

export default useModuleAccess;
